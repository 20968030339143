import { SedesUsuarioModel, EmpresasUsuarioModel } from './../../../models/login/usuarioLogin.model';
import { Component, Inject, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login/login.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-login-sede',
  templateUrl: './login-sede.component.html',
  styleUrls: ['./login-sede.component.scss']
})
export class LoginSedeComponent implements OnInit {
  empresaElegida: EmpresasUsuarioModel;
  barravisible: null;
  constructor(
    private loginServ: LoginService,
    @Inject(MAT_DIALOG_DATA) public data: { empresas: EmpresasUsuarioModel[] }
  ) { }

  ngOnInit(): void {
    if (this.data.empresas.length === 1) {
      this.empresaElegida = this.data.empresas[0];
    }
  }

  cambiarSeleccion(indice) {
    this.barravisible = indice;
  }

  cambiarSalida(indice) {
    this.barravisible = null;
  }

  public elegirEmpresa(empElegida: EmpresasUsuarioModel) {
    this.empresaElegida = empElegida;
  }
  public elegirSede(subElegido: SedesUsuarioModel) {
    this.loginServ.sedeLoginEvent.emit(subElegido);
  }
  public elegirPrimero() {
    if (!this.empresaElegida) {
      this.empresaElegida = this.data.empresas[0];
    } else {
      this.loginServ.sedeLoginEvent.emit(this.empresaElegida.sedes[0]);
    }
  }
}
