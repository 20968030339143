import { Component, OnInit ,Input} from '@angular/core';
import { LoginService } from 'src/app/services/login/login.service';
import { Router } from '@angular/router';
import { TurnosService } from 'src/app/services/turnos/turnos.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
  @Input() isMain = false; 
  
  constructor(public loginServ: LoginService,private turnosServ: TurnosService,
    public route: Router,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {

  }
  public cambiarSede() {
    this.route.navigate(['/principal'], { skipLocationChange: true });
    this.loginServ.cambiarSedeEvent.emit();
  }
  public irEmpresa() {
    if (!this.route.url.startsWith('/empresa')) {
      this.route.navigate([this.loginServ.empresaRutaSegunPriv()], { skipLocationChange: true });
    }
  }
  public irPerfil() {
    if (!this.route.url.startsWith('/usuario')) {
      this.route.navigate(['/usuario'], { skipLocationChange: true });
    }
  }
  public Logout() {
    this.route.navigate(['/principal'], { skipLocationChange: true });
    this.loginServ.logOutEvent.emit();
  }
  enviarMensajesPendientes() {
    this.turnosServ.enviarMensajesPendientes().subscribe((response) => {
      this.snackBar.open(response.message);
    });
  }
}
