<!-- <div *ngIf="item.tipoAccion!==acciones.SIN_ACCIONES"> -->
<button mat-button [ngClass]="small?'ABM-modificar-small':'ABM-modificar'"
  *ngIf="item.tipoAccion!==acciones.SIN_ACCIONES && mostrarEditar && item.tipoAccion!==acciones.ACTIVAR"
  (click)="update(item)" tabindex="-1">EDITAR</button>
<button mat-button [ngClass]="small?'ABM-eliminar-small':'ABM-eliminar'"
  *ngIf="item.tipoAccion!==acciones.SIN_ACCIONES && mostrarActivar && item.tipoAccion===acciones.DESACTIVAR"
  (click)="desactivar(item)" tabindex="-1">DESACTIVAR</button>
<!-- <button mat-button [ngClass]="small?'ABM-modificar-small':'ABM-eliminar'" *ngIf="mostrarEditar && item.tipoAccion!==acciones.VER" (click)="ver2(item)" tabindex="-1" style="font-size: 10px;color:red">VER</button> -->
<!-- <button mat-button [ngClass]="small?'ABM-modificar-small':'ABM-modificar'" *ngIf="mostrarVer && item.tipoAccion===acciones.ACTIVAR " (click)="ver2()"
    tabindex="-1">VER</button> -->
<button mat-button [ngClass]="small?'ABM-modificar-small':'ABM-modificar'"
  *ngIf="item.tipoAccion!==acciones.SIN_ACCIONES && mostrarActivar && item.tipoAccion===acciones.ACTIVAR"
  (click)="activar(item)" tabindex="-1">ACTIVAR</button>
<button mat-button [ngClass]="small?'ABM-eliminar-small':'ABM-eliminar'"
  *ngIf="item.tipoAccion!==acciones.SIN_ACCIONES && mostrarEliminar" (click)="eliminar()"
  tabindex="-1">ELIMINAR</button>
<!-- </div> -->
