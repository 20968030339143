import { TelefonoModel } from '../../../models/shared/telefono.model';
import { Component, OnInit, Inject, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AppConfig } from 'src/app/config/config';
import { SelectsModel } from 'src/app/models/shared/selects.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-modal-telefono',
  templateUrl: './modal-telefono.component.html',
  styleUrls: ['./modal-telefono.component.scss']
})
export class ModalTelefonoComponent implements OnInit, AfterViewInit {
  telefonoForm: UntypedFormGroup;

  @ViewChild('foco') campoFoco: ElementRef;

  constructor(
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ModalTelefonoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { telUpdate: TelefonoModel, datosSelect: SelectsModel }
  ) { }

  ngOnInit() {
    this.telefonoForm = new UntypedFormGroup({
      tipoTel: new UntypedFormControl(this.data.telUpdate.tipo, [Validators.required]),
      nroTel: new UntypedFormControl(this.data.telUpdate.numero, [
        Validators.required,
        Validators.pattern(AppConfig.PATTERN_NUMBERS),
        Validators.maxLength(50)
      ])
    });
  }
  ngAfterViewInit(): void {
    setTimeout(_ => this.campoFoco.nativeElement.focus());
  }
  public guardar() {
    if (!this.telefonoForm.valid) {
      Object.keys(this.telefonoForm.controls).forEach(key => {
        this.telefonoForm.get(key).markAsTouched();
      });
      this.snackBar.open('Por favor verifique los datos ingresados', 'OK');
      return;
    }
    this.data.telUpdate.numero = this.telefonoForm.get('nroTel').value;
    this.data.telUpdate.tipo = this.telefonoForm.get('tipoTel').value;
    this.data.telUpdate.tipoStr = this.data.datosSelect.tiposTel.find(x => x.id === this.telefonoForm.get('tipoTel').value).nombre;
    this.dialogRef.close(this.data.telUpdate);
  }
}
