import { SelectObjModel } from './../models/shared/selects.model';
import { ArrayHelperService } from './../services/helpers/array-helper.service';
import { Pipe, PipeTransform, NgModule } from '@angular/core';
import { PrivilegiosEnum } from '../models/shared/privilegios.enum';
import { LoginService } from '../services/login/login.service';
import { ElegirAgendaModel } from '../models/turnos/elegir-agenda.model';

@Pipe({
  name: 'tienePrivilegio'
})
export class TienePrivilegioPipe implements PipeTransform {
  constructor(private loginServ: LoginService, private ArrayHelper: ArrayHelperService) { }
  transform(privilegio: PrivilegiosEnum): boolean {
    if (this.loginServ.UsuarioLogueado.roles) {
      if (this.loginServ.UsuarioLogueado.roles.findIndex(x => x.esAdmin === true) === -1) {
        if (this.ArrayHelper.selectMany(this.loginServ.UsuarioLogueado.roles, 'privilegios')
          .findIndex(z => z === privilegio) > -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
}
@Pipe({
  name: 'esAdmin'
})
export class EsAdminPipe implements PipeTransform {
  constructor(private loginServ: LoginService) { }
  transform(): boolean {
    return this.loginServ.esAdmin();
  }
}
@Pipe({
  name: 'puedeConfigEmpresa'
})
export class PuedeConfigEmpresaPipe implements PipeTransform {
  constructor(private loginServ: LoginService, private ArrayHelper: ArrayHelperService) { }
  transform(): boolean {
    if (this.loginServ.UsuarioLogueado.roles) {
      if (this.loginServ.UsuarioLogueado.roles.findIndex(x => x.esAdmin === true) === -1) {
        const privilegios = this.ArrayHelper.selectMany(this.loginServ.UsuarioLogueado.roles, 'privilegios');
        if (
          privilegios.findIndex(z => z === this.loginServ.privilegios.LISTA_DE_ROLES) > -1 ||
          privilegios.findIndex(z => z === this.loginServ.privilegios.LISTA_PERSONAL) > -1 ||
          privilegios.findIndex(z => z === this.loginServ.privilegios.LISTA_DE_AGENDAS) > -1 ||
          privilegios.findIndex(z => z === this.loginServ.privilegios.LISTA_DE_OFERTA_PRESTACIONAL) > -1 ||
          privilegios.findIndex(z => z === this.loginServ.privilegios.LISTA_DE_ESPECIALIDADES) > -1 ||
          privilegios.findIndex(z => z === this.loginServ.privilegios.LISTA_DE_BANCOS) > -1 ||
          privilegios.findIndex(z => z === this.loginServ.privilegios.LISTA_DE_TARJETAS) > -1
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
}
@Pipe({
  name: 'permitirCambiarAgenda'
})
export class PermitirCambiarAgendaPipe implements PipeTransform {
  constructor(private loginServ: LoginService, private ArrayHelper: ArrayHelperService) { }
  transform(agendaElegida: ElegirAgendaModel, agendaItem: ElegirAgendaModel): boolean {
    if (agendaItem == null || (agendaItem !== agendaElegida)) {
      return false;
    } else if (this.loginServ.UsuarioLogueado.roles.findIndex(x => x.esAdmin === true) > -1 ||
      this.ArrayHelper.selectMany(this.loginServ.UsuarioLogueado.roles, 'privilegios')
        .findIndex(z => z === PrivilegiosEnum.VER_TODAS_AGENDAS) > -1) {
      return true;
    } else if (this.loginServ.UsuarioLogueado.datosAgenda) {
      return false;
    }
  }
}

@Pipe({
  name: 'filtrarPrivilegiosExistentes'
})
export class FiltrarPrivilegiosExistentesPipe implements PipeTransform {
  transform(privilegios: SelectObjModel[], privilegiosExisten: SelectObjModel[]): SelectObjModel[] {
    return privilegios.filter(x => privilegiosExisten.findIndex(y => y.id === x.id) === -1);
  }
}

@Pipe({
  name: 'recordatoriosPrivilegio'
})
export class RecordatoriosPrivilegioPipe implements PipeTransform {
  constructor(private loginServ: LoginService, private ArrayHelper: ArrayHelperService) { }
  transform(recordatorios: SelectObjModel[]): SelectObjModel[] {
    if (this.loginServ.UsuarioLogueado.roles.findIndex(x => x.esAdmin === true) === -1) {
      if (this.ArrayHelper.selectMany(this.loginServ.UsuarioLogueado.roles, 'privilegios')
        .findIndex(z => z === this.loginServ.privilegios.VISUALIZAR_RECORDATORIOS_DEL_PACIENTE) > -1) {
        return recordatorios;
      } else {
        return new Array<SelectObjModel>();
      }
    } else {
      return recordatorios;
    }
  }
}

@NgModule({
  declarations: [
    TienePrivilegioPipe,
    EsAdminPipe,
    PuedeConfigEmpresaPipe,
    PermitirCambiarAgendaPipe,
    FiltrarPrivilegiosExistentesPipe,
    RecordatoriosPrivilegioPipe
  ],
  exports: [
    TienePrivilegioPipe,
    EsAdminPipe,
    PuedeConfigEmpresaPipe,
    PermitirCambiarAgendaPipe,
    FiltrarPrivilegiosExistentesPipe,
    RecordatoriosPrivilegioPipe
  ]
})
export class PrivilegiosPipeModule { }
