import { PacienteDocModel } from './abm-paciente.model';

export class PacienteFiltro {
  id: number;
  apellido: string;
  nombre: string;
  tipoDNI: number;
  numeroDNI: string;
  mail: string;
  tiempoVisita: Date;
  cumpleanos: string;
  edad: number;
  fechaCumpleanos: Date;
  constructor() { }
}
export class PacienteFiltroLista {
  listaPacientesFiltro: PacienteFiltro[];
  totalRecords:number;
  constructor() {
    this.listaPacientesFiltro = new Array<PacienteFiltro>();
  }
}
export class PacienteDeudaModel {
  id: number;
  apellido: string;
  nombre: string;
  dni: string;
  mail: string;
  telefono: string;
  deuda: number;
  constructor() { }
}

export class PacienteMailDeudaConsultaModel {
  usuarioId: number;
  deuda: number;
  constructor() { }
}
export class PacienteVerificarMailConsultaModel{
  usuarioId: number;
  email: string;
  constructor(){ }
}
export class PacienteFiltroQueryParams {
  datosBusqueda: string;
  pageNumber: number;
  pageSize: number;

  constructor(datosBusqueda: string = '', pageNumber: number = 1, pageSize: number = 15) {
    this.datosBusqueda = datosBusqueda;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
  }
}



