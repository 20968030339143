import { environment } from 'src/environments/environment';

export class CajaApiConfig {
  // CAJA
  public static CAJA_TIENE_PRIVILEGIO_URL = environment.apiUrl + 'Caja/TienePrivilegio';
  public static CAJA_ABRIR_URL = environment.apiUrl + 'Caja/Abrir';
  public static CAJA_CERRAR_URL = environment.apiUrl + 'Caja/Cerrar';
  public static CAJA_AUDITAR_URL = environment.apiUrl + 'Caja/Auditar';
  public static CAJA_USUARIO_CAJA_ABIERTA_URL = environment.apiUrl + 'Caja/UsuarioCajaAbierta';
  public static CAJA_LISTA_CAJAS_URL = environment.apiUrl + 'Caja/ListaCajas';
  public static CAJA_VER_URL = environment.apiUrl + 'Caja/Ver';
  public static CAJA_DESCARGAR_URL = environment.apiUrl + 'Caja/Descargar';
  public static CAJA_DESCARGAR_LIQUIDACION_URL = environment.apiUrl + 'Caja/DescargarLiquidacion';
  public static CAJA_DESCARGAR_LIQUIDACION_OS_URL = environment.apiUrl + 'Caja/DescargarLiquidacionOS';
  public static CAJA_NUEVO_MOVIMIENTO_URL = environment.apiUrl + 'Caja/NuevoMovimiento';
  public static CAJA_BUSCAR_COMPROBANTES_URL = environment.apiUrl + 'Caja/BuscarComprobantes';
  public static CAJA_BUSCAR_CAJAS_CONTROL_URL = environment.apiUrl + 'Caja/BuscarCajasControl';
  // CLIENTE
  public static CLIENTE_UNO_URL = environment.apiUrl + 'Cliente/Uno';
  public static CLIENTE_LIST_URL = environment.apiUrl + 'Cliente/List';
  public static CLIENTE_ELIMINAR_URL = environment.apiUrl + 'Cliente/Eliminar';
  public static CLIENTE_ACTIVAR_URL = environment.apiUrl + 'Cliente/Activar';
  public static CLIENTE_DESACTIVAR_URL = environment.apiUrl + 'Cliente/Desactivar';
  public static CLIENTE_ACTUALIZAR_URL = environment.apiUrl + 'Cliente/Actualizar';
  public static CLIENTE_BUSQUEDA_SELECT_URL = environment.apiUrl + 'Cliente/BusquedaSelect';
  public static CLIENTE_ACTIVAR_TEL_URL = environment.apiUrl + 'Cliente/ActivarTelefono';
  public static CLIENTE_DESACTIVAR_TEL_URL = environment.apiUrl + 'Cliente/DesactivarTelefono';
  // ACREEDOR
  public static ACREEDOR_UNO_URL = environment.apiUrl + 'Acreedor/Uno';
  public static ACREEDOR_LIST_URL = environment.apiUrl + 'Acreedor/List';
  public static ACREEDOR_ELIMINAR_URL = environment.apiUrl + 'Acreedor/Eliminar';
  public static ACREEDOR_ACTIVAR_URL = environment.apiUrl + 'Acreedor/Activar';
  public static ACREEDOR_DESACTIVAR_URL = environment.apiUrl + 'Acreedor/Desactivar';
  public static ACREEDOR_ACTUALIZAR_URL = environment.apiUrl + 'Acreedor/Actualizar';
  public static ACREEDOR_BUSQUEDA_SELECT_URL = environment.apiUrl + 'Acreedor/BusquedaSelect';
  public static ACREEDOR_ACTIVAR_TEL_URL = environment.apiUrl + 'Acreedor/ActivarTelefono';
  public static ACREEDOR_DESACTIVAR_TEL_URL = environment.apiUrl + 'Acreedor/DesactivarTelefono';
  // CUENTA
  public static CUENTA_LIST_URL = environment.apiUrl + 'Cuenta/List';
  public static CUENTA_DESACTIVAR_URL = environment.apiUrl + 'Cuenta/Desactivar';
  public static CUENTA_ACTIVAR_URL = environment.apiUrl + 'Cuenta/Activar';
  public static CUENTA_UNO_URL = environment.apiUrl + 'Cuenta/Uno';
  public static CUENTA_ACTUALIZAR_URL = environment.apiUrl + 'Cuenta/Actualizar';
  public static CUENTA_NOMBRE_EXISTE_URL = environment.apiUrl + 'Cuenta/NombreExiste';
}
