import { NativeDateAdapter, MatDateFormats } from '@angular/material/core';
import { Injectable } from '@angular/core';

export const MAT_CUSTOM_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'dd/MM/yyyy',
  },
  display: {
    dateInput: 'dd/MM/yyyy',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
  parse(value: any): Date | null {
    if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
      const str = value.split('/');
      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);
      return new Date(year, month, date);
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }
  format(date: Date, displayFormat: any): string {
    switch (displayFormat) {
      default:
      case MAT_CUSTOM_DATE_FORMATS.parse.dateInput:
      case MAT_CUSTOM_DATE_FORMATS.display.dateInput:
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return this._to2digit(day) + '/' + this._to2digit(month) + '/' + year;
      case MAT_CUSTOM_DATE_FORMATS.display.monthYearLabel:
        return date.toLocaleDateString('es-AR', { month: 'short', year: 'numeric' }); // + '/' + year;
      case MAT_CUSTOM_DATE_FORMATS.display.dateA11yLabel:
        return date.toLocaleDateString('es-AR');
      case MAT_CUSTOM_DATE_FORMATS.display.monthYearA11yLabel:
        return date.toLocaleDateString('es-AR', { month: 'long', year: 'numeric' }); // + '/' + year;
    }
  }

  private _to2digit(n: number) {
    return ('00' + n).slice(-2);
  }
}
