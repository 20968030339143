import { eventNames } from 'process';
import { environment } from 'src/environments/environment';

export class PacienteApiConfig {
  // PACIENTE
  public static PACIENTE_UNO_URL = environment.apiUrl + 'Paciente/Uno';
  public static PACIENTE_UNO_HEADER_URL = environment.apiUrl + 'Paciente/UnoHeader';
  public static PACIENTE_LIST_URL = environment.apiUrl + 'Paciente/List';
  public static PACIENTE_ELIMINAR_URL = environment.apiUrl + 'Paciente/EliminarPaciente';
  public static PACIENTE_UPDATE_URL = environment.apiUrl + 'Paciente/ActualizarPaciente';
  public static PACIENTE_TELEFONO_UPDATE_URL = environment.apiUrl + 'Paciente/ActualizarTelefono';
  public static PACIENTE_TELEFONO_ELIMINAR_URL = environment.apiUrl + 'Paciente/EliminarTelefono';
  public static PACIENTE_TELEFONO_DESACTIVAR_URL = environment.apiUrl + 'Paciente/DesactivarTelefono';
  public static PACIENTE_TELEFONO_ACTIVAR_URL = environment.apiUrl + 'Paciente/ActivarTelefono';
  public static PACIENTE_DIRECCION_UPDATE_URL = environment.apiUrl + 'Paciente/ActualizarDireccion';
  public static PACIENTE_DIRECCION_ELIMINAR_URL = environment.apiUrl + 'Paciente/EliminarDireccion';
  public static PACIENTE_DIRECCION_DESACTIVAR_URL = environment.apiUrl + 'Paciente/DesactivarDireccion';
  public static PACIENTE_DIRECCION_ACTIVAR_URL = environment.apiUrl + 'Paciente/ActivarDireccion';
  public static PACIENTE_PLAN_UPDATE_URL = environment.apiUrl + 'Paciente/ActualizarPlan';
  public static PACIENTE_PLAN_DESACTIVAR_URL = environment.apiUrl + 'Paciente/DesactivarPlan';
  public static PACIENTE_PLAN_ACTIVAR_URL = environment.apiUrl + 'Paciente/ActivarPlan';
  public static PACIENTE_PLAN_ELIMINAR_URL = environment.apiUrl + 'Paciente/EliminarPlan';
  public static PACIENTE_NRODOCEXISTE_URL = environment.apiUrl + 'Paciente/NroDocExiste';
  public static PACIENTE_EMAILEXISTE_URL = environment.apiUrl + 'Paciente/EmailExiste';
  public static PACIENTE_PORDOC_URL = environment.apiUrl + 'Paciente/PorDoc';
  public static PACIENTE_BUSQUEDA_SELECT_URL = environment.apiUrl + 'Paciente/BusquedaSelect';

  public static PACIENTE_QUERY_SOLO_PTE_URL = environment.apiUrl + 'Paciente/QuerySoloPte';

  public static PACIENTE_SELECT_URL = environment.apiUrl + 'Paciente/PacienteSelect';
  public static PACIENTE_BUSQUEDA_LIST_URL = environment.apiUrl + 'Paciente/BusquedaList';
  public static PACIENTE_HAY_PACIENTES_BUSQUEDA_URL = environment.apiUrl + 'Paciente/HayPacientesBusqueda';
  public static PACIENTE_UNO_SIN_AUTH_URL = environment.apiUrl + 'Paciente/UnoSinAuth';
  // RECORDATORIOS
  public static RECORDATORIO_UNO_URL = environment.apiUrl + 'Recordatorio/Uno';
  public static RECORDATORIO_LIST_URL = environment.apiUrl + 'Recordatorio/Listar';
  public static RECORDATORIO_ELIMINAR_URL = environment.apiUrl + 'Recordatorio/Eliminar';
  public static RECORDATORIO_UPDATE_URL = environment.apiUrl + 'Recordatorio/Actualizar';
  //BUSCAR PACIENTE POR FILTRO
  public static PACIENTE_BUSCAR_POR_FILTRO = environment.apiUrl + 'Paciente/PacienteFiltro';
  //ENVIAR MAIL DE CUMPLEAÑOS
  public static PACIENTE_MAIL_POR_CUMPLEANOS = environment.apiUrl + 'Paciente/PacienteCumpleanos';
  //PACIENTE DEUDAS
  public static PACIENTE_DEUDA_URL = environment.apiUrl + 'Paciente/PacienteDeuda';
  public static PACIENTE_MAIL_DEUDA_URL = environment.apiUrl + 'Paciente/PaciEnviarMailDeuda';
  //VerificarSiExisteMail
  public static PACIENTE_VERIFICAR_SI_EXISTE_EMAIL = environment.apiUrl + 'Paciente/ValidarMailPersona';
  public static PACIENTE_VERIFICAR_EMAIL_WIZARD_URL = environment.apiUrl + 'Paciente/ValidarMailWizard';

}
