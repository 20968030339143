export enum ModulosModalEnum {
  ORDEN_ATENCION = 0,
  RECORDATORIOS,
  HISTORIA_CLINICA,
  CTA_CTE,
  HC_EVO_NUEVO_CERTIFICADO,
  HC_COMENTARIOS,
  HC_TRATAMIENTO,
  NUEVA_CIRUGIA
}
export enum ModulosModalUpdateEnum {
  PACIENTE = 0,
  CAJA_CUENTA,
  HC_EVO_NUEVA_PRESCRIPCION,
  HC_EVO_NUEVO_ESTUDIO,
  HC_NUEVO_Nuevo_Certificado_Tratamiento,
  NUEVA_CIRUGIA
}
export enum ModulosModalPrintEnum {
  RECIBO = 0,
  PAGARE,
  RESUMEN_COLEGIO_ODONTOLOGICO,
  RESUMEN_GENERAL,
  HC_ESTUDIO,
  HC_CERTIFICADO,
  HC_RECETA,
  HC_INDICACIONES,
  ODONTOGRAMA_PRINT,
  FACTURA_ELECTRONICA,
  EVOLUCION_LIST
}
export enum ModulosCtaCteEnum {
  DETALLE = 0,
  PAGARE,
  DEPOSITO_GARANTIA,
  COMPROBANTE,
  FACTURA_ELECTRONICA
}
export enum ModulosHCEnum {
  EVOLUCION = 0,
  REFERENCIAS,
  ODONTOGRAMA,
  PRESCRIPCIONES,
  ESTUDIOS,
  EST_NUTRICIONAL,
  ANTROPOMETRIA,
  IMC,
  DIETARIO,
  REF_NUTRICIONAL,
  ESTUDIOS_BIOQUIMICO,
  VADEMECUM_NUTRICIONAL
}
export enum ModulosTurnosTipoVista {
  DIA = 0,
  SEMANA,
  MES
}
