import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberNegativo'
})
export class NumberNegativoPipe implements PipeTransform {
  transform(numero: number) {
    if (numero) {
      const saldo = numero;
      return saldo.toLocaleString('es-AR', { minimumIntegerDigits: 1, useGrouping: true, minimumFractionDigits: 2 });
    } else {
      return '0,00';
    }
  }
}



@NgModule({
  declarations: [
    NumberNegativoPipe,

  ],
  exports: [
    NumberNegativoPipe,

  ]
})
export class NumberNegativoPipesModule { }
