import { Directive, ElementRef, OnDestroy } from '@angular/core';
import * as textMask from 'vanilla-text-mask/dist/vanillaTextMask.js';

@Directive({
  selector: '[appMaskNroPtoVta]'
})
export class MaskNroPtoVtaDirective implements OnDestroy {

  mask = [/\d/, /\d/, /\d/, /\d/]; // 0000
  maskedInputController;

  constructor(private element: ElementRef) {
    this.maskedInputController = textMask.maskInput({
      inputElement: this.element.nativeElement,
      mask: this.mask,
      keepCharPositions: true,
      showMask: true
    });
  }

  ngOnDestroy() {
    this.maskedInputController.destroy();
  }

}
