
export enum TipoSesionEnum {
  BROWSER = 0,
  ELECTRON,
  MOBILE
}
export enum EspecialidadesEnum {
  ODONTOLOGIA = 0,
  MEDICINA,
  PSICOLOGIA,
  PSIQUIATRIA
}
export enum TemasEnum {
  LIGHT = 0,
  DARK
}
export enum PrioridadEnum {
  ALTA = 0,
  BAJA = 2
}
export enum TriageEnum {
  REANIMACION = 0,
  EMERGENCIA,
  URGENCIA,
  PRIORITARIO,
  NO_URGENTE,
}
export enum MedioPagoEnum {
  EFECTIVO = 0,
  TARJETA_DEBITO,
  TARJETA_CREDITO,
  CUPON,
  CUENTA_CORRIENTE,
  TRANSFERENCIA_BANCARIA,
  CHEQUE,
  DEPOSITO_GARANTIA,
  DOLAR_BILLETE,
  DOLAR_CONVERTIDO
}
export enum TipoComprobanteEnum {
  RECIBO = 0,
  FACTURA,
  NOTACREDITO,
  NOTADEBITO,
  COMPROBANTEORDEN
}
export enum LetraComprobanteEnum {
  A = 0,
  B,
  C,
  X
}
export enum OrigenModalPagoEnum {
  COBRANZAORDEN = 0,
  NUEVOMOVIMIENTOCAJA,
  CTACTECUENTAS,
  CTACTEORDENES,
  CONCILIACION_PAGO
}
export enum TipoAccionABMEnum {
  DESACTIVAR = 0,
  ACTIVAR,
  ELIMINAR,
  SIN_ACCIONES
}
export enum TipoBuscadorAutoCompleteEnum {
  PACIENTE = 0,
  PERSONAL,
  CLIENTE,
  ACREEDOR,
  OBRASOCIAL,
  AGENDA,
  MEDICAMENTO,
  CIE10,
  MONODROGA,
  ALIMENTOS,
  EMPRESA,
  LUGAR,
  DiagnosticoProblemaofertaTexto,
  PRESTRACION,
}
export enum AuditoriaEstadoEnum {
  TODOS = 0,
  AUDITADAS,
  NO_AUDITADAS
}
export enum PrescripcionFrecuenciaTipoEnum {
  MINUTOS = 0,
  HORAS,
  DIAS,
  SEMANAS,
  MESES,
  AÑO
}
export enum PrescripcionDuracionTipoEnum {
  HORAS = 0,
  DIAS,
  SEMANAS,
  MESES
}
export enum TipoCobranzaOrdenEnum {
  CTACTE = 0,
  CUPON,
  COBRANZA,
  CTACTEDOL
}
export enum EstadoCivilEnum {
  SOLTERO = 0,
  EN_CONVIVENCIA,
  CASADO,
  VIUDO,
  DIVORCIADO
}
export enum TipoParentescoEnum {
  TITULAR = 0,
  CONYUGUE,
  HIJO,
  ADICIONAL
}
export enum TipoUndiadMedidaEnum {
  GR = 0,
  ML,
  U
}
