import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-confirmar',
  templateUrl: './modal-confirmar.component.html',
  styleUrls: ['./modal-confirmar.component.scss']
})
export class ModalConfirmarComponent implements OnInit {
  public accionEvent = new EventEmitter<boolean>();
  accion = 'borrar';
  mensaje = '¿Esta seguro que desea borrar?';
  constructor(
    public dialogRef: MatDialogRef<ModalConfirmarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { accionInject: string, mensajeInject: string }) {
    if (this.data) {
      if (this.data.accionInject && this.data.accionInject !== '') {
        this.accion = this.data.accionInject;
      }
      if (this.data.mensajeInject && this.data.mensajeInject !== '') {
        this.mensaje = this.data.mensajeInject;
      }
    }
  }

  ngOnInit() {
  }
  public onConfirm(): void {
    this.accionEvent.emit(true);
    this.dialogRef.close(true);
  }

  public onCancel(): void {
    this.accionEvent.emit(false);
    this.dialogRef.close(false);
  }
}
