import { ModalConfirmarComponent } from 'src/app/components/shared/modal-confirmar/modal-confirmar.component';
import { MatButtonModule } from '@angular/material/button';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [ModalConfirmarComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatCardModule,
    FlexLayoutModule,
  ],
  exports: [ModalConfirmarComponent]
})
export class ModalConfirmarModule { }
