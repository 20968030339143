import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../config/config';
import { SelectsModel } from 'src/app/models/shared/selects.model';
import { map } from 'rxjs/operators';
import { APIReturnModel } from 'src/app/models/shared/api-return.model';
import { SharedApiConfig } from './shared-api-config';

@Injectable({
  providedIn: 'root'
})
export class SelectsService {
  constructor(private http: HttpClient) { }

  guardarCombos(combos: SelectsModel) {
    localStorage.setItem(
      AppConfig.LOCAL_STORAGE_COMBOS,
      JSON.stringify(combos)
    );
  }
  public selects() {
    // const ItemCombos = localStorage.getItem(AppConfig.LOCAL_STORAGE_COMBOS);
    // if (ItemCombos !== '' &&
    //   ItemCombos !== undefined &&
    //   ItemCombos !== null) {
    //   return JSON.parse(ItemCombos);
    // } else {
    const selects: Promise<SelectsModel> =
      this.http.get<APIReturnModel<SelectsModel>>(SharedApiConfig.SELECTS_URL)
        .pipe(map(result => result.datos)).toPromise();
    selects.then(result => this.guardarCombos(result));
    return selects;
    // }
  }
  public selectsSinAuth() {
    // const ItemCombos = localStorage.getItem(AppConfig.LOCAL_STORAGE_COMBOS);
    // if (ItemCombos !== '' &&
    //   ItemCombos !== undefined &&
    //   ItemCombos !== null) {
    //   return JSON.parse(ItemCombos);
    // } else {
    const selects: Promise<SelectsModel> =
      this.http.get<APIReturnModel<SelectsModel>>(SharedApiConfig.SELECTS_SIN_AUTH_URL)
        .pipe(map(result => result.datos)).toPromise();
    selects.then(result => this.guardarCombos(result));
    return selects;
    // }
  }
}
