<mat-card class="tarjeta-Modal">
  <mat-card-header fxLayout="row" fxLayoutAlign="space-between start">
    <mat-card-title> Planes </mat-card-title>
    <div fxLayoutAlign="space-between center">
      <button mat-button (click)="guardar()" tabindex="-1" class="boton-titulo">
        <mat-icon >save</mat-icon>
      </button>
      <button mat-button (click)="dialogRef.close()" tabindex="-1" class="boton-titulo">
        <img class="rotado" src="\assets\images\icons\nuevo.svg" alt="Cerrar">
      </button>
    </div>
  </mat-card-header>
  <mat-card-content fxLayout="row wrap" [formGroup]="planForm" fxLayoutGap="10px" >
    <app-busqueda-autocomplete class="pop-up" fxFlex="50%" [label]="propsOS.label" [deshabilitado]="propsOS.deshabilitado"
      [valor]="propsOS.valor" [tipoBuscador]="propsOS.tipoBuscador" [requerido]="propsOS.requerido"
      [mostrarError]="true" (evElegir)='elegirOS($event)' (evLimpiar)='limpiarOS()'>
    </app-busqueda-autocomplete>
    <mat-form-field fxFlex="35%">
      <mat-label>Plan</mat-label>
      <mat-select formControlName="plan">
        <mat-option *ngFor="let item of planesObra" [value]='item.id'>{{item.nombre}}</mat-option>
      </mat-select>
      <mat-error *ngIf="planForm.get('plan').invalid && (planForm.get('plan').dirty || planForm.get('plan').touched)">
        <small *ngIf="planForm.get('plan').hasError('required')">
          Debe elegir un plan
        </small>
      </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="30%"class="pop-up">
      <input   matInput type="text" placeholder="Nro. asociado" formControlName="nroAsociado" autocomplete="off">
      <mat-error
        *ngIf="planForm.get('nroAsociado').invalid && (planForm.get('nroAsociado').dirty || planForm.get('nroAsociado').touched)">
        <small *ngIf="planForm.get('nroAsociado').hasError('required')">
          Debe ingresar un numero de asociado
        </small>
        <small *ngIf="planForm.get('nroAsociado').hasError('maxlength')">
          El numero de asociado no puede ser mayor a 50 caracteres
        </small>
        <small *ngIf="planForm.get('nroAsociado').hasError('pattern')">
          El numero de asociado no puede contener letras
        </small>
      </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="18%">
      <mat-label>Parentesco</mat-label>
      <mat-select formControlName="parentesco" placeholder="Parentesco" required>
        <mat-option *ngFor="let item of datosSelect.tipoParentescoEnum" [value]="(item.id)">
          {{ item.nombre }}
        </mat-option>
      </mat-select>
      <mat-error
        *ngIf="planForm.get('parentesco').invalid && (planForm.get('parentesco').dirty ||planForm.get('parentesco').touched)">
        <small *ngIf="planForm.get('parentesco').hasError('required')">
          Ingresar el tipo de parentesco
        </small>
      </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="30%"class="pop-up">
      <input   matInput type="text" placeholder="Otra info" formControlName="otraInfo" autocomplete="off">
      </mat-form-field>
  </mat-card-content>
</mat-card>
