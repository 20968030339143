import { HttpHelperService } from './../helpers/http-helper.service';
import { SelectObjModel } from './../../models/shared/selects.model';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ABMPersonalListModel, ABMPersonalUpdateModel } from 'src/app/models/empresa/abm-personal.model';
import { APIReturnModel } from 'src/app/models/shared/api-return.model';
import { ABMGenericoConsultaModel, ABMGenericoModel } from 'src/app/models/shared/abm-generico.model';
import { PersonaValidarDocModel } from 'src/app/models/shared/persona-validar.model';
import { ABMPacienteListModel } from 'src/app/models/paciente/abm-paciente.model';
import { EmpresaApiConfig } from './empresa-api-config';

@Injectable({
  providedIn: 'root'
})
export class PersonalService {
  public limpiabuscadorPersonal = new EventEmitter();
  constructor(
    private http: HttpClient,
    private HttpHelpers: HttpHelperService
  ) { }

  public actualizar(personal: ABMPersonalUpdateModel): Observable<ABMPersonalListModel> {
    return this.http.put<APIReturnModel<ABMPersonalListModel>>(EmpresaApiConfig.PERSONAL_UPDATE_URL, personal)
      .pipe(map(result => result.datos));
  }
  public eliminar(usuarioId: number): Observable<string> {
    return this.http.delete<APIReturnModel<string>>(EmpresaApiConfig.PERSONAL_ELIMINAR_URL,
      this.HttpHelpers.buildQueryParams(usuarioId, 'usuarioId'))
      .pipe(map(result => result.datos));
  }
  public desactivar(usuarioId: number): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(EmpresaApiConfig.PERSONAL_DESACTIVAR_URL, usuarioId)
      .pipe(map(result => result.datos));
  }
  public activar(usuarioId: number): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(EmpresaApiConfig.PERSONAL_ACTIVAR_URL, usuarioId)
      .pipe(map(result => result.datos));
  }
  public list(datosBusqueda: ABMGenericoConsultaModel): Observable<ABMGenericoModel<ABMPersonalListModel>> {
    return this.http.get<APIReturnModel<ABMGenericoModel<ABMPersonalListModel>>>(EmpresaApiConfig.PERSONAL_LIST_URL,
      this.HttpHelpers.buildQueryParams(datosBusqueda))
      .pipe(map(result => result.datos));
  }
  public uno(usuarioId: number): Observable<ABMPersonalUpdateModel> {
    return this.http.get<APIReturnModel<ABMPersonalUpdateModel>>(EmpresaApiConfig.PERSONAL_UNO_URL,
      this.HttpHelpers.buildQueryParams(usuarioId, 'usuarioId'))
      .pipe(map(result => result.datos));
  }
  public unoPorPersonal(personalId: number): Observable<ABMPersonalUpdateModel> {
    return this.http.get<APIReturnModel<ABMPersonalUpdateModel>>(EmpresaApiConfig.PERSONAL_UNO_POR_PERSONAL_URL,
      this.HttpHelpers.buildQueryParams(personalId, 'personalId'))
      .pipe(map(result => result.datos));
  }
  public porLegajo(datoslegajo: SelectObjModel): Observable<boolean> {
    return this.http.get<APIReturnModel<boolean>>(EmpresaApiConfig.PERSONAL_POR_LEGAJO_URL,
      this.HttpHelpers.buildQueryParams(datoslegajo))
      .pipe(map(result => result.datos));
  }
  public personalNroDoc(datos: PersonaValidarDocModel): Observable<ABMPersonalUpdateModel> {
    return this.http.get<APIReturnModel<ABMPersonalUpdateModel>>(EmpresaApiConfig.PERSONAL_NRO_DOC_URL,
      this.HttpHelpers.buildQueryParams(datos))
      .pipe(map(result => result.datos));
  }
  public nroDocExiste(datos: PersonaValidarDocModel): Observable<ABMPacienteListModel[]> {
    return this.http.get<APIReturnModel<ABMPacienteListModel[]>>(EmpresaApiConfig.PERSONAL_NRO_DOC_EXISTE_URL,
      this.HttpHelpers.buildQueryParams(datos))
      .pipe(map(result => result.datos));
  }
  public busquedaSelect(busqueda: number): Observable<SelectObjModel[]> {
    return this.http.get<APIReturnModel<SelectObjModel[]>>(EmpresaApiConfig.PERSONAL_BUSQUEDA_SELECT_URL,
      this.HttpHelpers.buildQueryParams(busqueda, 'busqueda'))
      .pipe(map(result => result.datos));
  }
  public nroDocExisteSinAuth(datos: PersonaValidarDocModel): Observable<ABMPacienteListModel[]> {
    return this.http.get<APIReturnModel<ABMPacienteListModel[]>>(EmpresaApiConfig.PERSONAL_NRO_DOC_EXISTE_SIN_AUTH_URL,
      this.HttpHelpers.buildQueryParams(datos))
      .pipe(map(result => result.datos));
  }
  public consultaCantidadMedicos(): Observable<number> {
    return this.http.get<APIReturnModel<number>>(EmpresaApiConfig.PERSONAL_CONSULTA_CANTIDAD_MEDICOS_URL)
      .pipe(map(result => result.datos));
  }
  public esMedico(): Observable<boolean> {
    return this.http.get<APIReturnModel<boolean>>(EmpresaApiConfig.PERSONAL_ES_MEDICO_URL)
      .pipe(map(result => result.datos));
  }
  public habilitadoCaja(cajaId:number): Observable<boolean> {
    return this.http.get<APIReturnModel<boolean>>(EmpresaApiConfig.PERSONAL_HABILITADO_CAJA_URL+ `?cajaId=${cajaId}`)
      .pipe(map(result => result.datos));
  }
  public consultaSedeLog(): Observable<number> {
    return this.http.get<number>(EmpresaApiConfig.PERSONAL_CONSULTA_SEDE_LOG_URL)
      .pipe(map(result => result));
  }
}
