import { TipoParentescoEnum } from "./enums.model";
import { DireccionesModel } from "./direcciones.model";
import { TelefonoModel } from "./telefono.model";
import { HCReferenciaAlergiasModel } from "../historia-clinica/historia-clinica.model";
export class PersonaModel {
  id: number;
  nombre: string;
  apellido: string;
  apellidoMatrimonial: string;
  email: string;
  tipoDocumento: number;
  tipoDocumentoStr: string;
  numeroDocumento: number;
  foto: string;
  fotoDatoBase64: string;
  sexoBiologico: number;
  sexoBiologicoStr: string;
  fechaNacimiento: Date;
  fallecido: boolean;
  genero: number;
  abreviacionPersona: string;
  generoStr: string;
  firmaDigital: string;
  direcciones: DireccionesModel[];
  telefonos: TelefonoModel[];
  enviarWhatsApp: boolean;
  enviarEmail: boolean;
  constructor() {
    this.telefonos = new Array<TelefonoModel>();
    this.direcciones = new Array<DireccionesModel>();
    this.enviarEmail = this.enviarWhatsApp = true;
  }
}
export class DatosPersonaHeaderModel {
  personaId:number;
  nombre: string;
  nroHC: string;
  tipoDoc: string;
  nroDoc: string;
  edad: string;
  sexo: string;
  osPlan: string;
  nroPlan: string;
  direccion: string;
  celular: string;
  foto: string;
  fotoDatoBase64: string;
  legajo: string;
  parentesco: TipoParentescoEnum;
  otraInfo: string;
  countActRefSwitch:number;
  hcReferencia: HCReferenciaHeaderModel;
  constructor() { }
}
export class HCReferenciaHeaderModel
{
  id:number;
  enfCardiovascular?: boolean;
  obesidad?: boolean;
  hipotiroidismo?: boolean;
  hipertension?: boolean;
  higadoGraso?: boolean;
  sindromeMetabolico?: boolean;
  epoc?: boolean;
  dislipemia?: boolean;
  trastornoPsiquiatrico?: boolean;
  enfRenal?: boolean;
  erge?: boolean;
  embarazoActual?: boolean;
  discapacidad?:string;
  DiabeteTipo ?: number;
  CancerEspecifique ?: string;
  fumador?:number;
  otroIndicador?:string;
  alergias?:HCReferenciaAlergiasModel[];
  constructor()
  {
    this.alergias = new Array<HCReferenciaAlergiasModel>();
  }
}
export class AbreviacionPersona {
  prefijo: string;
}
