import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { LoginService } from 'src/app/services/login/login.service';
import { Router } from '@angular/router';
import { PacienteService } from 'src/app/services/paciente/paciente.service';

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.scss']
})
export class PrincipalComponent implements OnInit {
  primerLogin: Observable<boolean>;
  storage = window.localStorage;
  constructor(private changeDetector: ChangeDetectorRef, 
              public loginServ: LoginService,
              public route: Router,
              private pacienteServ: PacienteService) {
    var anima = this.storage.getItem('animacion');
    if (!anima || anima !== 'false') {
      this.primerLogin = new Observable(observer => observer.next(true));
    }
  }

  ngOnInit() {
   // this.pacienteServ.irHC(1241154);
  }
  sacarAnimacion() {
    this.primerLogin = new Observable(observer => observer.next(false));
    this.storage.setItem('animacion', 'false');
    this.changeDetector.detectChanges();
  }
  
}
