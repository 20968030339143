import { LoginService } from 'src/app/services/login/login.service';
import { Injectable } from '@angular/core';

import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HeadersInterceptor implements HttpInterceptor {
  constructor(private loginServ: LoginService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.loginServ.datosHeader.authorization) {
      const storage: Storage = window.localStorage;
      if (storage.DatosHeader) {
        this.loginServ.datosHeader = JSON.parse(storage.getItem('DatosHeader'));
      }
    }
    const authReq = req.clone({
      headers: this.crearHeaders()
    });
    return next.handle(authReq).pipe(filter(event => event instanceof HttpResponse),
      tap((event: HttpResponse<any>) => {
        this.loginServ.datosHeader.authorization = event.headers.get('authorization');
        this.loginServ.datosHeader.usuarioLogId = event.headers.get('usuarioLogId');
        this.loginServ.datosHeader.empresaLogId = event.headers.get('empresaLogId');
        this.loginServ.datosHeader.sedeLogId = event.headers.get('sedeLogId');
        this.loginServ.datosHeader.agendaLogId = event.headers.get('agendaLogId');
        this.loginServ.datosHeader.temaLog = parseInt(event.headers.get('temaLog'), 10);
        const storage: Storage = window.localStorage;
        storage.setItem('DatosHeader', JSON.stringify(this.loginServ.datosHeader));
      }));
  }
  private crearHeaders(): HttpHeaders {
    let headersReq = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    if (this.loginServ.datosHeader.authorization) {
      headersReq = headersReq.append('Authorization', this.loginServ.datosHeader.authorization);
      headersReq = headersReq.append('UsuarioLogId', this.loginServ.datosHeader.usuarioLogId);
      headersReq = headersReq.append('EmpresaLogId', this.loginServ.datosHeader.empresaLogId ?? '');
      headersReq = headersReq.append('SedeLogId', this.loginServ.datosHeader.sedeLogId ?? '');
      if (this.loginServ.datosHeader.agendaLogId) {
        headersReq = headersReq.append('AgendaLogId', this.loginServ.datosHeader.agendaLogId);
      }
      if (this.loginServ.datosHeader.temaLog !== undefined && this.loginServ.datosHeader.temaLog !== null) {
        headersReq = headersReq.append('TemaLog', this.loginServ.datosHeader.temaLog.toString());
      }
    }
    return headersReq;
  }
}
