import { environment } from 'src/environments/environment';

export class TurnosApiConfig {
  // TURNOS
  public static TURNOS_ESTADO_MES_URL = environment.apiUrl + 'Turnos/EstadoMes';
  public static TURNOS_DIA_FERIADO_URL = environment.apiUrl + 'Turnos/DiaFeriado';
  public static TURNOS_UNO_UPDATE_URL = environment.apiUrl + 'Turnos/UnoUpdate';
  public static TURNOS_UNO_ROW_URL = environment.apiUrl + 'Turnos/UnoRow';
  public static TURNOS_CANCELAR_TURNO_URL = environment.apiUrl + 'Turnos/CancelarTurno';
  public static TURNOS_CANCELAR_TURNO_LINK_URL = environment.apiUrl + 'Turnos/CancelarTurnoByLink';
  public static TURNOS_UPDATE_URL = environment.apiUrl + 'Turnos/Actualizar';
  public static TURNOS_TICK_URL = environment.apiUrl + 'Turnos/TurnosTick';
  public static TURNOS_FECHA_URL = environment.apiUrl + 'Turnos/TurnosFecha';
  // public static TURNOS_SEMANA_URL = environment.apiUrl + 'Turnos/TurnosSemana';
  public static TURNOS_PACIENTES_TURNOS_URL = environment.apiUrl + 'Turnos/PacientesTurnos';
  public static TURNOS_CANTIDAD_AGENDAS_URL = environment.apiUrl + 'Turnos/CantidadAgendas';
  public static TURNOS_BUSQUEDA_AGENDA_MENOR_URL = environment.apiUrl + 'Turnos/BusquedaAgendaMenor';
  public static TURNOS_BUSQUEDA_AGENDA_MAYOR_URL = environment.apiUrl + 'Turnos/BusquedaAgendaMayor';
  public static TURNOS_AGENDA_BUSQUEDA_URL = environment.apiUrl + 'Turnos/AgendaBusqueda';
  public static TURNOS_PACIENTE_BUSQUEDA_URL = environment.apiUrl + 'Turnos/PacienteBusqueda';
  public static TURNOS_ASIGNAR_TURNO_URL = environment.apiUrl + 'Turnos/AsignarTurno';
  public static TURNOS_ENVIAR_MENSAJES_PENDIENTES_URL = environment.apiUrl + 'Turnos/EnviarMensajesPendientes';
  public static TURNOS_ENVIAR_CONFIRMACIONES_URL = environment.apiUrl + 'Turnos/EnviarConfirmaciones';
  public static TURNOS_CANCELAR_DIA_URL = environment.apiUrl + 'Turnos/CancelarDia';
  public static TURNOS_CAMBIAR_CONFIRMACION_TURNO_URL = environment.apiUrl + 'Turnos/CambiarConfirmacionTurno';
  public static TURNOS_ENVIAR_EMIAL_ZOOM_URL = environment.apiUrl + 'Turnos/EnviarEmailZoom';
  public static TURNOS_CANCELADOS_URL = environment.apiUrl + 'Turnos/TurnosCancelados';
  public static TURNOS_HORARIOS_ACTUALES_CAMBIO_URL = environment.apiUrl + 'Turnos/HorariosActualesCambio';
  public static TURNOS_CAMBIAR_HORARIOS_URL = environment.apiUrl + 'Turnos/CambiarHorarios';
  public static TURNOS_SIGUIENTE_ESTADO_URL = environment.apiUrl + 'Turnos/SiguienteEstado';
  public static TURNOS_HAY_SEG_POR_HORA_URL = environment.apiUrl + 'Turnos/HaySegPorHora';
  public static TURNOS_ACTUALIZAR_RECORDATORIO_DEMORA_URL = environment.apiUrl + 'Turnos/ActualizarRecordatorioDemora';
  public static TURNOS_ACTUALIZAR_RECORDATORIO_MEDICO_URL = environment.apiUrl + 'Turnos/ActualizarRecordatorioMedico';
  public static TURNOS_CONSULTA_RECORDATORIO_DEMORA_URL = environment.apiUrl + 'Turnos/ConsultaRecordatorioDemora';
  public static TURNOS_CONSULTA_RECORDATORIO_MEDICO_URL = environment.apiUrl + 'Turnos/ConsultaRecordatorioMedico';
  public static TURNOS_AGENDA_TURNOS_SEMANAL_URL = environment.apiUrl + 'Turnos/TurnosAgendaSemanal';
  public static TURNOS_SEGMENTO_DESACTIVAR_URL = environment.apiUrl + 'Turnos/SegmentoDesactivar';
  public static TURNOS_PUEDE_DURACION_EXTENDER_URL = environment.apiUrl + 'Turnos/PuedeDuracionExtender';
  public static TURNOS_VERIFICAR_SOBRETURNO_URL = environment.apiUrl + 'Turnos/VerificarSobreTurno';

  // TIMER
  public static TURNO_UPDATE_HUB_URL = environment.apiUrl + 'TurnosActualizacionHub';
}
