<div class="overlay" *ngIf="loaderService.isLoading | async">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142.6 116.6">
    <path fill="#4d91f8"
      d="M21.6 74c-.3-2.6.5-4.8 2.3-6.6 7.4-7.5 14.8-14.9 22.2-22.3 3.4-3.4 8.4-3.4 11.9.1l6.9 6.9c3.8 3.8 3.8 8.7 0 12.4-2.4 2.4-4.5 5.1-7.5 6.9-5.3 3.1-12.7 1.4-16.1-3.6-1.1-1.6-.9-3.7.4-5.1 1.4-1.4 3.6-1.7 5.2-.5.6.4 1 .9 1.5 1.4 1.5 1.5 3.9 1.8 5.5.3 1.8-1.6 3.4-3.3 5.1-4.9.4-.4.3-.6 0-1-2.1-2.1-4.3-4.2-6.4-6.4-.7-.7-1-.3-1.4.2-6 6-12.2 12.1-18.3 18.2-1.1 1.1-2.1 2.1-3.2 3.1-2.8-.1-5.4.5-8.1.9zM58.9 5.5c2.6-.3 4.7.6 6.5 2.4 7.4 7.3 14.7 14.6 22 22 3.7 3.7 3.7 8.6 0 12.3l-6.9 6.9c-3.5 3.5-8.6 3.5-12.1 0l-4.9-4.9c-5.3-5.5-4.7-14.1 1.3-18.7 1.7-1.3 4-1.2 5.5.3s1.6 3.9.2 5.6l-.3.3c-2.9 3.1-2.9 4.7.1 7.7l3.6 3.6c.5.5.7.5 1.2 0 2.1-2.1 4.2-4.3 6.4-6.3.7-.7.2-1-.2-1.4-6.8-6.8-13.6-13.7-20.4-20.5-.3-.4-.7-.7-1-1.1.1-2.8-.6-5.5-1-8.2zM89.8 111.1c-2.5.3-4.7-.5-6.4-2.3-7.5-7.4-15-14.9-22.4-22.3-3.4-3.4-3.4-8.4 0-11.8l7.1-7.1c3.6-3.6 8.6-3.6 12.2 0l4.9 4.9c5.3 5.4 4.7 14-1.4 18.6-1.8 1.4-4.2 1.2-5.6-.5-1.5-1.7-1.4-4 .3-5.7.7-.7 1.5-1.3 1.9-2.3.6-1.6.3-3.1-.9-4.3-1.5-1.6-3.1-3.1-4.6-4.7-.4-.5-.7-.5-1.2 0-2.1 2.2-4.3 4.3-6.5 6.4-.7.7-.1.9.3 1.2 6.8 6.9 13.7 13.7 20.5 20.6.3.3.6.7.9 1-.2 2.9.4 5.6.9 8.3zM126.9 42.8c.4 2.5-.4 4.6-2.1 6.4-7.5 7.6-15.1 15.2-22.7 22.8-3.1 3.1-8.1 3-11.3-.1-2.5-2.5-5-4.9-7.4-7.4-3.6-3.6-3.5-8.5.1-12.1l4.8-4.8c5.4-5.4 13.8-4.8 18.5 1.1 1.5 1.9 1.3 4.3-.4 5.8-1.7 1.5-4.2 1.3-5.9-.5-2.3-2.5-4.6-2.6-7-.2l-4.6 4.6c2.4 2.4 4.8 4.7 7.1 7.1.6.7.9 0 1.1-.3 3.6-3.6 7.2-7.1 10.8-10.7l10.8-10.8c2.8.2 5.5-.4 8.2-.9z"
      class="path" />
    <linearGradient class="path" id="SVGID_1_" x1="21.848" x2="59.816" y1="24.49" y2="24.49"
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#005e89" />
      <stop offset=".525" stop-color="#0082db" />
      <stop offset=".993" stop-color="#005e89" />
    </linearGradient>
    <path class="path" fill="url(#SVGID_1_)"
      d="M58.9 5.5c.4 2.7 1.1 5.4.8 8.1-2.1 1.7-3.8 3.7-5.7 5.6-7.7 7.6-15.3 15.2-22.9 22.8-.4.4-.9.7-1.1 1.3-2.8.2-5.5-.3-8.1-.9.2-2 1-3.7 2.4-5.1 5.6-5.7 11.2-11.3 16.9-17L54 7.5c1.3-1.1 3-1.8 4.9-2z" />
    <linearGradient class="path" id="SVGID_2_" x1="88.928" x2="126.901" y1="24.76" y2="24.76"
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#005e89" />
      <stop offset=".525" stop-color="#0082db" />
      <stop offset=".993" stop-color="#005e89" />
    </linearGradient>
    <path class="path" fill="url(#SVGID_2_)"
      d="M126.9 42.8c-2.7.5-5.4 1.1-8.2.9-.1-.5-.5-.8-.8-1.1L90 14.7c-.3-.3-.6-.7-1.1-.8-.2-2.7.4-5.4.8-8.1 2.1.1 3.7 1.1 5.3 2.6 2.3 2.1 4.5 4.4 6.8 6.6 4.3 4.2 8.5 8.4 12.7 12.7 3.3 3.3 6.5 6.6 9.8 9.9 1.5 1.4 2.5 3.1 2.6 5.2z" />
    <linearGradient class="path" id="SVGID_3_" x1="21.582" x2="59.512" y1="92.101" y2="92.101"
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#005e89" />
      <stop offset=".525" stop-color="#0082db" />
      <stop offset=".993" stop-color="#005e89" />
    </linearGradient>
    <path class="path" fill="url(#SVGID_3_)"
      d="M21.6 74c2.7-.5 5.4-1.1 8.1-.8 1.8 2.1 3.8 4 5.8 5.9 2.4 2.3 4.8 4.7 7.2 7.1 2 2 3.9 4.1 5.9 6 3.7 3.5 7.1 7.4 11 10.7.2 2.8-.4 5.4-.8 8.1-2.1-.2-3.9-1.1-5.4-2.6-6.7-6.7-13.5-13.4-20.2-20-3.2-3.2-6.4-6.3-9.5-9.6-1.3-1.3-2-3-2.1-4.8z" />
    <linearGradient class="path" id="SVGID_4_" x1="88.923" x2="126.903" y1="92.11" y2="92.11"
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#005e89" />
      <stop offset=".525" stop-color="#0082db" />
      <stop offset=".993" stop-color="#005e89" />
    </linearGradient>
    <path class="path" fill="url(#SVGID_4_)"
      d="M89.8 111.1c-.5-2.7-1.1-5.4-.8-8.1 1.5-.9 2.5-2.3 3.7-3.5 8.4-8.4 16.8-16.7 25.1-25.1.3-.3.8-.6.9-1.2 2.8-.2 5.5.4 8.1.9-.1 1.9-.9 3.6-2.3 5-2.5 2.5-4.9 5-7.4 7.5-3.7 3.7-7.3 7.4-11 11-2.5 2.4-4.8 5.1-7.5 7.4-1.3 1.1-2.4 2.5-3.6 3.7-1.3 1.4-3.1 2.2-5.2 2.4z" />
    <path class="path" fill="#4d91f8"
      d="M89.8 5.8c-.4 2.7-1 5.3-.8 8.1-1.8 1.9-3.6 3.8-5.5 5.6-1.6 1.5-4.1 1.3-5.6-.2s-1.8-3.9-.3-5.6c2.1-2.3 4.4-4.4 6.7-6.5 1.5-1.3 3.5-1.6 5.5-1.4zM58.6 111.1c.5-2.7 1-5.4.8-8.1 1.7-1.8 3.4-3.6 5.2-5.4 1.6-1.6 4.1-1.7 5.8-.1 1.7 1.5 1.8 4.2.2 5.9-1.9 2-3.8 4-5.9 5.8-1.6 1.5-3.7 2.2-6.1 1.9zM126.9 74.1c-2.7-.5-5.4-1.1-8.1-.9-1.8-1.7-3.5-3.4-5.3-5.1-1.7-1.7-1.8-4.2-.2-5.9 1.5-1.6 4.2-1.8 5.9-.2 2 1.9 4 3.9 5.8 5.9 1.6 1.7 2.3 3.8 1.9 6.2zM21.8 42.5c2.7.5 5.4 1.1 8.1.9 1.7 1.7 3.4 3.4 5.2 5 1.7 1.6 1.9 4.1.4 5.9-1.5 1.7-4.3 1.9-6 .3-2-1.9-4-3.8-5.9-5.9-1.5-1.7-2.2-3.8-1.8-6.2z"
      class="path" />
  </svg>

  <!-- <mat-progress-spinner class="spinner" [color]="'primary'" [mode]="'indeterminate'" [value]="50">
  </mat-progress-spinner> -->
</div>