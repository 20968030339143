import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { ShifterService } from "src/app/services/shifter/shifter.service";
import {
  shifterTurno,
  SedeSelectModel,
  EmpresaSelectModel,
} from "src/app/models/turnos/shifter-turno.model";
import { MatSelect } from "@angular/material/select";

interface SedeData {
  sedeId: number;
  playListId: string[];
}
@Component({
  selector: "app-select-sede-dialog",
  templateUrl: "./select-sede-dialog.component.html",
  styleUrls: ["./select-sede-dialog.component.scss"],
})
export class SelectSedeDialogComponent implements OnInit {
  centroList = new Observable<EmpresaSelectModel[]>();
  sedeList = new Observable<SedeSelectModel[]>();
  form: UntypedFormGroup;
  selectedCentro: EmpresaSelectModel = new EmpresaSelectModel();
  selectedSede: SedeSelectModel;
  videoLink: string = "";
  constructor(
    public dialogRef: MatDialogRef<SelectSedeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private shifterService: ShifterService
  ) {
    // Initialize the form and form controls
    this.form = new UntypedFormGroup({
      centro: new UntypedFormControl(),
      sede: new UntypedFormControl(),
      videoLink: new UntypedFormControl(),
    });

    // Assuming centroList and sedeList are provided through data
    //this.centroList = data.centroList;
    //this.sedeList = data.sedeList;
  }
  ngOnInit(): void {
    this.centroList = this.shifterService.GetCentroList();
  }
  centroSelected(centro: EmpresaSelectModel) {
    this.selectedCentro = centro;
    this.sedeList = this.shifterService.GetSedeList(this.selectedCentro.id);
    this.videoLink = "";
    // .pipe(
    //   map((arr: SelectObjModel[]) => {

    //     return arr.map((obj: SelectObjModel) => {
    //       obj.nombre = obj.nombre.replace(/%/g, " ").split(" ").filter((item:string) => item !== "").join(" ");
    //       return obj;
    //     });
    //   })
    // );

    //this.turnoForm.controls["sede"].reset();
    //this.turnoForm.controls["date"].reset();
  }
  sedeSelected(sede: SedeSelectModel) {
    this.selectedSede = sede;
    const storedData: SedeData[] =
      JSON.parse(localStorage.getItem("sedeData")) || [];
   }
  playlistSelected(playlist: string) {
    switch (playlist) {
      case "paisajes":
        this.videoLink =
          "PLhUoikWi1lnaqPJQPa4QlBSzXCUYoZHai";
        break;
      case "ciudades":
        this.videoLink =
          "PLhUoikWi1lnYaSfwFu4gQT5NDh2S6Fzez";
        break;
      case "deportes":
        this.videoLink =
          "PLhUoikWi1lnZG8f-FjsWAPHaU0ECrpCMO";
        break;
      case "animales":
        this.videoLink =
          "PLhUoikWi1lnYN5HP2Ijn5jX_WYrY3BDLA";
        break;
      default:
        console.log("Unknown playlist:", playlist);
        break;
    }
  }
  onCloseClick(): void {
    // Pass the selected values back to the calling component
    const selectedCentro = this.selectedCentro;
    const selectedSede = this.selectedSede;
    this.dialogRef.close({
      centro: selectedCentro,
      sede: selectedSede,
      videoLink: this.videoLink,
    });
  }
  @ViewChild("centroSelect") centroSelect: MatSelect;
  @ViewChild("sedeSelect") sedeSelect: MatSelect;
  @ViewChild("playListSelect") playListSelect: MatSelect;

  moveCentroSelect(direction: "up" | "down", select: MatSelect) {
    const options = select.options.toArray();
    const currentIndex = options.findIndex(
      (option) => option.value === select.value
    );

    if (direction === "up" && currentIndex > 0) {
      var selectedOpt = options[currentIndex - 1].value;
      select.writeValue(selectedOpt);
      this.centroSelected(selectedOpt);
    } else if (direction === "down" && currentIndex < options.length - 1) {
      var selectedOpt = options[currentIndex + 1].value;
      select.writeValue(selectedOpt);
      this.centroSelected(selectedOpt);
    }
  }
  moveSedeSelect(direction: "up" | "down", select: MatSelect) {
    const options = select.options.toArray();
    const currentIndex = options.findIndex(
      (option) => option.value === select.value
    );
    if (direction === "up" && currentIndex > 0) {
      var selectedOpt = options[currentIndex - 1].value;
      select.writeValue(selectedOpt);
      this.sedeSelected(selectedOpt);
    } else if (direction === "down" && currentIndex < options.length - 1) {
      var selectedOpt = options[currentIndex + 1].value;
      select.writeValue(selectedOpt);
      this.sedeSelected(selectedOpt);
    }
  }
  movePlayListSelect(direction: "up" | "down", select: MatSelect) {
    const options = select.options.toArray();
    const currentIndex = options.findIndex(
      (option) => option.value === select.value
    );
    if (direction === "up" && currentIndex > 0) {
      var selectedOpt = options[currentIndex - 1].value;
      select.writeValue(selectedOpt);
      this.playlistSelected(selectedOpt);
    } else if (direction === "down" && currentIndex < options.length - 1) {
      var selectedOpt = options[currentIndex + 1].value;
      select.writeValue(selectedOpt);
      this.playlistSelected(selectedOpt);
    }
  }
}
