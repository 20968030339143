import { DatosModalOrdenAtencionModel } from './../../models/facturacion/orden-atencion.model';
import { ModalModuloComponent } from 'src/app/components/shared/modal-modulo/modal-modulo.component';
import {
  OrdenAtencionModel,
  CabeceraModel,
  OrdenAtencionCtaCteCobranzaModel,
  ValorContratoPrestacionModel,
  ParametrosValorizacion,
  HistoricoOrdenesModel,
  OrdenesRechazadasConsultaModel
} from '../../models/facturacion/orden-atencion.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectObjModel } from 'src/app/models/shared/selects.model';
import { APIReturnModel } from 'src/app/models/shared/api-return.model';
import { HttpHelperService } from '../helpers/http-helper.service';
import { FacturacionApiConfig } from './facturacion-api-config';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModulosModalEnum } from 'src/app/models/shared/modulos-modal.enum';

@Injectable({
  providedIn: 'root'
})
export class OrdenAtencionService {

  constructor(
    private http: HttpClient,
    private HttpHelpers: HttpHelperService,
    private modalpage: MatDialog
  ) { }

  public abrirOrdenAtencion(orden: OrdenAtencionModel, auditoria: boolean, mVisualizacion: boolean)
    : MatDialogRef<ModalModuloComponent, any> {
    const datosModal = new DatosModalOrdenAtencionModel();
    datosModal.orden = orden;
    datosModal.Auditoria = auditoria;
    datosModal.MVisualizacion = mVisualizacion;
    return this.modalpage.open(ModalModuloComponent,
      {
        panelClass: 'custom-dialog-container-auto', data: { modulo: ModulosModalEnum.ORDEN_ATENCION, datosOrden: datosModal }
      });
  }
  public actualizar(orden: OrdenAtencionModel): Observable<SelectObjModel> {
    return this.http.put<APIReturnModel<SelectObjModel>>(FacturacionApiConfig.ORDEN_UPDATE_URL, orden)
      .pipe(map(result => result.datos));
  }

  public cabecera(turnoId: number): Observable<CabeceraModel> {
    return this.http.get<APIReturnModel<CabeceraModel>>(FacturacionApiConfig.ORDEN_CABECERA_URL,
      this.HttpHelpers.buildQueryParams(turnoId, 'turnoId'))
      .pipe(map(result => result.datos));
  }

  public agendaCabecera(): Observable<SelectObjModel[]> {
    return this.http.get<APIReturnModel<SelectObjModel[]>>(FacturacionApiConfig.ORDEN_AGENDA_CABECERA_URL)
      .pipe(map(result => result.datos));
  }

  public prestacionesOrdenAtencion(busqueda: string, especialidadId: number): Observable<SelectObjModel[]> {
    return this.http.get<APIReturnModel<SelectObjModel[]>>(FacturacionApiConfig.ORDEN_PRESTACIONES_ORDEN_URL,
      this.HttpHelpers.buildQueryParams({ busqueda, especialidadId }))
      .pipe(map(result => result.datos));
  }
  // public hayPrestacionesEspecialidad(especialidadId: number): Observable<boolean> {
  //   return this.http.get<APIReturnModel<boolean>>(FacturacionApiConfig.ORDEN_HAY_PRESTACIONES_ESPECIALIDAD_URL,
  //     this.HttpHelpers.buildQueryParams(especialidadId, 'especialidadId'))
  //     .pipe(map(result => result.datos));
  // }

  public ordenesPersona(personaUsId: number): Observable<OrdenAtencionCtaCteCobranzaModel[]> {
    return this.http.get<APIReturnModel<OrdenAtencionCtaCteCobranzaModel[]>>(FacturacionApiConfig.ORDEN_ORDENES_PERSONA_URL,
      this.HttpHelpers.buildQueryParams(personaUsId, 'personaUsId'))
      .pipe(map(result => result.datos));
  }

  public valorizarPrestacion(listParametro: ParametrosValorizacion): Observable<ValorContratoPrestacionModel> {
    return this.http.get<APIReturnModel<ValorContratoPrestacionModel>>(FacturacionApiConfig.ORDEN_VALOR_PRESTACION_URL,
      this.HttpHelpers.buildQueryParams(listParametro))
      .pipe(map(result => result.datos));
  }

  public OrdenAuditoria(ordenId: number): Observable<OrdenAtencionModel> {
    return this.http.get<APIReturnModel<OrdenAtencionModel>>(FacturacionApiConfig.ORDEN_AUDITORIA_URL,
      this.HttpHelpers.buildQueryParams(ordenId, 'ordenId'))
      .pipe(map(result => result.datos));
  }
  public HistoricoOA(pacienteId: number): Observable<HistoricoOrdenesModel> {
    return this.http.get<APIReturnModel<HistoricoOrdenesModel>>(FacturacionApiConfig.ORDEN_HISTORICO_URL,
      this.HttpHelpers.buildQueryParams(pacienteId, 'pacienteId'))
      .pipe(map(result => result.datos));
  }
  public ordenRechazadaPorNro(datos: OrdenesRechazadasConsultaModel): Observable<boolean> {
    return this.http.get<APIReturnModel<boolean>>(FacturacionApiConfig.ORDEN_RECHAZADA_POR_NRO_URL,
      this.HttpHelpers.buildQueryParams(datos))
      .pipe(map(result => result.datos));
  }
}
