import { DatosPersonaHeaderModel } from 'src/app/models/shared/persona.model';
import { PagosModel } from '../shared/pagos.model';

export class DatosModalOrdenAtencionModel {
  orden: OrdenAtencionModel;
  Auditoria: boolean;
  MVisualizacion: boolean;
}

export class CabeceraModel {
  efectorId: number;
  efectorNombre: string;
  datosPaciente: DatosPersonaHeaderModel;
  empresaId: number;
  especialidadId: number;
  pacientePlanesDetalle: PacientePlanesModel[];
  turnoId: number;
  pacienteId: number;
  hayPrestacionesEsp: boolean
  constructor() {
    this.pacientePlanesDetalle = new Array<PacientePlanesModel>();
    this.datosPaciente = new DatosPersonaHeaderModel();
  }
}

export class OrdenAtencionDetalleModel {
  id?: number;
  prestacionId: number;
  // codigoPrestacion: string;
  nombrePrestacion: string;
  cantidad: number;
  precio: number;
  codigoOS: string;
  constructor() { }
}

export class OrdenAtencionModel {
  id?: number;
  turnoId: number;
  pacienteId: number;
  efectorId: number;
  facturanteOSId: number;
  gestionPropia: boolean;
  procesoId: number;
  monedaId:number;
  detalles: OrdenAtencionDetalleModel[];
  caja: CajaOrdenAtencionModel;
  fecha: Date;
  mes: number;
  anio: number;
  esAuditada: boolean;
  numero?: number;
  cabecera: CabeceraModel;
  planId?: number;
  nroCupon?: string;
  modoPago: number;
  plus?: number;
  constructor();
  constructor(cabOrden: CabeceraModel);
  constructor(cabOrden?: CabeceraModel) {
    this.cabecera = cabOrden ? cabOrden : new CabeceraModel();
    this.detalles = new Array<OrdenAtencionDetalleModel>();
  }
}

export class PacientePlanesModel {
  id: number;
  planId: number;
  planNombre: string;
  coberturaId: number;
  coberturaNombre: string;
  constructor() { }
}

export class CajaOrdenAtencionModel {
  cajaId: number;
  pacienteId: number;
  pagos: PagosModel[];

  constructor() {
    this.pagos = new Array<PagosModel>();
  }
}
export class OrdenAtencionCtaCteCobranzaModel {
  id: number;
  cobrar: boolean;
  numero: number;
  fecha: Date;
  importe: number;
  monedaId:number;
  constructor() { }
}
export class ValorContratoPrestacionModel {
  contratoId: number;
  codigoFacturacion: string;
  importe: number;
  constructor() { }
}
export class ParametrosValorizacion {
  planId?: number;
  efectuanteId: number;
  prestacionId: number;
  constructor() { }
}
export class HistoricoOrdenesModel {
  paciente: DatosPersonaHeaderModel;
  detalle: DetallaHistoricoModel[];
  constructor() {
    this.paciente = new DatosPersonaHeaderModel();
    this.detalle = new Array<DetallaHistoricoModel>();
  }
}
export class DetallaHistoricoModel {
  id: number;
  numero: string;
  fecha: string;
  periodo: string;
  agendaEfectuante: string;
  usuarioCarga: string;
  gestionPropia: string;
  constructor() { }
}
export class OrdenesRechazadasConsultaModel {
  nro: number;
  resumenId: number;
  constructor() { }
}
