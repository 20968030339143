import { HttpHelperService } from './../helpers/http-helper.service';
import { Injectable } from '@angular/core';
import { HttpClient, } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SelectObjModel } from 'src/app/models/shared/selects.model';
import { ABMGenericoModel, ABMGenericoConsultaModel } from 'src/app/models/shared/abm-generico.model';
import { APIReturnModel } from 'src/app/models/shared/api-return.model';
import { ABMObraSocialModel, ABMObraSocialPageModel } from 'src/app/models/facturacion/abm-obra-social.model';
import { FacturacionApiConfig } from './facturacion-api-config';

@Injectable({
  providedIn: 'root'
})
export class ObraSocialService {
  constructor(
    private http: HttpClient,
    private HttpHelpers: HttpHelperService
  ) { }

  public actualizar(obraSocial: ABMObraSocialModel): Observable<ABMObraSocialModel> {
    return this.http.put<APIReturnModel<ABMObraSocialModel>>(FacturacionApiConfig.OBRA_SOCIAL_UPDATE_URL, obraSocial)
      .pipe(map(result => result.datos));
  }
  public borrar(obraSocialId: number): Observable<boolean> {
    return this.http.delete<APIReturnModel<boolean>>(FacturacionApiConfig.OBRA_SOCIAL_BORRAR_URL,
      this.HttpHelpers.buildQueryParams(obraSocialId, 'obraSocialId'))
      .pipe(map(result => result.datos));
  }
  ABMObraSocialPageModel
  public list(datosBusqueda: ABMGenericoConsultaModel): Observable<ABMGenericoModel<ABMObraSocialModel>> {
    return this.http.get<APIReturnModel<ABMGenericoModel<ABMObraSocialModel>>>(FacturacionApiConfig.OBRA_SOCIAL_LIST_URL,
      this.HttpHelpers.buildQueryParams(datosBusqueda))
      .pipe(map(result => result.datos));
  }
  public listNew(datosBusqueda: ABMGenericoConsultaModel): Observable<ABMGenericoModel<ABMObraSocialPageModel>> {
    return this.http.get<APIReturnModel<ABMGenericoModel<ABMObraSocialPageModel>>>(FacturacionApiConfig.OBRA_SOCIAL_LIST_URL,
      this.HttpHelpers.buildQueryParams(datosBusqueda))
      .pipe(map(result => result.datos));
  }
  public uno(obraSocialId: number): Observable<ABMObraSocialModel> {
    return this.http.get<APIReturnModel<ABMObraSocialModel>>(FacturacionApiConfig.OBRA_SOCIAL_UNO_URL,
      this.HttpHelpers.buildQueryParams(obraSocialId, 'obraSocialId'))
      .pipe(map(result => result.datos));
  }
  public planes(obraSocialId: number): Observable<SelectObjModel[]> {
    return this.http.get<APIReturnModel<SelectObjModel[]>>(FacturacionApiConfig.OBRA_SOCIAL_PLANES_URL,
      this.HttpHelpers.buildQueryParams(obraSocialId, 'obraSocialId'))
      .pipe(map(result => result.datos));
  }
  public busquedaSelect(busqueda: string): Observable<SelectObjModel[]> {
    return this.http.get<APIReturnModel<SelectObjModel[]>>(FacturacionApiConfig.OBRA_SOCIAL_BUSQUEDA_SELECT_URL,
      this.HttpHelpers.buildQueryParams(busqueda, 'busqueda'))
      .pipe(map(result => result.datos));
  }
  public planesUsuario(usuarioId: number): Observable<SelectObjModel[]> {
    return this.http.get<APIReturnModel<SelectObjModel[]>>(FacturacionApiConfig.OBRA_SOCIAL_PLANES_USUARIO_URL,
      this.HttpHelpers.buildQueryParams(usuarioId, 'usuarioId'))
      .pipe(map(result => result.datos));
  }

}
