import { HistoriaClinicaService } from 'src/app/services/historia-clinica/historia-clinica.service';
import { PersonalService } from 'src/app/services/empresa/personal.service';
import { ClienteService } from '../../../services/caja/cliente.service';
import { PacienteService } from '../../../services/paciente/paciente.service';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ElementRef,
  ViewChild,
  AfterContentInit,
  OnDestroy,
  ChangeDetectorRef,
  AfterViewInit
} from '@angular/core';
import { TipoBuscadorAutoCompleteEnum } from 'src/app/models/shared/enums.model';
import { debounceTime, filter, tap, switchMap, finalize } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { SelectObjModel } from 'src/app/models/shared/selects.model';
import { ObraSocialService } from 'src/app/services/facturacion/obra-social.service';
import { AgendasService } from 'src/app/services/empresa/agendas.service';
import { AcreedorService } from 'src/app/services/caja/acreedor.service';
import { EmpresaService } from 'src/app/services/empresa/empresa.service';
import { LugarService } from 'src/app/services/lugar/lugar.services';
import { DiagnosticoService } from 'src/app/services/receta/diagnostico.service';
import { MedicamentoService } from 'src/app/services/receta/medicamento.service';
import { PreserfarService } from 'src/app/services/receta/preserfar.service';

@Component({
  selector: 'app-busqueda-autocomplete',
  templateUrl: './busqueda-autocomplete.component.html',
  styleUrls: ['./busqueda-autocomplete.component.scss']
})
export class BusquedaAutocompleteComponent implements OnInit, OnChanges, AfterContentInit, OnDestroy {
  @Input() tipoBuscador: TipoBuscadorAutoCompleteEnum;
  @Input() requerido: boolean;
  @Input() valor: any;
  @Input() deshabilitado: boolean;
  @Input() mostrarError: boolean = true;
  @Input() classInput: string;
  @Input() claseMatForm: string;
  @Input() hideRequiredMarker: Boolean = false;
  @Input() floatLabel: string="auto";
  @Input() elegirPrimeraOpcion = true;
  @Input() requerirMatch = true;
  @Input() label = 'Paciente';
  @Input() soloPaciente = false;
  @Input() enfermedades = false;
  @Output() evLimpiar = new EventEmitter();
  @Output() evElegir = new EventEmitter<any>();
  @Output() focus = new EventEmitter();
  @Output() blur = new EventEmitter();
  @ViewChild('buscador') input: ElementRef;

  entidades = new Array<any>();
  isLoading = false;
  formBusqueda: UntypedFormGroup;
  subBusqueda: Subscription;
  frenarBusqueda = false;
  tiposBuscador = TipoBuscadorAutoCompleteEnum;

  constructor(
    private empresaServ: EmpresaService,
    private pacienteServ: PacienteService,
    private clienteServ: ClienteService,
    private acreedorServ: AcreedorService,
    private personalServ: PersonalService,
    private osServ: ObraSocialService,
    private agendasServ: AgendasService,
    private hcServ: HistoriaClinicaService,
    private LugarServ: LugarService,
    private DiagnosticoSer:DiagnosticoService,
    private PreserfarSer:PreserfarService
  ) {
   
  }
  ngOnDestroy(): void {
    if (this.subBusqueda) {
      this.subBusqueda.unsubscribe();
    }
  }
  ngOnInit() {
    this.formBusqueda = new UntypedFormGroup({
      busqueda: new UntypedFormControl({ value: this.valor, disabled: this.deshabilitado })
    });
    if ((this.requerido || this.elegirPrimeraOpcion) && this.mostrarError === true) {
      if (this.requerido && (this.elegirPrimeraOpcion && this.requerirMatch)) {
        this.formBusqueda.get('busqueda').setValidators([Validators.required, this.requireMatch.bind(this)]);
      } else if (this.requerido && (!this.elegirPrimeraOpcion && !this.requerirMatch)) {
        this.formBusqueda.get('busqueda').setValidators([Validators.required]);
      } else if (!this.requerido && (this.elegirPrimeraOpcion && this.requerirMatch)) {
        this.formBusqueda.get('busqueda').setValidators([this.requireMatch.bind(this)]);
      }
    }
      this.personalServ.limpiabuscadorPersonal.subscribe(x => {
        this.formBusqueda.get('busqueda').setValue(null);
      });
      this.subBusqueda = this.crearSub();
      if (!this.label) {
        switch (this.tipoBuscador) {
          case TipoBuscadorAutoCompleteEnum.EMPRESA:
            this.label = 'Empresa';
            break;
          case TipoBuscadorAutoCompleteEnum.PACIENTE:
            this.label = 'Paciente';
            break;
          case TipoBuscadorAutoCompleteEnum.PERSONAL:
            this.label = 'Prestador';
            break;
          case TipoBuscadorAutoCompleteEnum.AGENDA:
            this.label = 'Agenda';
            break;
          case TipoBuscadorAutoCompleteEnum.CLIENTE:
            this.label = 'Cliente';
            break;
          case TipoBuscadorAutoCompleteEnum.ACREEDOR:
            this.label = 'Acreedor';
            break;
          case TipoBuscadorAutoCompleteEnum.OBRASOCIAL:
            this.label = 'Obra Social';
            break;
          case TipoBuscadorAutoCompleteEnum.MEDICAMENTO:
            this.label = 'Medicamento';
            break;
          case TipoBuscadorAutoCompleteEnum.CIE10:
            if (this.enfermedades)
            {
              this.label = 'Enfermedad';
            } else {
              this.label = 'Diagnostico';
            }
            break;
          case TipoBuscadorAutoCompleteEnum.MONODROGA:
            this.label = 'Monodroga';
            break;
          case TipoBuscadorAutoCompleteEnum.ALIMENTOS:
            this.label = 'Alimento';
            break;
          case TipoBuscadorAutoCompleteEnum.LUGAR:
            this.label = 'Lugar';
            break;
            case TipoBuscadorAutoCompleteEnum.PRESTRACION:
              this.label = 'Prestacion'
        }
      }
   
  }
  ngAfterContentInit() {
    if (!this.elegirPrimeraOpcion) {
      setTimeout(() => {
        this.input.nativeElement.focus();
      }, 200);
    }
  }
  requireMatch(control: AbstractControl) {
    const selection: any = control.value;
    if (typeof selection === 'string' && selection.length > 0) {
      control.markAsTouched();
      return { noMatch: true };
    }
  }
  private crearSub(): Subscription {
    switch (this.tipoBuscador) {
      case TipoBuscadorAutoCompleteEnum.EMPRESA:
        return this.setearInput(this.empresaServ.busquedaSelect.bind(this.empresaServ));
      case TipoBuscadorAutoCompleteEnum.PACIENTE:
        if (this.soloPaciente === false) {
          return this.setearInput(this.pacienteServ.busquedaSelect.bind(this.pacienteServ));
        } else {
          return this.setearInput(this.pacienteServ.querySoloPte.bind(this.pacienteServ));
        }
      case TipoBuscadorAutoCompleteEnum.PERSONAL:
        return this.setearInput(this.personalServ.busquedaSelect.bind(this.personalServ));
      case TipoBuscadorAutoCompleteEnum.AGENDA:
        return this.setearInput(this.agendasServ.busquedaSelect.bind(this.agendasServ));
      case TipoBuscadorAutoCompleteEnum.CLIENTE:
        return this.setearInput(this.clienteServ.busquedaSelect.bind(this.clienteServ));
      case TipoBuscadorAutoCompleteEnum.ACREEDOR:
        return this.setearInput(this.acreedorServ.busquedaSelect.bind(this.acreedorServ));
      case TipoBuscadorAutoCompleteEnum.OBRASOCIAL:
        return this.setearInput(this.osServ.busquedaSelect.bind(this.osServ));
      case TipoBuscadorAutoCompleteEnum.MEDICAMENTO:
        //return this.setearInput(this.hcServ.medicamentoSearch.bind(this.hcServ));
       // return this.setearInput(this.MedicamentoSer.consultarMedicamento.bind(this.MedicamentoSer));
       return this.setearInput(this.PreserfarSer.buscarProducto.bind(this.PreserfarSer))
      case TipoBuscadorAutoCompleteEnum.CIE10:
        if (this.enfermedades)
        {
          return this.setearInput(this.DiagnosticoSer.consultarDiagnosticoProblemaofertaTexto.bind(this.DiagnosticoSer));
        }else {
          return
        }

      case TipoBuscadorAutoCompleteEnum.ALIMENTOS:
        return this.setearInput(this.hcServ.traerAlimentoPorFiltro.bind(this.hcServ));
      case TipoBuscadorAutoCompleteEnum.MONODROGA:
        return this.setearInput(this.hcServ.monodrogasSearch.bind(this.hcServ));
      case TipoBuscadorAutoCompleteEnum.LUGAR:
        return this.setearInput(this.LugarServ.busquedaSelect.bind(this.LugarServ));
        case TipoBuscadorAutoCompleteEnum.PRESTRACION:
          return this.setearInput(this.DiagnosticoSer.consultarDiagnosticoPractica.bind(this.DiagnosticoSer))
        break;
        
    }
  }
  setearInput(method: (value: string) => Observable<SelectObjModel[]>) {
    let minimoBusqueda = 0;
    switch (this.tipoBuscador) {
      default:
      case TipoBuscadorAutoCompleteEnum.EMPRESA:
      case TipoBuscadorAutoCompleteEnum.PACIENTE:
      case TipoBuscadorAutoCompleteEnum.PERSONAL:
      case TipoBuscadorAutoCompleteEnum.AGENDA:
      case TipoBuscadorAutoCompleteEnum.CLIENTE:
      case TipoBuscadorAutoCompleteEnum.ACREEDOR:
      case TipoBuscadorAutoCompleteEnum.OBRASOCIAL:
      case TipoBuscadorAutoCompleteEnum.LUGAR:
      case TipoBuscadorAutoCompleteEnum.MONODROGA:
        minimoBusqueda = 3;
        break;
      case TipoBuscadorAutoCompleteEnum.MEDICAMENTO:
        minimoBusqueda = 2;
        break;
      case TipoBuscadorAutoCompleteEnum.CIE10:
        minimoBusqueda = 2;
        break;
      case TipoBuscadorAutoCompleteEnum.ALIMENTOS:
        minimoBusqueda = 2;
        break;
        case TipoBuscadorAutoCompleteEnum.PRESTRACION:
        minimoBusqueda = 2;
        break;
    }
    return this.formBusqueda.get('busqueda').valueChanges.pipe(
      filter(x => !this.frenarBusqueda),
      tap(value => {
        let limpiar = false;
        if (value !== null && value !== undefined && (typeof value === 'string')) {
          if (value.length <= minimoBusqueda && value.length > 0) {
            if (this.mostrarError === true) {
              this.formBusqueda.get('busqueda').setErrors({ menosTres: true });
            } else {
              this.formBusqueda.get('busqueda').setErrors(null);
            }
            this.formBusqueda.get('busqueda').markAsTouched();
          } else if (this.entidades.findIndex(x => x.nombre === value) === -1) {
            this.valor = null;
          }
          if (value.length === 0) {
            this.evLimpiar.emit();
          }
          limpiar = true;
        }
        if (!value) {
          limpiar = true;
        }
        if (limpiar) {
          this.entidades = new Array<any>();
          return;
        }
      }),
      filter(x => x && (typeof (x) === 'string' && x.length > minimoBusqueda)),
      tap(x => {
        if (this.tipoBuscador === TipoBuscadorAutoCompleteEnum.MONODROGA && !this.requerirMatch && this.mostrarError === true) {
          if (x.length > 150) {
            this.formBusqueda.get('busqueda').setErrors({ alergiaMayor150: true });
            this.formBusqueda.get('busqueda').markAsTouched();
            return;
          }
        }
      }),
      debounceTime(600),
      tap(() => this.isLoading = true),
      switchMap(value => method(value).pipe(
        finalize(() => this.isLoading = false),
      ))
    ).subscribe(x => {
      if (this.formBusqueda.get('busqueda').value) {
        if (this.formBusqueda.get('busqueda').value !== '') {
          if (x.length === 0 && this.requerirMatch) {
            if (this.mostrarError === true) {
              this.formBusqueda.get('busqueda').setErrors({ noResultados: true });
            } else {
              this.formBusqueda.get('busqueda').setErrors(null);
            }
            this.formBusqueda.get('busqueda').markAsTouched();
          }
          this.entidades = x;
        } else {
          this.entidades = new Array<any>();
        }
      } else {
        this.entidades = new Array<any>();
      }
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.formBusqueda) {
      if (changes.requerido && this.mostrarError === true) {
        this.requerido = changes.requerido.currentValue;
        if (this.requerido) {
          this.formBusqueda.get('busqueda').setValidators(this.requerido ?
            [Validators.required, this.requireMatch.bind(this)] :
            [this.requireMatch.bind(this)]);
          if (!this.formBusqueda.get('busqueda').value || this.formBusqueda.get('busqueda').value === '') {
            this.formBusqueda.get('busqueda').setErrors({ required: true });
            this.formBusqueda.get('busqueda').markAsTouched();
          }
        } else {
          this.formBusqueda.get('busqueda').setValidators([this.requireMatch.bind(this)]);
          this.formBusqueda.get('busqueda').setErrors(null);
        }
      }
      if (changes.deshabilitado && this.mostrarError === true) {
        this.deshabilitado = changes.deshabilitado.currentValue;
        if (this.deshabilitado) {
          this.formBusqueda.get('busqueda').disable();
        } else {
          this.formBusqueda.get('busqueda').enable();
        }
      }
      if (changes.valor && this.mostrarError === true) {
        this.valor = changes.valor.currentValue;
        this.formBusqueda.get('busqueda').setValue(this.valor);
        if (this.valor === '' || !this.valor) {
          this.entidades = new Array<any>();
        }
      }
      if (changes.mostrarError && this.mostrarError === true) {
        if (changes.mostrarError.currentValue) {
          this.formBusqueda.get('busqueda').setErrors({ required: true });
          this.formBusqueda.get('busqueda').markAsTouched();
        } else {
          this.formBusqueda.get('busqueda').setErrors(null);
        }
      }

      if (changes.label) {
        this.label = changes.label.currentValue;
      }
    }
  }
  displayWith(entidad: any): string {
    if (entidad) {
      switch (this.tipoBuscador) {
        case TipoBuscadorAutoCompleteEnum.EMPRESA:
        case TipoBuscadorAutoCompleteEnum.PACIENTE:
        case TipoBuscadorAutoCompleteEnum.PERSONAL:
        case TipoBuscadorAutoCompleteEnum.AGENDA:
        case TipoBuscadorAutoCompleteEnum.OBRASOCIAL:
        case TipoBuscadorAutoCompleteEnum.MONODROGA:
        case TipoBuscadorAutoCompleteEnum.LUGAR:
        case TipoBuscadorAutoCompleteEnum.ACREEDOR:
          return entidad.nombre;
        case TipoBuscadorAutoCompleteEnum.CLIENTE:
          return entidad.razonSocial;
        case TipoBuscadorAutoCompleteEnum.MEDICAMENTO:
          return entidad.descripcion;
          //return entidad.codigo + ' - ' + entidad.nombre + ' - ' + entidad.posologia + ' x' + entidad.contenido + ' ' + entidad.formaFamarcologica ;
        case TipoBuscadorAutoCompleteEnum.CIE10:
          return entidad.texto;
        case TipoBuscadorAutoCompleteEnum.ALIMENTOS:
          return entidad.nombre + ' - Prc: ' + entidad.porcion + ' - Kcal: ' + entidad.calorias + ' - CarboHidratos: ' + entidad.carbohidratos + ' - Fibra: ' + entidad.fibra + ' - Sodio: ' + entidad.sodio + ' - Proteinas: ' + entidad.proteinas;
          case TipoBuscadorAutoCompleteEnum.PRESTRACION:
            return entidad.texto;
        }
    }
  }
  limpiarAutocomplete() {
    this.formBusqueda.get('busqueda').setValue(null);
    this.evLimpiar.emit();
  }
  pressEnter() {
    if (!this.elegirPrimeraOpcion) {
      if (this.formBusqueda.get('busqueda').value) {
        this.evElegir.emit(this.formBusqueda.get('busqueda').value);
        if (this.formBusqueda.get('busqueda').valid) {
          this.frenarBusqueda = true;
          this.formBusqueda.get('busqueda').setValue('');
          setTimeout(() => this.frenarBusqueda = false);
        }
      }
    }
  }
  public isInvalid(): boolean {
    this.formBusqueda.get('busqueda').markAsTouched();
    return this.formBusqueda.get('busqueda').invalid;
  }
  public setearError(error: any) {
    setTimeout(() => {
      this.formBusqueda.get('busqueda').setErrors(error);
      this.formBusqueda.get('busqueda').markAsTouched();
    });
  }
}
