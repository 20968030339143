import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HttpHelperService } from '../helpers/http-helper.service';
import { APIReturnModel } from 'src/app/models/shared/api-return.model';
import {
  shifterTurno,SedeSelectModel,EmpresaSelectModel
} from 'src/app/models/turnos/shifter-turno.model';
import {ShifterApiConfig} from 'src/app/services/shifter/shifter-api-config'
@Injectable({
  providedIn: 'root'
})
export class ShifterService {

  constructor(private http: HttpClient,
    private HttpHelpers: HttpHelperService) { }

  public getCurrentTurno(SedeId: any): Observable<shifterTurno[]> {
    return this.http.get<APIReturnModel<shifterTurno[]>>(ShifterApiConfig.TURNOS_CURRENT_URL,
      this.HttpHelpers.buildQueryParams({ SedeId:SedeId }))
      .pipe(map(result => result.datos));
  }
  public GetCentroList(): Observable<EmpresaSelectModel[]> {
    return this.http.get<APIReturnModel<EmpresaSelectModel[]>>(ShifterApiConfig.EMPRESA_LIST_URL)
      .pipe(map(result => result.datos));
    }
  public GetSedeList(EmpresaId:any): Observable<SedeSelectModel[]> {
    
    return this.http.get<APIReturnModel<SedeSelectModel[]>>(ShifterApiConfig.EMPRESA_SEDE_LIST_URL,
      this.HttpHelpers.buildQueryParams( {EmpresaId:EmpresaId}))
      .pipe(map(result => result.datos));   

}

}
