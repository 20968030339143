import { LoginService } from 'src/app/services/login/login.service';
import { ABMPacienteListModel, ABMPacienteUpdateModel } from '../../../models/paciente/abm-paciente.model';
import { BusquedaAutocompleteComponent } from '../../shared/busqueda-autocomplete/busqueda-autocomplete.component';
import { PacienteService } from 'src/app/services/paciente/paciente.service';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { BuscadorAutoCompleteProps } from 'src/app/models/shared/selects.model';
import { TipoBuscadorAutoCompleteEnum } from 'src/app/models/shared/enums.model';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ModalUpdateGenericoComponent } from '../../shared/modal-update-generico/modal-update-generico.component';
import { ModulosModalUpdateEnum } from 'src/app/models/shared/modulos-modal.enum';

@Component({
  selector: 'app-busqueda-paciente',
  templateUrl: './busqueda-paciente.component.html',
  styleUrls: ['./busqueda-paciente.component.scss']
})
export class BusquedaPacienteComponent implements OnInit {
  @Input() estadoBusqueda = 'closed';
  @Output() cambiarEstadoNav = new EventEmitter();


  @ViewChild(BusquedaAutocompleteComponent) buscador: BusquedaAutocompleteComponent;
  propPaciente = new BuscadorAutoCompleteProps(TipoBuscadorAutoCompleteEnum.PACIENTE, false, null, false, null);
  divColor: string = '#EAF1FB';
  constructor(
    public loginServ: LoginService,
    private pacServ: PacienteService,
    public route: Router,
    private modalpage: MatDialog
  ) { }

  ngOnInit() {
  }
  elegirPaciente(item: any) {
    this.propPaciente.valor = item;
    this.buscar();
  }
  limpiarPaciente() {
    this.propPaciente.valor = null;
  }
  buscar() {
    if (this.buscador.formBusqueda.get('busqueda').invalid) {
      return;
    } else {
      if (!this.propPaciente.valor) {
        this.propPaciente.valor = this.buscador.formBusqueda.get('busqueda').value;
      }
      if (this.propPaciente.valor.id) {
        this.irUpdatePaciente(this.propPaciente.valor.id);
      } else {
        if (this.propPaciente.valor.trim().length > 3) {
          this.pacServ.hayPacientesBusqueda(this.propPaciente.valor.trim()).toPromise().then(result => {
            if (result === 0) {
              this.buscador.setearError({ noPacientes: true });
              // this.buscador.formBusqueda.get('busqueda').setErrors({ noPacientes: true });
              // this.buscador.formBusqueda.get('busqueda').markAsTouched();
              return;
            }
            this.busquedaListOUpdate(result);
          });
        }
      }
    }
  }
  animacion() {
    this.estadoBusqueda = this.estadoBusqueda === 'closed' ? 'enter' : 'closed';
    this.cambiarEstadoNav.emit();
  }
  busquedaListOUpdate(pacientesUnicoId: number) {
    if (!pacientesUnicoId) {
      // this.pacServ.pacientesBusqueda = pacientes;
      this.pacServ.mostrarOpciones = false;
      this.route.navigate(['/paciente/list'], {
        skipLocationChange: true,
        queryParams: { filtro: this.buscador.formBusqueda.get('busqueda').value }
      });
      this.pacServ.pacienteBusquedaEv.emit(this.buscador.formBusqueda.get('busqueda').value);
      this.animacion();
    } else {
      this.irUpdatePaciente(pacientesUnicoId);
    }
  }
  irUpdatePaciente(pacienteId: number) {
    this.pacServ.uno(pacienteId).toPromise().then(result => {
      this.pacServ.PacienteUpdate = result;
      this.pacServ.mostrarOpciones = true;
      this.route.navigate(['/paciente/update'], { skipLocationChange: true });
      this.pacServ.pacienteUpdateEv.emit();
      this.animacion();
    });
  }
  pacienteNuevo() {
    this.pacServ.PacienteUpdate = new ABMPacienteUpdateModel();
    this.animacion();
    const dialogPaciente =
      this.modalpage.open(ModalUpdateGenericoComponent, {
        data: {
          modulo: ModulosModalUpdateEnum.PACIENTE,
        },
        panelClass: 'custom-dialog-container-auto'
      });
    dialogPaciente.afterClosed().subscribe((pac: ABMPacienteListModel) => {
      dialogPaciente.close();
      if (pac) {
        this.pacServ.uno(pac.id).toPromise().then(result => {
          this.pacServ.PacienteUpdate = result;
          this.pacServ.mostrarOpciones = true;
          this.route.navigate(['/paciente/update'], { skipLocationChange: true });
          this.pacServ.pacienteUpdateEv.emit();
        });
      }
    });
  }
  textBarFocus()
  {
    this.divColor = "white";
  }
  textBarBlur()
  {
    this.divColor = "#EAF1FB";
  }
}
