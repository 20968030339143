import { PresupuestoItemModel } from './../../models/historia-clinica/hc-odontogramas.model';
import { SelectObjModel } from 'src/app/models/shared/selects.model';
import { HCArchivosAdjuntosModel, HCReferenciaUpdateModel, HCCertificadoListModel, HCTratamientoListModel } from './../../models/historia-clinica/historia-clinica.model';
import { HCCertificadoViewModel, HCCertificadoNewModel, HCTratamientoNewModel, HCTratamientoViewModel } from '../../models/historia-clinica/hc-certificado-new.model';
import {
  OdontogramaListModel,
  OdontogramaImpresionModel,
  OdontogramaUpdateModel,
  DienteModel,
  OdontogramaListConsultaModel,
  OdontogramaMovimientoAnteriorModel, OdontogramaMovimientosModel
} from '../../models/historia-clinica/hc-odontogramas.model';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  DatosHCModel,
  ProcesoNuevoModel,
  ProcesosHCModel
} from 'src/app/models/historia-clinica/historia-clinica.model';
import { DiagnosticosModel } from 'src/app/models/shared/diagnosticos-cie10.model';
import { APIReturnModel } from 'src/app/models/shared/api-return.model';
import { HttpHelperService } from '../helpers/http-helper.service';
import { HistoriaClinicaApiConfig } from './historia-clinica-api-config';
import { MedicamentoBusquedaModel } from 'src/app/models/historia-clinica/hc-prescripciones.model';
import { DietaModel, IMCBuscarPorFecha, IndiceIMCModel, NuevoIndiceIMCModel } from 'src/app/models/historia-clinica/hc-nuevoIndiceIMC.model';
import { AlimentoCalcularModel, AlimentoCategoriaModel, AlimentosModel, CNActividadDiariaModel, ctosGinecologicosModel, HCNAntropometriasModel, indicadoresClinicosModel, prestadoresBioquimicosModel } from 'src/app/models/historia-clinica/hc-nutricion.model';
import { DatosPersonaHeaderModel } from 'src/app/models/shared/persona.model';


@Injectable({
  providedIn: 'root'
})
export class HistoriaClinicaService {
  public pacienteUsId: number;
  public procesoElegidoId: number;
  public tieneAgenda: boolean;
  public filtrarPorProceso = true;
  public cambiarFiltroEvent = new EventEmitter();
  public filtrarPorTodosPrivado = false;
  public todosFiltroEvent = new EventEmitter();
  public idTurno: number;
  public preguntarCambios = new EventEmitter();
  public contestarCambios = new EventEmitter();
  public volverAListado = new EventEmitter();
  public guardarDetalleSemanal = new EventEmitter();
  public cambiarVistaDietario = new EventEmitter();
  public actualizarEliminaDieta = new EventEmitter();
  public muestraDietaPaciente = new EventEmitter();
  public cierraEdicionNota = new EventEmitter();

  constructor(
    private http: HttpClient,
    private HttpHelpers: HttpHelperService
  ) { }

  public datosHC(): Observable<DatosHCModel> {
    return this.http.get<APIReturnModel<DatosHCModel>>(HistoriaClinicaApiConfig.HISTORIA_CLINICA_DATOS_URL,
      this.HttpHelpers.buildQueryParams(this.pacienteUsId, 'pacienteUsId'))
      .pipe(map(result => result.datos));
  }
  public procesoNuevo(diagProceso: ProcesoNuevoModel): Observable<ProcesosHCModel> {
    return this.http.post<APIReturnModel<ProcesosHCModel>>(HistoriaClinicaApiConfig.HC_PROCESO_NUEVO_URL, diagProceso)
      .pipe(map(result => result.datos));
  }
  public procesosPaciente(): Observable<ProcesosHCModel[]> {
    return this.http.get<APIReturnModel<ProcesosHCModel[]>>(HistoriaClinicaApiConfig.HC_PROCESOS_PACIENTE_URL,
      this.HttpHelpers.buildQueryParams(this.pacienteUsId, 'pacienteUsId'))
      .pipe(map(result => result.datos));
  }

  public ultimaAlturaProceso(): Observable<string> {
    return this.http.get<APIReturnModel<string>>(HistoriaClinicaApiConfig.HC_ULTIMA_ALTURA_PROCESO_URL,
      this.HttpHelpers.buildQueryParams(this.pacienteUsId, 'pacienteUsId'))
      .pipe(map(result => result.datos));
  }

  public diagnosticosCie10(busqueda: string): Observable<DiagnosticosModel[]> {
    return this.http.get<APIReturnModel<DiagnosticosModel[]>>(HistoriaClinicaApiConfig.HC_DIAGNOSTICOS_CIE10_URL,
      this.HttpHelpers.buildQueryParams(busqueda, 'busqueda'))
      .pipe(map(result => result.datos));
  }

  public odontogramaActual(): Observable<OdontogramaUpdateModel> {
    return this.http.get<APIReturnModel<OdontogramaUpdateModel>>(HistoriaClinicaApiConfig.HC_ODONTOGRAMA_ACTUAL_URL,
      this.HttpHelpers.buildQueryParams(this.pacienteUsId, 'pacienteUsId'))
      .pipe(map(result => result.datos));
  }

  public odontogramasList(datosConsulta: OdontogramaListConsultaModel): Observable<OdontogramaListModel[]> {
    return this.http.get<APIReturnModel<OdontogramaListModel[]>>(HistoriaClinicaApiConfig.HC_ODONTOGRAMAS_LIST_URL,
      this.HttpHelpers.buildQueryParams(datosConsulta))
      .pipe(map(result => result.datos));
  }
  public unOdontograma(odontogramaId: number): Observable<OdontogramaImpresionModel> {
    return this.http.get<APIReturnModel<OdontogramaImpresionModel>>(HistoriaClinicaApiConfig.HC_UN_ODONTOGRAMA_URL,
      this.HttpHelpers.buildQueryParams(odontogramaId, 'odontogramaId'))
      .pipe(map(result => result.datos));
  }
  public actualizarOdontograma(odontograma: OdontogramaUpdateModel): Observable<OdontogramaImpresionModel> {
    return this.http.put<APIReturnModel<OdontogramaImpresionModel>>(HistoriaClinicaApiConfig.HC_ACTUALIZAR_ODONTOGRAMA_URL, odontograma)
      .pipe(map(result => result.datos));
  }
  public PresupuestoOdontograma(odontograma: OdontogramaUpdateModel): Observable<OdontogramaImpresionModel> {
    return this.http.put<APIReturnModel<OdontogramaImpresionModel>>(HistoriaClinicaApiConfig.HC_PRESUPUESTO_ODONTOGRAMA_URL, odontograma)
      .pipe(map(result => result.datos));
  }

  public deleteMovimientoOdontograma(movimientoId: number): Observable<string> {
    return this.http.delete<APIReturnModel<string>>(HistoriaClinicaApiConfig.HC_DELETE_MOVIMIENTO_ODONTOGRAMA_URL,
      this.HttpHelpers.buildQueryParams(movimientoId, 'movimientoId'))
      .pipe(map(result => result.datos));
  }
  public guardarMovAntOdontograma(movAnt: OdontogramaMovimientoAnteriorModel): Observable<DienteModel> {
    return this.http.put<APIReturnModel<DienteModel>>(HistoriaClinicaApiConfig.HC_GUARDAR_MOV_ANT_ODONTOGRAMA_URL, movAnt)
      .pipe(map(result => result.datos));
  }
  public newCertificado(certificado: HCCertificadoNewModel): Observable<HCCertificadoViewModel> {
    return this.http.put<APIReturnModel<HCCertificadoViewModel>>(HistoriaClinicaApiConfig.HC_CERTIFICADO_UPDATE_URL, certificado)
      .pipe(map(result => result.datos));
  }
  public unoCertificado(certificadoId: number): Observable<HCCertificadoViewModel> {
    return this.http.get<APIReturnModel<HCCertificadoViewModel>>(HistoriaClinicaApiConfig.HC_CERTIFICADO_UNO_URL,
      this.HttpHelpers.buildQueryParams(certificadoId, 'certificadoId'))
      .pipe(map(result => result.datos));
  }
  public conseguirReferencias(): Observable<HCReferenciaUpdateModel> {
    return this.http.get<APIReturnModel<HCReferenciaUpdateModel>>(HistoriaClinicaApiConfig.HC_CONSEGUIR_REFERENCIAS_URL,
      this.HttpHelpers.buildQueryParams(this.pacienteUsId, 'pacienteUsId'))
      .pipe(map(result => result.datos));
  }
  public updateReferencias(referencias: HCReferenciaUpdateModel): Observable<HCReferenciaUpdateModel> {
    return this.http.post<APIReturnModel<HCReferenciaUpdateModel>>(HistoriaClinicaApiConfig.HC_REFERENCIAS_UPDATE_URL, referencias)
      .pipe(map(result => result.datos));
  }
  public updateHeaderReferencias(referencias: DatosPersonaHeaderModel): Observable<DatosPersonaHeaderModel> {
    return this.http.post<APIReturnModel<DatosPersonaHeaderModel>>(HistoriaClinicaApiConfig.HC_HEADER_REFERENCIAS_UPDATE_URL, referencias)
      .pipe(map(result => result.datos));
  }
  // MEDICAMENTOS
  public medicamentoSearch(busqueda: string): Observable<MedicamentoBusquedaModel[]> {
    return this.http.get<APIReturnModel<MedicamentoBusquedaModel[]>>(HistoriaClinicaApiConfig.HC_MEDICAMENTO_SEARCH,
      this.HttpHelpers.buildQueryParams(busqueda, 'busqueda'))
      .pipe(map(result => result.datos));
  }
  public monodrogasSearch(busqueda: string): Observable<SelectObjModel[]> {
    return this.http.get<APIReturnModel<SelectObjModel[]>>(HistoriaClinicaApiConfig.HC_MONODROGAS_SEARCH,
      this.HttpHelpers.buildQueryParams(busqueda, 'busqueda'))
      .pipe(map(result => result.datos));
  }
  public actualizarIMC(nuevoIMC: NuevoIndiceIMCModel): Observable<NuevoIndiceIMCModel> {
    return this.http.post<APIReturnModel<NuevoIndiceIMCModel>>(HistoriaClinicaApiConfig.HC_NUEVO_INDICE_IMC_URL, nuevoIMC)
      .pipe(map(result => result.datos));
  }
  public BuscarIMCporFecha(IMCFecha: IMCBuscarPorFecha): Observable<IndiceIMCModel[]> {
    return this.http.get<APIReturnModel<IndiceIMCModel[]>>(HistoriaClinicaApiConfig.HC_TRAER_POR_FECHA_INDICE_IMC_URL,
      this.HttpHelpers.buildQueryParams(IMCFecha))
      .pipe(map(result => result.datos));
  }
  public eliminarIMC(IMCId: number): Observable<string> {
    return this.http.delete<APIReturnModel<string>>(HistoriaClinicaApiConfig.HC_BORRAR_INDICE_IMC_URL,
      this.HttpHelpers.buildQueryParams(IMCId, 'IMCId'))
      .pipe(map(result => result.datos));
  }
  public listarPorIdusuarioIMC(usuarioId: number): Observable<IndiceIMCModel[]> {
    return this.http.get<APIReturnModel<IndiceIMCModel[]>>(HistoriaClinicaApiConfig.HC_LISTAR_INDICE_IMC_URL,
      this.HttpHelpers.buildQueryParams(usuarioId, 'usuarioId'))
      .pipe(map(result => result.datos));
  }
  public EnviarEmailConReceta(RecetaId: number): Observable<boolean> {
    return this.http.get<APIReturnModel<boolean>>(HistoriaClinicaApiConfig.HC_PRESCRIPCIONES_ENVIAR_PDF_MAIL_URL,
      this.HttpHelpers.buildQueryParams(RecetaId, 'RecetaId'))
      .pipe(map(result => result.datos));
  }
  public copiarNueva(RecetaId: number): Observable<boolean> {
    return this.http.get<APIReturnModel<boolean>>(HistoriaClinicaApiConfig.HC_PRESCRIPCIONES_ENVIAR_NUEVA_RECETA_URL,
      this.HttpHelpers.buildQueryParams(RecetaId, 'RecetaId'))
      .pipe(map(result => result.datos));
  }
  public movOdontoConTurno(turnoId: number): Observable<OdontogramaMovimientosModel[]> {
    return this.http.get<APIReturnModel<OdontogramaMovimientosModel[]>>(HistoriaClinicaApiConfig.HC_MOV_ODONTO_CON_TURNO_URL,
      this.HttpHelpers.buildQueryParams(turnoId, 'turnoId'))
      .pipe(map(result => result.datos));
  }

  public actualizarPresupuesto(odontograma: OdontogramaUpdateModel): Observable<boolean> {
    return this.http.put<APIReturnModel<boolean>>(HistoriaClinicaApiConfig.HC_ACTUALIZAR_PRESUPUESTO_URL, odontograma)
      .pipe(map(result => result.datos));
  }

  public obtenerMovimientosRealizados(pacienteId: number): Observable<OdontogramaMovimientosModel[]> {
    return this.http.get<APIReturnModel<OdontogramaMovimientosModel[]>>(HistoriaClinicaApiConfig.HC_MOV_REALIZADOS_URL,
      this.HttpHelpers.buildQueryParams(pacienteId, 'pacienteId'))
      .pipe(map(result => result.datos));
  }

  public obtenerMovimientosAnteriores(pacienteId: number): Observable<OdontogramaMovimientosModel[]> {
    return this.http.get<APIReturnModel<OdontogramaMovimientosModel[]>>(HistoriaClinicaApiConfig.HC_MOV_ANTERIORES_URL,
      this.HttpHelpers.buildQueryParams(pacienteId, 'pacienteId'))
      .pipe(map(result => result.datos));
  }

  public obtenerPresupuestos(pacienteId: number): Observable<PresupuestoItemModel[]> {
    return this.http.get<APIReturnModel<PresupuestoItemModel[]>>(HistoriaClinicaApiConfig.HC_LIST_PRESUPUESTO_URL,
      this.HttpHelpers.buildQueryParams(pacienteId, 'pacienteId'))
      .pipe(map(result => result.datos));
  }
  public actualizarHCDietario(dieta: DietaModel): Observable<DietaModel> {
    return this.http.post<APIReturnModel<DietaModel>>(HistoriaClinicaApiConfig.HC_ACTUALIZAR_DIETARIO_URL, dieta)
      .pipe(map(result => result.datos));
  }
  public traerDietasPorNombre(cadena: string): Observable<DietaModel[]> {
    return this.http.get<APIReturnModel<DietaModel[]>>(HistoriaClinicaApiConfig.HC_TRAER_DIETA_POR_NOMBRE,
      this.HttpHelpers.buildQueryParams(cadena, 'cadena'))
      .pipe(map(result => result.datos));
  }
  public comprobarNombreDieta(cadena: string): Observable<boolean> {
    return this.http.get<APIReturnModel<boolean>>(HistoriaClinicaApiConfig.HC_COMPROBAR_NOMBRE_DIETA,
      this.HttpHelpers.buildQueryParams(cadena, 'cadena'))
      .pipe(map(result => result.datos));
  }
  public listarHCDietario(pacienteId: number): Observable<DietaModel[]> {
    return this.http.get<APIReturnModel<DietaModel[]>>(HistoriaClinicaApiConfig.HC_LISTARDIETARIO_URL,
      this.HttpHelpers.buildQueryParams(pacienteId, 'pacienteId'))
      .pipe(map(result => result.datos));
  }
  public traerUnRegistroHCDieta(dietaId: number): Observable<DietaModel> {
    return this.http.get<APIReturnModel<DietaModel>>(HistoriaClinicaApiConfig.HC_TRAER_UN_REGISTRO_DIETARIO_URL,
      this.HttpHelpers.buildQueryParams(dietaId, 'dietaId'))
      .pipe(map(result => result.datos));
  }
  public borrarRegistroDetalle(idDetalleDieta: number): Observable<boolean> {
    return this.http.get<APIReturnModel<boolean>>(HistoriaClinicaApiConfig.HC_BORRAR_DETALLE_DIETA,
      this.HttpHelpers.buildQueryParams(idDetalleDieta, 'idDetalleDieta'))
      .pipe(map(result => result.datos));
  }

  //ASPECTO GINECOLOGICO
  public actuNutriGineco(modelo: ctosGinecologicosModel): Observable<ctosGinecologicosModel> {
    return this.http.post<APIReturnModel<ctosGinecologicosModel>>(HistoriaClinicaApiConfig.HC_NUTRICION_ACTUALIZAR_GINECO_URL, modelo)
      .pipe(map(result => result.datos));
  }
  public traerUnRegistroGineco(idPaciente: number): Observable<ctosGinecologicosModel> {
    return this.http.get<APIReturnModel<ctosGinecologicosModel>>(HistoriaClinicaApiConfig.HC_NUTRICION_TRAER_UN_REGISTRO_GINECO_URL,
      this.HttpHelpers.buildQueryParams(idPaciente, 'idPaciente'))
      .pipe(map(result => result.datos));
  }
  public traerVariosRegistGinecoPac(idPaciente: number): Observable<CNActividadDiariaModel[]> {
    return this.http.get<APIReturnModel<CNActividadDiariaModel[]>>(HistoriaClinicaApiConfig.HC_NUTRICION_TRAER_VARIOS_REGISTRO_GINECO_XPACIENTE_URL,
      this.HttpHelpers.buildQueryParams(idPaciente, 'idPaciente'))
      .pipe(map(result => result.datos));
  }
  public TraerVariosRegistGinecoUsu(): Observable<CNActividadDiariaModel[]> {
    return this.http.get<APIReturnModel<CNActividadDiariaModel[]>>(HistoriaClinicaApiConfig.HC_NUTRICION_TRAER_VARIOS_REGISTRO_GINECO_XPACIENTE_URL)
      .pipe(map(result => result.datos));
  }
  public BorrarUnRegistroGineco(IdAspectoGineco: number): Observable<boolean> {
    return this.http.get<APIReturnModel<boolean>>(HistoriaClinicaApiConfig.HC_NUTRICION_BORRAR_UN_REGISTRO_GINECO_URL,
      this.HttpHelpers.buildQueryParams(IdAspectoGineco, 'IdAspectoGineco'))
      .pipe(map(result => result.datos));
  }

  // INDICADORES BIOQUIMICOS
  public ActuNutriIndBio(modelo: prestadoresBioquimicosModel ): Observable<prestadoresBioquimicosModel> {
    return this.http.put<APIReturnModel<prestadoresBioquimicosModel>>(HistoriaClinicaApiConfig.HC_NUTRICION_ACTUALIZAR_INDI_BIO_URL, modelo)
      .pipe(map(result => result.datos));
  }
  public traerUnRegistroIndiBio(IdIndiBio: number): Observable<prestadoresBioquimicosModel> {
    return this.http.get<APIReturnModel<prestadoresBioquimicosModel>>(HistoriaClinicaApiConfig.HC_NUTRICION_TRAER_UN_REGISTRO_INDI_BIO_URL,
      this.HttpHelpers.buildQueryParams(IdIndiBio, 'IdIndiBio'))
      .pipe(map(result => result.datos));
  }
  public traerVariosRegistIndiBioPac(idPaciente: number): Observable<prestadoresBioquimicosModel[]> {
    return this.http.get<APIReturnModel<prestadoresBioquimicosModel[]>>(HistoriaClinicaApiConfig.HC_NUTRICION_TRAER_VARIOS_REGISTRO_INDI_BIO_XPACIENTE_URL,
      this.HttpHelpers.buildQueryParams(idPaciente, 'idPaciente'))
      .pipe(map(result => result.datos));
  }
  public TraerVariosRegistIndiBioUsu(): Observable<prestadoresBioquimicosModel[]> {
    return this.http.get<APIReturnModel<prestadoresBioquimicosModel[]>>(HistoriaClinicaApiConfig.HC_NUTRICION_TRAER_VARIOS_REGISTRO_INCI_BIO_XUSUARIO_URL)
      .pipe(map(result => result.datos));
  }
  public BorrarUnRegistroIndiBio(IdIndiBio: number): Observable<boolean> {
    return this.http.get<APIReturnModel<boolean>>(HistoriaClinicaApiConfig.HC_NUTRICION_BORRAR__INDI_BIO_URL,
      this.HttpHelpers.buildQueryParams(IdIndiBio, 'IdIndiBio'))
      .pipe(map(result => result.datos));
  }
  // HCANTROPOMETRICA
  public ActuNutriAntropo(modelo: HCNAntropometriasModel ): Observable<HCNAntropometriasModel> {
    return this.http.post<APIReturnModel<HCNAntropometriasModel>>(HistoriaClinicaApiConfig.HC_NUTRICION_ACTUALIZAR_ANTROPO_URL, modelo)
      .pipe(map(result => result.datos));
  }
  public traerUnRegistroAntropo(IdAntropo: number): Observable<HCNAntropometriasModel> {
    return this.http.get<APIReturnModel<HCNAntropometriasModel>>(HistoriaClinicaApiConfig.HC_NUTRICION_TRAER_UN_REGISTRO_ANTROPO_URL,
      this.HttpHelpers.buildQueryParams(IdAntropo, 'IdAntropo'))
      .pipe(map(result => result.datos));
  }
  public traerVariosRegistAntropoPac(idPaciente: number): Observable<HCNAntropometriasModel[]> {
    return this.http.get<APIReturnModel<HCNAntropometriasModel[]>>(HistoriaClinicaApiConfig.HC_NUTRICION_TRAER_VARIOS_REGISTRO_ANTROPO_XPACIENTE_URL,
      this.HttpHelpers.buildQueryParams(idPaciente, 'idPaciente'))
      .pipe(map(result => result.datos));
  }
  public TraerVariosRegistAntropoUsu(): Observable<HCNAntropometriasModel[]> {
    return this.http.get<APIReturnModel<HCNAntropometriasModel[]>>(HistoriaClinicaApiConfig.HC_NUTRICION_TRAER_VARIOS_REGISTRO_ANTROPO_XUSUARIO_URL)
      .pipe(map(result => result.datos));
  }
  public BorrarUnRegistroAntropo(IdAntropo: number): Observable<boolean> {
    return this.http.get<APIReturnModel<boolean>>(HistoriaClinicaApiConfig.HC_NUTRICION_BORRAR_ANTROPO_URL,
      this.HttpHelpers.buildQueryParams(IdAntropo, 'IdAntropo'))
      .pipe(map(result => result.datos));
  }
  public enviarDietaPorMail(idDieta: number): Observable<boolean> {
    return this.http.get<APIReturnModel<boolean>>(HistoriaClinicaApiConfig.HC_ENVIAR_EMAIL_DIETA,
      this.HttpHelpers.buildQueryParams(idDieta, 'idDieta'))
      .pipe(map(result => result.datos));
  }
  public descargarDieta(idDieta: number): Observable<any> {
    return this.http.get<APIReturnModel<any>>(HistoriaClinicaApiConfig.HC_DESCARGAR_DIETA,
      this.HttpHelpers.buildQueryParams(idDieta, 'idDieta'))
      .pipe(map(result => result.datos));
  }
  public ActuHCDietEnNull(IdDieta: number): Observable<boolean> {
    return this.http.get<APIReturnModel<boolean>>(HistoriaClinicaApiConfig.HC_HCID_EN_NULL,
      this.HttpHelpers.buildQueryParams(IdDieta, 'IdDieta'))
      .pipe(map(result => result.datos));
  }
  public ActuAlimento(modelo: AlimentosModel ): Observable<AlimentosModel> {
    return this.http.post<APIReturnModel<AlimentosModel>>(HistoriaClinicaApiConfig.HC_ALIMENTOS_ACTUALIZAR, modelo)
      .pipe(map(result => result.datos));
  }
  public traerAlimentoxId(idAlimento: number): Observable<AlimentosModel> {
    return this.http.get<APIReturnModel<AlimentosModel>>(HistoriaClinicaApiConfig.HC_ALIMENTOS_TRAERXID,
      this.HttpHelpers.buildQueryParams(idAlimento, 'idAlimento'))
      .pipe(map(result => result.datos));
  }
  public traerTodosAlimentos(): Observable<AlimentosModel[]> {
    return this.http.get<APIReturnModel<AlimentosModel[]>>(HistoriaClinicaApiConfig.HC_ALIMENTOS_TRAERTODOS)
      .pipe(map(result => result.datos));
  }
  public traerAlimentoPorFiltro(cadena: string): Observable<AlimentosModel[]> {
    return this.http.get<APIReturnModel<AlimentosModel[]>>(HistoriaClinicaApiConfig.HC_ALIMENTOS_TRAERPORFILTRO,
      this.HttpHelpers.buildQueryParams(cadena, 'cadena'))
      .pipe(map(result => result.datos));
  }
  public BorrarAlimento(idAlimento: number ): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(HistoriaClinicaApiConfig.HC_ALIMENTOS_BORRAR,
      this.HttpHelpers.buildQueryParams(idAlimento, 'idAlimento'))
      .pipe(map(result => result.datos));
  }
  public traerTodosAlimentosCat(): Observable<AlimentoCategoriaModel[]> {
    return this.http.get<APIReturnModel<AlimentoCategoriaModel[]>>(HistoriaClinicaApiConfig.HC_ALIMENTOS_CATEGORIA_TRAERTODOS)
      .pipe(map(result => result.datos));
  }
  public traerAlimCalcuPorId(idAlimento: number): Observable<AlimentoCalcularModel> {
    return this.http.get<APIReturnModel<AlimentoCalcularModel>>(HistoriaClinicaApiConfig.HC_ALIMENTOS_CALCULAR_TRAERXID,
      this.HttpHelpers.buildQueryParams(idAlimento, 'idAlimento'))
      .pipe(map(result => result.datos));
  }
  public listarArchivos(pacienteUsId: number): Observable<HCArchivosAdjuntosModel[]> {
    return this.http.get<APIReturnModel<HCArchivosAdjuntosModel[]>>(HistoriaClinicaApiConfig.HC_LISTAR_ARCHIVOS_ADJUNTOS_URL,
      this.HttpHelpers.buildQueryParams(pacienteUsId, 'pacienteUsId'))
      .pipe(map(result => result.datos));
  }
  public listarCertificados(pacienteUsId: number): Observable<HCCertificadoListModel[]> {
    return this.http.get<APIReturnModel<HCCertificadoListModel[]>>(HistoriaClinicaApiConfig.HC_LISTAR_CERTIFICADOS_URL,
      this.HttpHelpers.buildQueryParams(pacienteUsId, 'pacienteUsId'))
      .pipe(map(result => result.datos));
  }

  public descargarCertificado(certificadoId: number): Observable<any> {
    return this.http.get<APIReturnModel<any>>(HistoriaClinicaApiConfig.HC_DESCARGAR_CERTIFICADO_URL,
      this.HttpHelpers.buildQueryParams(certificadoId, 'CertificadoId'))
      .pipe(map(result => result.datos));
  }

  public tratamientoNew(tratamiento: HCTratamientoNewModel): Observable<HCTratamientoViewModel> {
    return this.http.put<APIReturnModel<HCTratamientoViewModel>>(HistoriaClinicaApiConfig.HC_TRATAMIENTO_UPDATE_URL, tratamiento)
      .pipe(map(result => result.datos));
  }

  public unoTratamiento(tratamientoId: number): Observable<HCTratamientoViewModel> {
    return this.http.get<APIReturnModel<HCTratamientoViewModel>>(HistoriaClinicaApiConfig.HC_TRATAMIENTO_UNO_URL,
      this.HttpHelpers.buildQueryParams(tratamientoId, 'TratamientoId'))
      .pipe(map(result => result.datos));
  }

  public listarTratamiento(pacienteUsId: number): Observable<HCTratamientoListModel[]> {
    return this.http.get<APIReturnModel<HCTratamientoListModel[]>>(HistoriaClinicaApiConfig.HC_LISTAR_TRATAMIENTO_URL,
      this.HttpHelpers.buildQueryParams(pacienteUsId, 'pacienteUsId'))
      .pipe(map(result => result.datos));
  }

  public descargarTratamiento(tratamientoId: number): Observable<any> {
    return this.http.get<APIReturnModel<any>>(HistoriaClinicaApiConfig.HC_DESCARGAR_TRATAMIENTO_URL,
      this.HttpHelpers.buildQueryParams(tratamientoId, 'TratamientoId'))
      .pipe(map(result => result.datos));
  }
}
