import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorHelperService {

  constructor(private snackBar: MatSnackBar) { }

  public handleError(error): string {
    let errorMessage = '';
    if (error.status !== null || error.status !== undefined) {
      if (error.status === 0) {
        errorMessage = 'Error: No hay conexion';
      } else {
        if (error.error.status !== 500) {
          if (error.error.message) {
            // client-side error
            errorMessage = 'Error: ' + error.error.message;
          } else {
            switch (error.error.status) {
              case 400:
                errorMessage = 'Error: La funcion solicitada no se encuentra disponible';
                break;
              case 401:
                errorMessage = 'Error: El token no es valido';
                break;
              default:
                errorMessage = 'Error: Desconocido';
                break;
            }
          }
        } else {
          // server-side error
          errorMessage = 'Error: ' + error.error.detail;
        }
      }
    } else {
      errorMessage = 'Error: ' + error.message;
    }
    this.snackBar.open(errorMessage, 'OK');
    return errorMessage;
  }
}
