import { LoginService } from 'src/app/services/login/login.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-modal-usuario-recup-pass',
  templateUrl: './modal-usuario-recup-pass.component.html',
  styleUrls: ['./modal-usuario-recup-pass.component.scss']
})
export class ModalUsuarioRecupPassComponent implements OnInit, AfterViewInit {
  usuarioForm: UntypedFormGroup;
  @ViewChild('foco') campoFoco: ElementRef;

  constructor(
    private snackBar: MatSnackBar,
    private loginServ: LoginService,
    public dialogRef: MatDialogRef<ModalUsuarioRecupPassComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      nombreUsuario: string
    }
  ) { }

  ngOnInit(): void {
    this.usuarioForm = new UntypedFormGroup({
      usuario: new UntypedFormControl(this.data.nombreUsuario, [Validators.required])
    });
  }
  ngAfterViewInit(): void {
    setTimeout(_ => this.campoFoco.nativeElement.focus());
  }
  confirmar() {
    if (!this.usuarioForm.valid) {
      Object.keys(this.usuarioForm.controls).forEach(key => {
        this.usuarioForm.get(key).markAsTouched();
      });
      this.snackBar.open('Por favor verifique los datos ingresados', 'OK');
      return;
    }
    this.loginServ.emailRecuperarPass(this.usuarioForm.get('usuario').value).toPromise().then(x => {
      if (x) {
        this.snackBar.open('El email fue enviado correctamente');
        this.dialogRef.close();
      }
    });
  }
}
