<mat-form-field [formGroup]="formBusqueda" fxFlex="100%" [ngClass]="claseMatForm" [hideRequiredMarker]="hideRequiredMarker"  [floatLabel]="floatLabel" >
  <input *ngIf="tipoBuscador===tiposBuscador.PACIENTE" matInput #buscador placeholder="{{label}}" [ngClass]="claseInput"
    formControlName="busqueda" [matAutocomplete]="auto" autocomplete="off" (keyup.escape)="limpiarAutocomplete()" (focus)="focus.emit()" (blur)="blur.emit()"
    (keyup.enter)="pressEnter()" (click)="$event.target.select()">

  <input *ngIf="tipoBuscador!=tiposBuscador.PACIENTE" matInput #buscador placeholder="{{label}}" [ngClass]="claseInput"
    formControlName="busqueda" [matAutocomplete]="auto" autocomplete="off" (keyup.escape)="limpiarAutocomplete()"
    (keyup.enter)="pressEnter()" (click)="$event.target.select()" [required]="mostrarError ? true : false" (focus)="focus.emit()" (blur)="blur.emit()">
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayWith.bind(this)"
    [autoActiveFirstOption]="elegirPrimeraOpcion" class="resultados-agenda" [panelWidth]="auto">
    <mat-option *ngIf="isLoading" class="is-loading">
      <mat-spinner diameter="50"></mat-spinner>
    </mat-option>
    <ng-container *ngIf="!isLoading">
      <mat-option *ngFor="let option of entidades" [value]="option" (onSelectionChange)="evElegir.emit(option)">
        <span *ngIf="tipoBuscador===tiposBuscador.EMPRESA">
        {{ option.nombre }}
        </span>
        <span
          *ngIf="tipoBuscador===tiposBuscador.PACIENTE||tipoBuscador===tiposBuscador.PERSONAL||tipoBuscador===tiposBuscador.AGENDA||tipoBuscador===tiposBuscador.OBRASOCIAL || tipoBuscador===tiposBuscador.MONODROGA||tipoBuscador===tiposBuscador.ACREEDOR">
          {{ option.nombre }} <mat-icon  matTooltip="Es paciente" matTooltipPosition="right" *ngIf="option.esPaciente" class="check">done</mat-icon>
        </span>
        <span *ngIf="tipoBuscador===tiposBuscador.CLIENTE">{{option.razonSocial}}</span>
        <span *ngIf="tipoBuscador===tiposBuscador.MEDICAMENTO">
          {{option.descripcion}}
           <!-- {{ option.codigo }} - {{ option.nombre }} - {{option.posologia}} x{{option.contenido}} - {{option.formaFamarcologica}} -->
        </span>
        <span *ngIf="tipoBuscador===tiposBuscador.CIE10">{{option.texto}}</span>
        <span *ngIf="tipoBuscador===tiposBuscador.PRESTRACION">{{option.texto}}</span>
        <span *ngIf="tipoBuscador===tiposBuscador.ALIMENTOS">{{option.nombre}} - Prc: {{option.porcion}} - Kcal: {{option.calorias}} - CarboHidratos: {{option.carbohidratos}} - Fibra: {{option.fibra}} - Sodio: {{option.sodio}} - Proteinas: {{option.proteinas}}</span>
     <span *ngIf="tipoBuscador===tiposBuscador.LUGAR">
        {{ option.nombre }}
        </span>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
  <mat-error
    *ngIf="formBusqueda.get('busqueda').invalid && (formBusqueda.get('busqueda').dirty || formBusqueda.get('busqueda').touched) && mostrarError === true">
    <small *ngIf="formBusqueda.get('busqueda').hasError('menosTres')">
      Debe ingresar mas de 3 caracteres
    </small>
    <small *ngIf="formBusqueda.get('busqueda').hasError('required')">
      Debe ingresar un dato
    </small>
    <small *ngIf="formBusqueda.get('busqueda').hasError('noMatch')">
      Debe elegir una opcion de la lista
    </small>
    <small *ngIf="formBusqueda.get('busqueda').hasError('noPacientes')">
      No se encontraron pacientes para esa busqueda
    </small>
    <small *ngIf="formBusqueda.get('busqueda').hasError('noDupliqueMed')">
      No puede cargar este medicamento mas de una vez
    </small>
    <small *ngIf="formBusqueda.get('busqueda').hasError('noDupliqueDiag')">
      No puede cargar este diagnostico mas de una vez
    </small>
    <small *ngIf="formBusqueda.get('busqueda').hasError('noResultados')">
      No se encontraron resultados para esta busqueda
    </small>
    <small *ngIf="formBusqueda.get('busqueda').hasError('alergiaMayor150')">
      No puede ingresar una alergia con mas de 150 caracteres
    </small>
  </mat-error>
  <button mat-icon-button type="button" matSuffix [disabled]="deshabilitado" (click)="limpiarAutocomplete()"
    tabindex=-1>
    <mat-icon>clear</mat-icon>
  </button>
</mat-form-field>
