import { SortDirection } from '@angular/material/sort';
import { TipoAccionABMEnum } from './enums.model';

export interface TipoABM {
  id: number;
  tipoAccion: TipoAccionABMEnum;
}
export enum DireccionOrdenEnum {
  ASC = 0,
  DESC
}
export class ABMGenericoModel<T> {
  items: T[];
  permiteAgregar: boolean;
  permiteEliminar: boolean;
  permiteEditar: boolean;
  permiteActivar: boolean;
  cantItems: number;
  constructor() { }
}
export class ABMGenericoConsultaModel {
  filtro: string;
  pagina: number;
  colOrden: string;
  tipoOrden: DireccionOrdenEnum;
  constructor(filtro: string, pagina: number, colOrden: string, tipoOrden: SortDirection | DireccionOrdenEnum) {
    this.filtro = filtro;
    this.pagina = pagina;
    this.colOrden = colOrden;
    this.tipoOrden = tipoOrden === 'asc' ? DireccionOrdenEnum.ASC : DireccionOrdenEnum.DESC;
  }
}
export enum ABMsEnum {
  OBRASOCIAL = 0,
  BANCO,
  TARJETA,
  ESPECIALIDAD,
  ROL,
  OFERTAPRESTACIONAL,
  PERSONAL,
  AGENDAS,
  CLIENTE,
  CUENTA,
  ACREEDOR,
  FACTURADOR,
  LUGAR
}
