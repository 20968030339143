<!-- <div [ngClass]="{'showYoutube':!showAppScreen,'hideYoutube':showAppScreen}">
  <div #youtubePlayerContainer>
  </div>
  <button mat-button color="primary" (click)="stop()">
  <mat-icon>visibility_off</mat-icon>
  </button>
</div> -->
<!-- <div class="main" style="width: 100%; height: 100vh;"> -->
  <div class="main">
  <header class="header">
      <div class="centroName" (click)="openDialog()">
        <span>
          {{ selectedSede && selectedCentro?(selectedCentro.nombre+" - "+ selectedSede.nombre ):"Nombre del Centro Médico"}}
        </span>
        
      </div>
      <div class="currentDate">
          <div class="todayDate">{{date}}</div>
          <div class="todayTime">{{time}}</div>

      </div>

  </header>
  <main class="content">
          <table style="margin: 0px !important;width: 100%;" mat-table [dataSource]="dataSource" matSort>

          <!-- Define the columns -->
          <ng-container matColumnDef="tuenos">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-header" >próximos turnos</th>
            <td mat-cell *matCellDef="let row" [ngClass]="{'custom-cell': true,'first-row': isFirstRow(row)}">
              <span>Consultorio N° {{ row.consultorioName }}</span></td>
          </ng-container>
        
          <ng-container matColumnDef="dni">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-header" style="text-align: right;">Apellido del paciente <img style="margin: -4px; padding-left: 20px;" src="/assets/images/icons/idCard.png"></th>
            <td mat-cell *matCellDef="let row" [ngClass]="{'custom-cell': true,'first-row': isFirstRow(row)}" style="text-align: right;"><img *ngIf="isFirstRow(row)" style="margin: -4px; padding-left: 20px;" src="/assets/images/icons/ion_arrow-up-outline.png">  {{ row.dni }}</td>
          </ng-container>
        
          <!-- Specify which columns to render -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
        </table>
  </main>
  <footer class="footer">
   
      <div class="footerDevText">
       <div style="font-family: 'RobotoRegular';font-style: normal; font-weight: 400;font-size: 24px;line-height: 119.52%;color: #FFFFFF;">
          DESARROLLADO POR
       </div>
       <!-- <div (click)="showAppScreen = !showAppScreen" style="cursor: pointer;"> -->
        <div>  
        <img  alt="top image" src="/assets/images/svg/logoMainW.png">           
        </div>
      </div>
      <div class="sprator" >                      
      </div>
      <div class="email">www.panaceamed.com.ar</div>
       <div class="number">
          <img  alt="top image" src="/assets/images/icons/ic_outline-whatsapp.png">
          3814152198
       </div>
  </footer>

</div>

