export enum PrivilegiosEnum {
  LISTA_DE_ROLES = 1,
  CARGAR_EDITAR_ROLES = 2,
  DESACTIVAR_Y_ELIMINAR_ROLES = 3,
  LISTA_PERSONAL = 4,
  CARGAR_EDITAR_PERSONAL = 5,
  DESACTIVAR_Y_ELIMINAR_PERSONAL = 6,
  LISTA_DE_AGENDAS = 7,
  CARGAR_EDITAR_AGENDAS = 8,
  DESACTIVAR_Y_ELIMINAR_AGENDAS = 9,
  LISTA_DE_OFERTA_PRESTACIONAL = 10,
  CARGAR_EDITAR_OFERTA_PRESTACIONAL = 11,
  DESACTIVAR_Y_ELIMINAR_OFERTA_PRESTACIONAL = 12,
  LISTA_DE_ESPECIALIDADES = 13,
  CARGAR_EDITAR_ESPECIALIDADES = 14,
  DESACTIVAR_Y_ELIMINAR_ESPECIALIDADES = 15,
  LISTA_DE_BANCOS = 16,
  CARGAR_EDITAR_BANCOS = 17,
  DESACTIVAR_Y_ELIMINAR_BANCOS = 18,
  LISTA_DE_TARJETAS = 19,
  CARGAR_EDITAR_TARJETAS = 20,
  DESACTIVAR_Y_ELIMINAR_TARJETAS = 21,
  VER_TODAS_AGENDAS = 22,
  ADMINISTRAR_TURNOS = 23,
  ACCESO_A_HISTORIA_CLINICA = 24,
  CARGAR_NOTA_EVOLUCION = 25,
  CREAR_PROCESO_NUEVO = 26,
  ACCESO_A_ODONTOGRAMA = 27,
  AGREGAR_PRESTACIONES_AL_ODONTOGRAMA = 28,
  ACCESO_A_CAJA = 29,
  ES_AUDITOR_CAJAS = 30,
  ES_TESORERO = 31,
  ACCESO_A_COMPROBANTES_EN_CAJA = 32,
  ABRIR_CAJA = 33,
  VER_CAJA_AJENA = 34,
  CERRAR_CAJA_PROPIA = 35,
  VER_COMPROBANTE_EN_CAJA = 36,
  ACCESO_A_CTA_CTE = 37,
  ACCESO_A_PAGARE_EN_CTA_CTE = 38,
  ACCESO_A_DEPOSITO_GARANTIA_EN_CTA_CTE = 39,
  ACCESO_A_COMPROBANTE_EN_CTA_CTE = 40,
  DEVOLVER_DEPOSITO_GARANTIA_CTA_CTE = 41,
  AGREGAR_DETALLE_A_CTA_CTE = 42,
  VER_ORDEN_ATENCION = 43,
  CREAR_ORDEN_ATENCION = 44,
  CARGAR_EDITAR_PACIENTES = 45,
  LISTA_DE_CLIENTES_EN_CAJA = 46,
  CARGAR_EDITAR_CLIENTES_EN_CAJA = 47,
  DESACTIVAR_Y_ELIMINAR_CLIENTES_EN_CAJA = 48,
  LISTA_DE_CUENTAS_EN_CAJA = 49,
  CARGAR_EDITAR_CUENTAS_EN_CAJA = 50,
  DESACTIVAR_Y_ELIMINAR_CUENTAS_EN_CAJA = 51,
  VISUALIZAR_RECORDATORIOS_DEL_PACIENTE = 52,
  ADMINISTRAR_RECORDATORIOS_DEL_PACIENTE = 53,
  MODIFICAR_EMAIL_EN_PERSONAL = 54,
  ACCESO_A_AUDITORIA_EN_FACTURACION = 55,
  ACCESO_A_CONTRATOS_EN_FACTURACION = 56,
  VISUALIZAR_CONTRATOS = 57,
  ACCESO_A_RESUMENES_EN_FACTURACION = 58,
  ADMINISTRAR_CONTRATOS_EN_FACTURACION = 59,
  ADMINISTRAR_RESUMENES_DE_FACTURACION = 60,
  LISTA_DE_ACREEDORES_EN_CAJA = 61,
  CARGAR_EDITAR_ACREEDORES_EN_CAJA = 62,
  DESACTIVAR_Y_ELIMINAR_ACREEDORES_EN_CAJA = 63,
  ACCESO_A_PRESCRIPCIONES = 64,
  CARGAR_PRESCRIPCIONES = 65,
  ACCESO_A_ESTUDIOS_EN_HC = 66,
  CARGAR_ESTUDIO_EN_HC = 67,
  ACCESO_A_REFERENCIAS_EN_HC = 68,
  EDITAR_REFERENCIAS_EN_HC = 69,
  PASAR_TURNO_A_ATENDIDO = 70,
  LISTA_DE_FACTURADORES = 71,
  CARGAR_EDITAR_FACTURADORES = 72,
  DESACTIVAR_Y_ELIMINAR_FACTURADORES = 73,
  COBRO_EN_CUENTA_CORRIENTE = 74,
  VER_ESTADISTICA = 75,
  NUEVO_SOBRETURNO = 76,
  NUEVA_CIRUGIA = 77,
  CARGAR_EDITAR_LUGARS = 78,
  DESACTIVAR_Y_ELIMINAR_LUGARS = 79,
  LIBRO_QUIRURGICO = 80
}
