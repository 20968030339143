import { environment } from 'src/environments/environment';
import { env } from 'process';

export class EmpresaApiConfig {
  // EMPRESA
  public static EMPRESA_BUSQUEDA_SELECT_URL = environment.apiUrl + 'Empresa/BusquedaSelect';
  public static EMPRESA_UPDATE_URL = environment.apiUrl + 'Empresa/Actualizar';
  public static EMPRESA_UNA_SEDE_URL = environment.apiUrl + 'Empresa/UnaSede';
  public static EMPRESA_CONSULTORIO_TIENE_HATENCION_URL = environment.apiUrl + 'Empresa/ConsultorioTieneHAtencion';
  public static EMPRESA_ACTUALIZAR_SEDE_URL = environment.apiUrl + 'Empresa/ActualizarSede';
  public static EMPRESA_ELIMINAR_SEDE_URL = environment.apiUrl + 'Empresa/EliminarSede';
  public static EMPRESA_DESACTIVAR_SEDE_URL = environment.apiUrl + 'Empresa/DesactivarSede';
  public static EMPRESA_ACTIVAR_SEDE_URL = environment.apiUrl + 'Empresa/ActivarSede';
  public static EMPRESA_UNA_LUGAR_URL = environment.apiUrl + 'Empresa/UnaLugar';
  public static EMPRESA_ACTUALIZAR_LUGAR_URL = environment.apiUrl + 'Empresa/ActualizarLugar';
  public static EMPRESA_ELIMINAR_LUGAR_URL = environment.apiUrl + 'Empresa/EliminarLugar';
  public static EMPRESA_DESACTIVAR_LUGAR_URL = environment.apiUrl + 'Empresa/DesactivarLugar';
  public static EMPRESA_ACTIVAR_LUGAR_URL = environment.apiUrl + 'Empresa/ActivarLugar';
  public static EMPRESA_UN_COMPROBANTE_URL = environment.apiUrl + 'Empresa/UnComprobante';
  public static EMPRESA_ACTUALIZAR_COMPROBANTE_URL = environment.apiUrl + 'Empresa/ActualizarComprobante';
  public static EMPRESA_UN_PTOVTA_URL = environment.apiUrl + 'Empresa/UnPtoVta';
  public static EMPRESA_EXISTE_PTO_POR_DEFECTO_URL = environment.apiUrl + 'Empresa/ExistePtoPorDefecto';
  public static EMPRESA_ACTUALIZAR_PTOVTA_URL = environment.apiUrl + 'Empresa/ActualizarPtoVta';
  public static EMPRESA_ELIMINAR_PTOVTA_URL = environment.apiUrl + 'Empresa/EliminarPtoVta';
  public static EMPRESA_DESACTIVAR_PTOVTA_URL = environment.apiUrl + 'Empresa/DesactivarPtoVta';
  public static EMPRESA_ACTIVAR_PTOVTA_URL = environment.apiUrl + 'Empresa/ActivarPtoVta';
  public static EMPRESA_UNO_URL = environment.apiUrl + 'Empresa/Uno';
  public static EMPRESA_PROXIMO_COMPROBANTE_URL = environment.apiUrl + 'Empresa/ProximoComprobante';
  public static EMPRESA_PRIMER_PTO_VTA_URL = environment.apiUrl + 'Empresa/PrimerPtoVta';
  public static EMPRESA_EXISTE_CONSULTORIO_URL = environment.apiUrl + 'Empresa/ExisteConsultorio';
  public static EMPRESA_EXISTE_CUIT_URL = environment.apiUrl + 'Empresa/ExisteCuit';
  public static EMPRESA_CONSULTA_LIMITE_MEDICO_URL = environment.apiUrl + 'Empresa/ConsultaLimiteMedico';
  public static EMPRESA_TO_EXCEL_URL = environment.apiUrl + 'Empresa/EmpresaToExcel';

  // PERSONAL
  public static PERSONAL_UNO_URL = environment.apiUrl + 'Personal/Uno';
  public static PERSONAL_UNO_POR_PERSONAL_URL = environment.apiUrl + 'Personal/UnoPorPersonal';
  public static PERSONAL_LIST_URL = environment.apiUrl + 'Personal/List';
  public static PERSONAL_ELIMINAR_URL = environment.apiUrl + 'Personal/Eliminar';
  public static PERSONAL_DESACTIVAR_URL = environment.apiUrl + 'Personal/Desactivar';
  public static PERSONAL_ACTIVAR_URL = environment.apiUrl + 'Personal/Activar';
  public static PERSONAL_UPDATE_URL = environment.apiUrl + 'Personal/Actualizar';
  public static PERSONAL_POR_LEGAJO_URL = environment.apiUrl + 'Personal/PorLegajo';
  public static PERSONAL_NRO_DOC_URL = environment.apiUrl + 'Personal/PersonalNroDoc';
  public static PERSONAL_NRO_DOC_EXISTE_URL = environment.apiUrl + 'Personal/NroDocExiste';
  public static PERSONAL_BUSQUEDA_SELECT_URL = environment.apiUrl + 'Personal/BusquedaSelect';
  public static PERSONAL_NRO_DOC_EXISTE_SIN_AUTH_URL = environment.apiUrl + 'Personal/NroDocExisteSinAuth';
  public static PERSONAL_CONSULTA_CANTIDAD_MEDICOS_URL = environment.apiUrl + 'Personal/ConsultaCantidadMedicos';
  public static PERSONAL_ES_MEDICO_URL = environment.apiUrl + 'Personal/EsMedico';
  public static PERSONAL_HABILITADO_CAJA_URL = environment.apiUrl + 'Personal/HabilitadoCaja';
  public static PERSONAL_CONSULTA_SEDE_LOG_URL = environment.apiUrl + 'Personal/ConsultaSedeLog';
  // ESPECIALIDAD
  public static ESPECIALIDAD_UNO_URL = environment.apiUrl + 'Especialidad/Uno';
  public static ESPECIALIDAD_LIST_URL = environment.apiUrl + 'Especialidad/List';
  public static ESPECIALIDAD_ELIMINAR_URL = environment.apiUrl + 'Especialidad/Eliminar';
  public static ESPECIALIDAD_DESACTIVAR_URL = environment.apiUrl + 'Especialidad/Desactivar';
  public static ESPECIALIDAD_ACTIVAR_URL = environment.apiUrl + 'Especialidad/Activar';
  public static ESPECIALIDAD_UPDATE_URL = environment.apiUrl + 'Especialidad/Actualizar';
  // LUGAR
  public static LUGAR_UNO_URL = environment.apiUrl + 'Lugar/Uno';
  public static LUGAR_LIST_URL = environment.apiUrl + 'Lugar/List';
  public static LUGAR_ELIMINAR_URL = environment.apiUrl + 'Lugar/Eliminar';
  public static LUGAR_DESACTIVAR_URL = environment.apiUrl + 'Lugar/Desactivar';
  public static LUGAR_ACTIVAR_URL = environment.apiUrl + 'Lugar/Activar';
  public static LUGAR_UPDATE_URL = environment.apiUrl + 'Lugar/Actualizar';
  // ROL
  public static ROL_UNO_URL = environment.apiUrl + 'Rol/Uno';
  public static ROL_LIST_URL = environment.apiUrl + 'Rol/List';
  public static ROL_ELIMINAR_URL = environment.apiUrl + 'Rol/Eliminar';
  public static ROL_DESACTIVAR_URL = environment.apiUrl + 'Rol/Desactivar';
  public static ROL_ACTIVAR_URL = environment.apiUrl + 'Rol/Activar';
  public static ROL_UPDATE_URL = environment.apiUrl + 'Rol/Actualizar';
  // CONSULTORIO
  public static CONSULTORIO_UNO_URL = environment.apiUrl + 'Consultorio/Uno';
  public static CONSULTORIO_TODO_URL = environment.apiUrl + 'Consultorio/Todo';
  public static CONSULTORIO_BORRAR_URL = environment.apiUrl + 'Consultorio/Borrar';
  public static CONSULTORIO_UPDATE_URL = environment.apiUrl + 'Consultorio/Actualizar';
  // BANCO
  public static BANCO_UNO_URL = environment.apiUrl + 'Banco/Uno';
  public static BANCO_LIST_URL = environment.apiUrl + 'Banco/List';
  public static BANCO_ELIMINAR_URL = environment.apiUrl + 'Banco/Eliminar';
  public static BANCO_DESACTIVAR_URL = environment.apiUrl + 'Banco/Desactivar';
  public static BANCO_ACTIVAR_URL = environment.apiUrl + 'Banco/Activar';
  public static BANCO_UPDATE_URL = environment.apiUrl + 'Banco/Actualizar';
  // TARJETA
  public static TARJETA_UNO_URL = environment.apiUrl + 'Tarjeta/Uno';
  public static TARJETA_LIST_URL = environment.apiUrl + 'Tarjeta/List';
  public static TARJETA_ELIMINAR_URL = environment.apiUrl + 'Tarjeta/Eliminar';
  public static TARJETA_DESACTIVAR_URL = environment.apiUrl + 'Tarjeta/Desactivar';
  public static TARJETA_ACTIVAR_URL = environment.apiUrl + 'Tarjeta/Activar';
  public static TARJETA_UPDATE_URL = environment.apiUrl + 'Tarjeta/Actualizar';
  // AGENDA
  public static AGENDA_UNA_URL = environment.apiUrl + 'Agendas/Uno';
  public static AGENDA_LIST_URL = environment.apiUrl + 'Agendas/List';
  public static AGENDA_ELIMINAR_URL = environment.apiUrl + 'Agendas/Eliminar';
  public static AGENDA_DESACTIVAR_URL = environment.apiUrl + 'Agendas/Desactivar';
  public static AGENDA_ACTIVAR_URL = environment.apiUrl + 'Agendas/Activar';
  public static AGENDA_UPDATE_URL = environment.apiUrl + 'Agendas/Actualizar';
  public static AGENDA_MIEMBROS_PERSONAL_SEDE_URL = environment.apiUrl + 'Agendas/MiembrosPersonalSede';
  public static AGENDA_MIEMBROS_PERSONAL_EMPRESA_URL = environment.apiUrl + 'Agendas/MiembrosPersonalEmpresa';
  public static AGENDA_GUARDAR_DIA_URL = environment.apiUrl + 'Agendas/GuardarDia';
  public static AGENDA_EXISTE_NOMBRE_URL = environment.apiUrl + 'Agenda/ExisteNombre';
  public static AGENDA_BUSQUEDA_SELECT_URL = environment.apiUrl + 'Agendas/BusquedaSelect';
  // OFERTA PRESTACIONAL
  public static OFERTA_PRESTACIONAL_UNO_URL = environment.apiUrl + 'OfPrestacional/Uno';
  public static OFERTA_PRESTACIONAL_LIST_URL = environment.apiUrl + 'OfPrestacional/List';
  public static OFERTA_PRESTACIONAL_ELIMINAR_URL = environment.apiUrl + 'OfPrestacional/Eliminar';
  public static OFERTA_PRESTACIONAL_DESACTIVAR_URL = environment.apiUrl + 'OfPrestacional/Desactivar';
  public static OFERTA_PRESTACIONAL_UPDATE_URL = environment.apiUrl + 'OfPrestacional/Actualizar';
  public static OFERTA_PRESTACIONAL_ACTIVAR_URL = environment.apiUrl + 'OfPrestacional/Activar';
  public static OFERTA_PRESTACIONAL_POR_CODIGO_URL = environment.apiUrl + 'OfPrestacional/OfertaPorCodigo';
  public static OFERTA_PRESTACIONAL_POR_NOMBRE_URL = environment.apiUrl + 'OfPrestacional/OfertaPorNombre';
  public static OFERTA_PRESTACIONAL_OFERTAS_EMPRESA_URL = environment.apiUrl + 'OfPrestacional/OfertasEmpresa';
  public static OFERTA_PRESTACIONAL_CAPITULOS_EMPRESA_URL = environment.apiUrl + 'OfPrestacional/CapitulosEmpresa';
  public static OFERTA_PRESTACIONAL_CAPITULOS_URL = environment.apiUrl + 'OfPrestacional/Capitulos';
  public static OFERTA_PRESTACIONAL_PRESTACIONES_NOMENCLADOR_URL = environment.apiUrl + 'OfPrestacional/PrestacionesNomenclador';
  public static OFERTA_PRESTACIONAL_DESDE_NOMENCLADOR_URL = environment.apiUrl + 'OfPrestacional/OfertaDesdeNomenclador';
  // FACTURADOR
  public static FACTURADOR_UNO_URL = environment.apiUrl + 'Facturador/Uno';
  public static FACTURADOR_LIST_URL = environment.apiUrl + 'Facturador/List';
  public static FACTURADOR_ELIMINAR_URL = environment.apiUrl + 'Facturador/Eliminar';
  public static FACTURADOR_DESACTIVAR_URL = environment.apiUrl + 'Facturador/Desactivar';
  public static FACTURADOR_ACTIVAR_URL = environment.apiUrl + 'Facturador/Activar';
  public static FACTURADOR_UPDATE_URL = environment.apiUrl + 'Facturador/Actualizar';
}
