import { MatDialog } from '@angular/material/dialog';
import { CajaService } from './../../../services/caja/caja.service';
import { ThemeService } from './../../../services/theme/theme.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login/login.service';
import { TemasEnum } from 'src/app/models/shared/enums.model';
import { trigger, style, transition, animate } from '@angular/animations';
import { ModalCamaraComponent } from '../modal-camara/modal-camara.component';
import { HcEvolucionesService } from 'src/app/services/historia-clinica/hc-evoluciones.service';
import { AgendasService } from 'src/app/services/empresa/agendas.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [
    trigger('entradaBusqueda', [
      transition(':enter', [
        style({ transform: 'translateX(-90%)', opacity: 0 }),
        animate('0.3s', style({ opacity: 1, transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateX(0)' }),
        animate('0.3s', style({ opacity: 0, transform: 'translateX(-90%)' })),
      ]),
    ])
  ]
})
export class NavbarComponent implements OnInit {
  public tiposTemas = TemasEnum;
  isShown = false;
  nutricion = false;
  estadistica = true;
  menuAbierto = true;
  constructor(
    public loginServ: LoginService,
    public themeServ: ThemeService,
    private cajaServ: CajaService,
    private modalpage: MatDialog,
    private evoServ: HcEvolucionesService,
    public route: Router,
    public changeDetection: ChangeDetectorRef,
    private agendaServ: AgendasService
  ) {
  }

  ngOnInit() {

    this.actualizarMenuApi();
    this.loginServ.actualizarMenuEvent.subscribe(x => {
      this.actualizarMenuApi();
    });
  }

  actualizarMenuApi() {
    this.loginServ.ShowEstadistica = this.loginServ.validateEstadistica();
    this.loginServ.IsNutritionist = this.loginServ.checkNutritionist();
    if (this.loginServ.datosHeader.agendaLogId !== undefined && this.loginServ.datosHeader.agendaLogId !== null) {
      this.evoServ.agendaLogueadoEvolucion().toPromise().then(y => {
        this.agendaServ.uno(y.agendaId).toPromise().then(x => {
          if (x) {
            // nutricion || x.especialidadId === 1035 || x.especialidadId ==1074
            if (x.especialidadId === 1058) {
              this.nutricion = true;
            } else {
              this.nutricion = false;
            }

          } else {
            this.nutricion = false;
          }
        });
      });
    }

  }

  accesoMenu() {
    var menu = document.getElementById("sideMenu");
    this.menuAbierto = !this.menuAbierto;
    if (this.menuAbierto) {
      menu.classList.add("menuvisibleAbierto");
      menu.classList.remove("menuhideAbierto");
    }
    else {
      menu.classList.add("menuhideAbierto");
      menu.classList.remove("menuvisibleAbierto");

    }

  }
  clickFacturacion() {
    if (!this.route.url.startsWith('/facturacion')) {
      this.route.navigate(['/facturacion/obra-social'], { skipLocationChange: true });
    }
  }
  clickEstadistica() {
    if (!this.route.url.startsWith('/estadistica')) {
      this.route.navigate(['/estadistica/exportar'], { skipLocationChange: true });
    }
  }
  clickPacienteList() {
    if (!this.route.url.startsWith('/pacientelist')) {
      this.route.navigate(['/pacientelist'], { skipLocationChange: true });
    }
  }
  clickQuirurgicoList() {
    if (!this.route.url.startsWith('/quirurgicolist')) {
      this.route.navigate(['/quirurgicolist'], { skipLocationChange: true });
    }
  }
  clickNutricion() {
    if (!this.route.url.startsWith('/nutricion')) {
      this.route.navigate(['/nutricion'], { skipLocationChange: true });
    }
  }
  clickCumpleanos() {
    if (!this.route.url.startsWith('/cumpleanos')) {
      this.route.navigate(['/cumpleanos'], { skipLocationChange: true });
    }
  }
  clickCaja() {
    if (this.loginServ.checkearPrivilegio(this.loginServ.privilegios.ACCESO_A_CAJA, false)) {
      if (!this.route.url.startsWith('/caja')) {
        this.cajaServ.usuarioCajaAbierta().toPromise().then(x => {
          this.cajaServ.usCaja = x;
          this.route.navigate(['/caja/cajas'], { skipLocationChange: true });
        });
      }
    }
    else {

      this.cajaServ.usuarioCajaAbierta().toPromise().then(x => {
        this.cajaServ.usCaja = x;
        this.route.navigate(['/cajaMedico'], { skipLocationChange: true });
      });

    }
  }
  clickAgenda() {
    if (this.loginServ.datosHeader.agendaLogId || this.loginServ.checkearPrivilegio(this.loginServ.privilegios.VER_TODAS_AGENDAS)) {
      if (!this.route.url.startsWith('/turnos')) {
        this.route.navigate(['/turnos'], { skipLocationChange: true });
      }
    }
  }
  clickGuardia() {
    if (!this.route.url.startsWith('/guardia')) {
      this.route.navigate(['/guardia'], { skipLocationChange: true });
    }
  }
  clickCosaCamara() {
    this.modalpage.open(ModalCamaraComponent);
  }
  clickPrincipal() {
    if (!this.route.url.startsWith('/principal')) {
      this.route.navigate(['/principal'], { skipLocationChange: true });
    }
  }
  public Logout() {
    this.route.navigate(['/principal'], { skipLocationChange: true });
    this.loginServ.logOutEvent.emit();
  }
  public cambiarSede() {
    this.route.navigate(['/principal'], { skipLocationChange: true });
    this.loginServ.cambiarSedeEvent.emit();
  }
  public irEmpresa() {
    if (!this.route.url.startsWith('/empresa')) {
      this.route.navigate([this.loginServ.empresaRutaSegunPriv()], { skipLocationChange: true });
    }
  }
  public irPerfil() {
    if (!this.route.url.startsWith('/usuario')) {
      this.route.navigate(['/usuario'], { skipLocationChange: true });
    }
  }
  public cambiarTema() {
    if (this.themeServ.SelectedTheme === TemasEnum.LIGHT) {
      this.themeServ.toggleDark();
    } else {
      this.themeServ.toggleLight();
    }
    this.loginServ.setearTema(this.themeServ.SelectedTheme).toPromise().then(x => {
    });
  }
  toggleShow() {
    if (this.modalpage.openDialogs.length === 0) {
      this.isShown = !this.isShown;
    }
  }
}


