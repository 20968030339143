import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHelperService } from '../helpers/http-helper.service';
import { ConsultarDiagnosticoInput, ProductoResponse,ProductoPreserfar } from 'src/app/models/shared/receta-models';
import { Observable } from 'rxjs';
import { APIReturnModel } from 'src/app/models/shared/api-return.model';
import { map } from 'rxjs/operators';
import { RecetaApiConfig } from './receta-api-config';

@Injectable({
  providedIn: 'root'
})
export class PreserfarService {

  constructor(
    private http: HttpClient,
    private HttpHelpers: HttpHelperService
  ) { }

   // Method for Consultar Medicamento
  
   public buscarProducto(texto: string |ConsultarDiagnosticoInput): Observable<ProductoPreserfar[]> {
    // Convert the string to a ConsultarDiagnosticoInput model if necessary
    const model = typeof texto === 'string' ? { texto } : texto;
  
    return this.http.put<APIReturnModel<ProductoResponse>>(
      RecetaApiConfig.BUSCAR_PRODUCTO_URL,model
    ).pipe(map(result => result.datos.data));
  }
}
