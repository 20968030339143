import { CajaApiConfig } from './caja-api-config';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHelperService } from '../helpers/http-helper.service';
import { ABMAcreedorUpdateModel, ABMAcreedorListModel } from 'src/app/models/caja/acreedor.model';
import { Observable } from 'rxjs';
import { APIReturnModel } from 'src/app/models/shared/api-return.model';
import { ABMGenericoConsultaModel, ABMGenericoModel } from 'src/app/models/shared/abm-generico.model';
import { SelectObjModel } from 'src/app/models/shared/selects.model';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class AcreedorService {
  constructor(
    private http: HttpClient,
    private HttpHelpers: HttpHelperService
  ) { }

  public actualizar(acreedor: ABMAcreedorUpdateModel): Observable<ABMAcreedorListModel> {
    return this.http.put<APIReturnModel<ABMAcreedorListModel>>(CajaApiConfig.ACREEDOR_ACTUALIZAR_URL, acreedor)
      .pipe(map(result => result.datos));
  }
  public list(datosBusqueda: ABMGenericoConsultaModel): Observable<ABMGenericoModel<ABMAcreedorListModel>> {
    return this.http.get<APIReturnModel<ABMGenericoModel<ABMAcreedorListModel>>>(CajaApiConfig.ACREEDOR_LIST_URL,
      this.HttpHelpers.buildQueryParams(datosBusqueda))
      .pipe(map(result => result.datos));
  }

  public desactivar(acreedorId: number): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(CajaApiConfig.ACREEDOR_DESACTIVAR_URL, acreedorId)
      .pipe(map(result => result.datos));
  }

  public eliminar(acreedorId: number): Observable<string> {
    return this.http.delete<APIReturnModel<string>>(CajaApiConfig.ACREEDOR_ELIMINAR_URL,
      this.HttpHelpers.buildQueryParams(acreedorId, 'acreedorId'))
      .pipe(map(result => result.datos));
  }
  public uno(acreedorId: number): Observable<ABMAcreedorUpdateModel> {
    return this.http.get<APIReturnModel<ABMAcreedorUpdateModel>>(CajaApiConfig.ACREEDOR_UNO_URL,
      this.HttpHelpers.buildQueryParams(acreedorId, 'acreedorId'))
      .pipe(map(result => result.datos));
  }
  public activar(acreedorId: number): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(CajaApiConfig.ACREEDOR_ACTIVAR_URL, acreedorId)
      .pipe(map(result => result.datos));
  }
  public desactivarTelefono(acreedorTelId: number): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(CajaApiConfig.ACREEDOR_DESACTIVAR_TEL_URL, acreedorTelId)
      .pipe(map(result => result.datos));
  }
  public activarTelefono(acreedorTelId: number): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(CajaApiConfig.ACREEDOR_ACTIVAR_TEL_URL, acreedorTelId)
      .pipe(map(result => result.datos));
  }
  public busquedaSelect(busqueda: string): Observable<SelectObjModel[]> {
    return this.http.get<APIReturnModel<SelectObjModel[]>>(CajaApiConfig.ACREEDOR_BUSQUEDA_SELECT_URL,
      this.HttpHelpers.buildQueryParams(busqueda, 'busqueda'))
      .pipe(map(result => result.datos));
  }
}
