import { ErrorHelperService } from './../services/helpers/error-helper.service';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginService } from '../services/login/login.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private loginServ: LoginService,
    private ErrorHelpers: ErrorHelperService
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse, value: any) => {
          if (error.status === 401) {
            this.loginServ.logOutEvent.emit();
          }
          return throwError(this.ErrorHelpers.handleError(error));
        })
      );
  }
}
