import { TipoCobranzaOrdenEnum } from './../../../models/shared/enums.model';
import { LoginService } from 'src/app/services/login/login.service';
import { CajaService } from './../../../services/caja/caja.service';
import { CajaVerModel, EstadoCajaEnum, DetallesMovimientosCajaModel, TotalesMediosCajaModel, ListadoOrdenesCajaModel, ResultadoLiquidacionEfectorModel } from './../../../models/caja/caja.model';
import { Component, OnInit, Output, EventEmitter, Input, HostBinding, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ModalCajaMovimientoNuevoComponent } from '../modal-caja-movimiento-nuevo/modal-caja-movimiento-nuevo.component';
import { ModalConfirmarComponent } from 'src/app/components/shared/modal-confirmar/modal-confirmar.component';
import { OrdenAtencionService } from 'src/app/services/facturacion/orden-atencion.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSort } from '@angular/material/sort';
@Component({
  selector: 'app-caja-medico',
  templateUrl: './caja-medico.component.html',
  styleUrls: ['./caja-medico.component.scss'], 
   animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class CajaMedicoComponent implements OnInit {
  cajaVer = new CajaVerModel();
  colliquiEfector: string[] = ['efectorNombre', 'cantidadCupon', 'efectivo', 'tarjetaCredito', 'tarjetaDebito', 'cheque','cuentaCorriente', 'total' ,'buttons'];
  liquidaEfectorDS = new MatTableDataSource<ResultadoLiquidacionEfectorModel>();
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  constructor(public loginServ: LoginService,
    private cajaServ: CajaService,
    private modalpage: MatDialog,
    private ordenServ: OrdenAtencionService) { }

  ngOnInit(): void {
    this.cajaServ.usuarioCajaAbierta().toPromise().then(x => {
      this.cajaVer = x;
      this.updateDS();
  });
}

updateDS() {
    this.liquidaEfectorDS = new MatTableDataSource<ResultadoLiquidacionEfectorModel>(this.cajaVer.liquiEfector);
}
descargarResumen(item: ResultadoLiquidacionEfectorModel) {
  this.cajaServ.descargarLiquidacion(item).toPromise().then((x) => {
    var raw = window.atob(x);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));
    for(var i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i)};
    var blob = new Blob([array], {type: "application/pdf"});
    var objectUrl = URL.createObjectURL(blob);
    window.open(objectUrl);
  });
}

}
