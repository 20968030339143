import { Injectable } from '@angular/core';
import { TemasEnum } from 'src/app/models/shared/enums.model';
import { LoginService } from '../login/login.service';

export const darkTheme = {
  'nav-color': '#000',
  'header-color': '#212121',
  'sidebar-color': '#000',
  'content-color': '#303030',
  'background-color': '#303030',
  'container-color': '#303030',
  'btn-color': '#505050',
  // text
  'text-color': '#fff',
  'text-color-invert': '#fff',
  'text-btn-color': '#fff',
  // table thenme
  'table-text-color': '#fff',
  'table-select-color': '#000',
  'table-open-select-color': 'rgb(38, 38, 38)',
  'table-background-color': '#212121',
  'table-cell-color': '#000'
};

export const lightTheme = {
  'nav-color': '#EAF1FB',
  'header-color': 'rgba(154, 168, 181, 1)',
  'sidebar-color': 'rgba(52, 82, 106, 1)',
  'content-color': '#cdcdcd',
  'background-color': '#white',
  'container-color': '#fff',
  'btn-color': '#fff',
  // text
  'text-color': '#474747',
  'text-color-invert': '#000',
  'text-btn-color': '#6574cf',
  // table
  'table-text-color': '#212121',
  'table-select-color': '#cdcdcd',
  'table-open-select-color': 'rgb(230, 230, 230)',
  'table-background-color': 'rgb(247, 247, 247)',
  'table-cell-color': '#cdcdcd'
};

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  public SelectedTheme = this.loginServ.datosHeader.temaLog;
  constructor(private loginServ: LoginService) { }

  toggleDark() {
    this.setTheme(darkTheme);
    this.SelectedTheme = TemasEnum.DARK;
  }

  toggleLight() {
    this.setTheme(lightTheme);
    this.SelectedTheme = TemasEnum.LIGHT;
  }

  private setTheme(theme: {}) {
    Object.keys(theme).forEach(k =>
      document.documentElement.style.setProperty(`--${k}`, theme[k])
    );
  }
}
