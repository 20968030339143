import { LoginModel } from './login.model';
import { PersonaModel } from '../shared/persona.model';
import { TelefonoModel } from '../shared/telefono.model';
import { SelectObjModel } from '../shared/selects.model';
import { ConsultaConsultorioModel, ABMEmpresaPtoVtaUpdateModel } from '../empresa/abm-empresa.model';

export class PrimerUsEmpresaModel {
  id: number;
  nombre: string;
  foto: string;
  fotoDatoBase64: string;
  email: string;
  cuit: string;
  medicosPago: number;
  tipoEsp: SelectObjModel;
  ptoVentas: ABMEmpresaPtoVtaUpdateModel[];
  constructor() {
    this.tipoEsp = new SelectObjModel();
    this.ptoVentas = new Array<ABMEmpresaPtoVtaUpdateModel>();
  }
}
export class PrimerUsSedeModel {
  id: number;
  nombre: string;
  calle: string;
  numero: number;
  piso: string;
  depto: string;
  timbre: string;
  ciudad: string;
  codigoPostal: string;
  provincia: number;
  provinciaStr: string;
  empresa: PrimerUsEmpresaModel;
  telefonos: TelefonoModel[];
  consultorios: ConsultaConsultorioModel[];
  constructor() {
    this.empresa = new PrimerUsEmpresaModel();
    this.telefonos = new Array<TelefonoModel>();
    this.consultorios = new Array<ConsultaConsultorioModel>();
  }
}
export class PrimerUsPersonalModel {
  id: number;
  fechaIngreso: Date;
  fechaEgreso?: Date;
  legajo: string;
  matriculaProfesional: string;

  sede: PrimerUsSedeModel;
  constructor() {
    this.sede = new PrimerUsSedeModel();
  }
}
export class PrimerUsuarioModel {
  id: number;
  // sedePreferencialId?: number;
  persona: PersonaModel;

  personal: PrimerUsPersonalModel;

  datosLogin: LoginModel;
  constructor() {
    this.persona = new PersonaModel();
    this.personal = new PrimerUsPersonalModel();
    this.datosLogin = new LoginModel();
  }
}
