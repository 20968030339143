import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ModalTelefonoModule } from './../../../shared/modal-telefono/modal-telefono.module';
import { MatDialogModule } from '@angular/material/dialog';
import { AccionesTableModule } from './../../../shared/acciones-table/acciones-table.module';
import { CdkTableModule } from '@angular/cdk/table';
import { MatTableModule } from '@angular/material/table';
import { MaskDirectivesModule } from 'src/app/directives/mask-directives.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PacienteUpdateComponent } from './paciente-update.component';
import { ModalPacienteExisteDniModule } from 'src/app/components/paciente/modal-paciente-existe-dni/modal-paciente-existe-dni.module';
import { ModalDireccionModule } from 'src/app/components/shared/modal-direccion/modal-direccion.module';
import { ModalPacientePlanesUpdateModule } from '../modal-paciente-planes-update/modal-paciente-planes-update.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from "@angular/material/checkbox";
@NgModule({
  declarations: [PacienteUpdateComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MaskDirectivesModule,
    MatTableModule,
    CdkTableModule,
    AccionesTableModule,
    MatDialogModule,
    ModalTelefonoModule,
    ModalDireccionModule,
    ModalPacientePlanesUpdateModule,
    ModalPacienteExisteDniModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatCheckboxModule,
  ],
})
export class PacienteUpdateModule {
  static getMyComponent() {
    return PacienteUpdateComponent;
  }
}
