<ng-container *ngIf="primerLogin | async">
  <app-starter (terminaAnimacion)="sacarAnimacion()"></app-starter>
</ng-container>
<div class="fondoPrincipal" style="position: relative">
  
  <!-- <img style="z-index: 32; left: 0px; position: relative;" alt="bottom image" src="/assets/images/svg/textura2.png"> -->
  <div style="z-index: 100; right: 40px; position: absolute; bottom: 30px;">
    <img  alt="top image" src="/assets/images/svg/logoMain.png">
    <!-- <img class="logo" alt="top image" src="/assets/images/svg/logoempresa5.png"> -->
  </div>
</div>
