import { environment } from 'src/environments/environment';

export class LoginApiConfig {
  // LOGIN
  public static LOGIN_USUARIO_URL = environment.apiUrl + 'Login/LoginUsuario';
  public static LOGIN_EMPRESAS_USUARIO_URL = environment.apiUrl + 'Login/EmpresasUsuario';
  public static LOGIN_SEDES_USUARIO_SIN_IMG_URL = environment.apiUrl + 'Login/SedesUsuarioSinImg';
  public static LOGIN_ELEGIR_SEDE_URL = environment.apiUrl + 'Login/ElegirSede';
  public static LOGIN_LOGOUT_URL = environment.apiUrl + 'Login/Logout';
  public static LOGIN_CONSEGUIR_HEADERS_URL = environment.apiUrl + 'Login/ConseguirHeaders';
  public static LOGIN_PRIMER_USUARIO_URL = environment.apiUrl + 'Login/PrimerUsuario';
  public static LOGIN_AGENDA_US_LOGUEADO_URL = environment.apiUrl + 'Login/AgendaUsLogueado';
  public static LOGIN_SETEAR_TEMA_URL = environment.apiUrl + 'Login/SetearTema';
  public static LOGIN_PASS_CONSULTA_URL = environment.apiUrl + 'Login/UsPassConsulta';
  public static LOGIN_UPDATE_PASS_URL = environment.apiUrl + 'Login/ActualizarPass';
  public static LOGIN_USUARIO_SESION_URL = environment.apiUrl + 'Login/UsuarioSesion';
  public static LOGIN_EMAIL_RECUPERAR_PASS_URL = environment.apiUrl + 'Login/EmailRecuperarPass';
  public static LOGIN_CAMBIO_PASS_LINK_URL = environment.apiUrl + 'Login/CambioPassLink';
  public static LOGIN_NOMBRE_USUARIO_URL = environment.apiUrl + 'Login/NombreUsuario';
  public static LOGIN_EXISTE_CUIT_URL = environment.apiUrl + 'Login/ExisteCuit';
  public static LOGIN_CONSIGUE_LIMITE_MEDICOS_URL = environment.apiUrl + 'Login/ConsigueLimiteMedicos';

}
