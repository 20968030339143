import { TipoAccionABMEnum } from 'src/app/models/shared/enums.model';
import { TipoABM } from 'src/app/models/shared/abm-generico.model';
import { MedioPagoEnum, LetraComprobanteEnum, TipoComprobanteEnum, TipoCobranzaOrdenEnum } from './../shared/enums.model';
import { PagosModel } from '../shared/pagos.model';
import { DatosPersonaHeaderModel } from '../shared/persona.model';

export enum EstadoCajaEnum {
  ABIERTA = 0,
  CERRADA,
  TRANSFERIDA,
  AUDITADA
}
export enum TipoCajaEnum {
  AUDITOR = 0,
  RECEPCION,
  TESORERO
}
export enum AfectaCuentaEnum {
  NO_AFECTA = 0,
  DEBITO,
  CREDITO
}
export enum TipoMovimientoCajaEnum {
  INGRESO = 0,
  EGRESO,
  NINGUNO
}
export class CajaNuevaModel {
  montoInicial: number;
  tipo: TipoCajaEnum;
  constructor() { }
}
export class CajaAuditarModel {
  id: number;
  observacionAuditoria: string;
  constructor() { }
}
export class CajasListModel {
  id: number;
  propietario: string;
  fechaApertura: Date;
  fechaCierre: Date;
  estado: string;
  propietarioId: number;
  usuarioId: number;
  estaAuditado: boolean;
  constructor() { }
}
export class CajaVerModel {
  id: number;
  datosPropietario: DatosPersonaHeaderModel;
  propietarioUsId: number;
  totalGeneral: number;
  totalDolarGeneral: number;
  estado: EstadoCajaEnum;
  totalCTACTE: string;
  totalCTACTEDOLAR:string;
  totalCUPON: string;
  totalCOBRANZA: string;
  totalCOBRANZADOLAR:string;

  totales: TotalesMediosCajaModel[];
  detalles: DetallesMovimientosCajaModel[];
  ordenes: ListadoOrdenesCajaModel[];
  liquiEfector: ResultadoLiquidacionEfectorModel[];
  liquiCirugia: ResultadoLiquidacionEfectorModel[];
  liquiOS: ResultadoLiquidacionOSModel[];
  constructor() {
    this.totales = new Array<TotalesMediosCajaModel>();
    this.detalles = new Array<DetallesMovimientosCajaModel>();
    this.ordenes = new Array<ListadoOrdenesCajaModel>();
    this.liquiEfector = new Array<ResultadoLiquidacionEfectorModel>();
    this.liquiCirugia = new Array<ResultadoLiquidacionEfectorModel>();
    this.liquiOS = new Array<ResultadoLiquidacionOSModel>();
    this.datosPropietario = new DatosPersonaHeaderModel();
  }
}

export class TotalesMediosCajaModel {
  medio: MedioPagoEnum;
  medioStr: string;
  monto: string;
  monedaId:number;
  constructor() { }
}

export class DetallesMovimientosCajaModel {
  fecha: string;
  concepto: string;
  cuenta: string;
  monedaId:number;
  ingreso: number;
  egreso: number;
  comprobante: string;
  constructor() { }
}

export class ListadoOrdenesCajaModel {
  idOrden: number;
  fechaHora: string;
  numero: string;
  paciente: string;
  efectuo: string;
  facturado: string;
  modoPago: TipoCobranzaOrdenEnum;
  symbol: string;
  importe: string;
  plus: string;
  monedaId:number;
}

export class MovimientoNuevoCajaModel {
  cajaId: number;
  fechaMov: Date;
  tipoMov: TipoMovimientoCajaEnum;
  concepto: string;
  importeTotal: number;
  fechaComp?: Date;
  ptoVtaIdComp?: number;
  ptoVtaEgreso?: number;
  tipoComp?: TipoComprobanteEnum;
  letraComp?: LetraComprobanteEnum;
  nroComprobanteEgreso?: number;
  clienteId?: number;
  personalMedicoId?: number;
  acreedorId?: number;
  pacienteId?: number;

  pagos: PagosModel[];
  constructor() {
    this.pagos = new Array<PagosModel>();
  }
}
export class CajaControlFiltrosModel {
  fechaDesde: Date;
  fechaHasta: Date;
  tipo: TipoCajaEnum;
  personalId?: number;
  constructor() { }
}
export class CajaControlListModel {
  id: number;
  fechaInicio: Date;
  fechaCierre: Date;
  tipo: string;
  propietario: string;
  importe: number;
  auditor: string;
  observacion: string;
  constructor() { }
}
export class ResultadoLiquidacionEfectorModel {
  cajaId?:number;
  efectorNombre: string;
  cantidadCupon: number;
  efectivo: number;
  dolarBillete: number;
  tarjetaCredito: number;
  tarjetaDebito: number;
  cheque: number;
  cuentaCorriente: number;
  cuentaCorrienteDolar:number;
  total: number;
  totalDolar: number;
  movimientos: DetalleCuponEfectorModel[];
  usuario: string;
  dataList: ResultadoLiquidacionEfectorDataModel[];
  constructor() {
    this.movimientos = new Array<DetalleCuponEfectorModel>();
    this.dataList = new Array<ResultadoLiquidacionEfectorDataModel>();
  }

}
export class ResultadoLiquidacionEfectorDataModel {
  efectorNombre: string;
  cantidadCupon: number;
  efectivo: number;
  dolarBillete:number;
  tarjetaCredito: number;
  tarjetaDebito: number;
  cheque: number;
  cuentaCorriente: number;
  cuentaCorrienteDolar:number;
  total: number;
  totalDolar:number;
  movimientos: DetalleCuponEfectorModel[];
  usuario: string;
  constructor() {
    this.movimientos = new Array<DetalleCuponEfectorModel>();
  }
}

export class DetalleCuponEfectorModel {
  obraSocial: string;
  numeroCupon: string;
  paciente: string;
  turnoMotivo: string;
  importe: string;
  medioPago: string;
  usuario: string;
  MonedaId:number;
  constructor() { }
}

export class ResultadoLiquidacionOSModel {
  osNombre: string;
  cantidad: number;
  efectivo: number;
  montoCupon: number;
  total: number;
  movimientos: DetalleCuponOSModel[];

  constructor() {
    this.movimientos = new Array<DetalleCuponOSModel>();
  }
}
export class DetalleCuponOSModel {
  plan: string;
  numeroCupon: string;
  paciente: string;
  turnoMotivo: string;
  importe: string;
  medioPago: string;
  constructor() { }
}