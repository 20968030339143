import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { Directive, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import * as textMask from 'vanilla-text-mask/dist/vanillaTextMask.js';

@Directive({
  selector: '[appMaskInteger]'
})
export class MaskIntegerDirective implements OnDestroy {
  maskedInputController;

  constructor(private element: ElementRef, private rend: Renderer2) {
    rend.setStyle(this.element.nativeElement, 'text-align', 'right');
    this.maskedInputController = textMask.maskInput({
      inputElement: this.element.nativeElement,
      mask: createNumberMask({
        prefix: '',
        suffix: '',
        includeThousandsSeparator: false,
        thousandsSeparatorSymbol: '',
        allowDecimal: false,
        decimalSymbol: '',
        decimalLimit: 0,
        integerLimit: 11,
        requireDecimal: false,
        allowNegative: false,
        allowLeadingZeroes: false
      }),
    });
  }

  ngOnDestroy() {
    this.maskedInputController.destroy();
  }
}
