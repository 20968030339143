import { NgModule } from '@angular/core';
import { MaskCurrencyDirective } from './mask-currency.directive';
import { MaskDateDirective } from './mask-date.directive';
import { MaskEmailDirective } from './mask-email.directive';
import { MaskHourDirective } from './mask-hour.directive';
import { MaskIntegerDirective } from './mask-integer.directive';
import { MaskNroComprobanteDirective } from './mask-nro-comprobante.directive';
import { MaskNroPtoVtaDirective } from './mask-nro-ptovta.directive';


@NgModule({
  declarations: [
    MaskCurrencyDirective,
    MaskDateDirective,
    MaskEmailDirective,
    MaskHourDirective,
    MaskIntegerDirective,
    MaskNroComprobanteDirective,
    MaskNroPtoVtaDirective,
  ],
  exports: [
    MaskCurrencyDirective,
    MaskDateDirective,
    MaskEmailDirective,
    MaskHourDirective,
    MaskIntegerDirective,
    MaskNroComprobanteDirective,
    MaskNroPtoVtaDirective,
  ]
})
export class MaskDirectivesModule { }
