import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { async } from 'rxjs/internal/scheduler/async';
import { Us1Service } from 'src/app/services/login/us1.service';

import { LoginSedeComponent } from './components/login/login-sede/login-sede.component';
import { LoginUsuarioComponent } from './components/login/login-usuario/login-usuario.component';
import { ModalRecuperarPassComponent } from './components/login/modal-recuperar-pass/modal-recuperar-pass.component';
import { LoginModel } from './models/login/login.model';
import { EmpresasUsuarioModel, SedesUsuarioModel, UsuarioLoginModel } from './models/login/usuarioLogin.model';
import { TemasEnum, TipoSesionEnum } from './models/shared/enums.model';
import { LoginService } from './services/login/login.service';
import { ThemeService } from './services/theme/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  // deviceInfo: DeviceInfo;
  title = 'Panacea';
  sidebarLinks = new Map<string, string>();
  tituloComp = 'Principal';
  isLayout = true;
  constructor(
    public loginServ: LoginService,
    private us1Serv: Us1Service,
    private modalpage: MatDialog,
    public route: Router,
    private temaServ: ThemeService
  ) {
    if (location.pathname == '/shifter' || location.pathname == '/shifter-youtube' || location.pathname.includes('/public')) {
      this.isLayout = false;
    }
    else {
      const storage: Storage = window.localStorage;
      this.loginServ.usuarioLoginEvent.subscribe((x: LoginModel) => {
        this.LoguearUsuario(x);
      });
      this.loginServ.cambiarSedeEvent.subscribe(() => {
        this.abrirSede(this.loginServ.UsuarioLogueado);
        this.loginServ.actualizarMenuEvent.emit();
      });
      this.loginServ.sedeLoginEvent.subscribe((y: SedesUsuarioModel) => {
        this.loginServ.UsuarioLogueado.sesion.sedeElegidaId = y.id;
        this.loginServ.UsuarioLogueado.sesion.empresaElegidaId = y.empresaId;
        this.loginServ.elegirSede(this.loginServ.UsuarioLogueado).toPromise().then(datosElegirSede => {
          if (datosElegirSede) {
            this.loginServ.UsuarioLogueado.roles = datosElegirSede.roles;
            this.loginServ.UsuarioLogueado.datosAgenda = datosElegirSede.datosAgenda;
            storage.setItem('User', JSON.stringify(this.loginServ.UsuarioLogueado));
            this.route.navigate(['/principal'], { skipLocationChange: true });
            this.loginServ.actualizarMenuEvent.emit();
            this.cerrarModal();
            this.updateUserNav();

          } else {
            this.Logout(true);
          }
        }).catch(err => this.Logout(true));
      });
      this.loginServ.logOutEvent.subscribe(() => {
        this.Logout(false);
      });
      this.us1Serv.finalizarEvent.subscribe(() => {
        this.us1Serv.crearPrimerUsuario().subscribe((Us1: UsuarioLoginModel) => {
          if (Us1 !== null || Us1 !== undefined) {
            // this.route.navigate(['/principal'], { skipLocationChange: true });
            this.cargarUsuario(storage, Us1, true);
          }
        });
      });
      this.us1Serv.cierraNuevaEmrpesa.subscribe(() => {
        this.route.navigate(['/principal'], { skipLocationChange: true });
        this.cerrarModal();
      });
      this.us1Serv.cancelarEvent.subscribe(() => {
        // this.route.navigate(['/principal'], { skipLocationChange: true });
        // this.abrirLogin();
        this.Logout(false);
      });

      if (location.pathname.search('reiniciar-pass') > -1) {
        const modal = this.modalpage.open(ModalRecuperarPassComponent, {
          data: {
            usId: location.pathname.substr(location.pathname.lastIndexOf('/') + 1)
          }
        });
        modal.afterClosed().subscribe((result: UsuarioLoginModel) => {
          if (result) {
            this.procesoLog(result, storage);
          } else {
            this.loginStorage(storage);
          }
        });
        this.route.navigate(['']);
        // this.route.navigate(['/principal'], { skipLocationChange: true });
        return;
      } else {
        this.loginStorage(storage);
      }
    }
  }
  loginStorage(storage: Storage) {
    // Si el usuario existe en storage lo asigna a la variable global
    if (storage.User && storage.User !== '') {
      const UsStorage = JSON.parse(storage.User);
      if (UsStorage.sesion) {
        this.loginServ.usuarioSesion(UsStorage.id, UsStorage.sesion.id).toPromise().then(Us => {
          this.cargarUsuario(storage, Us);
        }).catch((err) => {
          this.Logout(true);
        });
      } else {
        // si no abre el login
        this.abrirLogin();
      }
    } else {
      // si no abre el login
      this.abrirLogin();
    }
  }

  public updateTitulo(titulo: string) {
    this.tituloComp = titulo;
  }
  public LoguearUsuario(datosLog: LoginModel) {
    // crea storage y consigue datos de la sesion
    const storage: Storage = window.localStorage;
    // this.deviceInfo = this.deviceService.getDeviceInfo();
    datosLog.sesionNombre = 'Temp';
    datosLog.sesionIP = '';
    // if (this.elecServ.isElectronApp) {
    // x.sesionTipo = TipoSesionEnum.ELECTRON;
    // } else if (this.deviceService.isMobile()) {
    // x.sesionTipo = TipoSesionEnum.MOBILE;
    // } else {
    datosLog.sesionTipo = TipoSesionEnum.BROWSER;
    // }
    // llama al api
    this.loginServ.loginUsuario(datosLog).toPromise().then((response: UsuarioLoginModel) => {
      // si es primer usuario abre wizard
      if (response.primerUsuario === true) {
        this.cerrarModal();
        this.us1Serv.datosPersona.datosLogin = datosLog;
        this.us1Serv.datosPersona.id = response.id;
        this.abrirPrimerUsuario();
      } else {
        this.checkPassCorrecto(response, storage);
      }
    });
  }
  checkPassCorrecto(response: UsuarioLoginModel, storage: Storage) {
    if (response.passInicial) {
      const modal = this.modalpage.open(ModalRecuperarPassComponent, {
        data: {
          usId: response.id
        }
      });
      modal.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.procesoLog(response, storage);
        }
      });
    } else {
      this.procesoLog(response, storage);
    }
  }
  procesoLog(response: UsuarioLoginModel, storage: Storage) {
    // setea el tema segun el header
    switch (this.loginServ.datosHeader.temaLog) {
      case TemasEnum.DARK:
        this.temaServ.toggleDark();
        break;
      case TemasEnum.LIGHT:
        this.temaServ.toggleLight();
        break;
    }
    // si no,si el usuario tiene multiples sedes
    if (response.multipleSedes) {
      // si hay multiples sedes, abre el modal
      this.abrirSede(response);
    } else {
      // si no, loguea directamente
      this.cargarUsuario(storage, response);
    }
  }
  public Logout(ErrorConexion: boolean) {
    this.cerrarModal();
    this.route.navigate(['']);
    this.abrirLogin();
    const storage: Storage = window.localStorage;
    storage.removeItem('animacion');
    storage.removeItem('User');
    storage.removeItem('DatosHeader');
    this.loginServ.UsuarioLogueado = null;
    this.temaServ.toggleLight();
    if (ErrorConexion === false) {
      this.loginServ.logout(this.loginServ.UsuarioLogueado).toPromise().then();
    }
  }
  public cargarUsuario(storage: Storage, x: UsuarioLoginModel, nuevaEmpresa: boolean = false) {
    if (nuevaEmpresa === false) {
      this.route.navigate(['/principal'], { skipLocationChange: true });
    }
    this.loginServ.UsuarioLogueado = x;
    storage.setItem('User', JSON.stringify(x));
    if (nuevaEmpresa === false) {
      this.cerrarModal();
    }
    this.updateUserNav();
  }

  // POPUP
  private abrirLogin() {
    this.modalpage.open(LoginUsuarioComponent, {
      disableClose: true
    });
  }
  private abrirPrimerUsuario() {
    this.route.navigate(['/us1'], { skipLocationChange: true });
  }
  private abrirSede(x: UsuarioLoginModel) {
    this.loginServ.UsuarioLogueado = x;
    // storage.setItem('User', JSON.stringify(x));
    this.loginServ.empresasUsuario(x.id).toPromise().then((empresasUs: EmpresasUsuarioModel[]) => {
      this.cerrarModal();
      let modal = this.modalpage.open(LoginSedeComponent, {
        data: { empresas: empresasUs },
        disableClose: true
      });
      modal.afterClosed().subscribe(x => {
        this.loginServ.actualizarMenuEvent.emit();
      });
    });
  }
  private cerrarModal() {
    if (this.modalpage !== undefined) {
      this.modalpage.closeAll();
    }
  }
  private async updateUserNav() {
    // this.loginServ.usTieneConfigEmpresa = this.loginServ.updateTieneEmpresa();
    this.loginServ.usMultiplesSedes = await this.loginServ.UsuarioLogueado.multipleSedes;
    this.loginServ.ShowEstadistica = await this.loginServ.validateEstadistica();
    this.loginServ.ShowQuirurgico = await this.loginServ.validateQuirurgico();
    this.loginServ.IsNutritionist = await this.loginServ.checkNutritionist();
  }

}
