import { environment } from 'src/environments/environment';

export class FacturacionApiConfig {
  // FACTURACION
  public static FACTURACION_AUDITORIA_LISTA_ORDEN_URL = environment.apiUrl + 'Facturacion/listarOrdenes';
  public static FACTURACION_CONTRATOS_LIST_URL = environment.apiUrl + 'Facturacion/ContratosList';
  public static FACTURACION_CONTRATOS_UNO_URL = environment.apiUrl + 'Facturacion/ContratosUno';
  public static FACTURACION_CONTRATOS_ACTUALIZAR_URL = environment.apiUrl + 'Facturacion/ContratosActualizar';
  public static FACTURACION_CONTRATOS_EXISTE_NOMBRE_URL = environment.apiUrl + 'Facturacion/ContratosExisteNombre';
  public static FACTURACION_CONTRATOS_EXISTE_CODIGO_URL = environment.apiUrl + 'Facturacion/ContratosExisteCodigo';
  public static FACTURACION_CONTRATOS_EXISTE_PLAN_URL = environment.apiUrl + 'Facturacion/ContratosExistePlan';
  public static FACTURACION_CONTRATOS_HAY_APLICA_TODOS_URL = environment.apiUrl + 'Facturacion/ContratosHayAplicaTodos';
  public static FACTURACION_CONTRATOS_PRESTACIONES_LIST_URL = environment.apiUrl + 'Facturacion/ContratoPrestacionesList';
  public static FACTURACION_CONTRATOS_PRESTACIONES_UNO_URL = environment.apiUrl + 'Facturacion/ContratoPrestacionesUno';
  public static FACTURACION_CONTRATOS_PRESTACIONES_ACTUALIZAR_URL = environment.apiUrl + 'Facturacion/ContratoPrestacionesActualizar';
  public static FACTURACION_PRESTACIONES_CAPITULOS_URL = environment.apiUrl + 'Facturacion/PrestacionesCapitulos';
  public static FACTURACION_PRESTACIONES_POR_ID_URL = environment.apiUrl + 'Facturacion/PrestacionesPorId';
  public static FACTURACION_AUDITAR_ORDEN_URL = environment.apiUrl + 'Facturacion/auditaOrden';
  public static FACTURACION_ORDEN_ATENCION_MODIFICADAS_URL = environment.apiUrl + 'Facturacion/ordeneAtencionModificadas';
  public static FACTURACION_RESUMENES_BUSQUEDA_URL = environment.apiUrl + 'Facturacion/ResumenesBusqueda';
  public static FACTURACION_RESUMEN_ITEM_URL = environment.apiUrl + 'Facturacion/ResumenItem';
  public static FACTURACION_RESUMENES_SELECT_URL = environment.apiUrl + 'Facturacion/ResumenesSelect';
  public static FACTURACION_RESUMEN_ADMINISTRAR_URL = environment.apiUrl + 'Facturacion/ResumenAdministrar';
  public static FACTURACION_ORDENES_RESUMEN_URL = environment.apiUrl + 'Facturacion/OrdenesResumen';
  public static FACTURACION_RESUMEN_RECHAZAR_ORDEN_URL = environment.apiUrl + 'Facturacion/ResumenRechazarOrden';
  public static FACTURACION_RESUMEN_CONCILIACION_UPDATE_URL = environment.apiUrl + 'Facturacion/ResumenConciliacionUpdate';
  public static FACTURACION_RESUMEN_CONCILIACION_DEBITO_URL = environment.apiUrl + 'Facturacion/ResumenConciliacionDebito';
  public static FACTURACION_RESUMEN_PRINT_URL = environment.apiUrl + 'Facturacion/ResumenPrint';
  public static FACTURACION_RESUMEN_ANULAR_URL = environment.apiUrl + 'Facturacion/ResumenAnular';
  public static FACTURACION_RESUMEN_UPDATE_URL = environment.apiUrl + 'Facturacion/ResumenUpdate';
  // OBRASOCIAL
  public static OBRA_SOCIAL_UNO_URL = environment.apiUrl + 'ObraSocial/Uno';
  public static OBRA_SOCIAL_LIST_URL = environment.apiUrl + 'ObraSocial/List';
  public static OBRA_SOCIAL_BORRAR_URL = environment.apiUrl + 'ObraSocial/Borrar';
  public static OBRA_SOCIAL_UPDATE_URL = environment.apiUrl + 'ObraSocial/Actualizar';
  public static OBRA_SOCIAL_PLANES_URL = environment.apiUrl + 'ObraSocial/Planes';
  public static OBRA_SOCIAL_BUSQUEDA_SELECT_URL = environment.apiUrl + 'ObraSocial/BusquedaSelect';
  public static OBRA_SOCIAL_PLANES_USUARIO_URL = environment.apiUrl + 'ObraSocial/PlanesUsuario';
  // ORDEN DE ATENCION
  public static ORDEN_UPDATE_URL = environment.apiUrl + 'OrdenAtencion/Actualizar';
  public static ORDEN_CABECERA_URL = environment.apiUrl + 'OrdenAtencion/Cabecera';
  public static ORDEN_AGENDA_CABECERA_URL = environment.apiUrl + 'OrdenAtencion/AgendaCabecera';
  public static ORDEN_PRESTACIONES_ORDEN_URL = environment.apiUrl + 'OrdenAtencion/PrestacionesOrdenAtencion';
  // public static ORDEN_HAY_PRESTACIONES_ESPECIALIDAD_URL = environment.apiUrl + 'OrdenAtencion/HayPrestacionesEspecialidad';
  public static ORDEN_ORDENES_PERSONA_URL = environment.apiUrl + 'OrdenAtencion/OrdenesPersona';
  public static ORDEN_VALOR_PRESTACION_URL = environment.apiUrl + 'OrdenAtencion/valorizarPrestacion';
  public static ORDEN_AUDITORIA_URL = environment.apiUrl + 'OrdenAtencion/OrdenAuditoria';
  public static ORDEN_HISTORICO_URL = environment.apiUrl + 'OrdenAtencion/HistoricoOA';
  public static ORDEN_RECHAZADA_POR_NRO_URL = environment.apiUrl + 'OrdenAtencion/OrdenRechazadaPorNro';
}
