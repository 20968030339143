import { ABMGenericoConsultaModel, ABMGenericoModel } from 'src/app/models/shared/abm-generico.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SelectObjModel } from 'src/app/models/shared/selects.model';
import { APIReturnModel } from 'src/app/models/shared/api-return.model';
import { HttpHelperService } from '../helpers/http-helper.service';
import { ABMAgendaListModel, ABMAgendaUpdateModel, ABMAgendaActualizarDiaModel } from 'src/app/models/empresa/abm-agenda.model';
import { EmpresaApiConfig } from './empresa-api-config';

@Injectable({
  providedIn: 'root'
})
export class AgendasService {
  constructor(
    private http: HttpClient,
    private HttpHelpers: HttpHelperService
  ) { }

  public actualizar(agenda: ABMAgendaUpdateModel): Observable<ABMAgendaListModel> {
    return this.http.put<APIReturnModel<ABMAgendaListModel>>(EmpresaApiConfig.AGENDA_UPDATE_URL, agenda)
      .pipe(map(result => result.datos));
  }
  public eliminar(agendaId: number): Observable<string> {
    return this.http.delete<APIReturnModel<string>>(EmpresaApiConfig.AGENDA_ELIMINAR_URL,
      this.HttpHelpers.buildQueryParams(agendaId, 'agendaId'))
      .pipe(map(result => result.datos));
  }
  public desactivar(agendaId: number): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(EmpresaApiConfig.AGENDA_DESACTIVAR_URL, agendaId)
      .pipe(map(result => result.datos));
  }
  public activar(agendaId: number): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(EmpresaApiConfig.AGENDA_ACTIVAR_URL, agendaId)
      .pipe(map(result => result.datos));
  }
  public list(datosBusqueda: ABMGenericoConsultaModel): Observable<ABMGenericoModel<ABMAgendaListModel>> {
    return this.http.get<APIReturnModel<ABMGenericoModel<ABMAgendaListModel>>>(EmpresaApiConfig.AGENDA_LIST_URL,
      this.HttpHelpers.buildQueryParams(datosBusqueda))
      .pipe(map(result => result.datos));
  }
  public uno(agendaId: number): Observable<ABMAgendaUpdateModel> {
    return this.http.get<APIReturnModel<ABMAgendaUpdateModel>>(EmpresaApiConfig.AGENDA_UNA_URL,
      this.HttpHelpers.buildQueryParams(agendaId, 'agendaId'))
      .pipe(map(result => result.datos));
  }
  public miembrosPersonalSede(sedeId: number): Observable<SelectObjModel[]> {
    return this.http.get<APIReturnModel<SelectObjModel[]>>(EmpresaApiConfig.AGENDA_MIEMBROS_PERSONAL_SEDE_URL,
      this.HttpHelpers.buildQueryParams(sedeId, 'sedeId'))
      .pipe(map(result => result.datos));
  }
  public miembrosPersonalEmpresa(): Observable<SelectObjModel[]> {
    return this.http.get<APIReturnModel<SelectObjModel[]>>(EmpresaApiConfig.AGENDA_MIEMBROS_PERSONAL_EMPRESA_URL)
      .pipe(map(result => result.datos));
  }
  public guardarDia(datosDia: ABMAgendaActualizarDiaModel): Observable<ABMAgendaActualizarDiaModel> {
    return this.http.put<APIReturnModel<ABMAgendaActualizarDiaModel>>(EmpresaApiConfig.AGENDA_GUARDAR_DIA_URL, datosDia)
      .pipe(map(result => result.datos));
  }
  public existeNombre(datosAgenda: SelectObjModel): Observable<SelectObjModel> {
    return this.http.get<APIReturnModel<SelectObjModel>>(EmpresaApiConfig.AGENDA_EXISTE_NOMBRE_URL,
      this.HttpHelpers.buildQueryParams(datosAgenda))
      .pipe(map(result => result.datos));
  }
  public busquedaSelect(busqueda: number): Observable<SelectObjModel[]> {
    return this.http.get<APIReturnModel<SelectObjModel[]>>(EmpresaApiConfig.AGENDA_BUSQUEDA_SELECT_URL,
      this.HttpHelpers.buildQueryParams(busqueda, 'busqueda'))
      .pipe(map(result => result.datos));
  }
}
