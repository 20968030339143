<mat-card style=" padding: 32px !important;" >
 
  <div fxLayout="row" style="position:absolute; right: 15px; top: 15px;" >
  <button mat-button (click)="guardar()" tabindex="-1" class="boton-titulo">
    <mat-icon >save</mat-icon>
  </button>
     
  <button mat-button (click)="cerrar()" tabindex="-1" class="boton-titulo">
    <img class="rotado" src="\assets\images\icons\nuevo.svg" alt="Cerrar">
  </button>
</div>
<div class="host">

<div class="header">   
<mat-card-title class="title" >{{entidad}}</mat-card-title>
<div class="discription">
  {{discription}}
</div>
</div>
</div>
  <mat-card-content class="scroll">
    <ng-template #modulo></ng-template>
  </mat-card-content>
</mat-card>
