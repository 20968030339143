<app-loader></app-loader>



<div class="page" *ngIf="isLayout">
  <div class="sidenav">
    <app-navbar >
   </app-navbar>
  </div>
  <div class="mainContent">
   <div class="header">
     <app-topbar [isMain]="route.url.startsWith('/principal')?true:false">  
      </app-topbar></div>
      <div class="content">
        <div class="" style="border-radius: 10px;" [ngClass]="route.url.startsWith('/principal')?'main-Pantalla-Principal':'main'" fxFlex="100%">
       <router-outlet></router-outlet>
    </div>
    </div>
  </div>
</div>
<div *ngIf="!isLayout">
  <router-outlet></router-outlet>
</div>

