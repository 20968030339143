<div class="topBar" *ngIf="loginServ.UsuarioLogueado && loginServ.UsuarioLogueado.roles">
    <div class="leftContent" [style.display]="isMain ? 'flex':'none'" >
      <p ><span class="welcomeText">¡Hola,</span><span class="welcomeText" style="color:#5C0F8B"> {{loginServ.UsuarioLogueado?.datosAgenda?.nombre?.split(' ')[0]}}!</span> 👋</p>
      </div>
      <div class="rightContent" [style.width]="isMain ? 'auto':'100%'" [style.justify-content]="isMain ? 'end':'space-between'">
        <app-busqueda-paciente >
        </app-busqueda-paciente>
      
      <div [style.display]="isMain ? 'flex':'none'" style="background-color: #A3A3A3; height: 30px; width: 1px;"></div>
      <div class="settingProfileDiv">
        <div class="settingDiv">
          <button mat-button  class="menu-icon" style="margin: 3px;"  matTooltip="Enviar mensajes pendientes" (click)="enviarMensajesPendientes()">
            <img style="width: 30px;height: 30px; "   src="/assets/images/whatsapp-round.png" >            
          </button> 
          <button mat-button  class="menu-icon" [matMenuTriggerFor]="menuhelp">  
            <img src="/assets/images/qustionIcon.png" >
          </button>
          <mat-menu #menuhelp="matMenu">
            <a mat-menu-item href="https://wa.me/5493814152198" style="display: flex; align-items: center;" target="_blank">
              <img style="width: 20px;height: 20px; margin-right: 4px;" src="assets/images/whatsapp.png" alt="WhatsApp"> Asistencia
             </a>
  
             <a mat-menu-item href="https://docs.google.com/presentation/d/1jmztdGyWYBGpnGLGqAQOJkKYDnRhad3dda5qRuBMXMw/edit?usp=sharing" target="_blank">Manual de uso</a>
            </mat-menu>
          <button mat-button *ngIf="null|puedeConfigEmpresa" (click)="irEmpresa()"  class="menu-icon" >  
            <img src="/assets/images/settingIcon.png" >
          </button>
          </div>
        <div class="profileDiv">
          <button class="menu-icon" mat-button [matMenuTriggerFor]="menu" tabindex="-1" style="border-color: #5C0F8B;">
            <img
              [src]="loginServ.UsuarioLogueado.foto !== null?loginServ.UsuarioLogueado.fotoDatoBase64+ loginServ.UsuarioLogueado.foto: './assets/images/persona_upload.jpg'"
              alt="Perfil" class="perfil-nav" />
          </button>
          <mat-menu #menu="matMenu">
            <!-- <button mat-menu-item *ngIf="null|puedeConfigEmpresa" (click)="irEmpresa()" tabindex="-1">
              Configurar Empresa
            </button> -->
            <button mat-menu-item *ngIf="loginServ.usMultiplesSedes" (click)="cambiarSede()" tabindex="-1">
              Cambiar Sede
            </button>
            <!-- <button mat-menu-item (click)="cambiarTema()" tabindex="-1">
              {{themeServ.SelectedTheme === tiposTemas.LIGHT? 'Tema oscuro': 'Tema claro'}}
            </button> -->
            <button mat-menu-item (click)="irPerfil()" tabindex="-1">Perfil</button>
            <button mat-menu-item (click)="Logout()" tabindex="-1">Cerrar sesión</button>
          </mat-menu>
          <!-- <button mat-button  class="menu-icon"  matTooltip="Menu">  
            <img src="/assets/images/menu_icon.png" >
          </button> -->
        </div>
      </div>
      </div>
  </div>