import { environment } from 'src/environments/environment';

export class HistoriaClinicaApiConfig {
  // HISTORIA CLINICA
  public static HISTORIA_CLINICA_DATOS_URL = environment.apiUrl + 'HistoriaClinica/DatosHC';
  public static HC_PROCESO_NUEVO_URL = environment.apiUrl + 'HistoriaClinica/ProcesoNuevo';
  public static HC_PROCESOS_PACIENTE_URL = environment.apiUrl + 'HistoriaClinica/ProcesosPaciente';
  public static HC_MEDICAMENTO_SEARCH = environment.apiUrl + 'HistoriaClinica/MedicamentoSearch';
  public static HC_MONODROGAS_SEARCH = environment.apiUrl + 'HistoriaClinica/MonodrogasSearch';
  public static HC_DIAGNOSTICOS_CIE10_URL = environment.apiUrl + 'HistoriaClinica/DiagnosticosCie10';
  public static HC_ULTIMA_ALTURA_PROCESO_URL = environment.apiUrl + 'HistoriaClinica/UltimaAlturaProceso';
  // REFERENCIAS
  public static HC_CONSEGUIR_REFERENCIAS_URL = environment.apiUrl + 'HistoriaClinica/ConseguirReferencias';
  public static HC_REFERENCIAS_UPDATE_URL = environment.apiUrl + 'HistoriaClinica/ReferenciasUpdate';
  public static HC_HEADER_REFERENCIAS_UPDATE_URL = environment.apiUrl + 'HistoriaClinica/HeaderReferenciasUpdate';
  // CERTIFICADO
  public static HC_CERTIFICADO_UNO_URL = environment.apiUrl + 'HistoriaClinica/UnoCertificado';
  public static HC_CERTIFICADO_UPDATE_URL = environment.apiUrl + 'HistoriaClinica/NewCertificado';
  public static HC_DESCARGAR_CERTIFICADO_URL = environment.apiUrl + 'HistoriaClinica/DescargarCertificado';
  // TRATAMIENTOS
  public static HC_TRATAMIENTO_UNO_URL = environment.apiUrl + 'HistoriaClinica/UnoTratamiento';
  public static HC_TRATAMIENTO_UPDATE_URL = environment.apiUrl + 'HistoriaClinica/TratamientoNew';
  public static HC_DESCARGAR_TRATAMIENTO_URL = environment.apiUrl + 'HistoriaClinica/DescargarTratamiento';
  public static HC_LISTAR_TRATAMIENTO_URL = environment.apiUrl + 'HistoriaClinica/ListarTratamiento';
  // EVOLUCION
  public static HC_EVOLUCIONES_CARGAR_URL = environment.apiUrl + 'HCEvoluciones/EvolucionesCargar';
  public static HC_EVOLUCION_PROCESO_URL = environment.apiUrl + 'HCEvoluciones/EvolucionProceso';
  public static HC_UNA_EVOLUCION_URL = environment.apiUrl + 'HCEvoluciones/UnaEvolucion';
  public static HC_AGENDA_LOGUEADO_EVOLUCION_URL = environment.apiUrl + 'HCEvoluciones/AgendaLogueadoEvolucion';
  public static HC_ACTUALIZAR_EVOLUCION_URL = environment.apiUrl + 'HCEvoluciones/ActualizarEvolucion';
  public static HC_ELIMINAR_EVOLUCION_URL = environment.apiUrl + 'HCEvoluciones/EliminarEvolucion';
  public static HC_UN_ADJUNTO_EVOLUCION_URL = environment.apiUrl + 'HCEvoluciones/UnAdjuntoEvolucion';
  public static HC_ADJUNTO_UPDATE_EVOLUCION_URL = environment.apiUrl + 'HCEvoluciones/AdjuntoUpdate';
  // ODONTOGRAMA
  public static HC_ODONTOGRAMA_ACTUAL_URL = environment.apiUrl + 'HistoriaClinica/OdontogramaActual';
  public static HC_ODONTOGRAMAS_LIST_URL = environment.apiUrl + 'HistoriaClinica/OdontogramasList';
  public static HC_UN_ODONTOGRAMA_URL = environment.apiUrl + 'HistoriaClinica/UnOdontograma';
  public static HC_DELETE_MOVIMIENTO_ODONTOGRAMA_URL = environment.apiUrl + 'HistoriaClinica/DeleteMovimientoOdontograma';
  public static HC_ACTUALIZAR_ODONTOGRAMA_URL = environment.apiUrl + 'HistoriaClinica/ActualizarOdontograma';
  public static HC_ACTUALIZAR_PRESUPUESTO_URL = environment.apiUrl + 'HistoriaClinica/ActualizarPresupuesto';
  public static HC_PRESUPUESTO_ODONTOGRAMA_URL = environment.apiUrl + 'HistoriaClinica/PresupuestoOdontograma';
  public static HC_GUARDAR_MOV_ANT_ODONTOGRAMA_URL = environment.apiUrl + 'HistoriaClinica/GuardarMovAntOdontograma';
  public static HC_MOV_ODONTO_CON_TURNO_URL = environment.apiUrl + 'HistoriaClinica/MovOdontoConTurno';
  public static HC_MOV_REALIZADOS_URL = environment.apiUrl + 'HistoriaClinica/ObtenerMovimientosRealizados';
  public static HC_MOV_ANTERIORES_URL = environment.apiUrl + 'HistoriaClinica/ObtenerMovimientosAnteriores';
  public static HC_LIST_PRESUPUESTO_URL = environment.apiUrl + 'HistoriaClinica/ObtenerPresupuestos';

  // PRESCRIPCIONES
  public static HC_PRESCRIPCIONES_UNO_URL = environment.apiUrl + 'HCPrescripciones/Uno';
  public static HC_PRESCRIPCIONES_PRINT_URL = environment.apiUrl + 'HCPrescripciones/Print';
  public static HC_PRESCRIPCIONES_LIST_URL = environment.apiUrl + 'HCPrescripciones/Listar';
  public static HC_PRESCRIPCIONES_UPDATE_URL = environment.apiUrl + 'HCPrescripciones/Actualizar';
  public static HC_DESCARGAR_RECETA_URL = environment.apiUrl + 'HCPrescripciones/DescargarReceta';
  public static HC_PRESCRIPCIONES_ENVIAR_PDF_MAIL_URL = environment.apiUrl + 'HistoriaClinica/EnviarEmailConReceta';
  public static HC_PRESCRIPCIONES_ENVIAR_NUEVA_RECETA_URL = environment.apiUrl + 'HistoriaClinica/CopiarNuevaReceta';
  // ESTUDIOS
  public static HC_ESTUDIOS_UNO_URL = environment.apiUrl + 'HCEstudios/Uno';
  public static HC_ESTUDIOS_PRINT_URL = environment.apiUrl + 'HCEstudios/Print';
  public static HC_ESTUDIOS_LIST_URL = environment.apiUrl + 'HCEstudios/Listar';
  public static HC_ESTUDIOS_UPDATE_URL = environment.apiUrl + 'HCEstudios/Actualizar';
  public static HC_ESTUDIOS_PRESTACIONES_SEARCH_URL = environment.apiUrl + 'HCEstudios/PrestacionesSearch';
  public static HC_DESCARGAR_ESTUDIO_URL = environment.apiUrl + 'HCEstudios/DescargarEstudio';
  public static HC_AGREGAR_COMENTARIO_URL = environment.apiUrl + 'HCEstudios/AgregarComentario';
  // IMC
  public static HC_NUEVO_INDICE_IMC_URL = environment.apiUrl + 'HistoriaClinica/ActualizarIMC';
  public static HC_BORRAR_INDICE_IMC_URL = environment.apiUrl + 'HistoriaClinica/BorrarIMC';
  public static HC_LISTAR_INDICE_IMC_URL = environment.apiUrl + 'HistoriaClinica/ConsultaListIMC';
  public static HC_TRAER_POR_FECHA_INDICE_IMC_URL = environment.apiUrl + 'HistoriaClinica/BuscarIMCporFecha';
  //DIETARIO
  public static HC_ACTUALIZAR_DIETARIO_URL = environment.apiUrl + 'HistoriaClinica/ActuHCDiet';
  public static HC_TRAER_DIETA_POR_NOMBRE = environment.apiUrl + 'HistoriaClinica/TraerDietasPorNombre';
  public static HC_COMPROBAR_NOMBRE_DIETA = environment.apiUrl + 'HistoriaClinica/ComprobarNombreDieta';
  public static HC_LISTARDIETARIO_URL = environment.apiUrl + 'HistoriaClinica/ListarHCDietario';
  public static HC_TRAER_UN_REGISTRO_DIETARIO_URL = environment.apiUrl + 'HistoriaClinica/TraerUnRegistroHCDieta';
  public static HC_ENVIAR_EMAIL_DIETA = environment.apiUrl + 'HistoriaClinica/enviarDietaPorMail';
  public static HC_DESCARGAR_DIETA = environment.apiUrl + 'HistoriaClinica/descargarDieta';
  public static HC_HCID_EN_NULL = environment.apiUrl + 'HistoriaClinica/ActuHCDietEnNull';
  public static HC_BORRAR_DETALLE_DIETA = environment.apiUrl + 'HistoriaClinica/borrarRegistroDetalle';

  //GINECO
  public static HC_NUTRICION_ACTUALIZAR_GINECO_URL = environment.apiUrl + 'HistoriaClinica/ActuNutriGineco';
  public static HC_NUTRICION_TRAER_UN_REGISTRO_GINECO_URL = environment.apiUrl + 'HistoriaClinica/TraerUnRegistroGineco';
  public static HC_NUTRICION_TRAER_VARIOS_REGISTRO_GINECO_XPACIENTE_URL = environment.apiUrl + 'HistoriaClinica/TraerVariosRegistGinecoPac';
  public static HC_NUTRICION_TRAER_VARIOS_REGISTRO_GINECO_XUSUARIO_URL = environment.apiUrl + 'HistoriaClinica/TraerVariosRegistGinecoUsu';
  public static HC_NUTRICION_BORRAR_UN_REGISTRO_GINECO_URL = environment.apiUrl + 'HistoriaClinica/BorrarUnRegistroGineco';

  //INDICADORES BIOQUIMICOS
  public static HC_NUTRICION_ACTUALIZAR_INDI_BIO_URL = environment.apiUrl + 'HistoriaClinica/ActuNutriIndBio';
  public static HC_NUTRICION_TRAER_UN_REGISTRO_INDI_BIO_URL = environment.apiUrl + 'HistoriaClinica/TraerUnRegistroIndiBio';
  public static HC_NUTRICION_TRAER_VARIOS_REGISTRO_INDI_BIO_XPACIENTE_URL = environment.apiUrl + 'HistoriaClinica/TraerVariosRegistIndiBioPac';
  public static HC_NUTRICION_TRAER_VARIOS_REGISTRO_INCI_BIO_XUSUARIO_URL = environment.apiUrl + 'HistoriaClinica/TraerVariosRegistIndiBioUsu';
  public static HC_NUTRICION_BORRAR__INDI_BIO_URL = environment.apiUrl + 'HistoriaClinica/BorrarUnRegistroIndiBio';
  //HC ANTROPOMETRIAS
  public static HC_NUTRICION_ACTUALIZAR_ANTROPO_URL = environment.apiUrl + 'HistoriaClinica/ActuNutriAntropo';
  public static HC_NUTRICION_TRAER_UN_REGISTRO_ANTROPO_URL = environment.apiUrl + 'HistoriaClinica/TraerUnRegistroAntropo';
  public static HC_NUTRICION_TRAER_VARIOS_REGISTRO_ANTROPO_XPACIENTE_URL = environment.apiUrl + 'HistoriaClinica/TraerVariosRegistAntropoPac';
  public static HC_NUTRICION_TRAER_VARIOS_REGISTRO_ANTROPO_XUSUARIO_URL = environment.apiUrl + 'HistoriaClinica/TraerVariosRegistAntropo';
  public static HC_NUTRICION_BORRAR_ANTROPO_URL = environment.apiUrl + 'HistoriaClinica/BorrarUnRegistroAntropo';
  //HC Alimentos
  public static HC_ALIMENTOS_ACTUALIZAR = environment.apiUrl + 'HistoriaClinica/ActuAlimento';
  public static HC_ALIMENTOS_TRAERTODOS = environment.apiUrl + 'HistoriaClinica/TraerTodosAlimentos';
  public static HC_ALIMENTOS_TRAERPORFILTRO = environment.apiUrl + 'HistoriaClinica/TraerPorFiltroAlim';
  public static HC_ALIMENTOS_TRAERXID = environment.apiUrl + 'HistoriaClinica/TraerUnAlimentoId';
  public static HC_ALIMENTOS_BORRAR = environment.apiUrl + 'HistoriaClinica/BorrarUnRegisAlim';
  //HC Alimentos Categoria
  public static HC_ALIMENTOS_CATEGORIA_TRAERTODOS = environment.apiUrl + 'HistoriaClinica/TraerTodosAlimentosCat';
  public static HC_ALIMENTOS_CALCULAR_TRAERXID = environment.apiUrl + 'HistoriaClinica/TraerAlimCalcularPorId';
  //HC Archivos adjuntos
  public static HC_LISTAR_ARCHIVOS_ADJUNTOS_URL = environment.apiUrl + 'HistoriaClinica/ListarArchivos';
  public static HC_LISTAR_CERTIFICADOS_URL = environment.apiUrl + 'HistoriaClinica/ListarCertificados';

}
