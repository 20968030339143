import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import { Directive, ElementRef, OnDestroy, HostListener } from '@angular/core';
import * as textMask from 'vanilla-text-mask/dist/vanillaTextMask.js';

@Directive({
  selector: '[appMaskHour]'
})
export class MaskHourDirective implements OnDestroy {
  mask = [/\d/, /\d/, ':', /\d/, /\d/]; // HH:MM
  maskedInputController;

  constructor(private element: ElementRef) {
    const autoCorrectedDatePipe = createAutoCorrectedDatePipe('HH:MM');
    this.maskedInputController = textMask.maskInput({
      inputElement: this.element.nativeElement,
      mask: this.mask,
      keepCharPositions: true,
      showMask: true,
      pipe: autoCorrectedDatePipe
    });
  }

  ngOnDestroy() {
    this.maskedInputController.destroy();
  }

  @HostListener('focusout', ['$event'])
  focusoutEvent(event) {
    const ev: Event = document.createEvent('Event');
    ev.initEvent('input', true, true);
    Object.defineProperty(ev, 'target', { value: this.element.nativeElement, enumerable: true });
    event.target.value = event.target.value.replace(/_/g, 0);
    this.element.nativeElement.dispatchEvent(ev);
  }
}
