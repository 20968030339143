<mat-card class="tarjeta-Modal">
  <mat-card-header fxLayout="row" fxLayoutAlign="space-between start">
    <mat-card-title> Camara </mat-card-title>
    <div fxLayoutAlign="space-between center">
      <button mat-button (click)="dialogRef.close()" class="boton-titulo">
        <img class="rotado" src="\assets\images\icons\nuevo.svg" alt="Cerrar">
      </button>
    </div>
  </mat-card-header>
  <mat-card-content fxLayout="row nowrap">
    <table mat-table fxFlex="20%" [dataSource]="usuarios" class="mat-elevation-z8">
      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef> Nombre Usuario </th>
        <td mat-cell *matCellDef="let item"> {{item.username}} </td>
      </ng-container>
      <ng-container matColumnDef="estado">
        <th mat-header-cell *matHeaderCellDef> Estado </th>
        <td mat-cell *matCellDef="let item"> {{item.inCall?'En llamada':'libre'}} </td>
      </ng-container>
      <ng-container matColumnDef="buttons">
        <th mat-header-cell *matHeaderCellDef class="workWiths"></th>
        <td mat-cell *matCellDef="let item" class="workWiths">
          <button mat-button class="ABM-modificar-small" (click)="llamar(item)" tabindex="-1">LLAMAR</button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="['username','estado','buttons']"></tr>
      <tr mat-row *matRowDef="let row; columns: ['username','estado','buttons'];" class="sombreado-fila"></tr>
    </table>
    <video fxFlex="40%" #localVideo playsinline autoplay></video>
    <video fxFlex="40%" #remoteVideo playsinline autoplay></video>
    <button (click)="cortar()">Cortar</button>

    <div id="errorMsg"></div>
  </mat-card-content>
