import { LoginSedeComponent } from './login-sede.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import {MatSliderModule} from '@angular/material/slider';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [LoginSedeComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatDialogModule,
    MatSliderModule,
    MatIconModule
  ],
  exports: [LoginSedeComponent]
})
export class LoginSedeModule { }
