import {
  CajaAuditarModel,
  CajasListModel,
  CajaNuevaModel,
  TipoCajaEnum,
  CajaVerModel,
  MovimientoNuevoCajaModel,
  CajaControlListModel,
  CajaControlFiltrosModel,
  ResultadoLiquidacionEfectorModel,
  ResultadoLiquidacionOSModel
} from './../../models/caja/caja.model';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { CajaComprobanteListModel, CajaComprobanteFiltrosModel } from 'src/app/models/caja/caja-comprobante.model';
import { APIReturnModel } from 'src/app/models/shared/api-return.model';
import { HttpHelperService } from '../helpers/http-helper.service';
import { CajaApiConfig } from './caja-api-config';
import { ABMGenericoModel, ABMGenericoConsultaModel } from 'src/app/models/shared/abm-generico.model';

@Injectable({
  providedIn: 'root'
})
export class CajaService {
  public usCaja: CajaVerModel = null;
  public cajaAbrirEvent = new EventEmitter();
  public cajaCerrarEvent = new EventEmitter();

  constructor(
    private http: HttpClient,
    private HttpHelpers: HttpHelperService
  ) { }

  public tienePrivilegio(): Observable<TipoCajaEnum> {
    return this.http.get<APIReturnModel<TipoCajaEnum>>(CajaApiConfig.CAJA_TIENE_PRIVILEGIO_URL)
      .pipe(map(result => result.datos));
  }
  public abrir(caja: CajaNuevaModel): Observable<CajaVerModel> {
    return this.http.put<APIReturnModel<CajaVerModel>>(CajaApiConfig.CAJA_ABRIR_URL, caja)
      .pipe(map(result => result.datos));
  }
  public cerrar(cajaId: number): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(CajaApiConfig.CAJA_CERRAR_URL, cajaId)
      .pipe(map(result => result.datos));
  }
  public auditar(caja: CajaAuditarModel): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(CajaApiConfig.CAJA_AUDITAR_URL, caja)
      .pipe(map(result => result.datos));
  }
  public usuarioCajaAbierta(): Observable<CajaVerModel> {
    return this.http.get<APIReturnModel<CajaVerModel>>(CajaApiConfig.CAJA_USUARIO_CAJA_ABIERTA_URL)
      .pipe(map(result => result.datos));
  }
  public listaCajas(datosConsulta: ABMGenericoConsultaModel): Observable<ABMGenericoModel<CajasListModel>> {
    return this.http.get<APIReturnModel<ABMGenericoModel<CajasListModel>>>(CajaApiConfig.CAJA_LISTA_CAJAS_URL,
      this.HttpHelpers.buildQueryParams(datosConsulta))
      .pipe(map(result => result.datos));
  }
  public ver(cajaId: number): Observable<CajaVerModel> {
    return this.http.get<APIReturnModel<CajaVerModel>>(CajaApiConfig.CAJA_VER_URL,
      this.HttpHelpers.buildQueryParams(cajaId, 'cajaId'))
      .pipe(map(result => result.datos));
  }
  public descargarCaja(cajaId: number): Observable<any> {
    return this.http.get<APIReturnModel<any>>(CajaApiConfig.CAJA_DESCARGAR_URL,
      this.HttpHelpers.buildQueryParams(cajaId, 'cajaId'))
      .pipe(map(result => result.datos));
  }
  public nuevoMovimiento(movCaja: MovimientoNuevoCajaModel): Observable<CajaVerModel> {
    return this.http.post<APIReturnModel<CajaVerModel>>(CajaApiConfig.CAJA_NUEVO_MOVIMIENTO_URL, movCaja)
      .pipe(map(result => result.datos));
  }
  public buscarComprobantes(filtros: CajaComprobanteFiltrosModel): Observable<CajaComprobanteListModel[]> {
    return this.http.get<APIReturnModel<CajaComprobanteListModel[]>>(CajaApiConfig.CAJA_BUSCAR_COMPROBANTES_URL,
      this.HttpHelpers.buildQueryParams(filtros))
      .pipe(map(result => result.datos));
  }
  public buscarCajasControl(filtros: CajaControlFiltrosModel): Observable<CajaControlListModel[]> {
    return this.http.get<APIReturnModel<CajaControlListModel[]>>(CajaApiConfig.CAJA_BUSCAR_CAJAS_CONTROL_URL,
      this.HttpHelpers.buildQueryParams(filtros))
      .pipe(map(result => result.datos));
  }

  public descargarLiquidacion(item: ResultadoLiquidacionEfectorModel): Observable<any> {
    return this.http.post<APIReturnModel<any>>(CajaApiConfig.CAJA_DESCARGAR_LIQUIDACION_URL, item)
      .pipe(map(result => result.datos));
  }
  public descargarLiquidacionOS(item: ResultadoLiquidacionOSModel): Observable<any> {
    return this.http.post<APIReturnModel<any>>(CajaApiConfig.CAJA_DESCARGAR_LIQUIDACION_OS_URL, item)
      .pipe(map(result => result.datos));
  }
}
