import { MatTooltipModule } from '@angular/material/tooltip';
import { PrivilegiosPipeModule } from './../../../pipes/privilegios.pipes';
import { PacienteUpdateModule } from './../../paciente/paciente-abm/paciente-update/paciente-update.module';
import { ModalUpdateGenericoModule } from './../../shared/modal-update-generico/modal-update-generico.module';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { BusquedaAutocompleteModule } from './../../shared/busqueda-autocomplete/busqueda-autocomplete.module';
import { MatButtonModule } from '@angular/material/button';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BusquedaPacienteComponent } from './busqueda-paciente.component';


@NgModule({
  declarations: [BusquedaPacienteComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    BusquedaAutocompleteModule,
    MatIconModule,
    MatTooltipModule,
    RouterModule,
    MatDialogModule,
    ModalUpdateGenericoModule,
    PacienteUpdateModule,
    PrivilegiosPipeModule
  ],
  exports: [BusquedaPacienteComponent]
})
export class BusquedaPacienteModule { }
