import { PacienteAgendaModel } from '../../models/turnos/paciente-agenda.model';
import { CancelarDiaModel } from 'src/app/models/turnos/cancelar-dia.model';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ItemAcordeonModel, SelectObjModel } from '../../models/shared/selects.model';
import { APIReturnModel } from 'src/app/models/shared/api-return.model';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpHelperService } from '../helpers/http-helper.service';
import { TurnoConsultaModel } from 'src/app/models/turnos/turno-consulta.model';
import { PacientesTurnoModel } from 'src/app/models/turnos/pacientes-turno.model';
import { ElegirAgendaModel } from 'src/app/models/turnos/elegir-agenda.model';
import { datosVerificarModel, TurnoUpdateModel } from 'src/app/models/turnos/turno-update.model';
import {
  CalendarioModel,
  AgendaDiaModel,
  TurnoRowModel,
  AgendaAsignarModel,
  TurnoConfirmarModel,
  AgendaRecordatorioDemoraModel,
  ConsultaRecordatorioDemoraModel,
  TurnosAgendaSemanalModel,
  AgendaSemanalModel,
  AgendaRecordatorioMedicoModel,
  ConsultaRecordatorioMedicoModel
} from 'src/app/models/turnos/agenda-turnos.model';
import { TurnosApiConfig } from './turnos-api-config';
import { CambiosModalModel } from 'src/app/models/turnos/cambiar-horario-dia.model';
import { MensajeResponse } from 'src/app/models/turnos/MensajeResponse';

@Injectable({
  providedIn: 'root'
})

export class TurnosService {
  // CANT. AGENDAS BUSQUEDA MAX. EN TURNOS
  public CANTIDAD_AGENDAS_MAX = 10;
  // tslint:disable-next-line: variable-name
  private _pacienteElegido: PacienteAgendaModel = null;
  public get pacienteElegido(): PacienteAgendaModel {
    return this._pacienteElegido;
  }

  public set pacienteElegido(v: PacienteAgendaModel) {
    this._pacienteElegido = v;
    this.pacienteUpdatedEvent.emit(v ? new SelectObjModel(v.id, v.documento + ' - ' + v.nombre, false) : null);
  }
  public actualizarFechaEmitter = new EventEmitter<Date>();
  public turnoRowUpdatedEvent = new EventEmitter<number>();
  public VerificarCanceladosEvent = new EventEmitter();
  public pacienteUpdatedEvent = new EventEmitter<SelectObjModel>();
  public elegirAgendaEvent = new EventEmitter();
  public actualizarCalendarioEvent = new EventEmitter<CalendarioModel>();
  public fechaAgenda = new Date();
  public agendaElegida: ElegirAgendaModel = null;
  public ultimoTickTimer = new Date();

  constructor(
    private http: HttpClient,
    private HttpHelpers: HttpHelperService
  ) { }

  public turnosCancelados(agendaId: number, fecha: Date): Observable<TurnoRowModel[]> {
    return this.http.get<APIReturnModel<TurnoRowModel[]>>(TurnosApiConfig.TURNOS_CANCELADOS_URL,
      this.HttpHelpers.buildQueryParams({ agendaId, fecha }))
      .pipe(map(result => result.datos));
  }
  public estadoMes(agendaId: number, fecha: Date): Observable<CalendarioModel> {
    return this.http.get<APIReturnModel<CalendarioModel>>(TurnosApiConfig.TURNOS_ESTADO_MES_URL,
      this.HttpHelpers.buildQueryParams({ agendaId, fecha }))
      .pipe(map(result => result.datos));
  }
  public diaFeriado(agendaId: number, fecha: Date): Observable<CalendarioModel> {
    return this.http.get<APIReturnModel<CalendarioModel>>(TurnosApiConfig.TURNOS_DIA_FERIADO_URL,
      this.HttpHelpers.buildQueryParams({ agendaId, fecha }))
      .pipe(map(result => result.datos));
  }
  public actualizar(agenda: TurnoUpdateModel): Observable<TurnoUpdateModel> {
    return this.http.put<APIReturnModel<TurnoUpdateModel>>(TurnosApiConfig.TURNOS_UPDATE_URL, agenda)
      .pipe(map(result => result.datos));
  }
  public cancelarTurno(turnoId: number): Observable<boolean> {
    return this.http.delete<APIReturnModel<boolean>>(TurnosApiConfig.TURNOS_CANCELAR_TURNO_URL,
      this.HttpHelpers.buildQueryParams(turnoId, 'turnoId'))
      .pipe(map(result => result.datos));
  }
  public cancelarTurnoByLink(turnoLinkId: string): Observable<boolean> {
    return this.http.delete<APIReturnModel<boolean>>(TurnosApiConfig.TURNOS_CANCELAR_TURNO_LINK_URL,
      this.HttpHelpers.buildQueryParams(turnoLinkId, 'turnoLinkId'))
      .pipe(map(result => result.datos));
  }
  public turnosTick(datosConsulta: TurnoConsultaModel): Observable<AgendaDiaModel> {
    return this.http.get<APIReturnModel<AgendaDiaModel>>(TurnosApiConfig.TURNOS_TICK_URL, this.HttpHelpers.buildQueryParams(datosConsulta))
      .pipe(map(result => result.datos));
  }
  public turnosFecha(datosConsulta: TurnoConsultaModel): Observable<AgendaDiaModel> {
    return this.http.get<APIReturnModel<AgendaDiaModel>>(TurnosApiConfig.TURNOS_FECHA_URL, this.HttpHelpers.buildQueryParams(datosConsulta))
      .pipe(map(result => result.datos));
  }
  public unoUpdate(turnoId: number): Observable<TurnoUpdateModel> {
    return this.http.get<APIReturnModel<TurnoUpdateModel>>(TurnosApiConfig.TURNOS_UNO_UPDATE_URL,
      this.HttpHelpers.buildQueryParams(turnoId, 'turnoId'))
      .pipe(map(result => result.datos));
  }
  public unoRow(turnoId: number): Observable<TurnoRowModel> {
    return this.http.get<APIReturnModel<TurnoRowModel>>(TurnosApiConfig.TURNOS_UNO_ROW_URL,
      this.HttpHelpers.buildQueryParams(turnoId, 'turnoId'))
      .pipe(map(result => result.datos));
  }
  public pacientesTurnos(datosConsulta: TurnoConsultaModel): Observable<PacientesTurnoModel[]> {
    return this.http.get<APIReturnModel<PacientesTurnoModel[]>>(TurnosApiConfig.TURNOS_PACIENTES_TURNOS_URL,
      this.HttpHelpers.buildQueryParams(datosConsulta))
      .pipe(map(result => result.datos));
  }
  public cantidadAgendas(): Observable<number> {
    return this.http.get<APIReturnModel<number>>(TurnosApiConfig.TURNOS_CANTIDAD_AGENDAS_URL)
      .pipe(map(result => result.datos));
  }
  public busquedaMenor(): Observable<ElegirAgendaModel[]> {
    return this.http.get<APIReturnModel<ElegirAgendaModel[]>>(TurnosApiConfig.TURNOS_BUSQUEDA_AGENDA_MENOR_URL)
      .pipe(map(result => result.datos));
  }
  public busquedaMayor(): Observable<ItemAcordeonModel[]> {
    return this.http.get<APIReturnModel<ItemAcordeonModel[]>>(TurnosApiConfig.TURNOS_BUSQUEDA_AGENDA_MAYOR_URL)
      .pipe(map(result => result.datos));
  }
  public agendaBusqueda(agendaId: number): Observable<ElegirAgendaModel> {
    return this.http.get<APIReturnModel<ElegirAgendaModel>>(TurnosApiConfig.TURNOS_AGENDA_BUSQUEDA_URL,
      this.HttpHelpers.buildQueryParams(agendaId, 'agendaId'))
      .pipe(map(result => result.datos));
  }
  public pacienteBusqueda(pacienteUsId: number): Observable<PacienteAgendaModel> {
    return this.http.get<APIReturnModel<PacienteAgendaModel>>(TurnosApiConfig.TURNOS_PACIENTE_BUSQUEDA_URL,
      this.HttpHelpers.buildQueryParams(pacienteUsId, 'pacienteUsId'))
      .pipe(map(result => result.datos));
  }
  public asignarTurno(datosAsignar: AgendaAsignarModel): Observable<TurnoRowModel> {
    return this.http.post<APIReturnModel<TurnoRowModel>>(TurnosApiConfig.TURNOS_ASIGNAR_TURNO_URL, datosAsignar)
      .pipe(map(result => result.datos));
  }
   public enviarMensajesPendientes(): Observable<MensajeResponse> {
    return this.http.get<APIReturnModel<MensajeResponse>>(TurnosApiConfig.TURNOS_ENVIAR_MENSAJES_PENDIENTES_URL)
      .pipe(map(result => result.datos));
  }
  public enviarConfirmaciones(turnoId: number): Observable<boolean> {
    return this.http.get<APIReturnModel<boolean>>(TurnosApiConfig.TURNOS_ENVIAR_CONFIRMACIONES_URL,
      this.HttpHelpers.buildQueryParams(turnoId, 'turnoId'))
      .pipe(map(result => result.datos));
  }
  public cancelarDia(datosCancelar: CancelarDiaModel): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(TurnosApiConfig.TURNOS_CANCELAR_DIA_URL, datosCancelar)
      .pipe(map(result => result.datos));
  }
  public cambiarConfirmacionTurno(datosConfirmado: TurnoConfirmarModel): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(TurnosApiConfig.TURNOS_CAMBIAR_CONFIRMACION_TURNO_URL, datosConfirmado)
      .pipe(map(result => result.datos));
  }
  public horariosActualesCambio(datosConsulta: TurnoConsultaModel): Observable<CambiosModalModel> {
    return this.http.get<APIReturnModel<CambiosModalModel>>(TurnosApiConfig.TURNOS_HORARIOS_ACTUALES_CAMBIO_URL,
      this.HttpHelpers.buildQueryParams(datosConsulta))
      .pipe(map(result => result.datos));
  }
  public cambiarHorarios(cambios: CambiosModalModel): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(TurnosApiConfig.TURNOS_CAMBIAR_HORARIOS_URL, cambios)
      .pipe(map(result => result.datos));
  }
  public enviarEmailZoom(turnoId: number): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(TurnosApiConfig.TURNOS_ENVIAR_EMIAL_ZOOM_URL, turnoId)
      .pipe(map(result => result.datos));
  }
  public siguienteEstado(turnoId: number): Observable<TurnoRowModel> {
    return this.http.post<APIReturnModel<TurnoRowModel>>(TurnosApiConfig.TURNOS_SIGUIENTE_ESTADO_URL, turnoId)
      .pipe(map(result => result.datos));
  }
  public haySegPorHora(datosConsulta: TurnoConsultaModel): Observable<boolean> {
    return this.http.get<APIReturnModel<boolean>>(TurnosApiConfig.TURNOS_HAY_SEG_POR_HORA_URL,
      this.HttpHelpers.buildQueryParams(datosConsulta))
      .pipe(map(result => result.datos));
  }
  public actualizarRecordatorioDemora(recordatorioDemora: AgendaRecordatorioDemoraModel): Observable<AgendaRecordatorioDemoraModel> {
    return this.http.post<APIReturnModel<AgendaRecordatorioDemoraModel>>(TurnosApiConfig.TURNOS_ACTUALIZAR_RECORDATORIO_DEMORA_URL, recordatorioDemora)
      .pipe(map(result => result.datos));
  }
  public actualizarRecordatorioMedico(recordatorioMedico: AgendaRecordatorioMedicoModel): Observable<AgendaRecordatorioMedicoModel> {
    return this.http.post<APIReturnModel<AgendaRecordatorioMedicoModel>>(TurnosApiConfig.TURNOS_ACTUALIZAR_RECORDATORIO_MEDICO_URL, recordatorioMedico)
      .pipe(map(result => result.datos));
  }
  public consultaRecordarioDemora(datosConsulta: ConsultaRecordatorioDemoraModel): Observable<AgendaRecordatorioDemoraModel> {
    return this.http.get<APIReturnModel<AgendaRecordatorioDemoraModel>>(TurnosApiConfig.TURNOS_CONSULTA_RECORDATORIO_DEMORA_URL,
      this.HttpHelpers.buildQueryParams(datosConsulta))
      .pipe(map(result => result.datos));
  }
  public consultaRecordarioMedico(datosConsulta: ConsultaRecordatorioMedicoModel): Observable<AgendaRecordatorioMedicoModel> {
    return this.http.get<APIReturnModel<AgendaRecordatorioMedicoModel>>(TurnosApiConfig.TURNOS_CONSULTA_RECORDATORIO_MEDICO_URL,
      this.HttpHelpers.buildQueryParams(datosConsulta))
      .pipe(map(result => result.datos));
  }
  public turnosSemanales(datosConsulta: TurnosAgendaSemanalModel): Observable<AgendaSemanalModel> {
    return this.http.get<APIReturnModel<AgendaSemanalModel>>(TurnosApiConfig.TURNOS_AGENDA_TURNOS_SEMANAL_URL,
      this.HttpHelpers.buildQueryParams(datosConsulta))
      .pipe(map(result => result.datos));
  }

  public segmentoDesactivar(segmentoId: number): Observable<boolean> {
    return this.http.get<boolean>(TurnosApiConfig.TURNOS_SEGMENTO_DESACTIVAR_URL,
      this.HttpHelpers.buildQueryParams(segmentoId, 'segmentoId'))
      .pipe(map(result => result));
  }

  public puedeDuracionExtender(turnoId: number): Observable<boolean> {
    return this.http.get<APIReturnModel<boolean>>(TurnosApiConfig.TURNOS_PUEDE_DURACION_EXTENDER_URL,
      this.HttpHelpers.buildQueryParams(turnoId, 'turnoId'))
      .pipe(map(result => result.datos));
  }

  public verificarSobreTurno(datos: datosVerificarModel): Observable<boolean> {
    return this.http.get<boolean>(TurnosApiConfig.TURNOS_VERIFICAR_SOBRETURNO_URL,
      this.HttpHelpers.buildQueryParams(datos))
      .pipe(map(result => result));
  }
}
