import { TipoParentescoEnum } from './../../../../models/shared/enums.model';
import { DireccionesModel } from 'src/app/models/shared/direcciones.model';
import {
  ABMPacienteListModel,
  ABMPacienteUpdateModel,
  ABMPacienteUpdateUsuarioPlanModel
} from '../../../../models/paciente/abm-paciente.model';
import { AppConfig } from '../../../../config/config';
import { Component, OnInit, EventEmitter, Input, Output, HostBinding, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { SelectsModel } from 'src/app/models/shared/selects.model';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { SelectsService } from 'src/app/services/shared/selects.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ModalTelefonoComponent } from 'src/app/components/shared/modal-telefono/modal-telefono.component';
import { ModalDireccionComponent } from 'src/app/components/shared/modal-direccion/modal-direccion.component';
import { PersonaValidarDocModel } from 'src/app/models/shared/persona-validar.model';
import { ModalPacienteExisteDniComponent } from 'src/app/components/paciente/modal-paciente-existe-dni/modal-paciente-existe-dni.component';
import { TipoAccionABMEnum } from 'src/app/models/shared/enums.model';
import { TelefonoModel } from 'src/app/models/shared/telefono.model';
import { PacienteService } from 'src/app/services/paciente/paciente.service';
import { LoginService } from 'src/app/services/login/login.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModalPacientePlanesUpdateComponent } from '../modal-paciente-planes-update/modal-paciente-planes-update.component';
import { PrimeNGConfig } from 'primeng/api';
import { PacienteVerificarMailConsultaModel } from 'src/app/models/paciente/paciente-filtro-model';

@Component({
  selector: 'app-paciente-update',
  templateUrl: './paciente-update.component.html',
  styleUrls: ['./paciente-update.component.scss']
})
export class PacienteUpdateComponent implements OnInit, AfterViewInit {
  @HostBinding('class') classes = 'flex-center';
  public esModal = false;
  // @Input() objetoUpdate = new ABMPacienteUpdateModel();
  @Output() updateEmitter = new EventEmitter<ABMPacienteListModel>();
  buscarPacientesDoc = true;
  mailAVerificar: string;


  datosSelect = new SelectsModel();
  displayTelefono: string[] = ['tipo', 'numero', 'buttons'];
  telPaciente = new MatTableDataSource<TelefonoModel>();
  displayDirecciones: string[] = ['calle', 'numero', 'ciudad', 'buttons'];
  dirPaciente = new MatTableDataSource<DireccionesModel>();
  displayPlanes: string[] = ['os', 'nombre', 'asoc', 'titular', 'otraInfo', 'buttons'];
  planPaciente = new MatTableDataSource<ABMPacienteUpdateUsuarioPlanModel>();
  parentesco: TipoParentescoEnum;

  personaForm: UntypedFormGroup;
  selectedFileStr: string = null;
  selectedFileTipoStr: string = null;

  @ViewChild('foco') campoFoco: ElementRef;
  @ViewChild('imageInput') imagen: ElementRef;

  constructor(
    private snackBar: MatSnackBar,
    public pacienteServ: PacienteService,
    private selServ: SelectsService,
    private loginServ: LoginService,
    private modalpage: MatDialog,

  ) { }

  ngAfterViewInit(): void {
    setTimeout(_ => this.campoFoco.nativeElement.focus());
  }
  ngOnInit() {
    this.pacienteServ.pacienteUpdateEv.subscribe(() => {
      if (this.pacienteServ.PacienteUpdate) {
        this.actualizarPaciente();

      }
    });
    this.selServ.selects().then((x: SelectsModel) => {
      this.datosSelect = x;
    });
    this.personaForm = new UntypedFormGroup({
      nombre: new UntypedFormControl(
        this.pacienteServ.PacienteUpdate.persona.nombre,
        [
          Validators.required,
          Validators.pattern(AppConfig.PATTERN_LETTERS),
          Validators.maxLength(50),
        ]
      ),
      apellido: new UntypedFormControl(
        this.pacienteServ.PacienteUpdate.persona.apellido,
        [
          Validators.required,
          Validators.pattern(AppConfig.PATTERN_LETTERS),
          Validators.maxLength(50),
        ]
      ),
      apellidoMatrimonial: new UntypedFormControl(
        this.pacienteServ.PacienteUpdate.persona.apellidoMatrimonial,
        [
          Validators.pattern(AppConfig.PATTERN_LETTERS),
          Validators.maxLength(50),
        ]
      ),
      email: new UntypedFormControl(
        this.pacienteServ.PacienteUpdate.persona.email,
        [Validators.email]
      ),
      isWhatsApp: new UntypedFormControl(
        this.pacienteServ.PacienteUpdate.persona.enviarWhatsApp,
      ),
      isEmail: new UntypedFormControl(
        this.pacienteServ.PacienteUpdate.persona.enviarEmail,
      ),
      tipoDoc: new UntypedFormControl(
        {
          value: this.pacienteServ.PacienteUpdate.persona.tipoDocumento,
          disabled: this.pacienteServ.PacienteUpdate.id,
        },
        { validators: [Validators.required], updateOn: "blur" }
      ),
      nroDoc: new UntypedFormControl(
        {
          value: this.pacienteServ.PacienteUpdate.persona.numeroDocumento,
          disabled: this.pacienteServ.PacienteUpdate.id,
        },
        {
          validators: [
            Validators.required,
            Validators.pattern(AppConfig.PATTERN_NUMBERS),
            Validators.maxLength(15),
          ],
          updateOn: "blur",
        }
      ),
      sexo: new UntypedFormControl(
        this.pacienteServ.PacienteUpdate.persona.sexoBiologico,
        [Validators.required]
      ),
      genero: new UntypedFormControl(
        this.pacienteServ.PacienteUpdate.persona.genero
          ? this.pacienteServ.PacienteUpdate.persona.genero
          : 0,
        [Validators.required]
      ),
      fechaNac: new UntypedFormControl(
        this.pacienteServ.PacienteUpdate.persona.fechaNacimiento !== undefined
          ? this.pacienteServ.PacienteUpdate.persona.fechaNacimiento
          : new Date(),
        [Validators.required, this.fechaNacValidator.bind(this)]
      ),
      fallecido: new UntypedFormControl(
        this.pacienteServ.PacienteUpdate.persona.fallecido
      ),
    });

    if (this.pacienteServ.PacienteUpdate.persona.foto && this.pacienteServ.PacienteUpdate.persona.fotoDatoBase64) {
      this.updateImagen(this.pacienteServ.PacienteUpdate.persona.foto, this.pacienteServ.PacienteUpdate.persona.fotoDatoBase64);
    }
    this.updateDSDir();
    this.updateDSPlan();
    this.updateDSTel();
    this.personaForm.get('tipoDoc').valueChanges.subscribe(tipo => {
      if (this.buscarPacientesDoc && tipo && this.personaForm.get('nroDoc').value) {
        this.pacienteServ.nroDocExiste(new PersonaValidarDocModel(
          this.pacienteServ.PacienteUpdate.id, parseInt(this.personaForm.get('nroDoc').value, 10), tipo
        )).toPromise().then(result => {
          if (result && result.length > 0) {
            this.elegirPersonaExiste(result);
          }
        });
      }
    });
    this.personaForm.get('nroDoc').valueChanges.subscribe(nro => {
      if (this.buscarPacientesDoc && nro && this.personaForm.get('tipoDoc').value) {
        this.pacienteServ.nroDocExiste(new PersonaValidarDocModel(
          this.pacienteServ.PacienteUpdate.id, parseInt(nro, 10), this.personaForm.get('tipoDoc').value
        )).toPromise().then(result => {
          if (result && result.length > 0) {
            this.elegirPersonaExiste(result);
          }
        });
      }
    });
  }
  limpiarPersona() {
    this.pacienteServ.PacienteUpdate = new ABMPacienteUpdateModel();
    this.actualizarPaciente();
  }
  habilitarDNI() {
    if (this.personaForm.get('tipoDoc').value === 99) {
      this.personaForm.get('tipoDoc').enable();
    }
    this.personaForm.get('nroDoc').enable();
  }
  elegirPersonaExiste(pacientesResult: ABMPacienteListModel[]) {
    const modal = this.modalpage.open(ModalPacienteExisteDniComponent, {
      panelClass: 'custom-dialog-container-auto',
      data:
      {
        pacientes: pacientesResult
      }
    });
    modal.afterClosed().subscribe(x => {
      if (x) {
        this.pacienteServ.PacienteUpdate = x;
        this.actualizarPaciente();
      }
    });
  }
  private actualizarPaciente() {
    this.buscarPacientesDoc = false;
    this.updateImagen(this.pacienteServ.PacienteUpdate.persona.foto, this.pacienteServ.PacienteUpdate.persona.fotoDatoBase64);
    this.updateDSDir();
    this.updateDSPlan();
    this.updateDSTel();
    this.personaForm.get('nombre').setValue(this.pacienteServ.PacienteUpdate.persona.nombre);
    this.personaForm.get('apellido').setValue(this.pacienteServ.PacienteUpdate.persona.apellido);
    this.personaForm.get('apellidoMatrimonial').setValue(this.pacienteServ.PacienteUpdate.persona.apellidoMatrimonial);
    this.personaForm.get('email').setValue(this.pacienteServ.PacienteUpdate.persona.email);
    this.personaForm.get("isWhatsApp").setValue(this.pacienteServ.PacienteUpdate.persona.enviarWhatsApp);
    this.personaForm.get("isEmail").setValue(this.pacienteServ.PacienteUpdate.persona.enviarEmail);
    this.personaForm.get('tipoDoc').setValue(this.pacienteServ.PacienteUpdate.persona.tipoDocumento);
    this.personaForm.get('nroDoc').setValue(this.pacienteServ.PacienteUpdate.persona.numeroDocumento);
    this.personaForm.get('sexo').setValue(this.pacienteServ.PacienteUpdate.persona.sexoBiologico);
    this.personaForm.get('genero').setValue(this.pacienteServ.PacienteUpdate.persona.genero);
    this.personaForm.get('fechaNac').setValue(this.pacienteServ.PacienteUpdate.persona.fechaNacimiento);
    this.personaForm.get('fallecido').setValue(this.pacienteServ.PacienteUpdate.persona.fallecido);
    if (this.pacienteServ.PacienteUpdate.id) {
      this.personaForm.get('tipoDoc').disable();
      this.personaForm.get('nroDoc').disable();
    } else {
      this.personaForm.get('tipoDoc').enable();
      this.personaForm.get('nroDoc').enable();
    }
    this.buscarPacientesDoc = true;
  }
  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {
      const base64result = event.target.result.split(',');
      this.updateImagen(base64result[1], base64result[0] + ',');
    });

    reader.readAsDataURL(file);
  }
  quitarImagen() {
    this.imagen.nativeElement.value = null;
    this.updateImagen(null, null);
  }
  private updateImagen(imagen: string, tipo: string) {
    this.selectedFileStr = imagen;
    this.selectedFileTipoStr = tipo;
  }
  public fechaNacValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control && control.value !== '') {
      // let fecha: Date;
      // if (moment.isMoment(control.value)) {
      //   fecha = control.value.toDate();
      // } else {
      //   fecha = control.value;
      // }
      // if (moment.isDate(fecha)) {
      if (control.value > new Date()) {
        return { nacimientoMayorHoy: true };
      } else {
        return null;
      }
      // } else {
      //   return { required: true };
      // }
    }
    return null;
  }
  public guardar() {
    if (!this.loginServ.checkearPrivilegio(this.loginServ.privilegios.CARGAR_EDITAR_PACIENTES)) {
      return;
    }
    if (!this.personaForm.valid) {
      Object.keys(this.personaForm.controls).forEach(key => {
        this.personaForm.get(key).markAsTouched();
      });
      this.snackBar.open('Por favor verifique los datos ingresados', 'OK');
      return;
    }
    this.pacienteServ.PacienteUpdate.persona.email = this.personaForm.get('email').value;
    this.pacienteServ.PacienteUpdate.persona.enviarWhatsApp = this.personaForm.get("isWhatsApp").value;
    this.pacienteServ.PacienteUpdate.persona.enviarEmail = this.personaForm.get("isEmail").value;
    this.pacienteServ.PacienteUpdate.persona.apellido = this.personaForm.get('apellido').value;
    this.pacienteServ.PacienteUpdate.persona.apellidoMatrimonial = this.personaForm.get('apellidoMatrimonial').value;
    this.pacienteServ.PacienteUpdate.persona.fallecido = this.personaForm.get('fallecido').value || false;
    this.pacienteServ.PacienteUpdate.persona.fechaNacimiento = this.personaForm.get('fechaNac').value;
    this.pacienteServ.PacienteUpdate.persona.foto = this.selectedFileStr;
    this.pacienteServ.PacienteUpdate.persona.fotoDatoBase64 = this.selectedFileTipoStr;
    this.pacienteServ.PacienteUpdate.persona.genero = this.personaForm.get('genero').value;
    this.pacienteServ.PacienteUpdate.persona.generoStr = this.datosSelect.generos
      .find(x => x.id === this.personaForm.get('genero').value).nombre;
    this.pacienteServ.PacienteUpdate.persona.nombre = this.personaForm.get('nombre').value;
    this.pacienteServ.PacienteUpdate.persona.numeroDocumento = this.personaForm.get('nroDoc').value;
    this.pacienteServ.PacienteUpdate.persona.sexoBiologico = this.personaForm.get('sexo').value;
    this.pacienteServ.PacienteUpdate.persona.sexoBiologicoStr =
      this.datosSelect.sexoBio.find(x => x.id === this.personaForm.get('sexo').value).nombre;
    this.pacienteServ.PacienteUpdate.persona.tipoDocumento = this.personaForm.get('tipoDoc').value;
    this.pacienteServ.PacienteUpdate.persona.tipoDocumentoStr =
      this.datosSelect.tiposDoc.find(x => x.id === this.personaForm.get('tipoDoc').value).nombre;
    this.pacienteServ.actualizarPaciente(this.pacienteServ.PacienteUpdate).subscribe((paciente: ABMPacienteListModel) => {
      if (paciente.id === this.loginServ.UsuarioLogueado.id) {
        this.loginServ.UsuarioLogueado.fotoDatoBase64 = this.selectedFileTipoStr;
        this.loginServ.UsuarioLogueado.foto = this.selectedFileStr;
      }
      if (this.pacienteServ.PacienteUpdate.id > 0) {
        this.snackBar.open('Se guardo exitosamente');
      }
      this.updateEmitter.emit(paciente);
    });
  }
  updateDSDir() {
    this.dirPaciente = new MatTableDataSource<DireccionesModel>(this.pacienteServ.PacienteUpdate.persona.direcciones);
  }
  public updateDir(item: DireccionesModel) {
    const dialog = this.modalpage.open(ModalDireccionComponent, {
      panelClass: 'custom-dialog-container-auto',
      data: {
        dirUpdate: item === null ? new DireccionesModel() : item,
        datosSelect: this.datosSelect
      }
    });
    dialog.afterClosed().toPromise().then(result => {
      if (result) {
        if (this.pacienteServ.PacienteUpdate.id > 0) {
          const DirPaciente = DireccionesModel.toABMPacienteDireccionModel(result, this.pacienteServ.PacienteUpdate.persona.id);
          this.pacienteServ.actualizarDireccion(DirPaciente).toPromise().then(x => {
            this.guardarDireccion(x, item);
            this.updateDSDir();
          });
        } else {
          this.guardarDireccion(result, item);
          this.updateDSDir();
        }
      }
    });
  }
  public guardarDireccion(item: DireccionesModel, original: DireccionesModel) {
    if (this.pacienteServ.PacienteUpdate.persona.direcciones.findIndex(x => x === original) > -1) {
      const indexDir = this.pacienteServ.PacienteUpdate.persona.direcciones.findIndex(x => x === original);
      this.pacienteServ.PacienteUpdate.persona.direcciones[indexDir].calle = item.calle;
      this.pacienteServ.PacienteUpdate.persona.direcciones[indexDir].codigoPostal = item.codigoPostal;
      this.pacienteServ.PacienteUpdate.persona.direcciones[indexDir].depto = item.depto;
      this.pacienteServ.PacienteUpdate.persona.direcciones[indexDir].numero = item.numero;
      this.pacienteServ.PacienteUpdate.persona.direcciones[indexDir].piso = item.piso;
      this.pacienteServ.PacienteUpdate.persona.direcciones[indexDir].provincia = item.provincia;
      this.pacienteServ.PacienteUpdate.persona.direcciones[indexDir].provinciaStr = item.provinciaStr;
      this.pacienteServ.PacienteUpdate.persona.direcciones[indexDir].tipo = item.tipo;
      this.pacienteServ.PacienteUpdate.persona.direcciones[indexDir].tipoStr = item.tipoStr;
    } else {
      this.pacienteServ.PacienteUpdate.persona.direcciones.push(item);
    }
  }
  public desactivarDir(itemBorrar: DireccionesModel) {
    this.pacienteServ.desactivarDireccion(itemBorrar.id).toPromise().then((ok: boolean) => {
      if (ok) {
        itemBorrar.tipoAccion = TipoAccionABMEnum.ACTIVAR;
        this.updateDSDir();
      }
    });
  }
  public activarDir(itemBorrar: DireccionesModel) {
    this.pacienteServ.activarDireccion(itemBorrar.id).toPromise().then((ok: boolean) => {
      if (ok) {
        itemBorrar.tipoAccion = TipoAccionABMEnum.DESACTIVAR;
        this.updateDSDir();
      }
    });
  }
  public eliminarDir(itemBorrar: DireccionesModel) {
    if (this.pacienteServ.PacienteUpdate.persona.direcciones.findIndex(x => x === itemBorrar) > -1) {
      if (itemBorrar.id > 0) {
        this.pacienteServ.eliminarDireccion(itemBorrar.id).toPromise().then(x => {
          if (x !== null) {
            this.borrarDirTabla(itemBorrar);
          }
        });
      } else {
        this.borrarDirTabla(itemBorrar);
      }
    }
  }
  borrarDirTabla(itemBorrar: DireccionesModel) {
    this.pacienteServ.PacienteUpdate.persona.direcciones.splice(
      this.pacienteServ.PacienteUpdate.persona.direcciones.findIndex(x => x === itemBorrar), 1);
    this.updateDSDir();
  }
  updateDSTel() {
    this.telPaciente = new MatTableDataSource<TelefonoModel>(this.pacienteServ.PacienteUpdate.persona.telefonos);
  }
  public updateTel(item: TelefonoModel) {
    const dialog = this.modalpage.open(ModalTelefonoComponent, {
      panelClass: 'custom-dialog-container-auto',
      data: {
        telUpdate: item === null ? new TelefonoModel() : item,
        datosSelect: this.datosSelect
      }
    });
    dialog.afterClosed().toPromise().then((result: TelefonoModel) => {
      if (result) {
        if (this.pacienteServ.PacienteUpdate.id > 0) {
          const TelPaciente = TelefonoModel.toABMPacienteTelefonoModel(result, this.pacienteServ.PacienteUpdate.persona.id);
          this.pacienteServ.actualizarTelefono(TelPaciente).toPromise().then(x => {
            this.guardarTelefono(x, item);
            this.updateDSTel();
          });
        } else {
          this.guardarTelefono(result, item);
          this.updateDSTel();
        }
      }
    });
  }
  public guardarTelefono(item: TelefonoModel, original: TelefonoModel) {
    if (this.pacienteServ.PacienteUpdate.persona.telefonos.findIndex(x => x === original) > -1) {
      const telIndex = this.pacienteServ.PacienteUpdate.persona.telefonos.findIndex(x => x === original);
      this.pacienteServ.PacienteUpdate.persona.telefonos[telIndex].numero = item.numero;
      this.pacienteServ.PacienteUpdate.persona.telefonos[telIndex].tipo = item.tipo;
      this.pacienteServ.PacienteUpdate.persona.telefonos[telIndex].tipoStr = item.tipoStr;
      this.pacienteServ.PacienteUpdate.persona.telefonos[telIndex].tipoAccion = item.tipoAccion;
    } else {
      this.pacienteServ.PacienteUpdate.persona.telefonos.push(item);
    }
  }
  public desactivarTel(itemBorrar: TelefonoModel) {
    this.pacienteServ.desactivarTelefono(itemBorrar.id).toPromise().then((ok: boolean) => {
      if (ok) {
        itemBorrar.tipoAccion = TipoAccionABMEnum.ACTIVAR;
        this.updateDSTel();
      }
    });
  }
  public activarTel(itemBorrar: TelefonoModel) {
    this.pacienteServ.activarTelefono(itemBorrar.id).toPromise().then((ok: boolean) => {
      if (ok) {
        itemBorrar.tipoAccion = TipoAccionABMEnum.DESACTIVAR;
        this.updateDSTel();
      }
    });
  }
  public eliminarTel(itemBorrar: TelefonoModel) {
    if (this.pacienteServ.PacienteUpdate.persona.telefonos.findIndex(x => x === itemBorrar) > -1) {
      if (itemBorrar.id > 0) {
        this.pacienteServ.eliminarTelefono(itemBorrar.id).toPromise().then(x => {
          if (x !== null) {
            this.borrarTelTabla(itemBorrar);
          }
        });
      } else {
        this.borrarTelTabla(itemBorrar);
      }
    }
  }
  borrarTelTabla(itemBorrar: TelefonoModel) {
    this.pacienteServ.PacienteUpdate.persona.telefonos.splice(
      this.pacienteServ.PacienteUpdate.persona.telefonos.findIndex(x => x === itemBorrar), 1);
    this.updateDSTel();
  }
  updateDSPlan() {
    this.planPaciente = new MatTableDataSource<ABMPacienteUpdateUsuarioPlanModel>(this.pacienteServ.PacienteUpdate.usuariosPlanes);
  }
  public updatePlan(item: ABMPacienteUpdateUsuarioPlanModel) {
    const dialog = this.modalpage.open(ModalPacientePlanesUpdateComponent, {
      panelClass: 'custom-dialog-container-auto',
      data: {
        planUpdate: item === null ? new ABMPacienteUpdateUsuarioPlanModel() : item,
        planesActuales: this.pacienteServ.PacienteUpdate.usuariosPlanes
      }
    });
    dialog.afterClosed().toPromise().then((result: ABMPacienteUpdateUsuarioPlanModel) => {
      if (result) {
        if (this.pacienteServ.PacienteUpdate.id > 0) {
          result.usuarioId = this.pacienteServ.PacienteUpdate.id;
          this.pacienteServ.actualizarPlan(result).toPromise().then(x => {
            this.guardarPlan(x, item);
            this.updateDSPlan();
          });
        } else {
          this.guardarPlan(result, item);
          this.updateDSPlan();
        }
      }
    });
  }
  public guardarPlan(item: ABMPacienteUpdateUsuarioPlanModel, original: ABMPacienteUpdateUsuarioPlanModel) {
    if (this.pacienteServ.PacienteUpdate.usuariosPlanes.findIndex(x => x === original) > -1) {
      const planIndex = this.pacienteServ.PacienteUpdate.usuariosPlanes.findIndex(x => x === original);
      this.pacienteServ.PacienteUpdate.usuariosPlanes[planIndex].esTitular = item.esTitular;
      this.pacienteServ.PacienteUpdate.usuariosPlanes[planIndex].numeroAsociado = item.numeroAsociado;
      this.pacienteServ.PacienteUpdate.usuariosPlanes[planIndex].obraId = item.obraId;
      this.pacienteServ.PacienteUpdate.usuariosPlanes[planIndex].planId = item.planId;
      this.pacienteServ.PacienteUpdate.usuariosPlanes[planIndex].obraNombre = item.obraNombre;
      this.pacienteServ.PacienteUpdate.usuariosPlanes[planIndex].planNombre = item.planNombre;
    } else {
      this.pacienteServ.PacienteUpdate.usuariosPlanes.push(item);
    }
  }
  public desactivarPlan(itemBorrar: ABMPacienteUpdateUsuarioPlanModel) {
    this.pacienteServ.desactivarPlan(itemBorrar.id).toPromise().then((ok: boolean) => {
      if (ok) {
        itemBorrar.tipoAccion = TipoAccionABMEnum.ACTIVAR;
        this.updateDSPlan();
      }
    });
  }
  public activarPlan(itemBorrar: ABMPacienteUpdateUsuarioPlanModel) {
    this.pacienteServ.activarPlan(itemBorrar.id).toPromise().then((ok: boolean) => {
      if (ok) {
        itemBorrar.tipoAccion = TipoAccionABMEnum.DESACTIVAR;
        this.updateDSPlan();
      }
    });
  }
  public eliminarPlan(itemBorrar: ABMPacienteUpdateUsuarioPlanModel) {
    if (this.pacienteServ.PacienteUpdate.usuariosPlanes.findIndex(x => x === itemBorrar) > -1) {
      if (itemBorrar.id > 0) {
        this.pacienteServ.eliminarPlan(itemBorrar.id).toPromise().then(x => {
          if (x !== null) {
            this.borrarPlanTabla(itemBorrar);
          }
        });
      } else {
        this.borrarPlanTabla(itemBorrar);
      }
    }
  }
  borrarPlanTabla(itemBorrar: ABMPacienteUpdateUsuarioPlanModel) {
    this.pacienteServ.PacienteUpdate.usuariosPlanes.splice(
      this.pacienteServ.PacienteUpdate.usuariosPlanes.findIndex(x => x === itemBorrar), 1);
    this.updateDSPlan();
  }
  public cerrar() {
    // if (!this.pacienteServ.pacientePopUp) {
    // TENDRIA QUE VOLVER A LOS DATOS ANTERIORES
    // this.route.navigate(['principal'], { skipLocationChange: true });
    this.actualizarPaciente();
    // } else {
    //   this.pacienteServ.PacienteUpdate = new ABMPacienteUpdateModel();
    //   this.cerrarEmitter.emit();
    // }
  }
  mailExiste() {
    if (this.personaForm.get('email').value !== undefined && this.personaForm.get('email').value !== null && this.personaForm.get('email').value !== '') {
      const consulta = new PacienteVerificarMailConsultaModel();
      consulta.email = this.personaForm.get('email').value;
      if (this.pacienteServ.PacienteUpdate.id !== undefined && this.pacienteServ.PacienteUpdate.id !== null) {
        consulta.usuarioId = this.pacienteServ.PacienteUpdate.id;
      } else {
        consulta.usuarioId = 0;
      }

      consulta.usuarioId = this.pacienteServ.PacienteUpdate.id;
      this.pacienteServ.validarMailPersona(consulta).toPromise().then(x => {
        if (x) {
          this.snackBar.open('El mail que acaba de ingresar ya se encuentra en uso, por favor ingrese uno distinto', 'OK', { duration: 10000 });
          this.personaForm.get('email').setValue('');
        }
      });
    }
  }
}
