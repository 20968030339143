import { environment } from 'src/environments/environment';

export class CtaCteApiConfig {
  // CTACTE
  public static CTACTE_DETALLES_URL = environment.apiUrl + 'CtaCte/Detalles';
  public static CTACTE_COBRO_CUENTA_URL = environment.apiUrl + 'CtaCte/CobroCuenta';
  public static CTACTE_COBRO_ORDENES_URL = environment.apiUrl + 'CtaCte/CobroOrdenes';
  public static CTACTE_DEPOSITO_GARANTIA_URL = environment.apiUrl + 'CtaCte/DepositosGarantia';
  public static CTACTE_DEVOLVER_DEPOSITO_URL = environment.apiUrl + 'CtaCte/DevolverDep';
  public static CTACTE_COMPROBANTES_LIST_URL = environment.apiUrl + 'CtaCte/ComprobantesList';
  public static CTACTE_COMPROBANTE_PRINT_URL = environment.apiUrl + 'CtaCte/ComprobantePrint';
  public static CTACTE_PAGARES_URL = environment.apiUrl + 'CtaCte/Pagares';
  public static CTACTE_PAGARE_NUEVO_URL = environment.apiUrl + 'CtaCte/PagareNuevo';
  public static CTACTE_PAGARE_UNO_URL = environment.apiUrl + 'CtaCte/PagareUno';
  public static CTACTE_PAGARE_CANCELAR_URL = environment.apiUrl + 'CtaCte/PagareCancelar';
  public static CTACTE_PAGARE_EJECUTAR_URL = environment.apiUrl + 'CtaCte/PagareEjecutar';
  public static CTACTE_LISTAR_COBRO_A_CUENTA_URL = environment.apiUrl + 'CtaCte/ListarCobroACuenta';
  public static CTACTE_RELACIONAR_COBRO_ORDEN_URL = environment.apiUrl + 'CtaCte/RelacionarCobroOrden';
  // FACTURA ELECTRONICA
  public static CTACTE_FAC_ELECT_FACTURADORES_URL = environment.apiUrl + 'FacturaElectronica/Facturadores';
  public static CTACTE_FAC_ELECT_CERTIFICADO_FACTURADOR_URL = environment.apiUrl + 'FacturaElectronica/CertificadoFacturador';
  public static CTACTE_FAC_ELECT_CLIENTES_URL = environment.apiUrl + 'FacturaElectronica/Clientes';
  public static CTACTE_FAC_ELECT_ORDENES_URL = environment.apiUrl + 'FacturaElectronica/Ordenes';
  public static CTACTE_FAC_ELECT_GENERAR_URL = environment.apiUrl + 'FacturaElectronica/Generar';
}
