import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { format } from 'date-fns';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ShifterService } from 'src/app/services/shifter/shifter.service';
import {
  EmpresaSelectModel,
  SedeSelectModel,
  shifterTurno
} from 'src/app/models/turnos/shifter-turno.model';
import { SelectSedeDialogComponent } from '../select-sede-dialog/select-sede-dialog.component';
import { async } from 'rxjs/internal/scheduler/async';
interface SedeData {
  sedeId: number;
  playListId: string[];
}
@Component({
  selector: 'app-shifter-container',
  templateUrl: './shifter-container-simple.component.html',
  styleUrls: ['./shifter-container-simple.component.scss']
})
export class ShifterContainerSimpleComponent implements OnInit {
  @ViewChild('youtubePlayerContainer') youtubePlayerContainer!: ElementRef;
  private player: any;
  showAppScreen: boolean = true;
  showYouTubePlayer: boolean = false;
  dataSource: MatTableDataSource<any>;
  displayedColumns = ['tuenos', 'dni'];
  date: string;
  time: string;
  selectedSede: SedeSelectModel;
  selectedCentro: EmpresaSelectModel;
  audio = new Audio();
  playListId: string = "PLhUoikWi1lnYaSfwFu4gQT5NDh2S6Fzez";
  playListArray = [
    'PLhUoikWi1lnaqPJQPa4QlBSzXCUYoZHai',
    'PLhUoikWi1lnYaSfwFu4gQT5NDh2S6Fzez',
    'PLhUoikWi1lnZG8f-FjsWAPHaU0ECrpCMO',
    'PLhUoikWi1lnYN5HP2Ijn5jX_WYrY3BDLA',
    // Add more playlist IDs as needed
  ];
  currentPlaylistIndex = 0;
  constructor(private shifterService: ShifterService, private dialog: MatDialog, private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.startClock();
    const data = [
      { consultorioName: '2', dni: 'ABC' },
      { consultorioName: '20', dni: 'ABC' },
      { consultorioName: '8', dni: 'ABC' },
      { consultorioName: '4', dni: 'ABC' },
      // { tuenos: 'Consultorio N° 2', dni: 448 },
      // { tuenos: 'Consultorio N° 10', dni: 728 },
      // { tuenos: 'Consultorio N° 8', dni: 112 },
      // { tuenos: 'Consultorio N° 4', dni: 234 }
    ];
    this.dataSource = new MatTableDataSource(data);
    //this.InitializeYoutube();

  }
  startClock() {
    setInterval(() => {
      const currentDate = new Date();

      // Format date
      const dateFormat = 'dd / MM / yyyy';
      this.date = format(currentDate, dateFormat);

      // Format time
      const timeFormat = 'hh:mm a';
      this.time = format(currentDate, timeFormat);
    }, 1000);

  }
  isFirstRow(row: any): boolean {
    return this.dataSource.data.indexOf(row) === 0;
  }

  openDialog() {
    if (this.selectedSede) {
      return;
    }
    const dialogRef = this.dialog.open(SelectSedeDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      // Handle dialog close event and retrieve the data
      if (result && result.sede.id != 0) {

        this.selectedSede = result.sede;
        this.selectedCentro = result.centro;
        this.updateTuenoList();
        // Do something with the selected data
        console.log("Selected centro:", this.selectedCentro);
        console.log("Selected sede:", this.selectedSede);
        this.playListArray = result.videoLink.split(',').map(a => this.getYouTubePlaylistId(a));
        this.playListId = result.videoLink;
        //this.InitializeYoutube();
        //this.player.loadPlaylist(result.videoLink);
        //this.player.pauseVideo();
        //   if(this.playListArray.length>0)
        //   {
        //     //this.playListId = this.getYouTubePlaylistId(result.videoLink);
        //     // this.player.loadVideoById(this.playListId);
        //     //this.player.pauseVideo();
        //     this.saveUrl();
        //     this.currentPlaylistIndex = 0;

        //     this.loadPlaylist(this.currentPlaylistIndex);
        //   }
        //   else
        //   {
        //     const storedData: SedeData[] = JSON.parse(localStorage.getItem('sedeData')) || [];
        //     if (this.selectedSede && this.selectedSede.id) {
        //       const sedeId = this.selectedSede.id;
        //       const existingData = storedData.find((data) => data.sedeId === sedeId);      
        //       if (existingData) {
        //         this.playListArray = existingData.playListId;
        //         this.player.loadPlaylist(this.playListId);
        //         this.player.pauseVideo();
        //       }      
        //   }

        // }
      }
    });
  }
  updateTuenoList() {
    setInterval(async () => {
      this.shifterService.getCurrentTurno(this.selectedSede.id).subscribe(data => {
        let previousData = this.dataSource ? this.dataSource.data : [];
        if (!this.isEqual(data, previousData)) {
          this.dataSource = new MatTableDataSource(data);
          this.playSound();
          // this.stop();
          // setTimeout(() => {
          //   this.paly();
          // }, 30000);

        } else {
          console.log("Data is the same.");
        }
      })
    }, 5000);
  }
  // InitializeYoutube()
  // {   
  //   // Load YouTube IFrame API script
  //  const tag = document.createElement('script');
  //  tag.id = 'youtube-iframe-api-script';
  //  tag.src = "https://www.youtube.com/iframe_api";
  //  document.body.appendChild(tag);
  //    // Initialize the YouTube player once the script is loaded
  //  window['onYouTubeIframeAPIReady'] = () => {
  //   this.player = new window['YT'].Player(this.youtubePlayerContainer.nativeElement, {
  //     height: '100%',
  //     width: '100%',
  //     //videoId: this.videoId, // Replace this with your YouTube video ID
  //     playerVars: {

  //       listType: "player",
  //       list: this.playListId, // Pass the playlistId to load the playlist

  //     },
  //     events: {
  //       'onStateChange': (event: any) => this.onPlayerStateChange(event),
  //     },
  //   });
  // };
  // }
  // paly()
  // {
  //   this.showAppScreen = false;
  //   this.player.playVideo();
  // }
  // stop()
  // {
  //   this.showAppScreen = true;
  //   this.player.pauseVideo();
  // }
  // Function to load the next playlist
  //   loadNextPlaylist() {
  //   // Check if there are more playlists to play
  //   if (this.currentPlaylistIndex < this.playListArray.length - 1) {
  //     // Increment the index to load the next playlist
  //     this.currentPlaylistIndex++;

  //     // Load the next playlist
  //     this.loadPlaylist(this.currentPlaylistIndex);
  //     // You can optionally update the currentPlaylistIndex or perform other actions
  //     // based on your requirements.
  //   } else {
  //     // Load the first playlist
  //     this.currentPlaylistIndex = 0;
  //     this.loadPlaylist(this.currentPlaylistIndex);
  //   }
  // }
  // loadPlaylist(index:number = 0)
  // {
  //   const nextPlaylistId = this.playListArray[index];
  //   try{

  //     this.player.loadPlaylist({ listType: 'playlist', list: this.playListId });
  //   }
  //   catch
  //   {

  //   }
  // }
  //   onPlayerStateChange(event: any) {
  //     console.log(event);
  //     if (event.data === window['YT'].PlayerState.ENDED) {
  //       //this.player.playVideo(); // Play the video again when it ends
  //       this.loadNextPlaylist();
  //     }
  //     if(event.data === window['YT'].PlayerState.PAUSED)
  //     {
  //      this.stop();
  //     }
  //   } 
  isEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }
    for (let i = 0; i < arr1.length; i++) {
      if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
        return false;
      }
    }
    return true;
  }

  getYouTubeVideoId(url): string {
    const regex = /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/;
    const match: string = url.match(regex);
    return match && match[1] ? match[1] : null;
  }
  getYouTubePlaylistId(url) {
    const regex = /[?&]list=([^"&?/ ]{34})/;
    const match = url.match(regex);
    return match && match[1] ? match[1] : null;
  }
  saveUrl() {
    if (this.selectedSede && this.selectedSede.id) {
      // Get the stored data from local storage
      const storedData: SedeData[] = JSON.parse(localStorage.getItem('sedeData')) || [];

      // Find the index of the Sede ID in the stored data
      const sedeIndex = storedData.findIndex((data) => data.sedeId === this.selectedSede.id);

      if (sedeIndex !== -1) {
        // If the Sede ID already exists in local storage, update the URL by index
        storedData[sedeIndex].playListId = this.playListArray;
      } else {
        // If the Sede ID is new, add it to the storedData array
        const newData: SedeData = {
          sedeId: this.selectedSede.id,
          playListId: this.playListArray // Create an array to store video IDs
        };
        storedData.push(newData);
      }

      // Save the updated or new data back to local storage
      localStorage.setItem('sedeData', JSON.stringify(storedData));
    }
  }
  playSound(): void {
    this.audio.src = `assets/sounds/dingdong.mp3`;
    this.audio.load();
    this.audio.play();
  }
}
