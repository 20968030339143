import { DateHelperService } from 'src/app/services/helpers/date-helper.service';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
// import { isNil, isPlainObject } from 'lodash';
// import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class HttpHelperService {

  constructor(private dateHelpers: DateHelperService) { }

  public buildQueryParams(source: any, sourceName?: string) {
    let target: HttpParams = new HttpParams();
    if (sourceName) {
      target = target.append(sourceName, source);
    } else {
      for (const key of Object.keys(source)) {
        target = this.appendItem(source[key], key, target);
      }
    }
    return { params: target };
  }

  private appendItem(value: any, key: string, target: HttpParams): HttpParams {
    if (value === null || value === undefined) {
      return target;
    }
    if (typeof value === 'object' && (value instanceof Date) === false) {
      if (Array.isArray(value)) {
        if (typeof value[0] === 'string' || typeof value[0] === 'number' || typeof value[0] === 'boolean') {
          for (const iterator of value) {
            target = target.append(key, iterator);
          }
        } else {
          for (const iterator of value) {
            for (const elementKey of Object.keys(iterator)) {
              target = this.appendItem(iterator[elementKey], elementKey, target);
            }
          }
        }
      } else {
        for (const elementKey of Object.keys(value)) {
          target = this.appendItem(value[elementKey], elementKey, target);
        }
      }
    } else {
      if (value instanceof Date) {
        value = this.dateHelpers.fechaParaServer(value); // moment(value).format();
      } else {
        value = value.toString();
      }
      target = target.append(key, value);
    }
    return target;
  }
}
