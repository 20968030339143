<mat-card style="height:max-content">
  <mat-card-content class="cuerpocli" fxLayoutGap="10px">
    <div fxFlex="1 1 10%">
      <label fxLayout="column" fxLayoutAlign="space-between center">
        <input #imageInput type="file" accept="image/*" (change)="processFile(imageInput)" name="coso"
          style="display:none;" tabindex="-1" />
        <img
          [src]="!selectedFileStr? '../../../../../assets/images/persona_upload.jpg' : selectedFileTipoStr+selectedFileStr"
          class="foto-upload" alt="Foto" />
      </label>
      <button mat-mini-fab class="voladorDel" (click)="quitarImagen()" *ngIf="selectedFileStr" tabindex="-1">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
    <div fxLayout="row wrap" fxFlex="1 1 100%" class="contenerdor-info">
      <!-- Datos -->
      <mat-card class="tarjeta-ABM" fxFlex="49%">
        <mat-card-header>
          <mat-card-title>
            Datos del Paciente
          </mat-card-title>
        </mat-card-header>
        <mat-card-content [formGroup]="personaForm" fxLayout="row wrap" fxLayoutGap="10px">
          <mat-form-field fxFlex="20%">
            <mat-label>Tipo</mat-label>
            <mat-select formControlName="tipoDoc" required>
              <mat-option *ngFor="let item of datosSelect.tiposDoc" [value]='item.id'>{{item.nombre}}</mat-option>
            </mat-select>
            <mat-error
              *ngIf="personaForm.get('tipoDoc').invalid && (personaForm.get('tipoDoc').dirty || personaForm.get('tipoDoc').touched)">
              <small *ngIf="personaForm.get('tipoDoc').hasError('required')">
                Debe elegir un tipo de documento
              </small>
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="70%">
            <input matInput placeholder="Nro. Doc." formControlName="nroDoc" autocomplete="off" required>
            <mat-error
              *ngIf="personaForm.get('nroDoc').invalid && (personaForm.get('nroDoc').dirty || personaForm.get('nroDoc').touched)">
              <small *ngIf="personaForm.get('nroDoc').hasError('ExisteNroDoc')">
                Ya hay un usuario con este nro. de documento
              </small>
              <small *ngIf="personaForm.get('nroDoc').hasError('maxlength')">
                No puede escribir un nro. de documento de mas de 15 caracteres
              </small>
              <small *ngIf="personaForm.get('nroDoc').hasError('required')">
                Debe ingresar un nro. de documento
              </small>
              <small *ngIf="personaForm.get('nroDoc').hasError('pattern')">
                No puede ingresar letras al nro. de documento
              </small>
            </mat-error>
            <button mat-icon-button type="button" matTooltip="Editar DNI" matSuffix
            *ngIf="pacienteServ.PacienteUpdate.id" (click)="habilitarDNI()" tabindex=-1>
            <mat-icon>edit</mat-icon>
          </button>
            <button mat-icon-button type="button" matTooltip="Dejar de editar" matSuffix
              *ngIf="pacienteServ.PacienteUpdate.id" (click)="limpiarPersona()" tabindex=-1>
              <mat-icon>clear</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field fxFlex="100%">
            <input matInput #foco placeholder="Nombres" oninput="this.value = this.value.toUpperCase()" formControlName="nombre" autocomplete="off" required>
            <mat-error
              *ngIf="personaForm.get('nombre').invalid && (personaForm.get('nombre').dirty || personaForm.get('nombre').touched)">
              <small *ngIf="personaForm.get('nombre').hasError('maxlength')">
                No puede escribir un nombre de mas de 50 caracteres
              </small>
              <small *ngIf="personaForm.get('nombre').hasError('required')">
                Debe ingresar un nombre
              </small>
              <small *ngIf="personaForm.get('nombre').hasError('pattern')">
                No puede ingresar numeros al nombre
              </small>
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="45%">
            <input matInput placeholder="Apellidos"  oninput="this.value = this.value.toUpperCase()" oninput="this.value = this.value.toUpperCase()" formControlName="apellido" autocomplete="off" required>
            <mat-error
              *ngIf="personaForm.get('apellido').invalid && (personaForm.get('apellido').dirty || personaForm.get('apellido').touched)">
              <small *ngIf="personaForm.get('apellido').hasError('maxlength')">
                No puede escribir un apellido de mas de 50 caracteres
              </small>
              <small *ngIf="personaForm.get('apellido').hasError('required')">
                Debe ingresar un apellido
              </small>
              <small *ngIf="personaForm.get('apellido').hasError('pattern')">
                No puede ingresar numeros al apellido
              </small>
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="45%">
            <input matInput placeholder="Apellido Matrimonial" formControlName="apellidoMatrimonial" autocomplete="off">
            <mat-error
              *ngIf="personaForm.get('apellidoMatrimonial').invalid && (personaForm.get('apellidoMatrimonial').dirty || personaForm.get('apellidoMatrimonial').touched)">
              <small *ngIf="personaForm.get('apellidoMatrimonial').hasError('maxlength')">
                No puede escribir un apellido matrimonial de mas de 50 caracteres
              </small>
              <small *ngIf="personaForm.get('apellidoMatrimonial').hasError('pattern')">
                No puede ingresar numeros al apellido matrimonial
              </small>
            </mat-error>
          </mat-form-field>
          <div fxLayout="row" fxFlex="100%">
          <mat-form-field >
            <input appMaskEmail matInput (focusout)="mailExiste()" placeholder="Email" formControlName="email"
              autocomplete="off">
            <mat-error
              *ngIf="personaForm.get('email').invalid && (personaForm.get('email').dirty || personaForm.get('email').touched)">
              <small *ngIf="personaForm.get('email').hasError('emailExiste')">
                Ya hay un usuario con este Email
              </small>
              <small *ngIf="personaForm.get('email').hasError('email')">
                El email no es correcto
              </small>
            </mat-error>
          </mat-form-field>
          <div fxLayout="column" style="margin-left: 10px;">
         
             <mat-checkbox formControlName="isWhatsApp">Enviar WhatsApp </mat-checkbox>
         
            <mat-checkbox formControlName="isEmail">Enviar Email </mat-checkbox>
          
          </div>
          </div>

          <mat-form-field fxFlex="22%">
            <mat-label>Sexo</mat-label>
            <mat-select formControlName="sexo" required>
              <mat-option *ngFor="let item of datosSelect.sexoBio" [value]='item.id'>{{item.nombre}}</mat-option>
            </mat-select>
            <mat-error
              *ngIf="personaForm.get('sexo').invalid && (personaForm.get('sexo').dirty || personaForm.get('sexo').touched)">
              <small *ngIf="personaForm.get('sexo').hasError('required')">
                Debe elegir un sexo
              </small>
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="22%">
            <mat-label>Genero</mat-label>
            <mat-select formControlName="genero" required>
              <mat-option *ngFor="let item of datosSelect.generos" [value]='item.id'>{{item.nombre}}</mat-option>
            </mat-select>
            <mat-error
              *ngIf="personaForm.get('genero').invalid && (personaForm.get('genero').dirty || personaForm.get('genero').touched)">
              <small *ngIf="personaForm.get('genero').hasError('required')">
                Debe elegir un genero
              </small>
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="25%">
            <mat-label>Fecha de Nac.</mat-label>
            <input matInput [matDatepicker]="nacimientoPicker" formControlName="fechaNac" autocomplete="off" appMaskDate
              required>
            <mat-datepicker-toggle matSuffix [for]="nacimientoPicker"></mat-datepicker-toggle>
            <mat-datepicker #nacimientoPicker></mat-datepicker>
            <mat-error
              *ngIf="personaForm.get('fechaNac').invalid && (personaForm.get('fechaNac').dirty || personaForm.get('fechaNac').touched)">
              <small *ngIf="personaForm.get('fechaNac').hasError('nacimientoMayorHoy')">
                No puede ingresar un nacimiento mayor a la fecha actual
              </small>
              <small *ngIf="personaForm.get('fechaNac').hasError('required')">
                Debe ingresar una fecha de nacimiento valida
              </small>
            </mat-error>
          </mat-form-field>
          
          <mat-form-field fxFlex="20%" *ngIf="pacienteServ.PacienteUpdate.id">
            <mat-label>Fallecido</mat-label>
            <mat-select formControlName="fallecido">
              <mat-option [value]="false">NO</mat-option>
              <mat-option [value]="true">SI</mat-option>
            </mat-select>
          </mat-form-field>
          <span class="campos-obligatorios" fxFlex="100%">Los campos marcados con (*) son obligatorios</span>
          <div *ngIf="!esModal" class="botonera" fxFlex="74%" fxLayout="row" fxLayoutAlign="end start"
            fxLayoutGap="10px" style="right: 50px; padding-bottom: 20px;">
            <button mat-raised-button class="btn-cancelar" (click)="cerrar()" tabindex="-1">CANCELAR</button>
            <button mat-raised-button class="btn-guardar" (click)="guardar()" tabindex="-1">GUARDAR</button>
          </div>
        </mat-card-content>
      </mat-card>
      <!-- Direccion -->
      <div fxFlex="50%" fxLayout="row wrap" fxLayoutAlign="center start" class="grillas">
        <div class="tarjeta-ABM" fxFlex="100%">
          <mat-card-header fxLayout="row nowrap" fxLayoutAlign="space-between">
            <mat-card-title>
              Direcciones
            </mat-card-title>
            <button matTooltipClass="navbar-tooltip" matTooltip="Agregar Dirección" matTooltipPosition="right" mat-icon-button (click)="updateDir(null)" tabindex="-1">
              <mat-icon>add</mat-icon>
            </button>
          </mat-card-header>
          <mat-card-content>
            <table mat-table [dataSource]="dirPaciente" class="mat-elevation-z8">
              <ng-container matColumnDef="calle">
                <th class="text-left" mat-header-cell *matHeaderCellDef> Calle </th>
                <td mat-cell *matCellDef="let item"> {{item.calle}} </td>
              </ng-container>
              <ng-container matColumnDef="numero">
                <th mat-header-cell *matHeaderCellDef> Número </th>
                <td mat-cell *matCellDef="let item"> {{item.numero}} </td>
              </ng-container>
              <ng-container matColumnDef="ciudad">
                <th mat-header-cell *matHeaderCellDef> Ciudad </th>
                <td mat-cell *matCellDef="let item"> {{item.ciudad}} </td>
              </ng-container>
              <ng-container matColumnDef="buttons">
                <th mat-header-cell *matHeaderCellDef class="workWiths"></th>
                <td mat-cell *matCellDef="let item" class="workWiths">
                  <app-acciones-table [ignorarPrivilegios]="true" [small]="true" (activarEvent)="activarDir($event)"
                    (desactivarEvent)="desactivarDir($event)" [mostrarEliminar]="true" (eliminarEvent)="eliminarDir($event)"
                    (updateEvent)="updateDir($event)" [item]="item">
                  </app-acciones-table>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayDirecciones"></tr>
              <tr mat-row *matRowDef="let row; columns: displayDirecciones;" class="sombreado-fila"></tr>
            </table>
          </mat-card-content>
        </div>
        <!-- telefonos -->
        <div class="tarjeta-ABM" fxFlex="100%">
          <mat-card-header fxLayout="row nowrap" fxLayoutAlign="space-between">
            <mat-card-title>Teléfono</mat-card-title>
            <button matTooltipClass="navbar-tooltip" matTooltip="Agregar Teléfono" matTooltipPosition="right" mat-icon-button (click)="updateTel(null)" tabindex="-1">
              <mat-icon>add</mat-icon>
            </button>
          </mat-card-header>
          <mat-card-content style="height: min-content;">
            <table mat-table [dataSource]="telPaciente" class="mat-elevation-z8">
              <ng-container matColumnDef="tipo">
                <th mat-header-cell *matHeaderCellDef> Tipo </th>
                <td mat-cell *matCellDef="let item"> {{item.tipoStr}} </td>
              </ng-container>
              <ng-container matColumnDef="numero">
                <th mat-header-cell *matHeaderCellDef> Número </th>
                <td mat-cell *matCellDef="let item"> {{item.numero}} </td>
              </ng-container>
              <ng-container matColumnDef="buttons">
                <th mat-header-cell *matHeaderCellDef class="workWiths"></th>
                <td mat-cell *matCellDef="let item" class="workWiths">
                  <app-acciones-table [ignorarPrivilegios]="true" [small]="true" (activarEvent)="activarTel($event)"
                    (desactivarEvent)="desactivarTel($event)"  [mostrarEliminar]="true" (eliminarEvent)="eliminarTel($event)"
                    (updateEvent)="updateTel($event)" [item]="item">
                  </app-acciones-table>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayTelefono"></tr>
              <tr mat-row *matRowDef="let row; columns: displayTelefono;" class="sombreado-fila"></tr>
            </table>
          </mat-card-content>

        </div>
      </div>
      <!-- Planes -->
      <div class="tarjeta-ABM" fxFlex="100%">
        <mat-card-header fxLayout="row nowrap" fxLayoutAlign="space-between">
          <mat-card-title>
            Planes
          </mat-card-title>
          <button matTooltipClass="navbar-tooltip" matTooltip="Agregar Plan" matTooltipPosition="right" mat-icon-button (click)="updatePlan(null)" tabindex="-1">
            <mat-icon>add</mat-icon>
          </button>
        </mat-card-header>
        <mat-card-content style="height: min-content;">
          <table mat-table [dataSource]="planPaciente" class="mat-elevation-z8">
            <ng-container matColumnDef="os">
              <th mat-header-cell *matHeaderCellDef> O. Social </th>
              <td class="text-left" mat-cell *matCellDef="let item"> {{item.obraNombre}} </td>
            </ng-container>
            <ng-container matColumnDef="nombre">
              <th mat-header-cell *matHeaderCellDef> Plan </th>
              <td mat-cell *matCellDef="let item"> {{item.planNombre}} </td>
            </ng-container>
            <ng-container matColumnDef="asoc">
              <th mat-header-cell *matHeaderCellDef> Nro. Asociado </th>
              <td mat-cell *matCellDef="let item"> {{item.numeroAsociado}} </td>
            </ng-container>
            <ng-container matColumnDef="titular">
              <th mat-header-cell *matHeaderCellDef> Parentesco </th>
              <td mat-cell *matCellDef="let item"> {{item.esTitular === 0?'TITULAR':(item.esTitular === 1?'CONYUGUE':(item.esTitular === 2?'HIJOS':'ADICIONAL'))}} </td>
            </ng-container>
             <ng-container matColumnDef="otraInfo">
              <th mat-header-cell *matHeaderCellDef> Otra Info </th>
              <td mat-cell *matCellDef="let item"> {{item.otraInfo}}</td>
            </ng-container>
            <ng-container matColumnDef="buttons">
              <th mat-header-cell *matHeaderCellDef class="workWiths"></th>
              <td mat-cell *matCellDef="let item" class="workWiths">
                <app-acciones-table [ignorarPrivilegios]="true" [small]="true" (activarEvent)="activarPlan($event)"
                  (desactivarEvent)="desactivarPlan($event)" (eliminarEvent)="eliminarPlan($event)"
                  (updateEvent)="updatePlan($event)" [item]="item">
                </app-acciones-table>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayPlanes"></tr>
            <tr mat-row *matRowDef="let row; columns: displayPlanes;" class="sombreado-fila"></tr>
          </table>
        </mat-card-content>
      </div>
      <!-- Telefonos -->
      <!-- <div class="tarjeta-ABM" fxFlex="">

      </div> -->
    </div>
  </mat-card-content>
</mat-card>
