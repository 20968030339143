import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { LoginUsuarioComponent } from './login-usuario.component';
import { MatButtonModule } from '@angular/material/button';
import { ModalUsuarioRecupPassModule } from '../modal-usuario-recup-pass/modal-usuario-recup-pass.module';

@NgModule({
  declarations: [LoginUsuarioComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    ModalUsuarioRecupPassModule,
    MatIconModule
  ],
  exports: [LoginUsuarioComponent]
})
export class LoginUsuarioModule { }
