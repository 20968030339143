import { LoginService } from './../../../services/login/login.service';
import { Component, ViewChild, ElementRef } from '@angular/core';
import { LoginModel } from 'src/app/models/login/login.model';
import { MatDialog } from '@angular/material/dialog';
import { ModalUsuarioRecupPassComponent } from '../modal-usuario-recup-pass/modal-usuario-recup-pass.component';

@Component({
  selector: 'app-login-usuario',
  templateUrl: './login-usuario.component.html',
  styleUrls: ['./login-usuario.component.scss']
})
export class LoginUsuarioComponent {
  @ViewChild('usuario', { read: ElementRef })
  usuarioField: ElementRef;
  @ViewChild('password', { read: ElementRef })
  passField: ElementRef;
  @ViewChild('loginBtn', { read: ElementRef })
  loginBtn: ElementRef;

  datoslogin: LoginModel = new LoginModel();
  constructor(
    private modalpage: MatDialog,
    private loginServ: LoginService
  ) { }

  public login() {
    this.loginServ.usuarioLoginEvent.emit(this.datoslogin);
  }
  public nextField(event) {
    if (event.target === this.usuarioField.nativeElement) {
      this.passField.nativeElement.focus();
    } else {
      this.loginBtn.nativeElement.click();
    }
  }
  recuperarPass() {
    const modal = this.modalpage.open(ModalUsuarioRecupPassComponent, {
      data: {
        nombreUsuario: this.datoslogin.nombreUsuario
      }
    });
  }
}
