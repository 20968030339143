import { LoginService } from 'src/app/services/login/login.service';
import { PrivilegiosEnum } from './../../../models/shared/privilegios.enum';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TipoAccionABMEnum } from 'src/app/models/shared/enums.model';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfirmarComponent } from '../modal-confirmar/modal-confirmar.component';

@Component({
  selector: 'app-acciones-table',
  templateUrl: './acciones-table.component.html',
  styleUrls: ['./acciones-table.component.scss']
})
export class AccionesTableComponent {
  @Input() item: any;
  @Input() small = false;
  @Input() mostrarEditar = true;
  @Input() mostrarEliminar = true;
  @Input() mostrarActivar = true;
  @Input() mostrarVer = true;
  @Input() ignorarPrivilegios = false;
  @Input() privilegioEdit: PrivilegiosEnum;
  @Input() privilegioActivar: PrivilegiosEnum;
  @Output() updateEvent = new EventEmitter<any>();
  @Output() desactivarEvent = new EventEmitter<any>();
  @Output() verEvent = new EventEmitter<any>();
  @Output() activarEvent = new EventEmitter<any>();
  @Output() eliminarEvent = new EventEmitter<any>();
  acciones = TipoAccionABMEnum;

  constructor(
    private modalpage: MatDialog,
    private loginServ: LoginService) { }

  eliminar() {
    if (this.ignorarPrivilegios ||
      (this.privilegioActivar && this.loginServ.checkearPrivilegio(this.privilegioActivar) || this.loginServ.esAdmin())) {
      this.modalpage.open(ModalConfirmarComponent, {
        panelClass: 'custom-dialog-container-auto'
      }).componentInstance.accionEvent.subscribe((result: boolean) => {
        if (result) {
          this.eliminarEvent.emit(this.item);
        }
      });
    }
  }
  desactivar() {
    if (this.ignorarPrivilegios ||
      (this.privilegioActivar && this.loginServ.checkearPrivilegio(this.privilegioActivar) || this.loginServ.esAdmin())) {
      this.modalpage.open(ModalConfirmarComponent, {
        panelClass: 'custom-dialog-container-auto',
        data:
        {
          accionInject: 'desactivar',
          mensajeInject: '¿Esta seguro que desea desactivar?'
        }
      }).componentInstance.accionEvent.subscribe((result: boolean) => {
        if (result) {
          this.desactivarEvent.emit(this.item);
        }
      });
    }
  }
  activar() {
    if (this.ignorarPrivilegios ||
      (this.privilegioActivar && this.loginServ.checkearPrivilegio(this.privilegioActivar) || this.loginServ.esAdmin())) {
      this.activarEvent.emit(this.item);
    }
  }
  update() {
    if (this.ignorarPrivilegios ||
      (this.privilegioEdit && this.loginServ.checkearPrivilegio(this.privilegioEdit) || this.loginServ.esAdmin())) {
      this.updateEvent.emit(this.item);
    }
  }
  ver2() {
    this.verEvent.emit(this.item);
    // const modal = this.modalpage.open(ModalConfiguracionPtoVtaComponent,
    //   {
    //     width: '450px',
    //     panelClass: 'custom-dialog-container-auto'
    //   });
  }
}
