import { LoginService } from 'src/app/services/login/login.service';
import { Component, OnInit, Inject, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { UsuarioCambioPassModel, UsuarioRecuperaPassModel } from 'src/app/models/login/usuarioLogin.model';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-modal-recuperar-pass',
  templateUrl: './modal-recuperar-pass.component.html',
  styleUrls: ['./modal-recuperar-pass.component.scss']
})
export class ModalRecuperarPassComponent implements OnInit, AfterViewInit {
  hidenueva = true;
  hiderepetir = true;
  recupForm: UntypedFormGroup;
  titulo = 'Recuperar Constraseña';
  @ViewChild('foco') campoFoco: ElementRef;

  constructor(
    private snackBar: MatSnackBar,
    private loginServ: LoginService,
    public dialogRef: MatDialogRef<ModalRecuperarPassComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      usId: string
    }
  ) { }

  ngOnInit(): void {
    this.recupForm = new UntypedFormGroup({
      nuevoPass: new UntypedFormControl(null, [Validators.required, Validators.minLength(6), Validators.maxLength(50)]),
      confirmarPass: new UntypedFormControl(null, [Validators.required])
    });
    if (typeof this.data.usId === 'string') {
      this.titulo = 'Recuperar Constraseña';
    } else if (typeof this.data.usId === 'number') {
      this.titulo = 'Cambio de Constraseña';
    }
  }
  ngAfterViewInit(): void {
    setTimeout(_ => this.campoFoco.nativeElement.focus());
  }
  confirmar() {
    if (!this.recupForm.valid) {
      Object.keys(this.recupForm.controls).forEach(key => {
        this.recupForm.get(key).markAsTouched();
      });
      this.snackBar.open('Por favor verifique los datos ingresados', 'OK');
      return;
    }
    if (this.recupForm.get('nuevoPass').value !== this.recupForm.get('confirmarPass').value) {
      this.snackBar.open('La nueva contraseña no coincide con la confirmación');
      return;
    }
    if (typeof this.data.usId === 'string') {
      const usDato = new UsuarioRecuperaPassModel();
      usDato.id = this.data.usId;
      usDato.pass = this.recupForm.get('nuevoPass').value;
      this.loginServ.cambioPassLink(usDato).toPromise().then(result => {
        if (result) {
          this.dialogRef.close(result);
        } else {
          this.snackBar.open('Hubo un error al cambiar la contraseña');
        }
      });
    } else if (typeof this.data.usId === 'number') {
      const usDato = new UsuarioCambioPassModel();
      usDato.id = this.data.usId;
      usDato.pass = this.recupForm.get('nuevoPass').value;
      this.loginServ.actualizarPass(usDato).toPromise().then(result => {
        if (result) {
          this.dialogRef.close(result);
        } else {
          this.snackBar.open('Hubo un error al cambiar la contraseña');
        }
      });
    }
  }
  public onCancel(): void {
    this.dialogRef.close(null);
  }
}
