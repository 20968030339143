import { RTCUserModel } from './../../models/shared/web-rtc.model';
import { Injectable, EventEmitter } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { SharedApiConfig } from './shared-api-config';

@Injectable({
  providedIn: 'root'
})
export class WebRTCService {
  private hubConnection: signalR.HubConnection;
  private thenable: Promise<void>;

  public nombreUs = '';

  public evUpdateUs = new EventEmitter<RTCUserModel[]>();
  public evRecibirSignal = new EventEmitter<{ usuario: RTCUserModel, señal: string }>();
  public evTerminarLlamada = new EventEmitter<{ idConexionTarget: string, mensaje: string }>();
  public evLlamadaAceptada = new EventEmitter<RTCUserModel>();
  public evLlamadaDeclinada = new EventEmitter<{ idConexionTarget: string, mensaje: string }>();
  public evLlamadaEntrante = new EventEmitter<RTCUserModel>();

  constructor() { }

  public buildConnection() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withAutomaticReconnect([5000, 10000, 15000])
      .withUrl(SharedApiConfig.WEB_RTC_HUB_URL)
      .configureLogging(signalR.LogLevel.None)
      .build();
  }
  public startConnection() {
    this.thenable = this.hubConnection.start();
    this.thenable
      .then(() => {
        this.eventos();
        if (this.nombreUs !== '') {
          this.joinChannel(this.nombreUs);
        }
      })
      .catch(err => {
        console.log('Error starting connection: ' + err);

        // setTimeout(() => this.startConnection(), 3000);
      });
  }
  public stopConnection() {
    if (this.hubConnection) {
      this.hubConnection.stop();
    }
  }
  public joinChannel(nombreUsuario: string) {
    if (this.hubConnection && this.hubConnection.state === signalR.HubConnectionState.Connected) {
      this.thenable.then(() => {
        this.hubConnection.send('JoinChannel', nombreUsuario);
      });
    }
  }
  public callUser(idConexionTarget: string) {
    if (this.hubConnection && this.hubConnection.state === signalR.HubConnectionState.Connected) {
      this.thenable.then(() => {
        this.hubConnection.send('CallUser', idConexionTarget);
      });
    }
  }
  public answerCall(aceptarLlamada: boolean, idConexionTarget: string) {
    if (this.hubConnection && this.hubConnection.state === signalR.HubConnectionState.Connected) {
      this.thenable.then(() => {
        this.hubConnection.send('AnswerCall', aceptarLlamada, idConexionTarget);
      });
    }
  }
  public hangUp() {
    if (this.hubConnection && this.hubConnection.state === signalR.HubConnectionState.Connected) {
      this.thenable.then(() => {
        this.hubConnection.send('HangUp');
      });
    }
  }
  public sendSignal(señal: string, idConexionTarget: string) {
    if (this.hubConnection && this.hubConnection.state === signalR.HubConnectionState.Connected) {
      this.thenable.then(() => {
        this.hubConnection.send('SendSignal', señal, idConexionTarget);
      });
    }
  }
  public eventos() {
    if (this.hubConnection && this.hubConnection.state === signalR.HubConnectionState.Connected) {
      this.hubConnection.on('UpdateUserList', (usuarios: RTCUserModel[]) => {
        // ACTUALIZAR LISTA DE USUARIOS
        this.evUpdateUs.emit(usuarios);
      });
      this.hubConnection.on('ReceiveSignal', (usuario: RTCUserModel, señal: string) => {
        // RECIBIR SEÑAL DE CONEXION
        this.evRecibirSignal.emit({ usuario, señal });
      });
      this.hubConnection.on('CallEnded', (idConexionTarget: string, mensaje: string) => {
        // TERMINAR LLAMADA
        this.evTerminarLlamada.emit({ idConexionTarget, mensaje });
      });
      this.hubConnection.on('CallAccepted', (usuario: RTCUserModel) => {
        // LLAMADA ACEPTADA
        this.evLlamadaAceptada.emit(usuario);
      });
      this.hubConnection.on('CallDeclined', (idConexionTarget: string, mensaje: string) => {
        // LLAMADA DECLINADA
        this.evLlamadaDeclinada.emit({ idConexionTarget, mensaje });
      });
      this.hubConnection.on('IncomingCall', (usuario: RTCUserModel) => {
        // LLAMADA ENTRANTE
        this.evLlamadaEntrante.emit(usuario);
      });
    }
  }
}
