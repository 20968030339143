<div fxLayout="row wrap" style="width: 100%;" fxLayoutAlign="center center">
    <table mat-table [dataSource]="liquidaEfectorDS" class="mat-elevation-z8" fxFlex="95%" multiTemplateDataRows>
      <ng-container matColumnDef="efectorNombre">
        <th mat-header-cell *matHeaderCellDef> Efector </th>
        <td mat-cell *matCellDef="let item"> {{ item.efectorNombre }} </td>
      </ng-container>
      <ng-container matColumnDef="cantidadCupon">
        <th mat-header-cell *matHeaderCellDef> Cantidad Cupón </th>
        <td mat-cell *matCellDef="let item"> {{ item.cantidadCupon }} </td>
      </ng-container>
      <ng-container matColumnDef="efectivo">
        <th mat-header-cell *matHeaderCellDef> Efectivo </th>
        <td mat-cell *matCellDef="let item">$ {{  item.efectivo|numberNegativo }} </td>
      </ng-container>
      <ng-container matColumnDef="tarjetaCredito">
        <th mat-header-cell *matHeaderCellDef> Tarjeta Crédito </th>
        <td mat-cell *matCellDef="let item">$ {{ item.tarjetaCredito|numberNegativo }} </td>
      </ng-container>
      <ng-container matColumnDef="tarjetaDebito">
        <th mat-header-cell *matHeaderCellDef> Tarjeta Débito </th>
        <td mat-cell *matCellDef="let item">$ {{ item.tarjetaDebito|numberNegativo }} </td>
      </ng-container>
      <ng-container matColumnDef="cheque">
        <th mat-header-cell *matHeaderCellDef> Cheque TB </th>
        <td mat-cell *matCellDef="let item">$ {{ item.cheque|numberNegativo }} </td>
      </ng-container>
      <ng-container matColumnDef="cuentaCorriente">
        <th mat-header-cell *matHeaderCellDef> Cuenta Corriente </th>
        <td mat-cell *matCellDef="let item">$ {{ item.cuentaCorriente|numberNegativo }} </td>
      </ng-container>
      <ng-container matColumnDef="total">
        <th mat-header-cell *matHeaderCellDef> Total </th>
        <td mat-cell *matCellDef="let item">$ {{ item.total|numberNegativo }} </td>
      </ng-container>
      <ng-container matColumnDef="buttons">
        <th mat-header-cell *matHeaderCellDef class="workWiths"></th>
        <td mat-cell *matCellDef="let item" class="workWiths">
          <button mat-button class="ABM-modificar" (click)="descargarResumen(item)">IMPRIMIR</button>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="colliquiEfector.length" class="bg-selected">
          <div class="element-detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
            <div *ngIf="element.movimientos.length !== undefined && element.movimientos.length > 0">
              <!-- <div fxLayout="column" *ngFor="let mov of element.movimientos">
                <span *ngIf="mov.numeroCupon != 'other Info'" style="margin-top: 5px; margin-bottom: 5px;">->
                  {{'    '+mov.obraSocial + ' - N° Cupón: ' + mov.numeroCupon + ' - ' + mov.paciente + ' - Motivo: ' + mov.turnoMotivo + ' - Importe: $'+mov.importe}}</span>
                  <span  *ngIf="mov.numeroCupon == 'other Info'" style="font-weight: bold; color: blue; margin-top: 5px; margin-bottom: 5px;">
                    {{mov.importe}}</span>
                </div> -->
                 <table class="custom-table">
                  <thead>
                    <tr>
                      <th>Obra Social</th>
                      <th>N° Cupón</th>
                      <th>Paciente</th>
                      <th>Motivo</th>
                      <th>Importe</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let mov of element.movimientos">
                      <tr *ngIf="mov.numeroCupon !== 'other Info'">
                        <td>{{ mov.obraSocial }}</td>
                        <td>{{ mov.numeroCupon }}</td>
                        <td>{{ mov.paciente }}</td>
                        <td>{{ mov.turnoMotivo }}</td>
                        <td>{{ '$' + mov.importe }}</td>
                      </tr>
                      <tr *ngIf="mov.numeroCupon === 'other Info'">
                        <td colspan="5" class="merged-cell">{{ mov.importe }}</td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- <tr mat-header-row *matHeaderRowDef="colliquiEfector"></tr>
      <tr mat-row *matRowDef="let row; columns: colliquiEfector;"></tr> -->

      <tr mat-header-row *matHeaderRowDef="colliquiEfector"></tr>
            <tr mat-row *matRowDef="let item; columns:colliquiEfector;" class="element-row sombreado-fila"
              [class.expanded-row]="expandedElement === item"
              (click)="expandedElement = expandedElement === item ? null : item"></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
    </table>
  </div>
