<mat-card class="tarjeta-Modal">
  <mat-card-header fxLayout="row" fxLayoutAlign="space-between start">
    <mat-card-title>Ingresar un telefono</mat-card-title>
    <div fxLayoutAlign="space-between center">
      <button mat-button (click)="guardar()" tabindex="-1" class="boton-titulo">
        <mat-icon >save</mat-icon>
      </button>
      <button mat-button (click)="dialogRef.close()" tabindex="-1" class="boton-titulo">
        <img class="rotado" src="\assets\images\icons\nuevo.svg" alt="Cerrar">
      </button>
    </div>
  </mat-card-header>
  <mat-card-content fxLayoutAlign="center center" fxLayout="row wrap" [formGroup]="telefonoForm"
    class="contenedor-datos" fxLayoutGap="10px">
    <mat-form-field fxFlex="28%">
      <mat-label>Tipo</mat-label>
      <mat-select formControlName="tipoTel">
        <mat-option *ngFor="let item of data.datosSelect.tiposTel" [value]='item.id'>{{item.nombre}}</mat-option>
      </mat-select>
      <mat-error
        *ngIf="telefonoForm.get('tipoTel').invalid && (telefonoForm.get('tipoTel').dirty || telefonoForm.get('tipoTel').touched)">
        <small *ngIf="telefonoForm.get('tipoTel').hasError('required')">
          Debe ingresar el tipo de telefono
        </small>
      </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="48%">
      <input matInput #foco type="text" placeholder="Número de telefono" formControlName="nroTel" autocomplete="off">
      <mat-error
        *ngIf="telefonoForm.get('nroTel').invalid && (telefonoForm.get('nroTel').dirty || telefonoForm.get('nroTel').touched)">
        <small *ngIf="telefonoForm.get('nroTel').hasError('required')">
          Debe ingresar el numero de telefono
        </small>
        <small *ngIf="telefonoForm.get('nroTel').hasError('pattern')">
          El numero de telefonono no puede contener letras
        </small>
        <small *ngIf="telefonoForm.get('nroTel').hasError('maxlength')">
          El numero de telefono no puede ser mayor a 50 caracteres
        </small>
      </mat-error>
    </mat-form-field>
  </mat-card-content>