import { AuthGuardService } from './services/helpers/auth-guard.service';
import { PrincipalComponent } from './components/base/principal/principal.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Us1StepsComponent } from './components/login/primer-usuario/us1-steps/us1-steps.component';
import { GuardiaContainerComponent } from './components/guardia/guardia-container/guardia-container.component';
import { CajaMedicoComponent } from './components/caja/caja-medico/caja-medico.component';
import { ShifterContainerComponent } from './components/shifter/shifter-container/shifter-container.component';
import { ShifterContainerSimpleComponent } from './components/shifter/shifter-container-simple/shifter-container-simple.component';

const routes: Routes = [
  {
    path: '',
    canActivateChild: [AuthGuardService],
    children: [
      {
        path: 'shifter-youtube',
        component: ShifterContainerComponent,
        loadChildren: () =>
          import('./components/shifter/shifter-container/shifter-container.module').then(
            (m) => m.ShifterContainerModule
          ),
        canActivateChild: []
      },
      {
        path: 'shifter',
        component: ShifterContainerSimpleComponent,
        loadChildren: () =>
          import('./components/shifter/shifter-container-simple/shifter-container-simple.module').then(
            (m) => m.ShifterContainerModule
          ),
        canActivateChild: []
      },
      {
        path: 'us1',
        loadChildren: () => import('./components/login/primer-usuario/us1-steps/us1-steps.module').then(m => m.Us1StepsModule)
      },
      { path: 'principal', component: PrincipalComponent },
      {
        path: 'empresa',
        loadChildren: () => import('./components/empresa/empresa-container/empresa-container.module').then(m => m.EmpresaContainerModule)
      },
      {
        path: 'caja',
        loadChildren: () => import('./components/caja/caja-container/caja-container.module').then(m => m.CajaContainerModule)
      },
      {
        path: 'cajaMedico',
        component: CajaMedicoComponent,
        loadChildren: () => import('./components/caja/caja-medico/caja-medico.module').then(m => m.CajaMedicoModule)
      },
      {
        path: 'facturacion',
        loadChildren: () =>
          import('./components/facturacion/facturacion-container/facturacion-container.module').then(m => m.FacturacionContainerModule)
      },
      {
        path: 'paciente',
        loadChildren: () =>
          import('./components/paciente/paciente-container/paciente-container.module').then(m => m.PacienteContainerModule)
      },
      {
        path: 'turnos',
        loadChildren: () => import('./components/turnos/turnos-container/turnos.module').then(m => m.TurnosModule)
      },
      { path: 'public', 
        loadChildren: () => import('./public/public.module').then(m => m.PublicModule) ,
      canActivateChild: []
      },
      {
        path: 'usuario',
        loadChildren: () => import('./components/usuario/usuario-container/usuario-container.module').then(m => m.UsuarioContainerModule)
      },
      {
        path: 'guardia',
        loadChildren: () => import('./components/guardia/guardia-container/guardia-container.module').then(m => m.GuardiaContainerModule)
      },
      {
        path: 'estadistica',
        loadChildren: () => import('./components/estadistica/estadistica-container/estadistica-container.module').then(m => m.EstadisticaContainerModule)
      },
      {
        path: 'nutricion',
        loadChildren: () => import('./components/nutricion/nutricion-container/nutricion-container.module').then(m => m.NutricionContainerModule)
      },
      {
        path: 'pacientelist',
        loadChildren: () =>
          import('./components/paciente/paciente-list-container/paciente-list-container.module').then(m => m.PacienteListContainerModule)
      },
      {
        path: 'quirurgicolist',
        loadChildren: () =>
          import('./components/quirurgico/quirurgico-list-container/quirurgico-list-container.module').then(m => m.QuirurgicoListContainerModule)
      }
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
