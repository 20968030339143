<nav *ngIf="loginServ.UsuarioLogueado && loginServ.UsuarioLogueado.roles" fxLayout="column nowrap"
  fxLayoutAlign="start center" style="z-index: 100; transition: width 0.5s;" id="sideMenu" class="menuAbierto menuvisibleAbierto">
  <div class="menu-item">
      <button mat-button  class="menu-icon" (click)="accesoMenu()" matTooltip="Menu" matTooltipPosition="after" >  
    <img src="/assets/images/menu_icon.png" >
  </button>
  </div>
  <div class="menu-item" style="margin: 20px 0px 0px 0px;">
  <button mat-button fxLayoutAlign="center center"
    (click)="clickPrincipal()" class="menu-item-button" tabindex="-1">
   
    <img src="/assets/images/icons/logoMenu.png"   alt="Logo">
   
    <img src="/assets/images/icons/logoTextMenu.png" style="transition: visibility 0.5s, opacity 0.5s linear;" class="menu-item-logoText" alt="Logo">
  </button>
  </div>
  <div class="sprator" style="transition: width 0.5s; margin: 15px 0px;"></div>
  <div class="menu-item">
    <button mat-button  (click)="clickAgenda()" class="menu-item-button"
      [ngClass]="route.url.startsWith('/turnos')?'tab-elegido':'tab-elegido-disabled'" tabindex="-1">
      <img src="/assets/images/icons/agendaIcon.png" matTooltip="{{this.menuAbierto?'':'Agenda'}}" matTooltipPosition="after"  alt="Logo">   
      <spam style="transition: visibility 0.5s, opacity 0.5s linear;" class="menu-item-logoText" alt="Logo">
        Agenda
      </spam>
    </button>
  </div>
  <div class="menu-item">
    <button mat-button  (click)="clickCaja()" class="menu-item-button" 
      [ngClass]="route.url.startsWith('/caja')?'tab-elegido':'tab-elegido-disabled'" tabindex="-1">
      <img src="/assets/images/icons/cajaIcon.png"  matTooltip="{{this.menuAbierto?'':'Caja'}}" matTooltipPosition="after"  alt="Logo">   
      <spam style="transition: visibility 0.5s, opacity 0.5s linear;" class="menu-item-logoText" alt="Logo">
        Caja
      </spam>

    </button>
  </div>
  <div class="menu-item">
    <button mat-button  (click)="clickFacturacion()" class="menu-item-button"
      [ngClass]="route.url.startsWith('/facturacion')?'tab-elegido':'tab-elegido-disabled'" tabindex="-1">
      <img src="/assets/images/icons/facturacionIcon.png" matTooltip="{{this.menuAbierto?'':'Facturacion'}}" matTooltipPosition="after"  alt="Logo">   
      <spam style="transition: visibility 0.5s, opacity 0.5s linear;" class="menu-item-logoText" alt="Logo">
        Facturacion
      </spam>
    </button>
    </div>
    <div class="menu-item" *ngIf="loginServ.ShowEstadistica">
    <button mat-button    (click)="clickEstadistica()"  class="menu-item-button"
      [ngClass]="route.url.startsWith('/estadistica')?'tab-elegido':'tab-elegido-disabled'" tabindex="-1">
      <img src="/assets/images/icons/estadisticasIcon.png" matTooltip="{{this.menuAbierto?'':'Estadísticas'}}" matTooltipPosition="after"  alt="Logo">   
      <spam style="transition: visibility 0.5s, opacity 0.5s linear;" class="menu-item-logoText" alt="Logo">
        Informes
      </spam>
    </button>
    </div>
    <div class="menu-item">
    <button mat-button  (click)="clickPacienteList()" class="menu-item-button"
      [ngClass]="route.url.startsWith('/pacientelist')?'tab-elegido':'tab-elegido-disabled'" tabindex="-1">
      <img src="/assets/images/icons/misPacientesIcon.png" matTooltip="{{this.menuAbierto?'':'Mis Pacientes'}}" matTooltipPosition="after"   alt="Logo">   
      <spam style="transition: visibility 0.5s, opacity 0.5s linear;" class="menu-item-logoText" alt="Logo">
        Mis Paciente
      </spam>
    </button>
    </div>
    <div class="menu-item" *ngIf="loginServ.IsNutritionist">
    <button mat-button    (click)="clickNutricion()" class="menu-item-button"
      [ngClass]="route.url.startsWith('/nutricion')?'tab-elegido':'tab-elegido-disabled'" tabindex="-1"  >
      <mat-icon class="iconos" matTooltip="{{this.menuAbierto?'':'Mis Dietas'}}" matTooltipPosition="after">
        restaurant
      </mat-icon>     
      <spam style="transition: visibility 0.5s, opacity 0.5s linear;" class="menu-item-logoText" alt="Logo">
        Nutricion
      </spam>
    </button>
    </div>
    <div class="menu-item" *ngIf="loginServ.ShowQuirurgico">
     <button mat-button    (click)="clickQuirurgicoList()" class="menu-item-button"
      [ngClass]="route.url.startsWith('/quirurgico')?'tab-elegido':'tab-elegido-disabled'" tabindex="-1"  >
      <mat-icon class="iconos" matTooltip="{{this.menuAbierto?'':'Quirurgico'}}" matTooltipPosition="after">
        healing
      </mat-icon>     
      <spam style="transition: visibility 0.5s, opacity 0.5s linear;" class="menu-item-logoText" alt="Logo">
        Quirurgico
      </spam>
     </button>
    </div>
  <!-- <mat-nav-list fxLayout="column nowrap" id="navbarSupportedContent"> -->
   

    <!-- <button mat-button matTooltipClass="navbar-tooltip" matTooltip="Guardia" matTooltipPosition="right"
      fxLayout="row nowrap" fxLayoutAlign="center " (click)="clickGuardia()"
      [ngClass]="route.url.startsWith('/guardia')?'tab-elegido':'tab-elegido-disabled'" tabindex="-1">
      <mat-icon class="iconos">
        add_alert
      </mat-icon>
    </button> -->
    <!-- <button mat-button matTooltipClass="navbar-tooltip" matTooltip="Guardia" matTooltipPosition="right"
      fxLayout="row nowrap" fxLayoutAlign="center " (click)="clickCosaCamara()" tabindex="-1">
      <mat-icon class="iconos">
        camera_enhance
      </mat-icon>
    </button> -->
  <!-- </mat-nav-list>
  <div id="mi-perfil">
    <app-busqueda-paciente @entradaBusqueda *ngIf="isShown" (cambiarEstadoNav)="toggleShow()" [estadoBusqueda]="isShown"
      style="z-index: -1;">
    </app-busqueda-paciente>
    <button mat-button matTooltipClass="navbar-tooltip" matTooltip="Personas" matTooltipPosition="right" fxLayout="row"
      fxLayoutAlign="center center" (click)="toggleShow()" (document:keyup.F2)="toggleShow()" tabindex="-1">
      <img src="/assets/images/icons/buscador_paciente.svg" class="iconos-buscador-paciente"> -->
      <!-- <mat-icon>
        folder_share
      </mat-icon> -->
    <!-- </button>
    <button mat-button [matMenuTriggerFor]="menu" tabindex="-1">
      <img
        [src]="loginServ.UsuarioLogueado.foto !== null?loginServ.UsuarioLogueado.fotoDatoBase64+ loginServ.UsuarioLogueado.foto: './assets/images/persona_upload.jpg'"
        alt="Perfil" class="perfil-nav" />
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item *ngIf="null|puedeConfigEmpresa" (click)="irEmpresa()" tabindex="-1">
        Configurar Empresa
      </button>
      <button mat-menu-item *ngIf="loginServ.usMultiplesSedes" (click)="cambiarSede()" tabindex="-1">
        Cambiar Sede
      </button> -->
      <!-- <button mat-menu-item (click)="cambiarTema()" tabindex="-1">
        {{themeServ.SelectedTheme === tiposTemas.LIGHT? 'Tema oscuro': 'Tema claro'}}
      </button> -->
      <!-- <button mat-menu-item (click)="irPerfil()" tabindex="-1">Perfil</button>
      <button mat-menu-item (click)="Logout()" tabindex="-1">Cerrar sesión</button>
    </mat-menu> 
   </div> -->
</nav>
