import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import {
  // CargarTarjetasEvolucionModel,
  EvolucionHCTarjetaModel,
  EvolucionHCTarjetaAgendaModel,
  AdjuntoEvolucionHCUpdateModel,
  AdjuntoEvolucionHCTarjetaModel
} from 'src/app/models/historia-clinica/hc-evolucion.model';
import { HttpParams, HttpClient } from '@angular/common/http';
import { APIReturnModel } from 'src/app/models/shared/api-return.model';
import { HistoriaClinicaApiConfig } from './historia-clinica-api-config';
import { map, tap } from 'rxjs/operators';
import { HttpHelperService } from '../helpers/http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class HcEvolucionesService {

  public editarNota = new EventEmitter();

  constructor(
    private http: HttpClient,
    private HttpHelpers: HttpHelperService
  ) { }

  public evolucionesCargar(pacienteUsId: number, procesoElegidoId: number, filtrarPorProceso: boolean, pagina: number)
    : Observable<EvolucionHCTarjetaModel[]> {
    let parametros = new HttpParams();
    parametros = parametros.append('pacienteUsId', pacienteUsId.toString());
    parametros = parametros.append('pagina', pagina.toString());
    if (filtrarPorProceso === true) {
      parametros = parametros.append('procesoId', procesoElegidoId.toString());
    }
    return this.http.get<APIReturnModel<EvolucionHCTarjetaModel[]>>(HistoriaClinicaApiConfig.HC_EVOLUCIONES_CARGAR_URL,
      { params: parametros })
      .pipe(map(result => result.datos));
  }

  public unaEvolucion(evolucionId: number): Observable<EvolucionHCTarjetaModel> {
    return this.http.get<APIReturnModel<EvolucionHCTarjetaModel>>(HistoriaClinicaApiConfig.HC_UNA_EVOLUCION_URL,
      this.HttpHelpers.buildQueryParams(evolucionId, 'evolucionId'))
      .pipe(map(result => result.datos));
  }
  public agendaLogueadoEvolucion(): Observable<EvolucionHCTarjetaAgendaModel> {
    return this.http.get<APIReturnModel<EvolucionHCTarjetaAgendaModel>>(HistoriaClinicaApiConfig.HC_AGENDA_LOGUEADO_EVOLUCION_URL)
      .pipe(map(result => result.datos));
  }
  public actualizarEvolucion(evolucion: EvolucionHCTarjetaModel): Observable<EvolucionHCTarjetaModel> {
    return this.http.put<APIReturnModel<EvolucionHCTarjetaModel>>(HistoriaClinicaApiConfig.HC_ACTUALIZAR_EVOLUCION_URL, evolucion)
      .pipe(map(result => result.datos));
  }
  public eliminarEvolucion(notaId: number): Observable<boolean> {
    return this.http.delete<APIReturnModel<boolean>>(HistoriaClinicaApiConfig.HC_ELIMINAR_EVOLUCION_URL,
      this.HttpHelpers.buildQueryParams(notaId, 'notaId'))
      .pipe(map(result => result.datos));
  }
  public unAdjuntoEvolucion(adjuntoId: number): Observable<AdjuntoEvolucionHCUpdateModel> {
    return this.http.get<APIReturnModel<AdjuntoEvolucionHCUpdateModel>>(HistoriaClinicaApiConfig.HC_UN_ADJUNTO_EVOLUCION_URL,
      this.HttpHelpers.buildQueryParams(adjuntoId, 'adjuntoId'))
      .pipe(map(result => result.datos));
  }
  public adjuntoUpdate(adjunto: AdjuntoEvolucionHCUpdateModel): Observable<AdjuntoEvolucionHCTarjetaModel> {
    return this.http.post<APIReturnModel<AdjuntoEvolucionHCTarjetaModel>>(HistoriaClinicaApiConfig.HC_ADJUNTO_UPDATE_EVOLUCION_URL, adjunto)
      .pipe(map(result => result.datos));
  }
}
