import { MatDialogModule } from '@angular/material/dialog';
import { PrivilegiosPipeModule } from './../../../pipes/privilegios.pipes';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { BusquedaPacienteModule } from './../busqueda-paciente/busqueda-paciente.module';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NavbarComponent } from './navbar.component';
import { ModalCamaraModule } from '../modal-camara/modal-camara.module';

@NgModule({
  declarations: [NavbarComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatListModule,
    MatTooltipModule,
    MatIconModule,
    BusquedaPacienteModule,
    MatMenuModule,
    RouterModule,
    PrivilegiosPipeModule,
    MatDialogModule,
    ModalCamaraModule
  ],
  exports: [NavbarComponent]
})
export class NavbarModule { }
