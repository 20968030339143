import { ABMPacienteDireccionesModel } from '../paciente/abm-paciente.model';
import { TipoAccionABMEnum } from './enums.model';

export class DireccionesModel {
  id: number;
  tipo: number;
  tipoStr: string;
  calle: string;
  numero: number;
  piso: string;
  depto: string;
  timbre: string;
  ciudad: string;
  codigoPostal: string;
  provincia: number;
  provinciaStr: string;
  tipoAccion: TipoAccionABMEnum;
  constructor() { }
  public static toABMPacienteDireccionModel(Direccion: DireccionesModel, personaId: number): ABMPacienteDireccionesModel {
    const DirPaciente = new ABMPacienteDireccionesModel();
    DirPaciente.tipoAccion = Direccion.tipoAccion;
    DirPaciente.calle = Direccion.calle;
    DirPaciente.ciudad = Direccion.ciudad;
    DirPaciente.codigoPostal = Direccion.codigoPostal;
    DirPaciente.depto = Direccion.depto;
    DirPaciente.id = Direccion.id;
    DirPaciente.numero = Direccion.numero;
    DirPaciente.personaId = personaId;
    DirPaciente.piso = Direccion.piso;
    DirPaciente.provincia = Direccion.provincia;
    DirPaciente.provinciaStr = Direccion.provinciaStr;
    DirPaciente.timbre = Direccion.timbre;
    DirPaciente.tipo = Direccion.tipo;
    DirPaciente.tipoStr = Direccion.tipoStr;
    return DirPaciente;
  }
}
