import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-starter',
  templateUrl: './starter.component.html',
  styleUrls: ['./starter.component.scss']
})
export class StarterComponent implements OnInit {
  public lottieConfig: AnimationOptions;
  @Output() terminaAnimacion: EventEmitter<any> = new EventEmitter();
  constructor() {
    this.lottieConfig = {
      path: 'assets/images/animaciones-json/loading.json',
      renderer: 'canvas',
      autoplay: true,
      loop: false
    };
  }

  ngOnInit() {
  }
  loopComplete(animationCompleted: AnimationItem): void {
    this.terminaAnimacion.emit(null);
  }

}
