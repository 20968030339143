
<mat-card fxFlex="100%" class="tarjeta-Modal"  >
  <mat-card-header fxLayout="row" fxLayoutAlign="space-between start">
    <mat-card-title>Datos de la direccion</mat-card-title>
    <div fxLayoutAlign="space-between center">
      <button mat-button (click)="guardar()" tabindex="-1" class="boton-titulo">
        <mat-icon >save</mat-icon>
      </button>
      <button mat-button (click)="dialogRef.close()" tabindex="-1" class="boton-titulo" >
        <img class="rotado" src="\assets\images\icons\nuevo.svg" alt="Cerrar">
      </button>
    </div>
  </mat-card-header>
  <mat-card-content fxLayout="row wrap" fxLayoutGap="5px" [formGroup]="direccionForm"
    fxLayoutAlign="space-between center" class="popup-direccion">
    <mat-form-field fxFlex="74%">
      <input matInput type="text" placeholder="Calle" formControlName="calle" autocomplete="off" required>
      <mat-error
        *ngIf="direccionForm.get('calle').invalid && (direccionForm.get('calle').dirty || direccionForm.get('calle').touched)">
        <small *ngIf="direccionForm.get('calle').hasError('required')">
          Debe ingresar la calle
        </small>
        <small *ngIf="direccionForm.get('calle').hasError('maxlength')">
          La calle no puede ser mayor a 100 caracteres
        </small>
      </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="24%">
      <input matInput type="text" placeholder="Número" formControlName="numero" autocomplete="off" required>
      <mat-error
        *ngIf="direccionForm.get('numero').invalid && (direccionForm.get('numero').dirty || direccionForm.get('numero').touched)">
        <small *ngIf="direccionForm.get('numero').hasError('required')">
          Debe ingresar el numero
        </small>
        <small *ngIf="direccionForm.get('numero').hasError('pattern')">
          El numero no puede contener letras
        </small>
        <small *ngIf="direccionForm.get('numero').hasError('maxlength')">
          El numero no puede ser mayor a 8 caracteres
        </small>
      </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="32%">
      <input matInput type="text" placeholder="Piso" formControlName="piso" autocomplete="off">
      <mat-error
        *ngIf="direccionForm.get('piso').invalid && (direccionForm.get('piso').dirty || direccionForm.get('piso').touched)">
        <small *ngIf="direccionForm.get('piso').hasError('maxlength')">
          El piso no puede ser mayor a 5 caracteres
        </small>
      </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="32%">
      <input matInput type="text" placeholder="Depto" formControlName="depto" autocomplete="off">
      <mat-error
        *ngIf="direccionForm.get('depto').invalid && (direccionForm.get('depto').dirty || direccionForm.get('depto').touched)">
        <small *ngIf="direccionForm.get('depto').hasError('maxlength')">
          El piso no puede ser mayor a 2 caracteres
        </small>
      </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="32%">
      <input matInput type="text" formControlName="timbre" placeholder="Oficina" autocomplete="off">
      <mat-error
        *ngIf="direccionForm.get('timbre').invalid && (direccionForm.get('timbre').dirty || direccionForm.get('timbre').touched)">
        <small *ngIf="direccionForm.get('timbre').hasError('maxlength')">
          El timbre no puede ser mayor a 10 caracteres
        </small>
      </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="74%">
      <mat-label>Provincia</mat-label>
      <mat-select formControlName="provincia" Placeholder="Provincia" required>
        <mat-option *ngFor="let item of data.datosSelect.provincias" [value]='item.id'>{{item.nombre}}</mat-option>
      </mat-select>
      <mat-error
        *ngIf="direccionForm.get('provincia').invalid && (direccionForm.get('provincia').dirty || direccionForm.get('provincia').touched)">
        <small *ngIf="direccionForm.get('provincia').hasError('required')">
          Debe elegir una provincia
        </small>
      </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="24%">
      <input matInput type="text" placeholder="CP" formControlName="cp" autocomplete="off">
      <mat-error
        *ngIf="direccionForm.get('cp').invalid && (direccionForm.get('cp').dirty || direccionForm.get('cp').touched)">
        <small *ngIf="direccionForm.get('cp').hasError('required')">
          Debe ingresar el codigo postal
        </small>
        <small *ngIf="direccionForm.get('cp').hasError('pattern')">
          El codigo postal no puede contener letras
        </small>
        <small *ngIf="direccionForm.get('cp').hasError('maxlength')">
          El codigo postal no puede ser mayor a 4 caracteres
        </small>
      </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="100%">
      <input matInput type="text" formControlName="ciudad" placeholder="Ciudad" autocomplete="off">
      <mat-error
        *ngIf="direccionForm.get('ciudad').invalid && (direccionForm.get('ciudad').dirty || direccionForm.get('ciudad').touched)">
        <small *ngIf="direccionForm.get('ciudad').hasError('required')">
          Debe ingresar la ciudad
        </small>
        <small *ngIf="direccionForm.get('ciudad').hasError('pattern')">
          La ciudad no puede contener numeros
        </small>
        <small *ngIf="direccionForm.get('ciudad').hasError('maxlength')">
          La ciudad no puede ser mayor a 100 caracteres
        </small>
      </mat-error>
    </mat-form-field>
    <span class="campos-obligatorios">Los campos marcados con (*) son obligatorios</span>
  </mat-card-content>
