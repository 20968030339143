import { RecordatoriosService } from './recordatorios.service';
import { HttpHelperService } from './../helpers/http-helper.service';
import { SelectObjModel } from './../../models/shared/selects.model';
import { PersonaValidarEmailModel, PersonaValidarDocModel } from '../../models/shared/persona-validar.model';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LoginService } from '../login/login.service';
import { CtaCteService } from '../cta-cte/cta-cte.service';
import { HistoriaClinicaService } from '../historia-clinica/historia-clinica.service';
import { TurnosService } from '../turnos/turnos.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { APIReturnModel } from 'src/app/models/shared/api-return.model';
import { DireccionesModel } from 'src/app/models/shared/direcciones.model';
import {
  ABMPacienteListModel,
  ABMPacienteUpdateModel,
  ABMPacienteTelefonoModel,
  ABMPacienteDireccionesModel,
  ABMPacienteUpdateUsuarioPlanModel,
  PacienteDocModel
} from 'src/app/models/paciente/abm-paciente.model';
import { TelefonoModel } from 'src/app/models/shared/telefono.model';
import { PacienteApiConfig } from './paciente-api-config';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModalModuloComponent } from 'src/app/components/shared/modal-modulo/modal-modulo.component';
import { ModulosModalEnum } from 'src/app/models/shared/modulos-modal.enum';
import { ABMGenericoModel, ABMGenericoConsultaModel } from 'src/app/models/shared/abm-generico.model';
import { PacienteDeudaModel, PacienteFiltroLista, PacienteFiltroQueryParams, PacienteMailDeudaConsultaModel, PacienteVerificarMailConsultaModel } from 'src/app/models/paciente/paciente-filtro-model';
import { DatosPersonaHeaderModel } from 'src/app/models/shared/persona.model';

@Injectable({
  providedIn: 'root'
})
export class PacienteService {
  public pacienteUpdateEv = new EventEmitter();
  public pacienteBusquedaEv = new EventEmitter();
  public pacienteHistoricoOA = new EventEmitter();
  public irCTACTEev = new EventEmitter();
  public pacientesBusqueda = new ABMGenericoModel<ABMPacienteListModel>();
  public PacienteUpdate = new ABMPacienteUpdateModel();
  public mostrarOpciones = true;

  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private loginServ: LoginService,
    private ctaServ: CtaCteService,
    private hcServ: HistoriaClinicaService,
    private turnosServ: TurnosService,
    private recordServ: RecordatoriosService,
    private route: Router,
    private modalpage: MatDialog,
    private HttpHelpers: HttpHelperService
  ) {
  }
  // METODOS
  async irRecordatorios(pacienteElegidoId: number): Promise<MatDialogRef<ModalModuloComponent, any>> {
    if (this.loginServ.checkearPrivilegio(this.loginServ.privilegios.VISUALIZAR_RECORDATORIOS_DEL_PACIENTE)) {
      const resultado = await this.recordServ.listar(pacienteElegidoId).toPromise();
      if (resultado) {
        return this.modalpage.open(ModalModuloComponent, {
          panelClass: 'custom-dialog-container-auto',
          data: { modulo: ModulosModalEnum.RECORDATORIOS, recordatorios: resultado }
        });
      }
    } else {
      return null;
    }
  }
  irHC(pacienteId: number, turnoIdActual: number = 0) {
    if (this.loginServ.checkearPrivilegio(this.loginServ.privilegios.ACCESO_A_HISTORIA_CLINICA)) {
      this.hcServ.pacienteUsId = pacienteId;
      this.hcServ.tieneAgenda = this.loginServ.datosHeader.agendaLogId ? true : false;
      this.hcServ.datosHC().toPromise().then(result => {
        if (!this.loginServ.datosHeader.agendaLogId && result.procesos.length === 0) {
          this.snackBar.open('El paicente no tiene historia clinica, debe crearla alguien con agenda asignada');
        }
        this.hcServ.idTurno = turnoIdActual;
        this.modalpage.open(ModalModuloComponent, {
          maxWidth: '100vw',
          maxHeight: '100vh',
          panelClass: 'modulo-dialog-container',
          data: {
            modulo: ModulosModalEnum.HISTORIA_CLINICA,
            datosHC: result
          }
        }).afterClosed().subscribe(() => {
          this.hcServ.filtrarPorProceso = true;
        });
      });
    }
    // } else {
    //   this.snackBar.open('No puede ingresar a historia clinica ya que no tiene una agenda asignada');
    // }
  }
  irCtaCte(pacienteId: number) {
    if (this.loginServ.checkearPrivilegio(this.loginServ.privilegios.ACCESO_A_CTA_CTE)) {
      this.pacienteSelect(pacienteId).toPromise().then((paciente: SelectObjModel) => {
        this.ctaServ.pacienteUs = paciente;
        this.ctaServ.detalles(new ABMGenericoConsultaModel('', 0, 'cuenta', 'asc')).toPromise().then(x => {
          const modal = this.modalpage.open(ModalModuloComponent, {
            maxWidth: '100vw',
            maxHeight: '100vh',
            panelClass: 'modulo-dialog-container',
            data: {
              modulo: ModulosModalEnum.CTA_CTE,
              datosCta: x
            }
          });
          modal.afterClosed().subscribe(x => {
            this.irCTACTEev.emit();
          });
        });
      });
    }
  }
  darTurno(pacienteId: number) {
    if (this.loginServ.checkearPrivilegio(this.loginServ.privilegios.ADMINISTRAR_TURNOS)) {
      this.turnosServ.pacienteBusqueda(pacienteId).toPromise().then(x => {
        this.turnosServ.pacienteElegido = x;
        this.turnosServ.fechaAgenda = new Date();
        // si el usuario es agenda entra con esa agenda elegido
        if (this.loginServ.datosHeader.agendaLogId) {
          this.loginServ.agendaUsLogueado().toPromise().then(result => {
            if (result) {
              this.turnosServ.agendaElegida = result;
              this.route.navigate(['/turnos/turnos-agenda'], { skipLocationChange: true });
            } else {
              this.turnosServ.agendaElegida = null;
              this.route.navigate(['/turnos/buscador-agendas'], { skipLocationChange: true });
            }
          });
        } else {
          this.turnosServ.agendaElegida = null;
          this.route.navigate(['/turnos/buscador-agendas'], { skipLocationChange: true });
        }
      });
    }
  }

  public actualizarPaciente(paciente: ABMPacienteUpdateModel): Observable<ABMPacienteListModel> {
    return this.http.put<APIReturnModel<ABMPacienteListModel>>(PacienteApiConfig.PACIENTE_UPDATE_URL, paciente)
      .pipe(map(result => result.datos));
  }
  public eliminarPaciente(pacienteId: number): Observable<string> {
    return this.http.delete<APIReturnModel<string>>(PacienteApiConfig.PACIENTE_ELIMINAR_URL,
      this.HttpHelpers.buildQueryParams(pacienteId, 'pacienteId'))
      .pipe(map(result => result.datos));
  }
  public actualizarTelefono(tel: ABMPacienteTelefonoModel): Observable<TelefonoModel> {
    return this.http.put<APIReturnModel<TelefonoModel>>(PacienteApiConfig.PACIENTE_TELEFONO_UPDATE_URL, tel)
      .pipe(map(result => result.datos));
  }
  public eliminarTelefono(telId: number): Observable<string> {
    return this.http.delete<APIReturnModel<string>>(PacienteApiConfig.PACIENTE_TELEFONO_ELIMINAR_URL,
      this.HttpHelpers.buildQueryParams(telId, 'telId'))
      .pipe(map(result => result.datos));
  }
  public desactivarTelefono(telId: number): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(PacienteApiConfig.PACIENTE_TELEFONO_DESACTIVAR_URL, telId)
      .pipe(map(result => result.datos));
  }
  public activarTelefono(telId: number): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(PacienteApiConfig.PACIENTE_TELEFONO_ACTIVAR_URL, telId)
      .pipe(map(result => result.datos));
  }
  public actualizarDireccion(dir: ABMPacienteDireccionesModel): Observable<DireccionesModel> {
    return this.http.put<APIReturnModel<DireccionesModel>>(PacienteApiConfig.PACIENTE_DIRECCION_UPDATE_URL, dir)
      .pipe(map(result => result.datos));
  }
  public eliminarDireccion(dirId: number): Observable<string> {
    return this.http.delete<APIReturnModel<string>>(PacienteApiConfig.PACIENTE_DIRECCION_ELIMINAR_URL,
      this.HttpHelpers.buildQueryParams(dirId, 'dirId'))
      .pipe(map(result => result.datos));
  }
  public desactivarDireccion(dirId: number): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(PacienteApiConfig.PACIENTE_DIRECCION_DESACTIVAR_URL, dirId)
      .pipe(map(result => result.datos));
  }
  public activarDireccion(dirId: number): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(PacienteApiConfig.PACIENTE_DIRECCION_ACTIVAR_URL, dirId)
      .pipe(map(result => result.datos));
  }
  public actualizarPlan(plan: ABMPacienteUpdateUsuarioPlanModel): Observable<ABMPacienteUpdateUsuarioPlanModel> {
    return this.http.put<APIReturnModel<ABMPacienteUpdateUsuarioPlanModel>>(PacienteApiConfig.PACIENTE_PLAN_UPDATE_URL, plan)
      .pipe(map(result => result.datos));
  }
  public desactivarPlan(planId: number): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(PacienteApiConfig.PACIENTE_PLAN_DESACTIVAR_URL, planId)
      .pipe(map(result => result.datos));
  }
  public activarPlan(planId: number): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(PacienteApiConfig.PACIENTE_PLAN_ACTIVAR_URL, planId)
      .pipe(map(result => result.datos));
  }
  public eliminarPlan(planId: number): Observable<string> {
    return this.http.delete<APIReturnModel<string>>(PacienteApiConfig.PACIENTE_PLAN_ELIMINAR_URL,
      this.HttpHelpers.buildQueryParams(planId, 'planId'))
      .pipe(map(result => result.datos));
  }
  public list(): Observable<ABMPacienteListModel[]> {
    return this.http.get<APIReturnModel<ABMPacienteListModel[]>>(PacienteApiConfig.PACIENTE_LIST_URL)
      .pipe(map(result => result.datos));
  }
  public uno(pacienteId: number): Observable<ABMPacienteUpdateModel> {
    return this.http.get<APIReturnModel<ABMPacienteUpdateModel>>(PacienteApiConfig.PACIENTE_UNO_URL,
      this.HttpHelpers.buildQueryParams(pacienteId, 'pacienteId'))
      .pipe(map(result => result.datos));
  }
  public unoHeader(pacienteId: number): Observable<DatosPersonaHeaderModel> {
    return this.http.get<APIReturnModel<DatosPersonaHeaderModel>>(PacienteApiConfig.PACIENTE_UNO_HEADER_URL,
      this.HttpHelpers.buildQueryParams(pacienteId, 'pacienteId'))
      .pipe(map(result => result.datos));
  }
  public unoSinAuth(pacienteId: number): Observable<ABMPacienteUpdateModel> {
    return this.http.get<APIReturnModel<ABMPacienteUpdateModel>>(PacienteApiConfig.PACIENTE_UNO_SIN_AUTH_URL,
      this.HttpHelpers.buildQueryParams(pacienteId, 'pacienteId'))
      .pipe(map(result => result.datos));
  }
  public porDoc(datos: PacienteDocModel): Observable<ABMPacienteUpdateModel> {
    return this.http.get<APIReturnModel<ABMPacienteUpdateModel>>(PacienteApiConfig.PACIENTE_PORDOC_URL,
      this.HttpHelpers.buildQueryParams(datos))
      .pipe(map(result => result.datos));
  }
  public nroDocExiste(datosPac: PersonaValidarDocModel): Observable<ABMPacienteListModel[]> {
    return this.http.get<APIReturnModel<ABMPacienteListModel[]>>(PacienteApiConfig.PACIENTE_NRODOCEXISTE_URL,
      this.HttpHelpers.buildQueryParams(datosPac))
      .pipe(map(result => result.datos));
  }
  public emailExiste(datosPac: PersonaValidarEmailModel): Observable<ABMPacienteUpdateModel> {
    return this.http.get<APIReturnModel<ABMPacienteUpdateModel>>(PacienteApiConfig.PACIENTE_EMAILEXISTE_URL,
      this.HttpHelpers.buildQueryParams(datosPac))
      .pipe(map(result => result.datos));
  }
  public busquedaSelect(busqueda: string): Observable<SelectObjModel[]> {
    return this.http.get<APIReturnModel<SelectObjModel[]>>(PacienteApiConfig.PACIENTE_BUSQUEDA_SELECT_URL,
      this.HttpHelpers.buildQueryParams(busqueda, 'busqueda'))
      .pipe(map(result => result.datos));
  }

  public querySoloPte(busqueda: string): Observable<SelectObjModel[]> {
    return this.http.get<APIReturnModel<SelectObjModel[]>>(PacienteApiConfig.PACIENTE_QUERY_SOLO_PTE_URL,
      this.HttpHelpers.buildQueryParams(busqueda, 'busqueda'))
      .pipe(map(result => result.datos));
  }

  public pacienteSelect(id: number): Observable<SelectObjModel> {
    return this.http.get<APIReturnModel<SelectObjModel>>(PacienteApiConfig.PACIENTE_SELECT_URL,
      this.HttpHelpers.buildQueryParams(id, 'id'))
      .pipe(map(result => result.datos));
  }
  public busquedaList(datosConsulta: ABMGenericoConsultaModel): Observable<ABMGenericoModel<ABMPacienteListModel>> {
    return this.http.get<APIReturnModel<ABMGenericoModel<ABMPacienteListModel>>>(PacienteApiConfig.PACIENTE_BUSQUEDA_LIST_URL,
      this.HttpHelpers.buildQueryParams(datosConsulta))
      .pipe(map(result => result.datos));
  }
  public hayPacientesBusqueda(busqueda: string): Observable<number> {
    return this.http.get<APIReturnModel<number>>(PacienteApiConfig.PACIENTE_HAY_PACIENTES_BUSQUEDA_URL,
      this.HttpHelpers.buildQueryParams(busqueda, 'busqueda'))
      .pipe(map(result => result.datos));
  }
  public pacienteFiltro(queryParams:PacienteFiltroQueryParams): Observable<PacienteFiltroLista> {
    return this.http.get<APIReturnModel<PacienteFiltroLista>>(PacienteApiConfig.PACIENTE_BUSCAR_POR_FILTRO,
      this.HttpHelpers.buildQueryParams(queryParams))
      .pipe(map(result => result.datos));
  }
  public pacienteCumpleanos(usuarioId: number): Observable<boolean> {
    return this.http.get<APIReturnModel<boolean>>(PacienteApiConfig.PACIENTE_MAIL_POR_CUMPLEANOS,
      this.HttpHelpers.buildQueryParams(usuarioId, 'usuarioId'))
      .pipe(map(result => result.datos));
  }
  public pacienteDeuda(): Observable<PacienteDeudaModel[]> {
    return this.http.get<APIReturnModel<PacienteDeudaModel[]>>(PacienteApiConfig.PACIENTE_DEUDA_URL)
      .pipe(map(result => result.datos));
  }
  public pacienteMailDeuda(parametro: PacienteMailDeudaConsultaModel): Observable<boolean> {
    return this.http.get<APIReturnModel<boolean>>(PacienteApiConfig.PACIENTE_MAIL_DEUDA_URL,
      this.HttpHelpers.buildQueryParams(parametro))
      .pipe(map(result => result.datos));
  }
  public validarMailPersona(parametro: PacienteVerificarMailConsultaModel): Observable<boolean> {
    return this.http.get<APIReturnModel<boolean>>(PacienteApiConfig.PACIENTE_VERIFICAR_SI_EXISTE_EMAIL,
      this.HttpHelpers.buildQueryParams(parametro))
      .pipe(map(result => result.datos));
  }

  public ValidarMailWizard(parametro: PacienteVerificarMailConsultaModel): Observable<boolean> {
    return this.http.get<APIReturnModel<boolean>>(PacienteApiConfig.PACIENTE_VERIFICAR_EMAIL_WIZARD_URL,
      this.HttpHelpers.buildQueryParams(parametro))
      .pipe(map(result => result.datos));
  }
}
