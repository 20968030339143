<mat-card class="tarjeta-Modal">
  <mat-card-header fxLayout="row" fxLayoutAlign="space-between start">
    <mat-card-title> Ingresar un usuario </mat-card-title>
    <div fxLayoutAlign="space-between center">
      <button mat-button (click)="dialogRef.close()" class="boton-titulo">
        <img class="rotado" src="\assets\images\icons\nuevo.svg" alt="Cerrar">
      </button>
    </div>
  </mat-card-header>
  <mat-card-content fxLayout="row wrap" [formGroup]="usuarioForm" class="contenedor-recup-pass"
    style="width: 25vw; height: 25vh; align-items: center; justify-content: center;">
    <span>Ingrese su nombre de usuario o e-mail, el sistema le enviara un e-mail con instrucciones para recuperar su contraseña a
      la direccion registrada</span>
    <mat-form-field style="width: 90%;">
      <input matInput #foco placeholder="Usuario/E-mail" formControlName="usuario" autocomplete="false">
      <mat-error
        *ngIf="usuarioForm.get('usuario').invalid && (usuarioForm.get('usuario').dirty || usuarioForm.get('usuario').touched)">
        <small *ngIf="usuarioForm.get('usuario').hasError('required')">
          Debe ingresar un nombre de usuario o e-mail
        </small>
      </mat-error>
    </mat-form-field>
    <div fxLayout="row" fxLayoutAlign="center" style="width: 290px; padding-top: 10px;">
      <button mat-raised-button class="btn-guardar" (click)="confirmar()">Confirmar</button>
    </div>
  </mat-card-content>
