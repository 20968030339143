import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIReturnModel } from 'src/app/models/shared/api-return.model';
import { map } from 'rxjs/operators';
import { RecetaApiConfig } from './receta-api-config';
import { ConsultarDiagnosticoInput, ObtenerOfertaTextosResponseModel, OfertaTextoDetalleModel } from 'src/app/models/shared/receta-models';
import { HttpHelperService } from '../helpers/http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class DiagnosticoService {

  constructor(
    private http: HttpClient,
    private HttpHelpers: HttpHelperService
  ) { }

    
  // Method for ConsultarDiagnosticoProblema
  // Method for ConsultarDiagnosticoProblema
  public consultarDiagnosticoProblemaofertaTexto(  texto: string | ConsultarDiagnosticoInput): Observable<OfertaTextoDetalleModel[]> {
    // Convert the string to a ConsultarDiagnosticoInput model if necessary
    const model = typeof texto === 'string' ? { texto } : texto;
  
    return this.http.put<APIReturnModel<ObtenerOfertaTextosResponseModel>>(
      RecetaApiConfig.CONSULTAR_DIAGNOSTICO_PROBLEMA_URL,
      model
    ).pipe(map(result => result.datos.ofertaTextoDetalle));
  }
  public consultarDiagnosticoProblema(texto: ConsultarDiagnosticoInput): Observable<ObtenerOfertaTextosResponseModel> {
    return this.http.put<APIReturnModel<ObtenerOfertaTextosResponseModel>>(
      RecetaApiConfig.CONSULTAR_DIAGNOSTICO_PROBLEMA_URL,texto
    ).pipe(map(result => result.datos));
  }

  // Method for ConsultarDiagnosticoProcedimiento
  public consultarDiagnosticoProcedimiento(texto: ConsultarDiagnosticoInput): Observable<ObtenerOfertaTextosResponseModel> {
    return this.http.get<APIReturnModel<ObtenerOfertaTextosResponseModel>>(
      RecetaApiConfig.CONSULTAR_DIAGNOSTICO_PROCEDIMIENTO_URL,
      this.HttpHelpers.buildQueryParams(texto)
    ).pipe(map(result => result.datos));
  }

  // Method for ConsultarDiagnosticoPractica
  public consultarDiagnosticoPractica(texto: ConsultarDiagnosticoInput): Observable<OfertaTextoDetalleModel[]> {
    // Convert the string to a ConsultarDiagnosticoInput model if necessary
    const model = typeof texto === 'string' ? { texto } : texto;

    return this.http.get<APIReturnModel<ObtenerOfertaTextosResponseModel>>(
      RecetaApiConfig.CONSULTAR_DIAGNOSTICO_PRACTICA_URL,
      this.HttpHelpers.buildQueryParams(texto)
    ).pipe(map(result => result.datos.ofertaTextoDetalle));
  }
}
