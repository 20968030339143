import { environment } from 'src/environments/environment';

export class ShifterApiConfig {
  // TURNOS
  public static TURNOS_CURRENT_URL = environment.apiUrl + 'Turnos/CurrentTurno';

  public static EMPRESA_LIST_URL = environment.apiUrl + 'Empresa/EmpresaFullList';
  public static EMPRESA_SEDE_LIST_URL = environment.apiUrl + 'Empresa/EmpresaSedeList';
  
}
