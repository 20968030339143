import { TipoParentescoEnum } from './../shared/enums.model';
import { TipoAccionABMEnum } from '../shared/enums.model';
import { PersonaModel } from '../shared/persona.model';

export class ABMPacienteListModel {
  id: number;
  nombre: string;
  apellido: string;
  email: string;
  constructor() { }
}

export class ABMPacienteUpdateModel {
  id: number;

  persona: PersonaModel;

  usuariosPlanes: Array<ABMPacienteUpdateUsuarioPlanModel>;

  constructor() {
    this.id = 0;
    this.persona = new PersonaModel();
    this.usuariosPlanes = new Array<ABMPacienteUpdateUsuarioPlanModel>();
  }
}
export class ABMPacienteUpdateUsuarioPlanModel {
  id: number;
  numeroAsociado: string;
  esTitular: TipoParentescoEnum;
  planId: number;
  obraId: number;
  obraNombre: string;
  planNombre: string;
  usuarioId: number;
  tipoAccion: TipoAccionABMEnum;
  otraInfo:string;
  constructor() { }
}
export class ABMPacienteDireccionesModel {
  id: number;
  tipo: number;
  tipoStr: string;
  tipoAccion: TipoAccionABMEnum;
  calle: string;
  numero: number;
  piso: string;
  depto: string;
  timbre: string;
  ciudad: string;
  codigoPostal: string;
  provincia: number;
  provinciaStr: string;
  personaId: number;
  constructor() { }
}
export class ABMPacienteTelefonoModel {
  id: number;
  tipoAccion: TipoAccionABMEnum;
  numero: string;
  tipo: number;
  tipoStr: string;
  personaId: number;
  activo:boolean =true;
  constructor() { }
}
export class PacienteDocModel {
  tipo: number;
  numero: string;
}
