import { ArrayHelperService } from 'src/app/services/helpers/array-helper.service';
import { UsuarioRecuperaPassModel, EmpresasUsuarioModel } from './../../models/login/usuarioLogin.model';
import { HttpHelperService } from './../helpers/http-helper.service';
import { LoginModel } from '../../models/login/login.model';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  UsuarioLoginModel, SedesUsuarioModel, DatosElegirSedeModel,
  RequestHeaderModel, UsuarioCambioPassModel
} from 'src/app/models/login/usuarioLogin.model';
import { PrivilegiosEnum } from 'src/app/models/shared/privilegios.enum';
import { TemasEnum } from 'src/app/models/shared/enums.model';
import { APIReturnModel } from 'src/app/models/shared/api-return.model';
import { ElegirAgendaModel } from 'src/app/models/turnos/elegir-agenda.model';
import { LoginApiConfig } from './login-api-config';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AgendasService } from 'src/app/services/empresa/agendas.service';  
import { HcEvolucionesService } from 'src/app/services/historia-clinica/hc-evoluciones.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  // Logged User Data
  public datosHeader = new RequestHeaderModel();
  public UsuarioLogueado: UsuarioLoginModel = null;
  public privilegios = PrivilegiosEnum;
  public usMultiplesSedes = false;
  public abreModuloNutricion = false;
  public ShowEstadistica = false;
  public ShowQuirurgico = false;
  public IsNutritionist = false;
  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private HttpHelpers: HttpHelperService,
    private ArrayHelper: ArrayHelperService,
    private evoServ: HcEvolucionesService,
    private agendaServ: AgendasService
  ) { }

  usuarioLoginEvent = new EventEmitter<LoginModel>();
  sedeLoginEvent = new EventEmitter<SedesUsuarioModel>();
  logOutEvent = new EventEmitter();
  cambiarSedeEvent = new EventEmitter();
  actualizarMenuEvent = new EventEmitter();

  public conseguirHeaders() {
    return this.http.get(LoginApiConfig.LOGIN_CONSEGUIR_HEADERS_URL);
  }
  async getIpAddress() {
    // save result
    const result = this.http.get<any>('https://api.ipify.org?format=json')
      .pipe(map(x => x.ip)).toPromise();

    return result;
  }

  public loginUsuario(login: LoginModel): Observable<UsuarioLoginModel> {
    return this.http.post<APIReturnModel<UsuarioLoginModel>>(LoginApiConfig.LOGIN_USUARIO_URL, login)
      .pipe(map(result => result.datos));
  }
  public esAdmin(): boolean {
    return this.UsuarioLogueado.roles.findIndex(x => x.esAdmin === true) > -1;
  }
  public checkearPrivilegio(privilegio: PrivilegiosEnum ,mostrarError=true): boolean {
    if (this.UsuarioLogueado.roles.findIndex(x => x.esAdmin === true) === -1) {
      if (this.ArrayHelper.selectMany(this.UsuarioLogueado.roles, 'privilegios')
        .findIndex(z => z === privilegio) > -1) {
        return true;
      } else {
        if(mostrarError)
        {
        this.snackBar.open('No tiene los privilegios necesarios para esta funcion.');
        }
        return false;
      }
    } else {
      return true;
    }
  }
  public checkearPrivilegioSinMsj(privilegio: PrivilegiosEnum): boolean {
    if (this.UsuarioLogueado.roles.findIndex(x => x.esAdmin === true) === -1) {
      if (this.ArrayHelper.selectMany(this.UsuarioLogueado.roles, 'privilegios')
        .findIndex(z => z === privilegio) > -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
  public validateEstadistica():boolean {
    if(this.UsuarioLogueado)
    {
      if(this.esAdmin())
      {
        return true;
      }

      const privilegios = this.ArrayHelper.selectMany(this.UsuarioLogueado.roles, 'privilegios');
      if (privilegios.findIndex(z => z === this.privilegios.VER_ESTADISTICA) > -1) 
      { 
        return true;
      }
      else
      {
        return false;
      }
    }
    else {
      return false;
    }
    // if(this.UsuarioLogueado)
    // {
    //   return this.UsuarioLogueado.roles.some(a=> a.id == 3028 || a.id == 3030 || a.id ==1 ); 
    // }
    // else
    // {
    //   return false;
    // }
      
 }
  public validateQuirurgico(): boolean {
    if (this.UsuarioLogueado) {
      if (this.esAdmin()) {
        return true;
      }

      const privilegios = this.ArrayHelper.selectMany(this.UsuarioLogueado.roles, 'privilegios');
      if (privilegios.findIndex(z => z === this.privilegios.LIBRO_QUIRURGICO) > -1) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }

  }
 public checkNutritionist():boolean{
  if (this.datosHeader.agendaLogId !== undefined && this.datosHeader.agendaLogId !== null) {
    this.evoServ.agendaLogueadoEvolucion().toPromise().then(y => {
      this.agendaServ.uno(y.agendaId).toPromise().then(x => {
        if (x) {
          // nutricion || x.especialidadId === 1035 || x.especialidadId ==1074
          if (x.especialidadId === 1058 ) {
            this.IsNutritionist = true;
            return  true;
          } else {
            this.IsNutritionist = false;
            return false;
          }

        } else {
          this.IsNutritionist = false;
          return false;
        }
      });
    });
      }
      this.IsNutritionist = false;
      return false;
}
  public empresaRutaSegunPriv(): string {
    if (this.UsuarioLogueado.roles.findIndex(x => x.esAdmin === true) === -1) {
      const privilegios = this.ArrayHelper.selectMany(this.UsuarioLogueado.roles, 'privilegios');      
        return '/empresa/lugar'; 
      if (privilegios.findIndex(z => z === this.privilegios.LISTA_DE_ROLES) > -1) {
        return '/empresa/rol';
      } else if (privilegios.findIndex(z => z === this.privilegios.LISTA_PERSONAL) > -1) {
        return '/empresa/personal';
      } else if (privilegios.findIndex(z => z === this.privilegios.LISTA_DE_AGENDAS) > -1) {
        return '/empresa/agenda';
      } else if (privilegios.findIndex(z => z === this.privilegios.LISTA_DE_OFERTA_PRESTACIONAL) > -1) {
        return '/empresa/oferta-prestacional';
      } else if (privilegios.findIndex(z => z === this.privilegios.LISTA_DE_ESPECIALIDADES) > -1) {
        return '/empresa/especialidad';
      } else if (privilegios.findIndex(z => z === this.privilegios.LISTA_DE_BANCOS) > -1) {
        return '/empresa/banco';
      } else if (privilegios.findIndex(z => z === this.privilegios.LISTA_DE_TARJETAS) > -1) {
        return '/empresa/tarjeta';
      } else if (privilegios.findIndex(z => z === this.privilegios.LISTA_DE_FACTURADORES) > -1) {
        return '/empresa/facturador';
      }
    } else {
      return '/empresa/update';
    }
  }
  public cajaRutaSegunPriv(): string {
    if (this.UsuarioLogueado.roles.findIndex(x => x.esAdmin === true) === -1) {
      const privilegios = this.ArrayHelper.selectMany(this.UsuarioLogueado.roles, 'privilegios');
      if (privilegios.findIndex(z => z === this.privilegios.ACCESO_A_COMPROBANTES_EN_CAJA) > -1) {
        return '/caja/comprobantes';
      } else if (privilegios.findIndex(z => z === this.privilegios.LISTA_DE_CUENTAS_EN_CAJA) > -1) {
        return '/caja/cuentas';
      } else if (privilegios.findIndex(z => z === this.privilegios.LISTA_DE_CLIENTES_EN_CAJA) > -1) {
        return '/caja/clientes';
      } else if (privilegios.findIndex(z => z === this.privilegios.LISTA_DE_ACREEDORES_EN_CAJA) > -1) {
        return '/caja/acreedores';
      } else if (privilegios.findIndex(z => z === this.privilegios.ES_AUDITOR_CAJAS) > -1) {
        return '/caja/control-general';
      }
    }
  }
  // public facturacionRutaSegunPriv(): string {
  //   if (this.UsuarioLogueado.roles.findIndex(x => x.esAdmin === true) === -1) {
  //     const privilegios = this.ArrayHelper.selectMany(this.UsuarioLogueado.roles, 'privilegios');
  //     if (privilegios.findIndex(z => z === this.privilegios.ACCESO_A_COMPROBANTES_EN_CAJA) > -1) {
  //       return '/facturacion/contratos';
  //     } else if (privilegios.findIndex(z => z === this.privilegios.LISTA_DE_CUENTAS_EN_CAJA) > -1) {
  //       return '/facturacion/archivos';
  //     } else if (privilegios.findIndex(z => z === this.privilegios.LISTA_DE_CLIENTES_EN_CAJA) > -1) {
  //       return '/facturacion/auditorias';
  //     } else if (privilegios.findIndex(z => z === this.privilegios.ES_AUDITOR_CAJAS) > -1) {
  //       return '/facturacion/resumenes';
  //     }
  //   }
  // }
  public checkearPrivilegioRuta(ruta: string): boolean {
    if (this.UsuarioLogueado) {
      if (this.UsuarioLogueado.roles.findIndex(x => x.esAdmin === true) === -1) {
        switch (ruta) {
          case '/empresa/update':
            return this.esAdmin();
          case '/empresa/rol':
            return this.checkearPrivilegio(PrivilegiosEnum.LISTA_DE_ROLES);
          case '/empresa/personal':
            return this.checkearPrivilegio(PrivilegiosEnum.LISTA_PERSONAL);
          case '/empresa/agendas':
            return this.checkearPrivilegio(PrivilegiosEnum.LISTA_DE_AGENDAS);
          case '/empresa/oferta-prestacional':
            return this.checkearPrivilegio(PrivilegiosEnum.LISTA_DE_OFERTA_PRESTACIONAL);
          case '/empresa/especialidad':
            return this.checkearPrivilegio(PrivilegiosEnum.LISTA_DE_ESPECIALIDADES);
          case '/empresa/banco':
            return this.checkearPrivilegio(PrivilegiosEnum.LISTA_DE_BANCOS);
          case '/empresa/tarjeta':
            return this.checkearPrivilegio(PrivilegiosEnum.LISTA_DE_TARJETAS);
          case '/empresa/facturador':
            return this.checkearPrivilegio(PrivilegiosEnum.LISTA_DE_FACTURADORES);


          case '/caja/comprobantes':
            return this.checkearPrivilegio(PrivilegiosEnum.ACCESO_A_COMPROBANTES_EN_CAJA);
          case '/caja/cuentas':
            return this.checkearPrivilegio(PrivilegiosEnum.LISTA_DE_CUENTAS_EN_CAJA);
          case '/caja/clientes':
            return this.checkearPrivilegio(PrivilegiosEnum.LISTA_DE_CLIENTES_EN_CAJA);
          case '/caja/acreedores':
            return this.checkearPrivilegio(PrivilegiosEnum.LISTA_DE_ACREEDORES_EN_CAJA);
          case '/caja/control-general':
            return this.checkearPrivilegio(PrivilegiosEnum.ES_AUDITOR_CAJAS);

          case '/turnos/turnos-agenda':
            return ((this.datosHeader.agendaLogId !== null && this.datosHeader.agendaLogId !== undefined)
              || this.checkearPrivilegio(PrivilegiosEnum.VER_TODAS_AGENDAS));
          case '/turnos/buscador-agendas':
            return ((this.datosHeader.agendaLogId !== null && this.datosHeader.agendaLogId !== undefined)
              || this.checkearPrivilegio(PrivilegiosEnum.VER_TODAS_AGENDAS));


          case '/facturacion/contratos':
            return this.checkearPrivilegio(PrivilegiosEnum.ACCESO_A_CONTRATOS_EN_FACTURACION);
          case '/facturacion/archivos':
            return true;
          case '/facturacion/auditorias':
            return this.checkearPrivilegio(PrivilegiosEnum.ACCESO_A_AUDITORIA_EN_FACTURACION);
          case '/facturacion/resumenes':
            return this.checkearPrivilegio(PrivilegiosEnum.ACCESO_A_RESUMENES_EN_FACTURACION);
          default:
            return true;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
  public privilegioCajas(): boolean {
    if (this.UsuarioLogueado.roles.filter(x=> x.esAdmin).length > 0) {
     return true;
    } else {
        if (this.ArrayHelper.selectMany(this.UsuarioLogueado.roles, 'privilegios')
        .findIndex(z => z === PrivilegiosEnum.ES_AUDITOR_CAJAS || z === PrivilegiosEnum.ES_TESORERO) > -1) {
        return true;
      } else {
        return false;
      }
    }
  }
  public empresasUsuario(usId: number): Observable<EmpresasUsuarioModel[]> {
    return this.http.get<APIReturnModel<EmpresasUsuarioModel[]>>(LoginApiConfig.LOGIN_EMPRESAS_USUARIO_URL,
      this.HttpHelpers.buildQueryParams(usId, 'UsId'))
      .pipe(map(result => result.datos));
  }
  public sedesUsuarioSinImg(usId: number): Observable<SedesUsuarioModel[]> {
    return this.http.get<APIReturnModel<SedesUsuarioModel[]>>(LoginApiConfig.LOGIN_SEDES_USUARIO_SIN_IMG_URL,
      this.HttpHelpers.buildQueryParams(usId, 'usId'))
      .pipe(map(result => result.datos));
  }
  public logout(datosLogin: UsuarioLoginModel): Observable<void> {
    return this.http.post<void>(LoginApiConfig.LOGIN_LOGOUT_URL, datosLogin);
  }
  public elegirSede(datosLogin: UsuarioLoginModel): Observable<DatosElegirSedeModel> {
    return this.http.post<APIReturnModel<DatosElegirSedeModel>>(LoginApiConfig.LOGIN_ELEGIR_SEDE_URL, datosLogin)
      .pipe(map(result => result.datos));
  }
  public agendaUsLogueado(): Observable<ElegirAgendaModel> {
    return this.http.get<APIReturnModel<ElegirAgendaModel>>(LoginApiConfig.LOGIN_AGENDA_US_LOGUEADO_URL)
      .pipe(map(result => result.datos));
  }
  public setearTema(tema: TemasEnum) {
    return this.http.post<APIReturnModel<boolean>>(LoginApiConfig.LOGIN_SETEAR_TEMA_URL, tema)
      .pipe(map(result => result.datos));
  }
  public usPassConsulta(): Observable<UsuarioCambioPassModel> {
    return this.http.get<APIReturnModel<UsuarioCambioPassModel>>(LoginApiConfig.LOGIN_PASS_CONSULTA_URL)
      .pipe(map(result => result.datos));
  }
  public actualizarPass(usPass: UsuarioCambioPassModel) {
    return this.http.put<APIReturnModel<boolean>>(LoginApiConfig.LOGIN_UPDATE_PASS_URL, usPass)
      .pipe(map(result => result.datos));
  }
  public usuarioSesion(usuarioId: number, sesionId: number): Observable<UsuarioLoginModel> {
    return this.http.get<APIReturnModel<UsuarioLoginModel>>(LoginApiConfig.LOGIN_USUARIO_SESION_URL,
      this.HttpHelpers.buildQueryParams({ usuarioId, sesionId }))
      .pipe(map(result => result.datos));
  }
  public emailRecuperarPass(nombreUsuario: string): Observable<boolean> {
    return this.http.get<APIReturnModel<boolean>>(LoginApiConfig.LOGIN_EMAIL_RECUPERAR_PASS_URL,
      this.HttpHelpers.buildQueryParams(nombreUsuario, 'nombreUsuario'))
      .pipe(map(result => result.datos));
  }
  public cambioPassLink(usPass: UsuarioRecuperaPassModel): Observable<UsuarioLoginModel> {
    return this.http.put<APIReturnModel<UsuarioLoginModel>>(LoginApiConfig.LOGIN_CAMBIO_PASS_LINK_URL, usPass)
      .pipe(map(result => result.datos));
  }
  public nombreUsuario(usId: number): Observable<string> {
    return this.http.get<APIReturnModel<string>>(LoginApiConfig.LOGIN_NOMBRE_USUARIO_URL,
      this.HttpHelpers.buildQueryParams(usId, 'usId'))
      .pipe(map(result => result.datos));
  }
}
