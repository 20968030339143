export class CNActividadDiariaModel
{
 Id?: number;
 historiaClinicaId?: number;
 usuarioCargaId?: number;
 fechaCarga?: Date;
 pacienteId?: number;
 usuarioModificaId?: number;
 fechaModifica?: Date;
 tipoActividad: string;
 actividadRealizada: string;
 ejercicioTipo: string;
 frecuencia: string;
 duracion: string;
 inicio: string;
 alcohol: number;
 tabaco: string;
 cafe: number;
 presionArterial: string;
}

export class ctosGinecologicosModel
{
 Id?: number;
 historiaClinicaId?: number;
 usuarioCargaId?: number;
 usuarioModificaId?: number;
 fechaCarga?: Date;
 fechaModifica?: Date;
 pacienteId?: number;
 embarazoActual: boolean;
 anticonceptivosOrales: boolean;
 anticonceptivos: string;
 climaterio: boolean;
 observacion: string;
}

export class indicadoresClinicosModel
{
 Id?: number;
 historiaClinicaId?: number;
 usuarioCargaId?: number;
 fechaCarga?: Date;
 usuarioModificaId?: number;
 fechaModifica?: Date;
 pacienteId?: number;
 diarrea: boolean;
 estrenimiento: boolean;
 gastritis: boolean;
 ulcera: boolean;
 nausea: boolean;
 piroses: boolean;
 vomito: boolean;
 colitis: boolean;
 otroProblemaActual: string;
 obesidad: boolean;
 diabetes: boolean;
 hipertension: boolean;
 gota: boolean;
 dislipemia: boolean;
 hipercolesterolemia: boolean;
 insuficienciaRenal: boolean;
 problemasHepaticos: boolean;
 cancer: boolean;
 otraEnfermedadDiag: string;
 laxantes: boolean;
 diureticos: boolean;
 antiacidos: boolean;
 analgesicos: boolean;
 cirugiasPracticadas: boolean;
}

export class prestadoresBioquimicosModel
{
 id?: number;
 historiaClinicaId?: number;
 usuarioCargaId?: number;
 fechaCarga?: Date;
 pacienteId?: number;
 datoRelevante: string;
 analisisExistente: boolean;
 colesterol?: number;
 tg?: number;
 ldl?: number;
 hdl?: number;
 glucemia?: number;
 tsh?: number;
 t4?: number;
 t4Libre?: number;
 t3?: number;
 tgo?: number;
 tgp?: number;
 gtg?: number;
 fosfatasaAlcalina?: number;
 bilirrubinaTotal?: number;
 bilirrubinaDirecta?: number;
 bilirrubinaIndirecta?: number;
 //NUEVOS
 GlobuloRojo?: number;
 Hemoglobina?: number;
 Hto?: number;
 Leucocitos?: number;
 NeutrofSeg?: number;
 Eosinofilos?: number;
 Linfocitos?: number;
 Plaquetas?: number;
 HBGlicosilada?: number;
 Insulina?: number;
 IndiceHoma?: number;
 Urea?: number;
 Creatinina?: number;
 Proteinograma?: number;
 Albumina?: number;
 GammaGT?: number;
 Ferremia?: number;
 Transferrina?: number;
 Ferritina?: number;
 Orina?: number;
 Sodio?: number;
 Potasio?: number;
 Cloro?: number;
 ReservaAlcalina?: number;
 ParatoHormona?: number;
 Calcemia?: number;
 CalcioIonico?: number;
 Fosforo?: number;
 Magnesio?: number;
 VitaminaB12?: number;
 VitaminaD?: number;
 VitaminaB1?: number;
 VitaminaB6?: number;
 FechaEstudio?:Date;
}
export class HCNAntropometriasModel
{
 id?: number;
 historiaClinicaId?: number;
 usuarioCargaId?: number;
 fechaCarga?: Date;
 fechaControl: Date;
 pacienteId?: number;
 pesoActual?: number;
 pesoMaximo?: number;
 estatura: number;
 circunferenciaPunio: number;
 circunferenciaPecho: number;
 circunferenciaCintura: number;
 circunferenciaCadera: number;
 circunferenciaPierna: number;
 circunferenciaPantorrilla: number;
 circunferenciaBrazo: number;
 subcspular: number;
 tricipital: number;
 bicipital: number;
 axilar: number;
 crestalliaca: number;
 abdominal: number;
 supralliaco: number;
 femur: number;
 pantorrilla: number;
}
export class AlimentosModel
{
    id : number;
    nombre: string;
    porcion: string;
    calorias: string;
    carbohidratos: string;
    fibra: string;
    sodio: string;
    marca: string;
    grasasTotales: string;
    proteinas: string;
    categoriaId: number;
    categoriaNombre?: string;
}
export class AlimentoCategoriaModel
{
id: number;
nombre: string;
}
export class AlimentoCalcularModel
{
id: number;
nombre: string;
porcion: number;
calorias: number;
carbohidratos: number;
fibra: number;
sodio: number;
grasasTotales: number;
proteinas: number;
marca: string;
categoriaId: number;
}
