
import { MatSnackBar } from '@angular/material/snack-bar';
import { TipoAccionABMEnum } from './../../../models/shared/enums.model';
import { DireccionesModel } from 'src/app/models/shared/direcciones.model';
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AppConfig } from 'src/app/config/config';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelectsModel } from 'src/app/models/shared/selects.model';

@Component({
  selector: 'app-modal-direccion',
  templateUrl: './modal-direccion.component.html',
  styleUrls: ['./modal-direccion.component.scss']
})
export class ModalDireccionComponent implements OnInit {

  direccionForm: UntypedFormGroup;

  constructor(
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ModalDireccionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { dirUpdate: DireccionesModel, datosSelect: SelectsModel }) { }

  ngOnInit() {
    this.direccionForm = new UntypedFormGroup({
      tipoDir: new UntypedFormControl(0),
      calle: new UntypedFormControl(this.data.dirUpdate.calle, [Validators.required, Validators.maxLength(100)]),
      numero: new UntypedFormControl(this.data.dirUpdate.numero,
        [Validators.required, Validators.pattern(AppConfig.PATTERN_NUMBERS), Validators.maxLength(8)]),
      piso: new UntypedFormControl(this.data.dirUpdate.piso, [Validators.maxLength(5)]),
      depto: new UntypedFormControl(this.data.dirUpdate.depto, [Validators.maxLength(2)]),
      timbre: new UntypedFormControl(this.data.dirUpdate.timbre, [Validators.maxLength(10)]),
      provincia: new UntypedFormControl(this.data.dirUpdate.provincia, [Validators.required]),
      cp: new UntypedFormControl(this.data.dirUpdate.codigoPostal,
        [Validators.pattern(AppConfig.PATTERN_NUMBERS), Validators.maxLength(4)]),
      ciudad: new UntypedFormControl(this.data.dirUpdate.ciudad,
        [
        Validators.pattern(AppConfig.PATTERN_LETTERS),
        Validators.maxLength(100)])
    });
  }

  public guardar() {
    if (!this.direccionForm.valid) {
      Object.keys(this.direccionForm.controls).forEach(key => {
        this.direccionForm.get(key).markAsTouched();
      });
      this.snackBar.open('Por favor verifique los datos ingresados', 'OK');
      return;
    }
    this.data.dirUpdate.tipo = 0;
    this.data.dirUpdate.tipoStr =
      this.data.datosSelect.tiposDireccion.find(x => x.id === 0).nombre;
    this.data.dirUpdate.calle = this.direccionForm.get('calle').value;
    this.data.dirUpdate.numero = parseInt(this.direccionForm.get('numero').value, 10);
    this.data.dirUpdate.piso = this.direccionForm.get('piso').value;
    this.data.dirUpdate.depto = this.direccionForm.get('depto').value;
    this.data.dirUpdate.timbre = this.direccionForm.get('timbre').value;
    this.data.dirUpdate.ciudad = this.direccionForm.get('ciudad').value;
    this.data.dirUpdate.codigoPostal = this.direccionForm.get('cp').value;
    this.data.dirUpdate.provincia = this.direccionForm.get('provincia').value;
    this.data.dirUpdate.provinciaStr =
      this.data.datosSelect.provincias.find(x => x.id === this.direccionForm.get('provincia').value).nombre;
    if (!this.data.dirUpdate.id) {
      this.data.dirUpdate.tipoAccion = TipoAccionABMEnum.DESACTIVAR;
    }
    this.dialogRef.close(this.data.dirUpdate);
  }
}
