import {
  ABMEmpresaSedeListModel,
  ABMEmpresaNroComprobModel,
  ABMEmpresaPtoVtaListModel,
  ConsultaConsultorioModel,
  ABMEmpresaLugarUpdateModel,
  ABMEmpresaLugarListModel
} from '../../models/empresa/abm-empresa.model';
import { LetraComprobanteEnum } from './../../models/shared/enums.model';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SelectObjModel } from './../../models/shared/selects.model';
import { ABMEmpresaModel, ABMEmpresaSedeUpdateModel, ABMEmpresaPtoVtaUpdateModel } from 'src/app/models/empresa/abm-empresa.model';
import { TipoComprobanteEnum } from 'src/app/models/shared/enums.model';
import { APIReturnModel } from 'src/app/models/shared/api-return.model';
import { HttpHelperService } from '../helpers/http-helper.service';
import { EmpresaApiConfig } from './empresa-api-config';
import { DescargarBackUpModel } from 'src/app/models/empresa/DescargarBackUpModels';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {
  public ChequearTodo = new EventEmitter();
  headers: Headers;
  constructor(
    private http: HttpClient,
    private HttpHelpers: HttpHelperService
  ) { }

  public actualizar(empresa: ABMEmpresaModel): Observable<ABMEmpresaModel> {
    return this.http.put<APIReturnModel<ABMEmpresaModel>>(EmpresaApiConfig.EMPRESA_UPDATE_URL, empresa)
      .pipe(map(result => result.datos));
  }
  public unaSede(sedeId: number): Observable<ABMEmpresaSedeUpdateModel> {
    return this.http.get<APIReturnModel<ABMEmpresaSedeUpdateModel>>(EmpresaApiConfig.EMPRESA_UNA_SEDE_URL,
      this.HttpHelpers.buildQueryParams(sedeId, 'sedeId'))
      .pipe(map(result => result.datos));
  }
  public consultorioTieneHAtencion(consultorioId: number): Observable<boolean> {
    return this.http.get<APIReturnModel<boolean>>(EmpresaApiConfig.EMPRESA_CONSULTORIO_TIENE_HATENCION_URL,
      this.HttpHelpers.buildQueryParams(consultorioId, 'consultorioId'))
      .pipe(map(result => result.datos));
  }
  public actualizarSede(sede: ABMEmpresaSedeUpdateModel): Observable<ABMEmpresaSedeListModel> {
    return this.http.put<APIReturnModel<ABMEmpresaSedeListModel>>(EmpresaApiConfig.EMPRESA_ACTUALIZAR_SEDE_URL, sede)
      .pipe(map(result => result.datos));
  }
  public eliminarSede(sedeId: number): Observable<string> {
    return this.http.delete<APIReturnModel<string>>(EmpresaApiConfig.EMPRESA_ELIMINAR_SEDE_URL,
      this.HttpHelpers.buildQueryParams(sedeId, 'sedeId'))
      .pipe(map(result => result.datos));
  }
  public desactivarSede(sedeId: number): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(EmpresaApiConfig.EMPRESA_DESACTIVAR_SEDE_URL, sedeId)
      .pipe(map(result => result.datos));
  }
  public activarSede(sedeId: number): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(EmpresaApiConfig.EMPRESA_ACTIVAR_SEDE_URL, sedeId)
      .pipe(map(result => result.datos));
  }
  public unaLugar(lugarId: number): Observable<ABMEmpresaLugarUpdateModel> {
    return this.http.get<APIReturnModel<ABMEmpresaLugarUpdateModel>>(EmpresaApiConfig.EMPRESA_UNA_LUGAR_URL,
      this.HttpHelpers.buildQueryParams(lugarId, 'lugarId'))
      .pipe(map(result => result.datos));
  }
  public actualizarLugar(lugar: ABMEmpresaLugarUpdateModel): Observable<ABMEmpresaLugarListModel> {
    return this.http.put<APIReturnModel<ABMEmpresaLugarListModel>>(EmpresaApiConfig.EMPRESA_ACTUALIZAR_LUGAR_URL, lugar)
      .pipe(map(result => result.datos));
  }
  public eliminarLugar(lugarId: number): Observable<string> {
    return this.http.delete<APIReturnModel<string>>(EmpresaApiConfig.EMPRESA_ELIMINAR_LUGAR_URL,
      this.HttpHelpers.buildQueryParams(lugarId, 'lugarId'))
      .pipe(map(result => result.datos));
  }
  public desactivarLugar(lugarId: number): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(EmpresaApiConfig.EMPRESA_DESACTIVAR_LUGAR_URL, lugarId)
      .pipe(map(result => result.datos));
  }
  public activarLugar(lugarId: number): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(EmpresaApiConfig.EMPRESA_ACTIVAR_LUGAR_URL, lugarId)
      .pipe(map(result => result.datos));
  }
  public unComprobante(comprobanteId: number): Observable<ABMEmpresaNroComprobModel> {
    return this.http.get<APIReturnModel<ABMEmpresaNroComprobModel>>(EmpresaApiConfig.EMPRESA_UN_COMPROBANTE_URL,
      this.HttpHelpers.buildQueryParams(comprobanteId, 'comprobanteId'))
      .pipe(map(result => result.datos));
  }
  public actualizarComprobante(comprobante: ABMEmpresaNroComprobModel): Observable<ABMEmpresaNroComprobModel> {
    return this.http.put<APIReturnModel<ABMEmpresaNroComprobModel>>(EmpresaApiConfig.EMPRESA_ACTUALIZAR_COMPROBANTE_URL, comprobante)
      .pipe(map(result => result.datos));
  }
  public unPtoVta(ptoVtaId: number): Observable<ABMEmpresaPtoVtaUpdateModel> {
    return this.http.get<APIReturnModel<ABMEmpresaPtoVtaUpdateModel>>(EmpresaApiConfig.EMPRESA_UN_PTOVTA_URL,
      this.HttpHelpers.buildQueryParams(ptoVtaId, 'ptoVtaId'))
      .pipe(map(result => result.datos));
  }
  public existePtoPorDefecto(ptoVtaId: number): Observable<boolean> {
    return this.http.get<APIReturnModel<boolean>>(EmpresaApiConfig.EMPRESA_EXISTE_PTO_POR_DEFECTO_URL,
      this.HttpHelpers.buildQueryParams(ptoVtaId, 'ptoVtaId'))
      .pipe(map(result => result.datos));
  }
  public actualizarPtoVta(ptoVta: ABMEmpresaPtoVtaUpdateModel): Observable<ABMEmpresaPtoVtaListModel> {
    return this.http.put<APIReturnModel<ABMEmpresaPtoVtaListModel>>(EmpresaApiConfig.EMPRESA_ACTUALIZAR_PTOVTA_URL, ptoVta)
      .pipe(map(result => result.datos));
  }
  public eliminarPtoVta(ptoVtaId: number): Observable<string> {
    return this.http.delete<APIReturnModel<string>>(EmpresaApiConfig.EMPRESA_ELIMINAR_PTOVTA_URL,
      this.HttpHelpers.buildQueryParams(ptoVtaId, 'ptoVtaId'))
      .pipe(map(result => result.datos));
  }
  public desactivarPtoVta(ptoVtaId: number): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(EmpresaApiConfig.EMPRESA_DESACTIVAR_PTOVTA_URL, ptoVtaId)
      .pipe(map(result => result.datos));
  }
  public activarPtoVta(ptoVtaId: number): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(EmpresaApiConfig.EMPRESA_ACTIVAR_PTOVTA_URL, ptoVtaId)
      .pipe(map(result => result.datos));
  }
  public uno(empresaId: number): Observable<ABMEmpresaModel> {
    return this.http.get<APIReturnModel<ABMEmpresaModel>>(EmpresaApiConfig.EMPRESA_UNO_URL,
      this.HttpHelpers.buildQueryParams(empresaId, 'empresaId'))
      .pipe(map(result => result.datos));
  }
  public proximoComprobante(ptoVtaId: number, comp: TipoComprobanteEnum, letra: LetraComprobanteEnum): Observable<number> {
    return this.http.get<APIReturnModel<number>>(EmpresaApiConfig.EMPRESA_PROXIMO_COMPROBANTE_URL,
      this.HttpHelpers.buildQueryParams({ ptoVtaId, comp, letra }))
      .pipe(map(result => result.datos));
  }
  public primerPtoVta(): Observable<number> {
    return this.http.get<APIReturnModel<number>>(EmpresaApiConfig.EMPRESA_PRIMER_PTO_VTA_URL)
      .pipe(map(result => result.datos));
  }
  public existeConsultorio(datos: ConsultaConsultorioModel): Observable<boolean> {
    return this.http.get<APIReturnModel<boolean>>(EmpresaApiConfig.EMPRESA_EXISTE_CONSULTORIO_URL,
      this.HttpHelpers.buildQueryParams(datos))
      .pipe(map(result => result.datos));
  }
  public existeCuit(nroCuit: string): Observable<boolean> {
    return this.http.get<APIReturnModel<boolean>>(EmpresaApiConfig.EMPRESA_EXISTE_CUIT_URL,
      this.HttpHelpers.buildQueryParams(nroCuit, 'nroCuit'))
      .pipe(map(result => result.datos));
  }
  public consultaLimiteMedico(): Observable<number> {
    return this.http.get<APIReturnModel<number>>(EmpresaApiConfig.EMPRESA_CONSULTA_LIMITE_MEDICO_URL)
      .pipe(map(result => result.datos));
  }
  public EmpresaToExcel(): Observable<DescargarBackUpModel> {
    return this.http.get<APIReturnModel<DescargarBackUpModel>>(EmpresaApiConfig.EMPRESA_TO_EXCEL_URL)
      .pipe(map(result => result.datos));
  }
  public busquedaSelect(busqueda: string): Observable<SelectObjModel[]> {
    return this.http.get<APIReturnModel<SelectObjModel[]>>(EmpresaApiConfig.EMPRESA_BUSQUEDA_SELECT_URL,
      this.HttpHelpers.buildQueryParams(busqueda, 'busqueda'))
      .pipe(map(result => result.datos));
  }
}
