<mat-card class="tarjeta-Modal">
  <mat-card-header fxLayout="row" fxLayoutAlign="space-between start">
    <mat-card-title>Atencion</mat-card-title>
    <button mat-button (click)="onCancel()" tabindex="-1">
      <img class="rotado" src="\assets\images\icons\nuevo.svg" alt="Cerrar">
    </button>
  </mat-card-header>
  <mat-card-content fxLayout="row">
    <h5>{{mensaje}}</h5>
  </mat-card-content>
  <mat-card-actions fxLayout="row" fxLayoutAlign="end start">
    <button mat-raised-button class="btn-guardar" (click)="onConfirm()" tabindex="-1">{{accion.toUpperCase()}}</button>
  </mat-card-actions>
</mat-card>
