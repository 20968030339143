<div style="min-width: 450px; width: auto; height: auto; padding: 10px">
  <h2 mat-dialog-title>Select Sede</h2>
  <div fxLayout="column">
    <div fxLayout="row" fxFlexFill style="display: inline-flex">
      <mat-form-field
        style="width: 380px; margin-right: 10px"
        appearance="outline"
      >
        <mat-label>Empresa</mat-label>
        <mat-select
          #centroSelect
          (selectionChange)="centroSelected($event.value)"
          formControlName="centro"
        >
          <mat-option *ngFor="let item of centroList | async" [value]="item">
            {{ item.nombre }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div fxLayout="column" fxFlex="25%" fxFlexAlign="center">
        <div>
          <button (click)="moveCentroSelect('up', centroSelect)">
            <mat-icon>keyboard_arrow_up</mat-icon>
          </button>
        </div>
        <div>
          <button (click)="moveCentroSelect('down', centroSelect)">
            <mat-icon>keyboard_arrow_down</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div>
      <div fxLayout="row" fxFlexFill style="display: inline-flex">
        <mat-form-field
          style="width: 380px; margin-right: 10px"
          appearance="outline"
        >
          <mat-label>Sede</mat-label>
          <mat-select
            #sedeSelect
            (selectionChange)="sedeSelected($event.value)"
            formControlName="sede"
          >
            <mat-option *ngFor="let item of sedeList | async" [value]="item">
              {{ item.nombre || "" }} {{ item.calle || "" }}
              {{ item.numero || "" }} {{ item.piso || "" }}
              {{ item.depto || "" }}
              {{ item.ciudad || "" }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div fxLayout="column" fxFlex="25%" fxFlexAlign="center">
          <div>
            <button (click)="moveSedeSelect('up', sedeSelect)">
              <mat-icon>keyboard_arrow_up</mat-icon>
            </button>
          </div>
          <div>
            <button (click)="moveSedeSelect('down', sedeSelect)">
              <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
          </div>
        </div>
      </div>

    </div>
    <div>
      <div fxLayout="row" fxFlexFill style="display: inline-flex">
      <mat-form-field      
        style="width: 380px; margin-right: 10px"
        appearance="outline"
      >
        <mat-label>Seleccionar lista de reproducción</mat-label>
        <mat-select      
        (selectionChange)="playlistSelected($event.value)"  #playListSelect>
          <mat-option value="paisajes">PAISAJES</mat-option>
          <mat-option value="ciudades">CIUDADES</mat-option>
          <mat-option value="deportes">DEPORTES</mat-option>
          <mat-option value="animales">ANIMALES</mat-option>
        </mat-select>
      </mat-form-field>
        <div fxLayout="column" fxFlex="25%" fxFlexAlign="center">
        <div>
          <button (click)="movePlayListSelect('up', playListSelect)">
            <mat-icon>keyboard_arrow_up</mat-icon>
          </button>
        </div>
        <div>
          <button (click)="movePlayListSelect('down', playListSelect)">
            <mat-icon>keyboard_arrow_down</mat-icon>
          </button>
        </div>
      </div>
      </div>
    </div>
    <div>
      <!-- <mat-form-field style="width: 100%;" appearance="outline" fxFlex="1">
      <mat-label>Youtube Playlists Links (Separe cada línea con coma " , ")</mat-label>
      <textarea  matInput [(ngModel)]="videoLink"></textarea>

    </mat-form-field> -->
    </div>
  </div>
  <mat-dialog-actions align="end">
    <div class="dialog-buttons">
      <button mat-raised-button color="primary" (click)="onCloseClick()">
        OK
      </button>
    </div>
  </mat-dialog-actions>
</div>
