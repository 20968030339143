import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

  if (module['hot']) {
    module['hot'].accept();
    // module['hot'].dispose(() => {
    //   const appRef = module['hot'].data.appRef;
    //   const elements = document.body.querySelectorAll('app-root');
    //   elements.forEach(el => el.remove());
    //   appRef.destroy();
    // });
  }