import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusquedaPacienteModule } from "./../busqueda-paciente/busqueda-paciente.module";
import { MatMenuModule } from '@angular/material/menu';
import { PrivilegiosPipeModule } from './../../../pipes/privilegios.pipes';
import { MatButtonModule } from '@angular/material/button';
import { TopbarComponent } from './../topbar/topbar.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';


@NgModule({
  declarations: [TopbarComponent],
  imports: [
    CommonModule,
    BusquedaPacienteModule,
    MatMenuModule,
    MatTooltipModule,
    PrivilegiosPipeModule,
    MatButtonModule,
    MatSnackBarModule
  ],
  exports:[TopbarComponent]
})
export class TopbarModule { }
