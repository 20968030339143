import { TipoComprobanteEnum } from './enums.model';
export class PersonaValidarEmailModel {
  usId: number;
  email: string;
  constructor(usId: number, email: string) {
    this.usId = usId;
    this.email = email;
  }
}
export class PersonaValidarDocModel {
  usId: number;
  doc: number;
  tipo: number;
  constructor(usId: number, doc: number, tipo: number) {
    this.usId = usId;
    this.doc = doc;
    this.tipo = tipo;
  }
}
