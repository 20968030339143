<!-- <div class="titulo-busqueda">
  <span>Buscador de Personas</span>
  <button mat-icon-button class="cerrar" (click)="animacion()" tabindex="-1">
    <img class="rotado" src="\assets\images\icons\nuevo.svg" alt="Cerrar" />
  </button>
</div> -->

  
<div class="inputcont" [style.background-color]="divColor">
  <img  alt="top image" src="/assets/images/icons/personSearchIcon.png">
  <app-busqueda-autocomplete [label]="'Buscar paciente'" [deshabilitado]="propPaciente.deshabilitado" style="width:400px;"
    [valor]="propPaciente.valor" [tipoBuscador]="propPaciente.tipoBuscador" [requerido]="propPaciente.requerido" [floatLabel]="'never'" [hideRequiredMarker]="'true'"
    [claseMatForm]="'buscar-paciente-nav'"  [mostrarError]="true" [elegirPrimeraOpcion]="false" (focus) ="textBarFocus()" (blur)="textBarBlur()"
    (evElegir)='elegirPaciente($event)' (evLimpiar)='limpiarPaciente()'>
  </app-busqueda-autocomplete>

  <!-- <button matTooltipClass="navbar-tooltip" matTooltip="Buscar" matTooltipPosition="right" mat-icon-button color="primary" (click)="buscar()" tabindex="-1" style="top:10px">
    <mat-icon>search</mat-icon>
  </button> -->
  <button matTooltipClass="navbar-tooltip" matTooltip="Nueva Persona" matTooltipPosition="right" mat-icon-button color="primary" *ngIf="loginServ.privilegios.CARGAR_EDITAR_PACIENTES|tienePrivilegio"
    (click)="pacienteNuevo()" tabindex="-1">
    <mat-icon>add</mat-icon>
  </button>
</div>

