import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import EsArLocale from '@angular/common/locales/es-AR';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import player from 'lottie-web';
import { MatTableExporterModule } from 'mat-table-exporter';
import { LottieModule } from 'ngx-lottie';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoaderComponent } from './components/base/loader/loader.component';
import { NavbarModule } from './components/base/navbar/navbar.module';
import { TopbarModule } from './components/base/topbar/topbar.module';
import { PrincipalComponent } from './components/base/principal/principal.component';
import { StarterComponent } from './components/base/starter/starter.component';
import { LoginSedeModule } from './components/login/login-sede/login-sede.module';
import { LoginUsuarioModule } from './components/login/login-usuario/login-usuario.module';
import { ModalRecuperarPassModule } from './components/login/modal-recuperar-pass/modal-recuperar-pass.module';
import { AppDateAdapter, MAT_CUSTOM_DATE_FORMATS } from './config/date-adapter';
import { getEspPaginatorIntl } from './config/paginator-adapter';
import { DateInterceptor } from './interceptors/date.interceptor';
import { HeadersInterceptor } from './interceptors/headers.interceptor';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { LoginService } from './services/login/login.service';
import { LoaderService } from './services/shared/loader.service';

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}



registerLocaleData(EsArLocale, 'es');

@NgModule({
    declarations: [
        AppComponent,
        LoaderComponent,
        StarterComponent,
        PrincipalComponent
    ],
    providers: [
        LoginService,
        LoaderService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeadersInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: DateInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'es-AR'
        },
        {
            provide: DateAdapter,
            useClass: AppDateAdapter
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: MAT_CUSTOM_DATE_FORMATS
        },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                disableClose: true,
                hasBackdrop: true
            }
        },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: {
                duration: 3000
            }
        },
        {
            provide: MatPaginatorIntl,
            useValue: getEspPaginatorIntl()
        }
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        CommonModule,
        AppRoutingModule,
        NavbarModule,
        TopbarModule,
        FlexLayoutModule,
        LoginUsuarioModule,
        LoginSedeModule,
        ModalRecuperarPassModule,
        VirtualScrollerModule,
        MatTableExporterModule,
        LottieModule.forRoot({ player: playerFactory })
    ]
})
export class AppModule { }
