import { ABMPacienteTelefonoModel } from '../paciente/abm-paciente.model';
import { TipoAccionABMEnum } from './enums.model';

export class TelefonoModel {
  id: number;
  tipoAccion: TipoAccionABMEnum;
  numero: string;
  tipo: number;
  tipoStr: string;
  constructor() { }
  public static toABMPacienteTelefonoModel(tel: TelefonoModel, personaId: number): ABMPacienteTelefonoModel {
    const telPaciente = new ABMPacienteTelefonoModel();
    telPaciente.tipoAccion = tel.tipoAccion;
    telPaciente.id = tel.id;
    telPaciente.numero = tel.numero;
    telPaciente.personaId = personaId;
    telPaciente.tipo = tel.tipo;
    telPaciente.tipoStr = tel.tipoStr;
    return telPaciente;
  }
}
