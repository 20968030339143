import { HttpHelperService } from './../helpers/http-helper.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ABMClienteUpdateModel, ABMClienteListModel } from 'src/app/models/caja/cliente.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectObjModel } from 'src/app/models/shared/selects.model';
import { APIReturnModel } from 'src/app/models/shared/api-return.model';
import { ABMGenericoConsultaModel, ABMGenericoModel } from 'src/app/models/shared/abm-generico.model';
import { CajaApiConfig } from './caja-api-config';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  constructor(
    private http: HttpClient,
    private HttpHelpers: HttpHelperService
  ) { }

  public actualizar(cliente: ABMClienteUpdateModel): Observable<ABMClienteListModel> {
    return this.http.put<APIReturnModel<ABMClienteListModel>>(CajaApiConfig.CLIENTE_ACTUALIZAR_URL, cliente)
      .pipe(map(result => result.datos));
  }
  public list(datosBusqueda: ABMGenericoConsultaModel): Observable<ABMGenericoModel<ABMClienteListModel>> {
    return this.http.get<APIReturnModel<ABMGenericoModel<ABMClienteListModel>>>(CajaApiConfig.CLIENTE_LIST_URL,
      this.HttpHelpers.buildQueryParams(datosBusqueda))
      .pipe(map(result => result.datos));
  }

  public desactivar(clienteId: number): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(CajaApiConfig.CLIENTE_DESACTIVAR_URL, clienteId)
      .pipe(map(result => result.datos));
  }

  public eliminar(clienteId: number): Observable<string> {
    return this.http.delete<APIReturnModel<string>>(CajaApiConfig.CLIENTE_ELIMINAR_URL,
      this.HttpHelpers.buildQueryParams(clienteId, 'clienteId'))
      .pipe(map(result => result.datos));
  }
  public uno(clienteId: number): Observable<ABMClienteUpdateModel> {
    return this.http.get<APIReturnModel<ABMClienteUpdateModel>>(CajaApiConfig.CLIENTE_UNO_URL,
      this.HttpHelpers.buildQueryParams(clienteId, 'clienteId'))
      .pipe(map(result => result.datos));
  }
  public activar(clienteId: number): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(CajaApiConfig.CLIENTE_ACTIVAR_URL, clienteId)
      .pipe(map(result => result.datos));
  }
  public busquedaSelect(busqueda: string): Observable<SelectObjModel[]> {
    return this.http.get<APIReturnModel<SelectObjModel[]>>(CajaApiConfig.CLIENTE_BUSQUEDA_SELECT_URL,
      this.HttpHelpers.buildQueryParams(busqueda, 'busqueda'))
      .pipe(map(result => result.datos));
  }
  public desactivarTelefono(clienteTelId: number): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(CajaApiConfig.CLIENTE_DESACTIVAR_TEL_URL, clienteTelId)
      .pipe(map(result => result.datos));
  }
  public activarTelefono(clienteTelId: number): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(CajaApiConfig.CLIENTE_ACTIVAR_TEL_URL, clienteTelId)
      .pipe(map(result => result.datos));
  }
}
