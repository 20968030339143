import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ArrayHelperService {
  constructor() { }
  public sum(array: Array<any>, key: string) {
    return array.reduce((a, b) => a + (b[key] || 0), 0);
  }
  public selectMany(array: Array<any>, key: string): any[] {
    if (array.length > 0) {
      return array.map((p) => p[key])
        .reduce((a, b) => a.concat(b));
    } else {
      return array;
    }
  }
  public arrayIgual(arrayOrigen: Array<any>, arrayNuevo: Array<any>) {
    if (arrayOrigen.length !== arrayNuevo.length) {
      return false;
    }
    for (let i = 0; i < arrayOrigen.length; i++) {
      if (!this.valueEqual(arrayOrigen[i], arrayNuevo[i])) {
        return false;
      }
    }
    return true;
  }
  public valueEqual(a1, a2) {
    /* WARNING: arrays must not contain {objects} or behavior may be undefined */
    return JSON.stringify(a1) === JSON.stringify(a2);
  }
}
