import { LetraComprobanteEnum } from './../../models/shared/enums.model';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SelectObjModel } from './../../models/shared/selects.model';

import { APIReturnModel } from 'src/app/models/shared/api-return.model';
import { HttpHelperService } from '../helpers/http-helper.service';
import { LugarApiConfig } from './lugar-api-config';
@Injectable({
    providedIn: 'root'
})
export class LugarService {
    public ChequearTodo = new EventEmitter();
    headers: Headers;
    constructor(
        private http: HttpClient,
        private HttpHelpers: HttpHelperService
    ) { }
public busquedaSelect(busqueda: string): Observable < SelectObjModel[] > {
    return this.http.get<APIReturnModel<SelectObjModel[]>>(LugarApiConfig.LUGAR_BUSQUEDA_SELECT_URL,
        this.HttpHelpers.buildQueryParams(busqueda, 'busqueda'))
        .pipe(map(result => result.datos));
}}