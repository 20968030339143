import { CuentaPagosModel } from './pagos.model';
import { TipoBuscadorAutoCompleteEnum, TipoParentescoEnum } from './enums.model';

export class SelectsModel {
  tiposDoc: SelectObjModel[];
  sexoBio: SelectObjModel[];
  generos: SelectObjModel[];
  prefijo: SelectObjModel []
  tiposDireccion: SelectObjModel[];
  provincias: SelectObjModel[];
  tiposTel: SelectObjModel[];
  privilegios: SelectObjModel[];
  tipoEspEmpresa: SelectObjModel[];
  roles: SelectConEmpresaModel[];
  estadosTurno: SelectObjModel[];
  tipoAbarca: SelectObjModel[];
  tipoPrestacion: SelectObjModel[];
  sedes: SelectConEmpresaModel[];
  consultorios: SelectObjModel[];
  especialidades: SelectObjModel[];
  tiposEventualidad: SelectObjModel[];
  mediosPago: SelectObjModel[];
  afectaCuenta: SelectObjModel[];
  cuentasCaja: CuentaPagosModel[];
  tiposComprobante: SelectObjModel[];
  letrasComprobante: SelectObjModel[];
  bancos: SelectObjModel[];
  tarjetas: SelectObjModel[];
  ptosVta: SelectObjModel[];
  tiposCaja: SelectObjModel[];
  triage: SelectObjModel[];
  auditoriaEstados: SelectObjModel[];
  prescripcionFrecuenciaTipo: SelectObjModel[];
  prescripcionDuracionTipo: SelectObjModel[];
  resumenPlantillasEnum: SelectObjModel[];
  tiposAlergiasEnum: SelectObjModel[];
  haceEjercicioEnum: SelectObjModel[];
  tiposFumadorEnum: SelectObjModel[];
  tiposSangreEnum: SelectObjModel[];
  estadoCivilEnum: SelectObjModel[];
  tipoIvaEnum: SelectObjModel[];
  condicionIvaEnum: SelectObjModel[];
  tipoParentescoEnum: SelectObjModel[];
  tipoUnidadMedida: SelectObjModel[];
  constructor() { }
}

export class SelectConEmpresaModel {
  id: number;
  nombre: string;
  empresaId?: number;
  constructor() { }
}

export class SelectObjModel {
  id: number;
  nombre: string;
  esPaciente: boolean;
  constructor();
  constructor(id: number, nombre: string, esPaciente: boolean);
  constructor(id?: number, nombre?: string, esPaciente?: boolean) {
    this.id = id;
    this.nombre = nombre;
    this.esPaciente = esPaciente;
  }
  public static convertirCheckTable(items: CheckTableModel[]): SelectObjModel[] {
    const itemsSelect = new Array<SelectObjModel>();
    items.forEach(element => {
      itemsSelect.push(new SelectObjModel(element.id, element.nombre, element.esPaciente));
    });
    return itemsSelect;
  }
}
export class SubItemAcordeonModel {
  id: number;
  nombre: string;
  elegido: boolean;
  deshabilitado: boolean;
  constructor() {
    this.elegido = false;
    this.deshabilitado = false;
  }
}
export class ItemAcordeonModel {
  id: number;
  nombre: string;
  icono: string;
  elegido: boolean;
  deshabilitado: boolean;
  subItems: SubItemAcordeonModel[];
  constructor() {
    this.subItems = new Array<SubItemAcordeonModel>();
    this.deshabilitado = this.subItems.filter(x => x.deshabilitado).length === this.subItems.length;
    this.elegido = this.subItems.filter(x => x.elegido).length === this.subItems.length;
  }
}
export class CheckTableModel {
  id: number;
  nombre: string;
  elegido: boolean;
  deshabilitado: boolean;
  esPaciente: boolean;
  constructor();
  constructor(id: number, nombre: string, elegido: boolean, deshabilitado: boolean);
  constructor(id?: number, nombre?: string, elegido?: boolean, deshabilitado?: boolean) {
    this.id = id;
    this.nombre = nombre;
    this.elegido = elegido;
    this.deshabilitado = deshabilitado;
  }
  public static convertirSelectObj(items: SelectObjModel[], itemsElegidos: number[], itemsDeshabilitados?: number[]): CheckTableModel[] {
    const itemsTabla = new Array<CheckTableModel>();
    let deshabilitado = false;
    let habilitado = false;
    items.forEach(element => {
      if (itemsElegidos) {
        habilitado = itemsElegidos.findIndex(x => x === element.id) > -1;
      }
      if (itemsDeshabilitados) {
        deshabilitado = itemsDeshabilitados.findIndex(x => x === element.id) > -1;
      }
      itemsTabla.push(new CheckTableModel(element.id, element.nombre, habilitado, deshabilitado));
    });
    return itemsTabla;
  }
}

export class BuscadorAutoCompleteProps {
  tipoBuscador: TipoBuscadorAutoCompleteEnum;
  requerido: boolean;
  valor: any;
  deshabilitado: boolean;
  mostrarError: boolean;
  label: string;
  constructor();
  constructor(tipoBuscador: TipoBuscadorAutoCompleteEnum, requerido: boolean, valor: any, deshabilitado: boolean, label: string)
  constructor(tipoBuscador?: TipoBuscadorAutoCompleteEnum, requerido?: boolean, valor?: any, deshabilitado?: boolean, label?: string) {
    this.tipoBuscador = tipoBuscador;
    this.requerido = requerido;
    this.valor = valor;
    this.deshabilitado = deshabilitado;
    this.mostrarError = false;
    this.label = label;
  }
}
