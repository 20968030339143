<div class="cont-principal-login" fxLayout="row wrap" fxFlex="100%">
  <div fxFlex="100%" fxLayout="column nowrap" fxLayoutAlign="center center">
    <div  class="cont-controles" fxLayout="row wrap" fxLayoutAlign="start stretch">
      <div fxFlex="100%" fxLayout="row nowrap" fxLayoutAlign="start stretch">
      <div style="width: 500px;" class="logo-login" >
        <img class="img1" src="../../../../assets/images/BG2x.png">
        <img class="img2" src="../../../../assets/images/Logopanacea.png">
      </div>
      <div style="width: 300px;" fxLayout="column wrap" fxLayoutAlign="center center">
      <div  class="cont-input" fxLayout="column wrap">
        <span style="width: 100% !important; color: #6823FF; font-size: 12pt; margin-left: 0px; padding-bottom: 30px;">Iniciar Sesión</span>
        <div>
          <mat-form-field fxFlex="82%">
            <mat-icon matPrefix style="color: #c9c9c9; position: relative; top: 5px;font-size: 14pt;">person_outline</mat-icon>
            <input matInput type="text" placeholder="Usuario" [(ngModel)]="datoslogin.nombreUsuario"
              [ngModelOptions]="{ standalone: true }" #usuario (keydown.enter)="nextField($event)" />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field fxFlex="82%">
            <mat-icon matPrefix style="color: #c9c9c9; position: relative; top: 3px;font-size: 12pt;">lock</mat-icon>
            <input matInput type="Password" placeholder="Contraseña" [(ngModel)]="datoslogin.password"
              [ngModelOptions]="{ standalone: true }" #password (keydown.enter)="nextField($event)" />
          </mat-form-field>
        </div>
        <span mat-flat-button (click)="recuperarPass()" tabindex="-1" style="width: 100% !important; color: #6823FF; font-size: 7pt; margin-left: 0px; margin-right: 0px; text-align: right; padding-bottom: 10px;">
          ¿Has olvidado tu contraseña?
        </span>
          <button mat-button #loginBtn style=" margin-bottom: 20px; background-color: #6823FF; font-family: Gilroy; color: white; width: 100%; border-radius: 10px; font-size: 8pt;"  (click)="login()" tabindex="-1">
            INGRESAR
          </button>
          <span style="width: 100% !important; color: #6823FF; font-size: 7pt; margin-left: 0px; text-align: center; margin-top: 80px;">Descargar nuestra aplicación</span>
          <div >
            <img src="../../../../assets/images/PlayStore.png" style="width: 126px !important; height: 35px !important; margin-right: 2px;">
            <img src="../../../../assets/images/AppStore.png" style="width: 126px !important; height: 35px !important;">
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</div>
