import { BusquedaAutocompleteComponent } from '../../../shared/busqueda-autocomplete/busqueda-autocomplete.component';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { AppConfig } from 'src/app/config/config';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelectObjModel, BuscadorAutoCompleteProps, SelectsModel } from 'src/app/models/shared/selects.model';
import { TipoBuscadorAutoCompleteEnum } from 'src/app/models/shared/enums.model';
import { ObraSocialService } from 'src/app/services/facturacion/obra-social.service';
import { ABMPacienteUpdateUsuarioPlanModel } from 'src/app/models/paciente/abm-paciente.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectsService } from 'src/app/services/shared/selects.service';

@Component({
  selector: 'app-modal-paciente-planes-update',
  templateUrl: './modal-paciente-planes-update.component.html',
  styleUrls: ['./modal-paciente-planes-update.component.scss']
})
export class ModalPacientePlanesUpdateComponent implements OnInit, AfterViewInit {
  planForm: UntypedFormGroup;
  planesObra: SelectObjModel[];
  @ViewChild(BusquedaAutocompleteComponent, { static: true }) buscador: BusquedaAutocompleteComponent;
  propsOS = new BuscadorAutoCompleteProps(TipoBuscadorAutoCompleteEnum.OBRASOCIAL, true, null, false, null);
  datosSelect = new SelectsModel();
  constructor(
    private snackBar: MatSnackBar,
    private osServ: ObraSocialService,
    public dialogRef: MatDialogRef<ModalPacientePlanesUpdateComponent>,
    public selServ: SelectsService,
    @Inject(MAT_DIALOG_DATA) public data: {
      planUpdate: ABMPacienteUpdateUsuarioPlanModel
      planesActuales: ABMPacienteUpdateUsuarioPlanModel[]
    }) { }

  ngOnInit() {
    this.selServ.selects().then((x: SelectsModel) => {
      this.datosSelect = x;
    });
    this.planForm = new UntypedFormGroup({
      plan: new UntypedFormControl(this.data.planUpdate.planId, [Validators.required]),
      nroAsociado: new UntypedFormControl(this.data.planUpdate.numeroAsociado, [
        Validators.required,
        Validators.pattern(AppConfig.PATTERN_NUMBERS),
        Validators.maxLength(50)
      ]),
      parentesco: new UntypedFormControl( this.data.planUpdate.esTitular, [Validators.required]),
      otraInfo: new UntypedFormControl(this.data.planUpdate.otraInfo, [Validators.required])
    });
    if (this.data.planUpdate.planId) {
      this.elegirOS(new SelectObjModel(this.data.planUpdate.obraId, this.data.planUpdate.obraNombre, false));
    }
    this.planForm.get('plan').valueChanges.subscribe(plan => {
      if (this.data.planesActuales.findIndex(x => x.planId === plan) > -1) {
        if (this.data.planUpdate.id > 0 && plan === this.data.planUpdate.planId) {
          return;
        }

        this.snackBar.open('El paciente ya tiene este plan');
        if (this.data.planUpdate.id > 0) {
          this.planForm.get('plan').setValue(this.data.planUpdate.planId);
        } else {
          this.planForm.get('plan').setValue(null);
        }
      }
    });
  }
  ngAfterViewInit(): void {
    setTimeout(_ => this.buscador.input.nativeElement.focus());
  }
  elegirOS(item: SelectObjModel) {
    this.propsOS.valor = item;
    this.osServ.planes(item.id).toPromise().then(planes => {
      this.planesObra = planes;
      if (this.data.planUpdate) {
        this.planForm.get('plan').setValue(this.planesObra.find(x => x.id === this.data.planUpdate.planId).id);
      }
    });
  }
  limpiarOS() {
    this.propsOS.valor = null;
    this.planesObra = new Array<SelectObjModel>();
  }
  public guardar() {
    let hayError = false;
    if (!this.planForm.valid) {
      Object.keys(this.planForm.controls).forEach(key => {
        this.planForm.get(key).markAsTouched();
      });
    }
    if (this.buscador.isInvalid()) {
      this.propsOS.mostrarError = true;
      hayError = true;
    }
    if (hayError) {
      this.snackBar.open('Por favor verifique los datos ingresados', 'OK');
      return;
    }
    this.data.planUpdate.esTitular = this.planForm.get('parentesco').value;
    this.data.planUpdate.numeroAsociado = this.planForm.get('nroAsociado').value;
    this.data.planUpdate.obraId = this.propsOS.valor.id;
    this.data.planUpdate.obraNombre = this.propsOS.valor.nombre.substring(
      this.propsOS.valor.nombre.indexOf('-') + 2, this.propsOS.valor.nombre.length);
    this.data.planUpdate.planId = this.planForm.get('plan').value;
    this.data.planUpdate.planNombre = this.planesObra.find(x => x.id === this.planForm.get('plan').value).nombre;
    this.data.planUpdate.otraInfo = this.planForm.get('otraInfo').value;
    this.dialogRef.close(this.data.planUpdate);
  }
}
