import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APIReturnModel } from 'src/app/models/shared/api-return.model';
import { HttpHelperService } from '../helpers/http-helper.service';
import { PacienteRecordatoriosModel, PacienteRecordatorioItemModel } from 'src/app/models/paciente/paciente-recordatorio.model';
import { PacienteApiConfig } from './paciente-api-config';

@Injectable({
  providedIn: 'root'
})
export class RecordatoriosService {

  constructor(
    private http: HttpClient,
    private HttpHelpers: HttpHelperService
  ) { }

  public actualizar(recordatorios: PacienteRecordatoriosModel): Observable<PacienteRecordatoriosModel> {
    return this.http.put<APIReturnModel<PacienteRecordatoriosModel>>(PacienteApiConfig.RECORDATORIO_UPDATE_URL, recordatorios)
      .pipe(map(result => result.datos));
  }
  public eliminar(recordatorioId: number): Observable<boolean> {
    return this.http.delete<APIReturnModel<boolean>>(PacienteApiConfig.RECORDATORIO_ELIMINAR_URL,
      this.HttpHelpers.buildQueryParams(recordatorioId, 'recordatorioId'))
      .pipe(map(result => result.datos));
  }
  public listar(pacienteId: number): Observable<PacienteRecordatoriosModel> {
    return this.http.get<APIReturnModel<PacienteRecordatoriosModel>>(PacienteApiConfig.RECORDATORIO_LIST_URL,
      this.HttpHelpers.buildQueryParams(pacienteId, 'pacienteId'))
      .pipe(map(result => result.datos));
  }
  public uno(recordatorioId: number): Observable<PacienteRecordatorioItemModel> {
    return this.http.get<APIReturnModel<PacienteRecordatorioItemModel>>(PacienteApiConfig.RECORDATORIO_UNO_URL,
      this.HttpHelpers.buildQueryParams(recordatorioId, 'recordatorioId'))
      .pipe(map(result => result.datos));
  }
}
