import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UsuarioLoginModel } from 'src/app/models/login/usuarioLogin.model';
import { PrimerUsuarioModel } from 'src/app/models/login/primerUs.model';
import { APIReturnModel } from 'src/app/models/shared/api-return.model';
import { LoginApiConfig } from './login-api-config';
import { HttpHelperService } from '../helpers/http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class Us1Service {
  constructor(private http: HttpClient, private HttpHelpers: HttpHelperService) { }
  public datosPersona = new PrimerUsuarioModel();
  public empresaEvent: EventEmitter<void> = new EventEmitter();
  // public personaEvent: EventEmitter<void> = new EventEmitter();
  public volverEvent: EventEmitter<void> = new EventEmitter();
  public cancelarEvent: EventEmitter<void> = new EventEmitter();
  public finalizarEvent: EventEmitter<void> = new EventEmitter();
  public Paso3Event: EventEmitter<void> = new EventEmitter();
  public cierraNuevaEmrpesa: EventEmitter<void> = new EventEmitter();


  public crearPrimerUsuario(): Observable<UsuarioLoginModel> {
    return this.http.post<APIReturnModel<UsuarioLoginModel>>(LoginApiConfig.LOGIN_PRIMER_USUARIO_URL, this.datosPersona)
      .pipe(map(result => result.datos));
  }

  public existeCuit(nroCuit: string): Observable<boolean> {
    return this.http.get<APIReturnModel<boolean>>(LoginApiConfig.LOGIN_EXISTE_CUIT_URL,
      this.HttpHelpers.buildQueryParams(nroCuit, 'nroCuit'))
      .pipe(map(result => result.datos));
  }

  public consigueLimiteMedicos(usuarioId: number): Observable<number> {
    return this.http.get<APIReturnModel<number>>(LoginApiConfig.LOGIN_CONSIGUE_LIMITE_MEDICOS_URL,
      this.HttpHelpers.buildQueryParams(usuarioId, 'usuarioId'))
      .pipe(map(result => result.datos));
  }
}
