import { ModalConfirmarComponent } from 'src/app/components/shared/modal-confirmar/modal-confirmar.component';
import { Subscription } from 'rxjs';
import { WebRTCService } from 'src/app/services/shared/web-rtc.service';
import { LoginService } from 'src/app/services/login/login.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { RTCUserModel } from 'src/app/models/shared/web-rtc.model';

@Component({
  selector: 'app-modal-camara',
  templateUrl: './modal-camara.component.html',
  styleUrls: ['./modal-camara.component.scss']
})
export class ModalCamaraComponent implements OnInit, OnDestroy {
  usuarios = new MatTableDataSource<RTCUserModel>();
  // startTime: number;
  localStream: MediaStream;
  connection: RTCPeerConnection;
  subscripciones = new Array<Subscription>();
  llamadaActual: RTCUserModel;
  // error: string;

  @ViewChild('localVideo') localVideo: ElementRef;
  @ViewChild('remoteVideo') remoteVideo: ElementRef;

  constructor(
    public modalpage: MatDialog,
    public dialogRef: MatDialogRef<ModalCamaraComponent>,
    public rtcServ: WebRTCService,
    public loginServ: LoginService
  ) { }

  ngOnDestroy(): void {
    this.localStream.getTracks().forEach((track) => {
      track.stop();
    });
    this.rtcServ.stopConnection();
    for (const iterator of this.subscripciones) {
      iterator.unsubscribe();
    }
  }
  ngOnInit(): void {
    this.loginServ.nombreUsuario(this.loginServ.UsuarioLogueado.id).toPromise().then(us => {

      this.subscripciones.push(this.rtcServ.evUpdateUs.subscribe((x: RTCUserModel[]) => {
        // ACTUALIZAR LISTA DE USUARIOS
        this.usuarios = new MatTableDataSource(x.filter(y => y.username !== us));
      }));

      this.subscripciones.push(this.rtcServ.evRecibirSignal.subscribe((x: { usuario: RTCUserModel, señal: string }) => {
        // RECIBIR SEÑAL DE CONEXION
        const signal = JSON.parse(x.señal);
        // Route signal based on type
        if (signal.sdp) {
          console.log('WebRTC: processing sdp signal');
          this.connection.setRemoteDescription(new RTCSessionDescription(signal.sdp)).then(() => {
            if (this.connection.remoteDescription.type === 'offer') {
              console.log('WebRTC: received offer, sending response...');
              // _onReadyForStreamCallback(connection);
              this.connection.createAnswer().then((desc) => {
                this.connection.setLocalDescription(desc).then(() => {
                  this.rtcServ.sendSignal(JSON.stringify({ sdp: this.connection.localDescription }), x.usuario.connectionId);
                });
              }).catch((error) => { console.log('Error creating session description: ' + error); });
            } else if (this.connection.remoteDescription.type === 'answer') {
              console.log('WebRTC: received answer');
            }
          });
        } else if (signal.candidate) {
          console.log('WebRTC: processing candidate signal');
          this.connection.addIceCandidate(new RTCIceCandidate(signal.candidate));
        }
      }));

      this.subscripciones.push(this.rtcServ.evTerminarLlamada.subscribe((x: { idConexionTarget: string, mensaje: string }) => {
        // TERMINAR LLAMADA
        this.errorMsg('llamada terminada con: ' + x.idConexionTarget, x.mensaje);
        this.llamadaActual = null;
        this.connection.close();
      }));

      this.subscripciones.push(this.rtcServ.evLlamadaAceptada.subscribe((x: RTCUserModel) => {
        // LLAMADA ACEPTADA
        // segun lo otro, tengo que, crear la conexion(o buscarla de las conexiones existentes wtf),
        this.connection = this.crearConexion(x);
        // Send an offer for a connection
        this.connection.createOffer().then((desc) => {
          this.connection.setLocalDescription(desc).then(() => {
            this.rtcServ.sendSignal(JSON.stringify({ sdp: this.connection.localDescription }), x.connectionId);
          });
        }).catch((error) => { console.log('Error creating session description: ' + error); });
      }));

      this.subscripciones.push(this.rtcServ.evLlamadaDeclinada.subscribe((x: { idConexionTarget: string, mensaje: string }) => {
        // LLAMADA DECLINADA
        this.errorMsg('llamada declinada por: ' + x.idConexionTarget, x.mensaje);
        this.llamadaActual = null;
        this.connection.close();
      }));

      this.subscripciones.push(this.rtcServ.evLlamadaEntrante.subscribe((x: RTCUserModel) => {
        // LLAMADA ENTRANTE
        this.modalpage.open(ModalConfirmarComponent, {
          panelClass: 'custom-dialog-container-auto',
          data:
          {
            accionInject: 'Atender',
            mensajeInject: 'Tiene una llamada entrante de: ' + x.username + '¿Desea Atender?'
          }
        }).componentInstance.accionEvent.subscribe((result: boolean) => {
          if (result) {
            this.connection = this.crearConexion(x);
            this.rtcServ.answerCall(true, x.connectionId);
          } else {
            this.rtcServ.answerCall(false, x.connectionId);
            this.llamadaActual = null;
            this.connection.close();
          }
        });
      }));

      this.rtcServ.nombreUs = us;
      this.rtcServ.buildConnection();
      this.rtcServ.startConnection();
      navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(x => {
        this.handleSuccess(x);
        this.localStream = x;
      }).catch(e => {
        this.handleError(e);
      });
    });
  }
  llamar(item: RTCUserModel) {
    this.rtcServ.callUser(item.connectionId);
  }
  cortar() {
    this.rtcServ.hangUp();
    this.llamadaActual = null;
    this.connection.close();
  }
  crearConexion(usuario: RTCUserModel): RTCPeerConnection {
    // Create a new PeerConnection
    const connection = new RTCPeerConnection({ iceServers: [{ urls: 'stun:stun.l.google.com:19302' }] });

    connection.ontrack = (ev: RTCTrackEvent) => {
      console.log('WebRTC: adding stream');
      console.log(`Using video device: ${ev.track.label}`);
      if (!this.remoteVideo.nativeElement.srcObject) {
        this.remoteVideo.nativeElement.srcObject = new MediaStream();
      }
      (this.remoteVideo.nativeElement.srcObject as MediaStream).addTrack(ev.track);
    };
    // ICE Candidate Callback
    connection.onicecandidate = (ev: RTCPeerConnectionIceEvent) => {
      if (ev.candidate) {
        // Found a new candidate
        console.log('WebRTC: new ICE candidate');
        this.rtcServ.sendSignal(JSON.stringify({ candidate: ev.candidate }), usuario.connectionId);
      } else {
        // Null candidate means we are done collecting candidates.
        console.log('WebRTC: ICE candidate gathering complete');
      }
    };
    // despues agregar el stream,
    for (const iterator of this.localStream.getTracks()) {
      connection.addTrack(iterator);
    }
    // connection.addTransceiver('video'); // The line to be added
    this.llamadaActual = usuario;
    return connection;
  }

  handleSuccess(stream) {
    const videoTracks = stream.getVideoTracks();
    console.log(`Using video device: ${videoTracks[0].label}`);

    this.localVideo.nativeElement.srcObject = stream;
    this.localVideo.nativeElement.muted = 'muted';
  }
  maybeAddLineBreakToEnd(sdp) {
    const endWithLineBreak = new RegExp(/\n$/);
    if (!endWithLineBreak.test(sdp)) {
      return sdp + '\n';
    }
    return sdp;
  }
  handleError(error) {
    if (error.name === 'ConstraintNotSatisfiedError') {
    } else if (error.name === 'PermissionDeniedError') {
      // this.errorMsg('Permissions have not been granted to use your camera and ' +
      // 'microphone, you need to allow the page access to your devices in ' +
      // 'order for the demo to work.');
    }
    this.errorMsg(`getUserMedia error: ${error.name}`, error);
  }

  errorMsg(msg, error) {
    const errorElement = document.querySelector('#errorMsg');
    errorElement.innerHTML += `<p>${msg}</p>`;
    if (typeof error !== 'undefined') {
      console.error(error);
    }
  }
}
