import { Subject } from 'rxjs';
import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  ComponentFactoryResolver,
  ComponentRef,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
  EventEmitter
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModulosModalUpdateEnum } from 'src/app/models/shared/modulos-modal.enum';
import { TipoBuscadorAutoCompleteEnum } from 'src/app/models/shared/enums.model';
import { BuscadorAutoCompleteProps } from 'src/app/models/shared/selects.model';

@Component({
  selector: 'app-modal-update-generico',
  templateUrl: './modal-update-generico.component.html',
  styleUrls: ['./modal-update-generico.component.scss']
})
export class ModalUpdateGenericoComponent implements OnInit, OnDestroy {
  /** The view container reference for the planet template. */
  @ViewChild(TemplateRef, { read: ViewContainerRef })
  componentPlaceholder: ViewContainerRef;
  /** Unsubscribe from observable streams when the component is destroyed. */
  private unsubscribe = new Subject();

  componentRef: ComponentRef<any>;
  entidad: string;
  discription:string;
  public evCargado = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<ModalUpdateGenericoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      modulo: ModulosModalUpdateEnum,
      datoUpdate: any
    },
    private readonly resolver: ComponentFactoryResolver,
  ) { }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
  ngOnInit() {
    switch (this.data.modulo) {
      case ModulosModalUpdateEnum.PACIENTE:
        this.entidad = 'Paciente';
        import('../../paciente/paciente-abm/paciente-update/paciente-update.module')
          .then(({ PacienteUpdateModule }) => {
            const MyComponent = PacienteUpdateModule.getMyComponent();
            const factory = this.resolver.resolveComponentFactory(MyComponent);
            this.componentRef = this.componentPlaceholder.createComponent(factory);
            this.componentRef.instance.esModal = true;
            this.evCargado.emit();
            this.componentRef.instance.updateEmitter.subscribe(paciente => {
              this.dialogRef.close(paciente);
            });
          });
        break;
      case ModulosModalUpdateEnum.CAJA_CUENTA:
        this.entidad = 'Cuenta';
        import('../../caja/caja-cuentas/caja-cuenta-update/caja-cuenta-update.module')
          .then(({ CajaCuentaUpdateModule }) => {
            const MyComponent = CajaCuentaUpdateModule.getMyComponent();
            const factory = this.resolver.resolveComponentFactory(MyComponent);
            this.componentRef = this.componentPlaceholder.createComponent(factory);
            this.componentRef.instance.esModal = true;
            this.evCargado.emit();
          });
        break;
      case ModulosModalUpdateEnum.HC_EVO_NUEVA_PRESCRIPCION:
        this.entidad = 'Nueva Prescripción';
        this.discription ='Ingrese un nuevo Problema / Diagnóstico';
        import('../../historia-clinica/prescripciones/nueva-prescripcion/nueva-prescripcion.module')
          .then(({ NuevaPrescripcionModule }) => {
            const MyComponent = NuevaPrescripcionModule.getMyComponent();
            const factory = this.resolver.resolveComponentFactory(MyComponent);
            this.componentRef = this.componentPlaceholder.createComponent(factory);
            this.componentRef.instance.esModal = true;
            this.componentRef.instance.alergias = this.data.datoUpdate.alergias;
            this.componentRef.instance.procesoId = this.data.datoUpdate.procesoId;
            this.componentRef.instance.selectedDiagnostico = this.data.datoUpdate.selectedDiagnostico;
            this.componentRef.instance.propCie10 = new BuscadorAutoCompleteProps(TipoBuscadorAutoCompleteEnum.CIE10, true,{texto:this.data.datoUpdate.selectedDiagnostico.motivo} , false,"Diagnostico");
            this.evCargado.emit();
          });
        break;
      case ModulosModalUpdateEnum.HC_EVO_NUEVO_ESTUDIO:
        this.entidad = 'Nuevo Estudio Médico';
        this.discription= 'Ingrese una prestación y luego presione agregar. En caso de agregar por error, podrá eliminar una prestación.';
        import('../../historia-clinica/hc-estudios/hc-estudios-update/hc-estudios-update.module')
          .then(({ HcEstudiosUpdateModule }) => {
            const MyComponent = HcEstudiosUpdateModule.getMyComponent();
            const factory = this.resolver.resolveComponentFactory(MyComponent);
            this.componentRef = this.componentPlaceholder.createComponent(factory);
            this.componentRef.instance.esModal = true;
            this.componentRef.instance.procesoId = this.data.datoUpdate.procesoId;
            this.componentRef.instance.propCie10 = new BuscadorAutoCompleteProps(TipoBuscadorAutoCompleteEnum.CIE10, true,{texto:this.data.datoUpdate.selectedDiagnostico.motivo} , false,"Diagnostico");
            this.evCargado.emit();
          });
        break;
        case ModulosModalUpdateEnum.HC_NUEVO_Nuevo_Certificado_Tratamiento:
          if (this.data.datoUpdate.procesoId) {
            this.entidad = 'Nuevo Certificado o Tratamiento';
            this.discription= 'Seleccione el documento que desea generar. Al presionar en crear, el sistema le permitirá descargar el documento en .pdf.';
            
            import('../../historia-clinica/evolucion/hc-modal-certificado-update/hc-modal-certificado-update.module')
              .then(({ ModalCertificadoUpdateModule }) => {
                const MyComponent = ModalCertificadoUpdateModule.getMyComponent();
                const factory = this.resolver.resolveComponentFactory(MyComponent);
                const ref = this.componentPlaceholder.createComponent(factory);
                ref.instance.procesoId = this.data.datoUpdate.procesoId;
              });
          }
          break;
          case ModulosModalUpdateEnum.NUEVA_CIRUGIA:
            this.entidad = 'Nueva Cirugía';
            
          import('../../quirurgico/quirurgico-abm/cirugia-nueva/cirugia-nueva.module')
            .then(({ CirugiaNuevaModule }) => {
              const MyComponent = CirugiaNuevaModule.getMyComponent();
              const factory = this.resolver.resolveComponentFactory(MyComponent);
              const ref = this.componentPlaceholder.createComponent(factory);
              if (this.data.datoUpdate) {
              ref.instance.datosPaciente = this.data.datoUpdate;}
              if (this.data.datoUpdate.cirugiaId)
                {
                ref.instance.cirugiaId = this.data.datoUpdate.cirugiaId;
                 }                
            });
        
        break;
    }
  }

  cerrar() {
    this.dialogRef.close();
  }
  guardar() {
    (this.componentRef.instance as any).guardar();
  }
}
