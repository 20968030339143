import { TemasEnum } from 'src/app/models/shared/enums.model';
import { PrivilegiosEnum } from '../shared/privilegios.enum';

export class UsAgendaModel {
  id: number;
  nombre: string;
  especialidad: string;
  email: string;
  constructor() { }
}
export class DatosElegirSedeModel {

  datosAgenda: UsAgendaModel;

  roles: RolesUsuarioModel[];

  constructor() {
    this.datosAgenda = new UsAgendaModel();
    this.roles = new Array<RolesUsuarioModel>();
  }
}
export class SesionModel {
  id: number;
  nombre: string;
  ultimoLogueo: Date;
  token: string;
  tokenExpiracion: Date;
  sedeElegidaId: number;
  empresaElegidaId: number;
}
export class RequestHeaderModel {
  authorization: string;
  usuarioLogId: string;
  empresaLogId: string;
  sedeLogId: string;
  agendaLogId: string;
  temaLog: TemasEnum;
}
export class UsuarioLoginModel {
  id: number;
  foto: string;
  fotoDatoBase64: string;
  multipleSedes: boolean;
  primerUsuario: boolean;
  passInicial: boolean;

  datosAgenda: UsAgendaModel;
  sesion: SesionModel;

  roles: RolesUsuarioModel[];

  constructor() {
    this.sesion = new SesionModel();
    this.roles = new Array<RolesUsuarioModel>();
    this.datosAgenda = new UsAgendaModel();
  }
}
export class RolesUsuarioModel {
  id: number;
  esAdmin: boolean;
  nombre: string;
  privilegios: PrivilegiosEnum[];
  constructor() {
    this.privilegios = new Array<PrivilegiosEnum>();
  }
}
export class EmpresasUsuarioModel {
  id: number;
  nombre: string;
  foto: string;
  fotoDatoBase64: string;
  sedes: SedesUsuarioModel[];
  constructor() {
    this.sedes = new Array<SedesUsuarioModel>();
  }
}
export class SedesUsuarioModel {
  id: number;
  nombre: string;
  empresaId: number;
  direccion: string;
  telefono: string;
  constructor() { }
}
export class UsuarioCambioPassModel {
  id: number;
  pass: string;
}
export class UsuarioRecuperaPassModel {
  id: string;
  pass: string;
}
