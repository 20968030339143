export class shifterTurno{

    consultorioName:string;
    dni:string;

constructor()
{
}
}
export class SelectObjModel {
    
    id: number|null;
    nombre: string;

    constructor()
    {
        this.id = null;
        this.nombre='';
    }
}

export class SedeSelectModel {
    id: number;
    nombre: string;
    calle: string;
    numero: number;
    piso: string;
    depto: string;
    ciudad: string;
}
export class EmpresaSelectModel {    
    id: number|null;
    nombre: string;
    cobroPacientes:boolean;
    constructor()
    {
        this.id = null;
        this.nombre='';
        this.cobroPacientes = false;
    }
}