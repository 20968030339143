import { DatosModalOrdenAtencionModel } from './../../../models/facturacion/orden-atencion.model';
import { CtaCteDatosInicialModel } from 'src/app/models/cta-cte/cta-cte.model';
import { DatosHCModel } from 'src/app/models/historia-clinica/historia-clinica.model';
import { PacienteRecordatoriosModel } from 'src/app/models/paciente/paciente-recordatorio.model';
import { Component, OnInit, ComponentFactoryResolver, OnDestroy, ViewChild, TemplateRef, ViewContainerRef, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModulosModalEnum } from 'src/app/models/shared/modulos-modal.enum';
import { DatosPersonaHeaderModel, PersonaModel } from 'src/app/models/shared/persona.model';
import { SelectObjModel } from 'src/app/models/shared/selects.model';

@Component({
  selector: 'app-modal-modulo',
  templateUrl: './modal-modulo.component.html',
  styleUrls: ['./modal-modulo.component.scss']
})
export class ModalModuloComponent implements OnInit, OnDestroy {
  /** The view container reference for the planet template. */
  @ViewChild(TemplateRef, { read: ViewContainerRef })
  componentPlaceholder: ViewContainerRef;

  /** Unsubscribe from observable streams when the component is destroyed. */
  private unsubscribe = new Subject();

  constructor(
    private readonly resolver: ComponentFactoryResolver,
    @Inject(MAT_DIALOG_DATA) public data: {
      modulo: ModulosModalEnum,
      recordatorios: PacienteRecordatoriosModel,
      datosHC: DatosHCModel,
      datosCta: CtaCteDatosInicialModel,
      datosOrden: DatosModalOrdenAtencionModel,
      datosPaciente: SelectObjModel,
      cirugiaId: number ,
      ProcesoId: number,
      evolucionId: number,
      comentario: string
    }
  ) { }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit(): void {
    switch (this.data.modulo) {
      case ModulosModalEnum.ORDEN_ATENCION:
        if (this.data.datosOrden) {
          import('../../facturacion/orden-atencion/modal-orden-atencion/modal-orden-atencion.module')
            .then(({ ModalOrdenAtencionModule }) => {
              const MyComponent = ModalOrdenAtencionModule.getMyComponent();
              const factory = this.resolver.resolveComponentFactory(MyComponent);
              const ref = this.componentPlaceholder.createComponent(factory);
              ref.instance.datosOrden = this.data.datosOrden;
            });
        }
        break;
      case ModulosModalEnum.RECORDATORIOS:
        if (this.data.recordatorios) {
          import('../../paciente/modal-recordatorio/modal-recordatorio.module').then(({ ModalRecordatorioModule }) => {
            const MyComponent = ModalRecordatorioModule.getMyComponent();
            const factory = this.resolver.resolveComponentFactory(MyComponent);
            const ref = this.componentPlaceholder.createComponent(factory);
            ref.instance.recordatorios = this.data.recordatorios;
          });
        }
        break;
      case ModulosModalEnum.HISTORIA_CLINICA:
        if (this.data.datosHC) {
          import('../../historia-clinica/hc-container/hc-container.module')
            .then(({ HcContainerModule }) => {
              const MyComponent = HcContainerModule.getMyComponent();
              const factory = this.resolver.resolveComponentFactory(MyComponent);
              const ref = this.componentPlaceholder.createComponent(factory);
              ref.instance.datosHC = this.data.datosHC;
            });
        }
        break;
      case ModulosModalEnum.CTA_CTE:
        if (this.data.datosCta) {
          import('../../cta-cte/cta-cte-container/cta-cte-container.module')
            .then(({ CtaCteContainerModule }) => {
              const MyComponent = CtaCteContainerModule.getMyComponent();
              const factory = this.resolver.resolveComponentFactory(MyComponent);
              const ref = this.componentPlaceholder.createComponent(factory);
              ref.instance.datosCta = this.data.datosCta;
            });
        }
        break;
      case ModulosModalEnum.HC_COMENTARIOS:
          if (this.data.evolucionId) {
            import('../../historia-clinica/evolucion/hc-modal-comentario-new/hc-modal-comentario-new.module')
              .then(({ HcModalComentarioNewModule }) => {
                const MyComponent = HcModalComentarioNewModule.getMyComponent();
                const factory = this.resolver.resolveComponentFactory(MyComponent);
                const ref = this.componentPlaceholder.createComponent(factory);
                ref.instance.evolucionId = this.data.evolucionId;
                ref.instance.comentario = this.data.comentario;
              });
          }
         break;
      case ModulosModalEnum.HC_EVO_NUEVO_CERTIFICADO:
        if (this.data.ProcesoId) {
          import('../../historia-clinica/evolucion/hc-modal-certificado-update/hc-modal-certificado-update.module')
            .then(({ ModalCertificadoUpdateModule }) => {
              const MyComponent = ModalCertificadoUpdateModule.getMyComponent();
              const factory = this.resolver.resolveComponentFactory(MyComponent);
              const ref = this.componentPlaceholder.createComponent(factory);
              ref.instance.procesoId = this.data.ProcesoId;
            });
        }
        break;
      case ModulosModalEnum.HC_TRATAMIENTO:
        if (this.data.ProcesoId) {
          import('../../historia-clinica/evolucion/hc-modal-tratamiento-new/hc-modal-tratamiento-new.module')
            .then(({ HcModalTratamientoNewModule }) => {
              const MyComponent = HcModalTratamientoNewModule.getMyComponent();
              const factory = this.resolver.resolveComponentFactory(MyComponent);
              const ref = this.componentPlaceholder.createComponent(factory);
              ref.instance.procesoId = this.data.ProcesoId;
            });
        }
        break;
      case ModulosModalEnum.NUEVA_CIRUGIA:
       
        
          import('../../quirurgico/quirurgico-abm/cirugia-nueva/cirugia-nueva.module')
            .then(({ CirugiaNuevaModule }) => {
              const MyComponent = CirugiaNuevaModule.getMyComponent();
              const factory = this.resolver.resolveComponentFactory(MyComponent);
              const ref = this.componentPlaceholder.createComponent(factory);
              if (this.data.datosPaciente) {
              ref.instance.datosPaciente = this.data.datosPaciente;}
              if (this.data.cirugiaId)
                {
                ref.instance.cirugiaId = this.data.cirugiaId;
                 }                
            });
        
        break;
    }
  }
}
