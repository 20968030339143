import { environment } from 'src/environments/environment';

export class RecetaApiConfig {
  // Profesional
  public static VALIDAR_PROFESIONAL_URL = environment.apiUrl + 'Profesional/ValidarProfesional'; 
  public static VALIDAR_PROFESIONAL_AGENDA_URL = environment.apiUrl + 'Profesional/ValidarProfesionalAgenda'; 
  
  // Diagnostico
  public static CONSULTAR_DIAGNOSTICO_PROBLEMA_URL = environment.apiUrl + 'Diagnostico/ConsultarDiagnosticoProblema'; 
  public static CONSULTAR_DIAGNOSTICO_PRACTICA_URL = environment.apiUrl + 'Diagnostico/ConsultarDiagnosticoPractica'; 
  public static CONSULTAR_DIAGNOSTICO_PROCEDIMIENTO_URL = environment.apiUrl + 'Diagnostico/ConsultarDiagnosticoProcedimiento'; 

  // Medicamento
  public static CONSULTAR_MEDICAMENTO_URL = environment.apiUrl + 'Medicamento/ConsultarMedicamento'; 
  public static CONSULTAR_MEDICAMENTO_CON_MARCA_URL = environment.apiUrl + 'Medicamento/ConsultarMedicamentoConMarca'; 
  public static CONSULTAR_COMPO_GENERICA_URL = environment.apiUrl + 'Medicamento/ConsultarCompoGenerica'; 
  public static CONSULTAR_COMPO_MARCA_URL = environment.apiUrl + 'Medicamento/ConsultarCompoMarca'; 

  //PreserfarService
  public static BUSCAR_PRODUCTO_URL = environment.apiUrl + 'Preserfar/BuscarProducto'; 
}