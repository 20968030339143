import { PacienteService } from 'src/app/services/paciente/paciente.service';
import { ABMPacienteListModel } from 'src/app/models/paciente/abm-paciente.model';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-paciente-existe-dni',
  templateUrl: './modal-paciente-existe-dni.component.html',
  styleUrls: ['./modal-paciente-existe-dni.component.scss']
})
export class ModalPacienteExisteDniComponent implements OnInit {
  displayedColumns: string[] = ['nombre', 'apellido', 'email', 'buttons'];

  constructor(
    private pacienteServ: PacienteService,
    public dialogRef: MatDialogRef<ModalPacienteExisteDniComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      pacientes: ABMPacienteListModel[],
      auth: boolean
    }
  ) { }

  ngOnInit(): void {
    if (this.data.auth === null || this.data.auth === undefined) {
      this.data.auth = true;
    }
  }
  elegir(item: ABMPacienteListModel) {
    if (this.data.auth) {
      this.pacienteServ.uno(item.id).toPromise().then(result => {
        this.dialogRef.close(result);
      });
    } else {
      this.pacienteServ.unoSinAuth(item.id).toPromise().then(result => {
        this.dialogRef.close(result);
      });
    }
  }
}
