<div (document:keydown.enter)="elegirPrimero()" class="cont-principal-login" fxLayout="column nowrap" fxLayoutAlign="center center">
  <div *ngIf="!empresaElegida" class="window" fxLayout="row wrap" fxLayoutAlign="start stretch">
    <div style="width: 500px;" class="logo-login" >
      <img class="img1" src="../../../../assets/images/BG2x.png">
      <img class="img2" src="../../../../assets/images/Logopanacea.png">
    </div>
    <div  fxLayout="column"  fxLayoutAlign="start center" style="width: 300px; padding-top: 110px;" fxLayoutGap="20px">
      <div>
        <span style="color: #6823FF; font-size: 12pt;  padding-top: 3px; font-weight: bold;">Seleccione clinica o consultorio</span>
      </div>
      <div *ngFor="let item of data.empresas  let i= index"  (click)="elegirEmpresa(item)" fxLayout="column" fxLayoutAlign="center stretch" class="cont-empresa" (mouseover)="cambiarSeleccion(i)" (mouseout)="cambiarSalida(i)" >
        <div fxLayout="column" fxLayoutAlign="center center">
          <div fxLayout="row" fxLayoutAlign="center center" >
            <img
              [src]="item.fotoDatoBase64 ===null? '../../../../assets/images/empresas1.jpg' : item.fotoDatoBase64+item.foto"
               width="50px" height="50px" style="background-color: transparent; margin-right: 5px; border-radius: 25px;" />
            <label class="empresa-sede-elegida">{{item.nombre}}</label>
          </div>
          <hr *ngIf="barravisible == i"  style="color: #6823FF; width: 147px; margin: 0px; position: relative; top: 7px;">
        </div>
    </div>
  </div>
</div>


  <!-- -----SELECCION DE SUCURSAL----  -->
  <div *ngIf="empresaElegida" class="window2" fxLayout="row wrap" fxLayoutAlign="start stretch">
    <div style="width: 500px;" class="logo-login" >
      <img class="img1" src="../../../../assets/images/BG2x.png">
      <img class="img2" src="../../../../assets/images/Logopanacea.png">
    </div>
    <div fxLayout="column" fxLayoutAlign="start center" style="width: 300px; padding-top: 110px;">
      <div fxLayout="row" class="fondo-blanco" fxLayoutAlign="center start">
          <button mat-button tabindex="-1" class="flecha" fxLayout=" column" fxLayoutAlign="center center">
            <mat-icon (click)="empresaElegida=null;">arrow_back</mat-icon>
          </button>
          <span style="color: #6823FF; font-size: 12pt; margin-left: 20px; padding-top: 3px; font-weight: bold;">Seleccione sucursal</span>
      </div>
      <div fxLayout="column" class="cont-sede" fxLayoutAlign="start center">
        <div fxLayout="row" fxLayoutAlign="center center">
            <img
              [src]="empresaElegida.fotoDatoBase64 ===null? '../../../../assets/images/empresas1.jpg' : empresaElegida.fotoDatoBase64+empresaElegida.foto"
              alt="logoEmpresa" width="50px" height="50px" style="background-color: transparent; margin-right: 20px; border-radius: 25px;" />
            <label class="empresa-sede-elegida">{{empresaElegida.nombre}}</label>
        </div>
        <div class="cont-sedes-from" *ngFor="let item of empresaElegida.sedes" (click)="elegirSede(item)"
          fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
          <div class="cont-datos" fxLayout="row" fxLayoutAlign="center stretch">
            <div style="width: 50%;" fxLayoutAlign="center center">
              <label class="nombre-sede">{{item.nombre}}</label>
            </div>
            <div style="width: 50%;  border-left: solid white 1px;" fxLayoutAlign="start center">
              <label class="datos-sede">{{item.direccion}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
