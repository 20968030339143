<mat-card class="tarjeta-Modal" style="width: 310px ;">
  <mat-card-header class="header-gris" fxLayout="row" fxLayoutAlign="space-between start">
    <mat-card-title>{{titulo}}</mat-card-title>
    <button mat-button (click)="onCancel()">
      <img class="rotado" src="\assets\images\icons\nuevo.svg" alt="Cerrar">
    </button>
  </mat-card-header>
  <mat-card-content fxLayout="column" [formGroup]="recupForm">
    <div fxLayout="row" fxLayoutAlign="center">
      <img id="logo-medio" src="assets/images/svg/logo.svg" alt="Logo" />
    </div>
    <br>
    <span *ngIf="titulo==='Cambio de Constraseña'">Debe modificar la contraseña inicial</span>
    <br>
    <span style="margin-left:20px">Escriba su nueva contraseña</span>
    <mat-form-field style="width: 250px;margin-left: 20px;">
      <input matInput #foco name="bangagalo" placeholder="Nueva Contraseña" style="font-size: large;"
        [type]="hidenueva ? 'password' : 'text'" formControlName="nuevoPass" autocomplete="new-password" required>
      <mat-icon matSuffix (click)="hidenueva = !hidenueva">{{hidenueva ? 'visibility_off' : 'visibility'}}</mat-icon>
      <mat-error
        *ngIf="recupForm.get('nuevoPass').invalid && (recupForm.get('nuevoPass').dirty ||recupForm.get('nuevoPass').touched)">
        <small *ngIf="recupForm.get('nuevoPass').hasError('required')">
          Debe ingresar una contraseña
        </small>
        <small *ngIf="recupForm.get('nuevoPass').hasError('maxlength')">
          La contraseña no debe superar 50 caracteres
        </small>
        <small *ngIf="recupForm.get('nuevoPass').hasError('minlength')">
          La contraseña debe tener 6 o más caracteres
        </small>
      </mat-error>
    </mat-form-field>
    <mat-form-field style="width: 250px;margin-left: 20px;">
      <input matInput name="atomico" placeholder="Confirmar Contraseña" style="font-size: large;"
        [type]="hiderepetir ? 'password' : 'text'" formControlName="confirmarPass" autocomplete="new-password" required>
      <mat-icon matSuffix (click)="hiderepetir = !hiderepetir">{{hiderepetir ? 'visibility_off' : 'visibility'}}
      </mat-icon>
    </mat-form-field>
    <div fxLayout="row" fxLayoutAlign="end" style="width: 290px;">
      <button mat-raised-button class="btn-guardar" (click)="confirmar()">CONFIRMAR</button>
    </div>
  </mat-card-content>
</mat-card>