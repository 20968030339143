import { cobrosModel, OrdenRelacionCobro } from './../../models/cta-cte/cta-cte.model';
import { PagarePrintModel } from './../../models/cta-cte/cta-cte-pagare.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  CtaCteDetalleModel,
  CtaCteDatosInicialModel
} from 'src/app/models/cta-cte/cta-cte.model';
import { map } from 'rxjs/operators';
import { CobranzaNuevaCtaCteModel, CobranzaOrdenesCtaCteModel } from 'src/app/models/cta-cte/cta-cte-cobranza.model';
import { DepositoGarantiaModel } from 'src/app/models/cta-cte/cta-cte-deposito-garantia.model';
import { CtaCteComprobanteListModel, CtaCteComprobanteFiltrosModel } from 'src/app/models/cta-cte/cta-cte-comprobantes.model';
import { CtaCtePagareListModel, CtaCtePagareNuevoModel } from 'src/app/models/cta-cte/cta-cte-pagare.model';
import { SelectObjModel } from 'src/app/models/shared/selects.model';
import { APIReturnModel } from 'src/app/models/shared/api-return.model';
import { HttpHelperService } from '../helpers/http-helper.service';
import { CtaCteApiConfig } from './cta-cte-api-config';
import { ABMGenericoConsultaModel } from 'src/app/models/shared/abm-generico.model';

@Injectable({
  providedIn: 'root'
})
export class CtaCteService {
  public pacienteUs: SelectObjModel;
  constructor(
    private http: HttpClient,
    private HttpHelpers: HttpHelperService
  ) { }

  public detalles(datosBusqueda: ABMGenericoConsultaModel): Observable<CtaCteDatosInicialModel> {
    const pacienteUsId = this.pacienteUs.id;
    return this.http.get<APIReturnModel<CtaCteDatosInicialModel>>(CtaCteApiConfig.CTACTE_DETALLES_URL,
      this.HttpHelpers.buildQueryParams({ pacienteUsId, datosBusqueda }))
      .pipe(map(result => result.datos));
  }
  public cobroCuenta(datosCobro: CobranzaNuevaCtaCteModel): Observable<CtaCteDetalleModel> {
    return this.http.post<APIReturnModel<CtaCteDetalleModel>>(CtaCteApiConfig.CTACTE_COBRO_CUENTA_URL, datosCobro)
      .pipe(map(result => result.datos));
  }
  public cobroOrdenes(datosCobro: CobranzaOrdenesCtaCteModel): Observable<CtaCteDetalleModel> {
    return this.http.post<APIReturnModel<CtaCteDetalleModel>>(CtaCteApiConfig.CTACTE_COBRO_ORDENES_URL, datosCobro)
      .pipe(map(result => result.datos));
  }
  public depositosGarantia(): Observable<CtaCteDetalleModel[]> {
    return this.http.get<APIReturnModel<CtaCteDetalleModel[]>>(CtaCteApiConfig.CTACTE_DEPOSITO_GARANTIA_URL,
      this.HttpHelpers.buildQueryParams(this.pacienteUs.id, 'pacienteUsId'))
      .pipe(map(result => result.datos));
  }
  public devolverDeposito(ctaDevolucion: DepositoGarantiaModel): Observable<CtaCteDetalleModel[]> {
    return this.http.post<APIReturnModel<CtaCteDetalleModel[]>>(CtaCteApiConfig.CTACTE_DEVOLVER_DEPOSITO_URL, ctaDevolucion)
      .pipe(map(result => result.datos));
  }
  public comprobantesList(filtros: CtaCteComprobanteFiltrosModel): Observable<CtaCteComprobanteListModel[]> {
    const pacienteUsId = this.pacienteUs.id;
    return this.http.get<APIReturnModel<CtaCteComprobanteListModel[]>>(CtaCteApiConfig.CTACTE_COMPROBANTES_LIST_URL,
      this.HttpHelpers.buildQueryParams({ filtros, pacienteUsId }))
      .pipe(map(result => result.datos));
  }
  public comprobantePrint(comprobanteId: number): Observable<CtaCteComprobanteListModel[]> {
    return this.http.get<APIReturnModel<CtaCteComprobanteListModel[]>>(CtaCteApiConfig.CTACTE_COMPROBANTE_PRINT_URL,
      this.HttpHelpers.buildQueryParams(comprobanteId, 'comprobanteId'))
      .pipe(map(result => result.datos));
  }
  public pagares(): Observable<CtaCtePagareListModel[]> {
    return this.http.get<APIReturnModel<CtaCtePagareListModel[]>>(CtaCteApiConfig.CTACTE_PAGARES_URL,
      this.HttpHelpers.buildQueryParams(this.pacienteUs.id, 'pacienteUsId'))
      .pipe(map(result => result.datos));
  }
  public pagareNuevo(pagareNuevo: CtaCtePagareNuevoModel): Observable<CtaCtePagareListModel> {
    return this.http.post<APIReturnModel<CtaCtePagareListModel>>(CtaCteApiConfig.CTACTE_PAGARE_NUEVO_URL, pagareNuevo)
      .pipe(map(result => result.datos));
  }
  public pagareUno(pagareId: number): Observable<PagarePrintModel> {
    return this.http.get<APIReturnModel<PagarePrintModel>>(CtaCteApiConfig.CTACTE_PAGARE_UNO_URL,
      this.HttpHelpers.buildQueryParams(pagareId, 'pagareId'))
      .pipe(map(result => result.datos));
  }
  public pagareCancelar(pagareId: number): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(CtaCteApiConfig.CTACTE_PAGARE_CANCELAR_URL,
      pagareId)
      .pipe(map(result => result.datos));
  }
  public pagareEjecutar(pagareId: number): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(CtaCteApiConfig.CTACTE_PAGARE_EJECUTAR_URL,
      pagareId)
      .pipe(map(result => result.datos));
  }
  public listarCobroACuenta(pacienteId: number): Observable<cobrosModel[]> {
    return this.http.get<APIReturnModel<cobrosModel[]>>(CtaCteApiConfig.CTACTE_LISTAR_COBRO_A_CUENTA_URL,
      this.HttpHelpers.buildQueryParams(pacienteId, 'PacienteId'))
      .pipe(map(result => result.datos));
  }
  public relacionarCobroOrden(datos: OrdenRelacionCobro): Observable<boolean> {
    return this.http.post<APIReturnModel<boolean>>(CtaCteApiConfig.CTACTE_RELACIONAR_COBRO_ORDEN_URL, datos)
      .pipe(map(result => result.datos));
  }
}
