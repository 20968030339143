import { Injectable } from '@angular/core';
// import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateHelperService {

  constructor() { }

  public hoyDesdeHsHoras(tiempo: string): Date {
    const fecha = new Date();
    fecha.setHours(parseInt(tiempo.substr(0, 2), 10));
    fecha.setMinutes(parseInt(tiempo.substr(3, 5), 10));
    fecha.setSeconds(parseInt(tiempo.substr(6, 8), 10));
    fecha.setMilliseconds(0);
    return fecha;
  }

  // public hoyHoras(fecha: Date): string {
  //   return fecha.toLocaleTimeString('es-AR',{hour:'2-digit',minute:'2-digit',second:'2-digit'});
  // }

  public fechaSinHoras(fecha: Date): Date {
    const fechaSinHs = new Date(fecha.getTime());
    fechaSinHs.setHours(0);
    fechaSinHs.setMinutes(0);
    fechaSinHs.setSeconds(0);
    fechaSinHs.setMilliseconds(0);
    return fechaSinHs;
  }

  public fechaMaxHoras(fecha: Date): Date {
    const fechaSinHs = new Date(fecha.getTime());
    fechaSinHs.setHours(23);
    fechaSinHs.setMinutes(59);
    fechaSinHs.setSeconds(59);
    fechaSinHs.setMilliseconds(999);
    return fechaSinHs;
  }

  public mesAnteriorFecha(fecha: Date): Date {
    const mesAnterior = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
    mesAnterior.setMonth(fecha.getMonth() - 1);
    return mesAnterior;
  }

  public mesSiguienteFecha(fecha: Date): Date {
    const mesSiguiente = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
    mesSiguiente.setMonth(fecha.getMonth() + 1);
    return mesSiguiente;
  }

  public edad(cumpleaños: Date) {
    return Math.floor((new Date().getTime() - cumpleaños.getTime()) / 31536000000);
  }

  public dentroPeriodo(periodo1Inicio, periodo1Fin, periodo2Inicio, periodo2Fin): boolean {
    // const periodo1Inicio = moment.isMoment(periodo1Inicionicio) ? periodo1Inicionicio.toDate() : periodo1Inicionicio;
    // const periodo1Fin = moment.isMoment(periodo1Fin) ? periodo1Fin.toDate() : periodo1Fin;
    // const periodo2Inicio = moment.isMoment(periodo2Inicio) ? periodo2Inicio.toDate() : periodo2Inicio;
    // const periodo2Fin = moment.isMoment(periodo2Fin) ? periodo2Fin.toDate() : periodo2Fin;
    if ((periodo1Fin >= periodo2Inicio && periodo1Inicio <= periodo2Inicio)
      || (periodo1Inicio <= periodo2Fin && periodo1Fin >= periodo2Fin)
      || (periodo1Inicio >= periodo2Inicio && periodo1Fin <= periodo2Fin)) {
      return true;
    } else {
      return false;
    }
  }

  public calculateAge(fecha: Date) {
    const timeDiff = Math.abs(Date.now() - new Date(fecha).getTime());
    return Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
  }

  public corregirFecha(fecha: any): Date {
    if (!fecha) {
      return null;
    }
    // if (!moment.isMoment(fecha) && fecha.toString().indexOf('_') > -1) {
    //   fecha = fecha.toString().replace('_', '');
    //   fecha = moment(fecha).toDate();
    // } else if (moment.isMoment(fecha)) {
    //   fecha = fecha.toDate();
    // }
    return fecha;
  }

  public corregirHora(Hora: string): string {
    if (Hora.indexOf('_') > -1) {
      Hora = Hora.replace('_', '');
    }
    if (Hora.length === 3) {
      Hora = '0' + Hora;
    }
    return Hora;
  }

  public corregirHoraTime(Hora: string): number {
    if (!Hora) {
      return null;
    }
    if (Hora.indexOf('_') > -1) {
      Hora = Hora.replace('_', '');
    }
    if (Hora.length === 3) {
      Hora = '0' + Hora;
    }
    const tempTime = Hora.split(':').map(x => parseInt(x, 10));
    const fecha = new Date();
    fecha.setHours(tempTime[0]);
    fecha.setMinutes(tempTime[1]);
    fecha.setSeconds(0);
    return fecha.getTime();
  }

  public compararFechaSinHora(fecha1: Date, fecha2: Date) {
    fecha1.setHours(0, 0, 0, 0);
    fecha2.setHours(0, 0, 0, 0);
    return fecha1.getTime() !== fecha2.getTime();
  }

  public FechaMenorHoy(fecha: Date) {
    fecha.setHours(0, 0, 0, 0);
    const Hoy = new Date().setHours(0, 0, 0, 0);
    return fecha.getTime() < Hoy;
  }

  public FechaMenorIgualHoy(fecha: Date) {
    fecha.setHours(0, 0, 0, 0);
    const Hoy = new Date().setHours(0, 0, 0, 0);
    return fecha.getTime() <= Hoy;
  }

  public FechaMayorIgual(fecha: Date) {
    fecha.setHours(0, 0, 0, 0);
    const Hoy = new Date().setHours(0, 0, 0, 0);
    return fecha.getTime() > Hoy;
  }

  public MesesEntreFechas(fecha1: Date, fecha2: Date) {
    const diffTime = Math.abs(fecha1.getTime() - fecha2.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  public DiasEnMes(fecha: Date): number[] {
    return Array(32 - new Date(fecha.getFullYear(), fecha.getMonth(), 32).getDate()).fill(0).map((i, idx) => idx + 1);
  }

  public DiasEnMesFecha(fecha: Date): Date[] {
    return Array(new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0).getDate()).fill(0)
      .map((i, idx) => new Date(fecha.getFullYear(), fecha.getMonth(), idx + 1));
  }

  public fechaParaServer(fecha: Date): string {
    const tzo = -fecha.getTimezoneOffset();
    const dif = tzo >= 0 ? '+' : '-';
    const pad = (num) => {
      const norm = Math.floor(Math.abs(num));
      return (norm < 10 ? '0' : '') + norm;
    };
    return fecha.getFullYear() +
      '-' + pad(fecha.getMonth() + 1) +
      '-' + pad(fecha.getDate()) +
      'T' + pad(fecha.getHours()) +
      ':' + pad(fecha.getMinutes()) +
      ':' + pad(fecha.getSeconds()) +
      dif + pad(tzo / 60) +
      ':' + pad(tzo % 60);
  }
}
