import { LoginService } from 'src/app/services/login/login.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivateChild {
  constructor(private router: Router, private loginServ: LoginService) { }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const result = this.loginServ.checkearPrivilegioRuta(state.url);
    if (result === false) {
      const tree: UrlTree = this.router.parseUrl('principal');
      return tree;
      // this.router.navigate(['/principal'], { skipLocationChange: true });
    } else {
      return true;
    }
  }
}
