<mat-card fxflex="100%" class="tarjeta-Modal" >
  <mat-card-header fxLayout="row" fxLayoutAlign="space-between start">
    <mat-card-title> Elegir un paciente </mat-card-title>
   <button  mat-raised-button class="btn-guardar" (click)="dialogRef.close()" tabindex="-1">NUEVO</button>

  </mat-card-header>
  <div class="contenedor-principal">
  <mat-card-content fxLayout="row wrap" >
    <span>Estos pacientes tiene el mismo documento que el ingresado: </span>
    <table mat-table fxFlex="100%" [dataSource]="data.pacientes" class="mat-elevation-z8" >
      <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef fxFlex="25%"> Nombre </th>
        <td mat-cell *matCellDef="let item" fxFlex="25%" class="truncate"> {{item.nombre}} </td>
      </ng-container>
      <ng-container matColumnDef="apellido">
        <th mat-header-cell *matHeaderCellDef fxFlex="25%"> Apellido </th>
        <td mat-cell *matCellDef="let item" fxFlex="25%" class="truncate"> {{item.apellido}} </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef fxFlex="35%"> E-Mail </th>
        <td mat-cell *matCellDef="let item" fxFlex="35%" class="truncate"> {{ item.email }} </td>
      </ng-container>
      <ng-container matColumnDef="buttons">
        <th mat-header-cell *matHeaderCellDef class="workWiths" fxFlex="15%"></th>
        <td mat-cell *matCellDef="let item" class="workWiths" fxFlex="15%">
          <button mat-button class="ABM-modificar" (click)="elegir(item)">ELEGIR</button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    </mat-card-content>
</div>
</mat-card>

