import { MatButtonModule } from '@angular/material/button';
import { AccionesTableComponent } from './acciones-table.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { ModalConfirmarModule } from '../modal-confirmar/modal-confirmar.module';


@NgModule({
  declarations: [AccionesTableComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    ModalConfirmarModule
  ],
  exports: [AccionesTableComponent]
})
export class AccionesTableModule { }
